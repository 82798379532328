import Process from './process.js'
class Triangle extends Process {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.setWidth(width).setHeight(height)
  }
  initial(sx, sy, ox, oy) { // 初始化画布
    return this.init(sx, sy, ox, oy)._horizontal()._vertical()
  }
  process() { // 绘制三角形
    const { ctx, _left, _top, _right, _bottom, height, width, arcRadius } = this
    console.log(arcRadius);
    const start1 = (_top + _bottom) / 2;
    ctx.beginPath();
    ctx.moveTo(_left,start1,);
    ctx.arcTo(_left,_top,this._right,_bottom,1)
    ctx.arcTo(this._right,_bottom,_left,_bottom,1)
    ctx.arcTo(_left,_bottom,_left,_top,1)
    ctx.lineTo(_left,start1);
  }
}
export default Triangle
class Collision {
  static rightBorder(x1,y1,top,bottom,right,fit=10){
    return y1>=top && y1<=bottom && x1>=right-fit && x1<=right
  }
  static arc (x1, y1, r1, x2, y2, r2) {
    return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2)) <= r1 + r2;
  }
  static rect (x1, y1, x2, y2, x3, y3, x4, y4) {
    return x1 <= x4 && y1 <= y4 && x3 <= x2 && y3 <= y2;
  }
  static getRectIntersection(shape1, shape2) { // 取两个矩形的交集
    const left = Math.max(shape1._left, shape2._left)
    const top = Math.max(shape1._top, shape2._top)
    const right = Math.min(shape1._right, shape2._right)
    const bottom = Math.min(shape1._bottom, shape2._bottom)
    const width = right - left
    const height = bottom - top
    const hasInter = width > 0 && height > 0 // 是否有交集
    return hasInter ? {left, right, top, bottom, width, height, hasInter} : false
  }
  static clipRectfromRect(rect1, rect2) { // 从rect2 中裁掉rect1部分 适用于rect1在rect2边角的部分
    const {left, right, top, bottom, width, height} = rect1
    const fragments = []
    let temp1, temp2
    temp1 = left === rect2.left ? right : left
    temp2 = top === rect2.top ? bottom : rect2.top
    const rt1 = {x: temp1, y:top, width: rect2.width - width, height: rect2.height}
    const rt2 = {x: left, y: temp2, width, height: rect2.height - height}
    ;[rt1, rt2].forEach(rt => {
      rt.width > 0 && rt.height > 0 && fragments.push(rt)
    })
    return fragments.length ? fragments : void 0
  }
  static irregularImage (shape, transform, shape1, shape2) { // 不规则图形的碰撞检测
    // 重叠区域
    let isHasIs = false
    const intersection = Collision.getRectIntersection(shape1, shape2)
    if (!intersection) return isHasIs
    const { left, right, top, bottom } = intersection
    const center = [(left + right) / 2, (top + bottom) / 2]
    const a = [[left, top], [right, top], [left, bottom], [right, bottom], center]
    let p
    for(let i = 0; i < a.length; i++) {
      p = transform.getMousePoint(a[i][0], a[i][1]);
      if (shape.isPointInPath(p.x, p.y)) { isHasIs = true; break }
    }
    return isHasIs
  }

  static arcRect (x, y, r, x1, y1, minX, minY, ox, oy, cos, sin) {
    var t;
    return (
      11 === arguments.length &&
        ((t = (ox - x) * sin + (y - oy) * cos + oy),
        (x = (x - ox) * cos + (y - oy) * sin + ox),
        (y = t)),
      (minX = x < x1 ? x1 : minX < x ? minX : x),
      (minY = y < y1 ? y1 : minY < y ? minY : y),
      Math.sqrt(Math.pow(minX - x, 2) + Math.pow(minY - y, 2)) <= r
    );
  }
  static light (x, y, a2, ix, x1, y1, x2, b2) {
    if ((x - x1) * (y - b2) < (y - y1) * (x - x2)) return !1;
    var a1 = ix - y,
      ix = x - a2,
      a2 = b2 - y1,
      b2 = x1 - x2,
      ix =
        ((a1 * x + ix * y) * b2 - (a2 * x1 + b2 * y1) * ix) /
        (a1 * b2 - a2 * ix);
    return x1 <= ix && ix <= x2;
  }
  static sepAxis (points1, points2) {
    for (var tx, ty, i = 0; i < points1.length; i += 2)
      if (
        ((tx = points1[i + 2]),
        (ty = points1[i + 3]),
        i + 2 === points1.length && ((tx = points1[0]), (ty = points1[1])),
        projection(points1, points2, ty - points1[i + 1], points1[i] - tx))
      )
        return !1;
    for (i = 0; i < points2.length; i += 2)
      if (
        ((tx = points2[i + 2]),
        (ty = points2[i + 3]),
        i + 2 === points2.length && ((tx = points2[0]), (ty = points2[1])),
        projection(points1, points2, ty - points2[i + 1], points2[i] - tx))
      )
        return !1;
    return !0;

    function projection(points1, points2, axisX, axisY) {
      for (
        var min1, max1, min2, max2, prod, i = 0;
        i < points1.length;
        i += 2
      )
        (prod = points1[i] * axisX + points1[i + 1] * axisY),
          isNaN(min1)
            ? (min1 = max1 = prod)
            : prod < min1
            ? (min1 = prod)
            : max1 < prod && (max1 = prod);
      for (i = 0; i < points2.length; i += 2)
        (prod = points2[i] * axisX + points2[i + 1] * axisY),
          isNaN(min2)
            ? (min2 = max2 = prod)
            : prod < min2
            ? (min2 = prod)
            : max2 < prod && (max2 = prod);
      return max1 < min2 || max2 < min1;
    }
  }
  static sepAxisArc (points, x, y, r) {
    var tx, ty, minX, minY;
    for (let i = 0; i < points.length; i += 2) {
      tx = points[i + 2]
      ty = points[i + 3]
      if (i + 2 === points.length) {
        tx = points[0]
        ty = points[1]
      }
    }
    if (projection(points, x, y, r, ty - points[i + 1], points[i] - tx)) return !1;

    for (i = 0; i < points.length; i += 2) {
      tx = points[i]
      ty = points[i + 1]
      if (!isNaN(minX)) {
        if (Math.pow(tx - x, 2) + Math.pow(ty - y, 2) < Math.pow(minX - x, 2) + Math.pow(minY - y, 2)) {
          minX = tx
          minY = ty
        }
      }
    }
    return !projection(points, x, y, r, minX - x, minY - y);

    function projection(points, x, y, r, axisX, axisY) {
      let min1, max1, prod, i
      let modulus = Math.sqrt(Math.pow(axisX, 2) + Math.pow(axisY, 2))
      axisX /= modulus
      axisY /= modulus
      for ( i = 0; i < points.length; i += 2) {
        prod = points[i] * axisX + points[i + 1] * axisY
        if (isNaN(min1)) {
          min1 = max1 = prod
        } else {
          if (prod < min1) {
            min1 = prod
          } else {
            max1 < prod && (max1 = prod)
          }
        }
      }
      return max1 < (prod = x * axisX + y * axisY) - r || prod + r < min1;
    }
  }
}

export default Collision
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ArrowRight } from './ArrowRight'
// import useReference from './hooks/useReference'

const CssWrapper = styled.div`
  display: flex;
  // flex-direction: row;
  width: 110px;

  .inputBorder {
    border: 1px solid ${props => props.theme.main.borderColor};
    height: ${props => props.theme.main.inputHeight};
    display: flex;
  }

  .editorInput {
    line-height: 22px;
    // margin-right: 8px;
    margin-left: 15px;
    flex-grow: 1;
    width: 71px;
    border-width: 0;
    outline: none;
    border-right: 1px solid #ccc;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    height: 34px;
    width: 40px;
    justify-content: space-around;
    align-items: center;
    padding-right: 3px;
  }

  .link{
    border:1px solid #c2c2c2;
    width: 22px;
    height:1px;
    margin-top: -2px;
  }
  
`

const NumberEditorRight = (props) => {
  const { value, unit, onValueChanged = () => {}, hasBorder, min = 1, max = 10, name } = props
  const [valStr, setValStr] = useState('1px')
  const num = name === 'link' ? 1 : 0

  const handleClick = (type) => {
    const newV = type === '+' ? value + 1 : (value - 1) >= num ? (value - 1) : num
    updateNewValStr(newV)
  }

  const updateNewValStr = useCallback((_value, type) => {
    let _valStr = ''
    if (type === 'initial') {
      _valStr = _value < num ? valStr : _value + unit
    } else {
      value !== _value && onValueChanged(_value)
      _valStr = _value + unit
    }
    setValStr(_valStr)
  }, [onValueChanged])

  useEffect(() => {
    updateNewValStr(value, 'initial')
  }, [updateNewValStr, value])

  const handValueChange = useCallback((e) => {
    setValStr(e.target.value)
  }, [])

  const handBlurEvent = useCallback((e) => {
    const value = e.target.value
    const numberStr = value.replace(/[^0-9]/g, '') || min
    let number = parseInt(numberStr)
    if (number > max) {
      number = max
    } else if (number <= min) {
      number = min
    }

    updateNewValStr(number)
  }, [updateNewValStr])

  return (
    <CssWrapper className={hasBorder ? 'inputBorder' : ''}>
      <div onKeyDown={(event) => {
        // 阻止input onChange事件触发onKeyDow冒泡到document
        event.stopPropagation()
      }}>
        <input
          value={valStr}
          onInput={handValueChange}
          className='editorInput'
          onBlur={handBlurEvent}
        />
      </div>
      <div className='buttons'>
        <ArrowRight disabled={value >= max} angle={0} onClick={() => { handleClick('+') }} />
        <div className='link'></div>
        <ArrowRight disabled={value <= min} angle={180} onClick={() => { handleClick('-') }} />
      </div>
    </CssWrapper>
  )
}

NumberEditorRight.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  unit: PropTypes.string,
  onValueChanged: PropTypes.func,
  hasBorder: PropTypes.bool,
  name: PropTypes.string
}

export default NumberEditorRight

import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'

import useOutsideClick from './useOutsideClick'

export const ModalCssWrapper = styled.div`
  position: absolute;
  z-index: 1;
  transform: translateX(-50%);
  min-height: 10px;
  padding: 4px;
  box-shadow: 1px 1px 3px 0px rgba(4, 0, 0, 0.1);
  border-radius: 6px;
  background-color: white;
  top: 34px;
  width: 110px;
  right: -55px;
`

const withClickModalV3 = (CustomBtn, CustomModalContent) => {
  // eslint-disable-next-line react/prop-types
  const ColorCmp = ({ btnProps, modProps, show = false }) => {
    const [isShow, setIsShow] = useState(show || false)

    const handleClick = useCallback(() => {
      setIsShow(!isShow)
    }, [])

    const handleOutClick = useCallback(() => {
      setIsShow(false)
    }, [])

    const wrapperRef = useRef(null)
    useOutsideClick(wrapperRef, handleOutClick)

    return (
      <div ref={wrapperRef} style={{
        position: 'relative'
      }}>
        <CustomBtn onClick={handleClick} {...btnProps}></CustomBtn>
        {show && isShow && (

          <ModalCssWrapper>
            <CustomModalContent {...modProps} />
          </ModalCssWrapper>
        )}
      </div>
    )
  }

  return ColorCmp
}

export default withClickModalV3

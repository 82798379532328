import { ARROWTYPE } from '../components/drawBoard/link/constant'

export const getArrowTypes = () => {
  return Object.values(ARROWTYPE).map(e => ({
    id: getArrowIconName(e)
  }))
}

export const getArrowIconName = (type) => {
  switch (type) {
    case ARROWTYPE.NONE: {
      return 'null'
    }

    case ARROWTYPE.TRIANGLE: {
      return 'arrow1'
    }

    default: return 'null'
  }
}

export const getArrowType = (name) => {
  switch (name) {
    case 'null': {
      return ARROWTYPE.NONE
    }

    case 'arrow1': {
      return ARROWTYPE.TRIANGLE
    }

    default: ARROWTYPE.NONE
  }
}


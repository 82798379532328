
/*
  表示文件的icon
 */

  import Process from './process'
  class Cross extends  Process {
      constructor(ctx, sx, width, height, sy, ox, oy) {
          super(ctx, sx, sy, ox, oy)
          this.setWidth(width).setHeight(height)
          this.arcRadius = 3
      }
      process() { // 绘制十字形
          const {ctx,_left, _top,_right,_bottom, width,height,arcRadius,} = this
          const _wf = width / 3
          const _ho = height / 3
          const _ht = height / 3 * 2
          let arc2 = 4
          let arc1 = 4
          if(width <63){
             arc2 = 2,arc1=2
            if(width<45){
                arc2 = 2,arc1=1
            }
            if(width <= 27){
              arc2 =1,arc1=1
            }
          }
          if(height <63){
            arc2 = 2,arc1=2
           if(height<45){
            arc2 = 1,arc1=2
           }
           if(height <= 27){
            arc2 =1,arc1=1
           }
         }
          ctx.beginPath()
          ctx.moveTo(_left+ width/2, _top)
          ctx.arcTo(_right - _wf, _top, _right - _wf, _top + _ho, arc2)
          ctx.arcTo(_right - _wf, _top + _ho, _right, _top + _ho, arc2)
          ctx.arcTo(_right, _top + _ho, _right, _top + _ht, arc1)
          ctx.arcTo(_right, _top + _ht, _right - _wf, _bottom - _ho, arc1)
          ctx.arcTo(_right - _wf, _top + _ht, _right - _wf, _bottom, arc1)
          ctx.arcTo(_right - _wf, _bottom, _left + _wf, _bottom, arc1)
          ctx.arcTo(_left + _wf, _bottom, _left + _wf, _bottom - _ho, arc2)
          ctx.arcTo(_left + _wf, _bottom - _ho, _left, _bottom - _ho, arc2)
          ctx.arcTo(_left, _bottom - _ho, _left, _top + _ho, arc1)
          ctx.arcTo(_left, _top + _ho, _left + _ho, _top + _ho, arc1)
          ctx.arcTo(_left + _wf, _top + _ho, _left + _wf, _top, arc1)
          ctx.arcTo(_left + _wf, _top, _right - _wf, _top, arc2)
          ctx.lineTo(_left+ width/2, _top)
  
      }
   
  
  }
  
  export default Cross
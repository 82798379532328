
// 更新样式
export const CHANGE_TITLE = 'change_title'

// 更新画布设置
export const CHANGE_CANVAS_SETTING = 'change_canvas_setting'
export const CHANGE_CANVAS_SCALE = 'change_canvas_scale'

// 更新页面设置
export const CHANGE_PAGE_SETTING = 'change_page_setting'

// 更新网格设置
export const CHANGE_GRID_SETTING = 'change_grid_setting'

// 更新选中节点
export const CHANGE_SELECT_OBJECT = 'change_select_object'

// 更新节点（node link)样式
export const CHANGE_NODE_STYLE = 'change_node_style'

// 节点或者连接线加锁
export const CHANGE_NODE_LOCK = 'change_node_lock'

// 更新系统样式
export const CHANGE_SYSTEM_STYLE = 'change_system_style'

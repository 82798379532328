import React from 'react'
import { Switch as SwitchAntd } from 'antd'
import PropTypes from 'prop-types'

export default function Switch(props) {
  const { checkedName, unCheckedName, checked, onChange, disabled } = props

  return (
    <SwitchAntd
      checkedChildren={checkedName || 'on'}
      unCheckedChildren={unCheckedName || 'off'}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  )
}

Switch.propTypes = {
  checkedName: PropTypes.string,
  unCheckedName: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}


import PropTypes from 'prop-types'

export const Arrow = ({ angle = 0, onClick, size, color, disabled }) => {
  return <span
    style={{
      width: '0',
      height: '0',
      border: `${size || 3}px solid transparent`,
      borderBottom: `${size * 2 || 6}px solid ${color || 'black'}`,
      transform: `rotate(${angle}deg)`,
      cursor: 'pointer'
    }}
    className={disabled ? 'disabled' : ''}
    onClick={onClick}
  >
  </span>
}

Arrow.propTypes = {
  angle: PropTypes.number,
  size: PropTypes.number,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

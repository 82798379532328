import Arc from '../shapes/arc'
import Rect from '../shapes/rect'
import Point from '../shapes/point'
class Junction {
  constructor(target) {
    this.target = target
    this.board = target.board
    this.points = []
    this.mouseInPoint = null
    this.isMouseIn = false
    this.style = new Style(this)
    this.spread = { // 用于target被选择后，junction 向四周移动，方便target进行大小调节
      target: null
    }
    this.isSpread = false
    this._points = [] // 存放未spread，即原始点的坐标
    this.touchPoint = null // 节点连接点（移动轨迹再节点的边缘）
    this.isTouchBoundary = false
    this.point = new Point(0, 0) // 用于吸附判断
    this.init()
  }
  init() {
    for(let i = 0; i < 4; i++) {
      this.points.push(new Arc(this.target.$ctx, 0, 0, 0, 0, 2 * Math.PI))
    }
    this.touchPoint = new Arc(this.target.$ctx, 0, 0, 0, 0, 2 * Math.PI)
    this.touchPoint.isTouchPoint = true
    this.spread.target = new Rect(this.target.$ctx, 0, 0, 0, 0)
  }
  apply() {
    this.setPointRadius()
    this.setSpreadSize()
    this._apply()
  }
  _apply() {
    const {sx, sy, _right, halfX, halfY, _bottom} = this.spread.target
    const _ps = [{x: sx, y: halfY}, {x: halfX, y: sy}, {x: _right, y: halfY}, {x: halfX, y: _bottom}]
    const { x, y, right, bottom, cx, cy } = this.target
    const ps = [{x: x, y: cy}, {x: cx, y: y}, {x: right, y: cy}, {x: cx, y: bottom}]
    const s = this.isSpread ? _ps : ps
    s.forEach((p, ind) => {
      this.points[ind].setStart(p.x, p.y)
      this.points[ind].index = ind
      this.setPointByTarget(this.points[ind])
      this._points[ind] = {sx: ps[ind].x, sy: ps[ind].y, index: ind}
    })
  }
  setPointByTarget(p) {  // 判断点是否在图形边上，由于要暂时隐藏不再图形边上的锚点，后面会根据需求再进行调整
    const d = this.board.getMousePoint(p.sx, p.sy)
    p.pointInTarget = this.target.background._isPointInStroke(d.x, d.y, 10)
  }
  setTouchPoint(x, y) {
    this.touchPoint.setStart(x, y)
  }
  setPointRadius() {
    [this.touchPoint, ...this.points].forEach(p => {
      p.setRadius(this.style._radius)
    })
  }
  setSpreadSize() { // 设置扩散后的范围
    const { x, y, width, height } = this.target
    const gap = this.style._spreadDistance
    this.spread.target.setStart(x - gap, y - gap).setWidth(width + gap * 2).setHeight(height + gap * 2)
  }
  draw() {
    // 暂时去掉 touchpoint 由于定位不准确
    // this.isTouchBoundary && this.touchPoint.fill()
    if (this.isMouseIn) {
      this.points.forEach(p => {
        if (this.mouseInPoint === p) {
          p.fill()
        } else {
          (p.pointInTarget || this.isSpread) && p.strokeFill()
        }
      })
    }
  }
  hide() {
    this.isMouseIn = false
  }
  pointInAround(points = []) { // 监控是否有点在附近移动
    const gridSize = this.board.gridPage.getGridSize()
    this.isMouseIn = false
    for (let p of points) {
      this.isMouseIn = this.spread.target._isQuickInPath(p.x, p.y, gridSize * 4)
      if (this.isMouseIn) break
    }
  }
  onmouseleave() {
    this.isMouseIn = false
  }
  onSpread(type = 1) { // type: 1 扩散 0: 不扩散，回到原来的位置
    if (type === 1) {
      this.isSpread = true
      this.setTouchPoint(-1, -1)
      this._apply()
    } else {
      this.isSpread = false
      this._apply()
    }
  }
  onscale() {
    this.style.onscale()
    this.setPointRadius()
    this.setSpreadSize()
    this._apply()
  }
  onadsorb(p) { // 判断连接线是否触碰到junction, 产生吸附的效果
    this.point.setXY(p.x, p.y)
    const gSize = this.board.gridPage.getGridSize()
    for(let a of this.points) {
      p = {x: a.sx, y: a.sy}
      if (this.point.distance(p) < gSize - 5) { // this.style._radius * 3
        return p
      }
    }
  }
  eventmove({x, y}, mouse) { // eventmove会高频率执行，逻辑书写要考虑到实时情况
    let p;
    const {isSelected, background, isMouseIn} = this.target
    this.preMouseInPoint = this.mouseInPoint // 根据touch到的元素不同用来对比是否需要重新渲染画布
    this.mouseInPoint && this.style.unhoverAnchor(this.mouseInPoint) // 重置上一次touch到的元素样式
    this.mouseInPoint = null
    if (isSelected) {
      this.isMouseIn = this.spread.target._isQuickInPath(x, y, this.style._radius)
    } else {
      this.isMouseIn = isMouseIn || background._isPointInStroke(mouse.x, mouse.y, this.style._radius * 2)
    }
    if (this.isMouseIn) {
      for(let i = 0; i < this.points.length; i++) {
        p = this.points[i]
        if (p._isQuickInPath(x, y)) {
          this.style.hoverAnchor(p)
          this.mouseInPoint = p
          break
        }
      }
      // 当鼠标进入 junction 范围才会执行重新渲染的逻辑，因为超出node节点范围上层逻辑会执行重新渲染来表现出节点的touch样式
      if (this.preMouseInPoint !== this.mouseInPoint) this.board.draw()
      if (this.isTouchBoundary && !this.mouseInPoint) this.mouseInPoint = this.touchPoint
    }
    return this.mouseInPoint
  }
}
class Style {
  constructor(target) {
    this.target = target
  }
  apply(style = {}) {
    this.backgroundColor = style.backgroundColor || '#fff'
    this.borderColor = style.borderColor || 'blue'
    this.hoverColor = style.hoverColor || 'teal'
    this.hoverRadius = style.hoverRadius || 6
    this.radius = style.radius || 4
    this.lineWidth = style.lineWidth || 1
    this.spreadDistance = style.spreadDistance || 16 // 点击图形节点junction扩散的距离
    this.onscale()
  }
  getScaleValue(v) {
    return this.target.board.getDrawLength(v)
  }
  onscale() { // 缩放时设置配置
    this._radius = Math.max(this.getScaleValue(this.radius), 1)
    this._hoverRadius = Math.max(this.getScaleValue(this.hoverRadius), 1)
    this._lineWidth = this.getScaleValue(this.lineWidth)
    this._spreadDistance = Math.max(this.getScaleValue(this.spreadDistance), 3)
    this._apply()
  }
  hoverAnchor(anchor) { // 鼠标放到锚点上的样式
    anchor.setRadius(this._hoverRadius)
    anchor.getStyle().setFillStyle(this.hoverColor)
  }
  unhoverAnchor(anchor) { // 鼠标离开锚点的样式
    anchor.setRadius(this._radius)
    anchor.getStyle().setFillStyle(this.backgroundColor)
  }
  _apply() {
    const { points, touchPoint } = this.target
    points.forEach(p => {
      p.getStyle().setLineWidth(this._lineWidth).setStrokeStyle(this.borderColor).setFillStyle(this.backgroundColor)
    })
    touchPoint.getStyle().setFillStyle(this.hoverColor)
  }
}
export default Junction
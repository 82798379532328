

/*
  表示决定的icon 五角星
 */
import Process from './process'
class FiveStar extends Process {
    constructor(ctx, sx, sy, width, height, ox, oy) {
        super(ctx, sx, sy, ox, oy)
        this.setWidth(width).setHeight(height)
    }
    initial(sx, sy, ox, oy) { // 初始化画布
        return this.init(sx, sy, ox, oy)._horizontal()._vertical()
    }
    process() {

        const { ctx, _left, _top, _right, _bottom, width, height, halfX, halfY } = this

        // （x，y）起点坐标，R 大圆半径， r小圆半径， rot起始旋转角度
        let x = halfX, y = halfY, R1 = (_right - _left) / 2, r1 = R1 / 3, R2 = (_bottom - _top) / 2, r2 = R2 / 3;
        
        let arcRadius1 = height > 65 && width > 60 ? 4 : 1
        let arcRadius2 = height > 65 && width > 60 ? 2 : 1

        this.ctx.beginPath();

        let a1 = {
            x: Math.cos((18 + 72 * 0) / 180 * Math.PI) * R1 + x,
            y: -Math.sin((18 + 72 * 0) / 180 * Math.PI) * R1 + y
        }
        let a2 = {
            x: Math.cos((54 + 72 * 0) / 180 * Math.PI) * r2 + x,
            y: - Math.sin((54 + 72 * 0) / 180 * Math.PI) * r2 + y
        }
        let b1 = {
            x: Math.cos((18 + 72 * 1) / 180 * Math.PI) * R2 + x,
            y: -Math.sin((18 + 72 * 1) / 180 * Math.PI) * R2 + y
        }
        let b2 = {
            x: Math.cos((54 + 72 * 1) / 180 * Math.PI) * r2 + x,
            y: - Math.sin((54 + 72 * 1) / 180 * Math.PI) * r2 + y
        }
        let c1 = {
            x: Math.cos((18 + 72 * 2) / 180 * Math.PI) * R1 + x,
            y: -Math.sin((18 + 72 * 2) / 180 * Math.PI) * R1 + y
        }
        let c2 = {
            x: Math.cos((54 + 72 * 2) / 180 * Math.PI) * r2 + x,
            y: - Math.sin((54 + 72 * 2) / 180 * Math.PI) * r2 + y
        }
        let d1 = {
            x: Math.cos((18 + 72 * 3) / 180 * Math.PI) * R2 + x,
            y: -Math.sin((18 + 72 * 3) / 180 * Math.PI) * R2 + y
        }
        let d2 = {
            x: Math.cos((54 + 72 * 3) / 180 * Math.PI) * r2 + x,
            y: - Math.sin((54 + 72 * 3) / 180 * Math.PI) * r2 + y
        }
        let e1 = {
            x: Math.cos((18 + 72 * 4) / 180 * Math.PI) * R2 + x,
            y: -Math.sin((18 + 72 * 4) / 180 * Math.PI) * R2 + y
        }
        let e2 = {
            x: Math.cos((54 + 72 * 4) / 180 * Math.PI) * r2 + x,
            y: - Math.sin((54 + 72 * 4) / 180 * Math.PI) * r2 + y
        }

        let middle = {
            x: (a1.x + a2.x) / 2,
            y: (a1.y + a2.y) / 2
        }

        this.ctx.moveTo(middle.x, middle.y);
        this.ctx.arcTo(middle.x, middle.y, a2.x, a2.y, arcRadius2)
        this.ctx.arcTo(a2.x, a2.y, b1.x, b1.y, arcRadius1)
        this.ctx.arcTo(b1.x, b1.y, b2.x, b2.y, arcRadius2)
        this.ctx.arcTo(b2.x, b2.y, c1.x, c1.y, arcRadius1)
        this.ctx.arcTo(c1.x, c1.y, c2.x, c2.y, arcRadius2)
        this.ctx.arcTo(c2.x, c2.y, d2.x, d2.y, arcRadius1)
        this.ctx.arcTo(d1.x, d1.y, d2.x, d2.y, arcRadius2)
        this.ctx.arcTo(d2.x, d2.y, e1.x, e1.y, arcRadius1)
        this.ctx.arcTo(e1.x, e1.y, e2.x, e2.y, arcRadius2)
        this.ctx.arcTo(e2.x, e2.y, a1.x, a1.y, arcRadius1)
        this.ctx.arcTo(a1.x, a1.y, middle.x, middle.y, arcRadius2)
        this.ctx.arcTo(middle.x, middle.y, middle.x, middle.y, arcRadius2)
    }
}
export default FiveStar

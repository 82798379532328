/*
  此文件作用：画板设置页面元素状态
  example: 当选择画板形状节点或者连接线（node)时，需要同步页面顶部节点属性值
  为画板和外部页面交流提供的方法集
  画板调用本文件内部的方法： (画板实例)board.$dispatch.handleChangeScale
*/
import store from 'store/index.js'
import {
  CHANGE_PAGE_SETTING,
  CHANGE_SELECT_OBJECT,
  CHANGE_CANVAS_SCALE,
  CHANGE_NODE_LOCK,
  CHANGE_NODE_STYLE
} from './mutation-type'

export const handleChangeScale = value => { // 画布缩放修改页面显示的缩放系数value
  store.dispatch({ type: CHANGE_CANVAS_SCALE, value })
}

export const handleChangePageSize = (value) => {
  store.dispatch({ type: CHANGE_PAGE_SETTING, value })
}

export const handleChangeLockStatus = value => {
  store.dispatch({ type: CHANGE_NODE_LOCK, value })
}

export const handleSetActiveNode = (value) => {
  // return store.dispatch({ type: CHANGE_NODE_LOCK, value })
}

export const handleChangeActiveNodeStyle = (value) => {
  store.dispatch({ type: CHANGE_NODE_STYLE, value })
}

export const handleChangeActiveObject = (value) => {
  const state = store.getState()
  if (state.selectObject.activeNode === value) return
  return store.dispatch({ type: CHANGE_SELECT_OBJECT, value })
}


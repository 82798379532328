import ShapeStyle from './shapeStyle'

class TextStyle extends ShapeStyle {
  constructor() {
    super()
    this.font = TextStyle.DEFAULT_FONT
    this.textAlign = TextStyle.DEFAULT_TEXT_ALIGN
    this.textBaseline = TextStyle.DEFAULT_TEXT_BASELINE
    this.direction = TextStyle.DEFAULT_DIRECTION
    this.underline = false
    this.strikeout = false
  }

  static DEFAULT_FONT = "italic bold 10px sans-serif"
  static DEFAULT_TEXT_ALIGN = "start"
  static DEFAULT_TEXT_BASELINE = "alphabetic"
  static DEFAULT_DIRECTION = "ltr"

  getFont () {
    return this.font;
  }
  setFont (font) {
    return (this.font = font), this;
  }
  setUnderline(v) {
    return (this.underline = v), this;
  }
  getUnderline() {
    return this.underline;
  }
  setStrikeout(v) {
    return (this.strikeout = v), this;
  }
  getStrikeout() {
    return this.strikeout;
  }
  getTextAlign () {
    return this.textAlign;
  }
  setTextAlign (textAlign) {
    return (this.textAlign = textAlign), this;
  }
  getTextBaseline () {
    return this.textBaseline;
  }
  setTextBaseline (textBaseline) {
    return (this.textBaseline = textBaseline), this;
  }
  getDirection () {
    return this.direction;
  }
  setDirection (direction) {
    return (this.direction = direction), this;
  }
}

export default TextStyle
/*
  节点的选择， 配合键盘的单选，多选，划区域选择
 */
class Selector {
  constructor(box) {
    this.selected = []
    this._shift = []
    this.ctrl = null // 选中多个节点时，默认指向第一个
    this.box = box // 框选工具
  }
  select (node, ev) { // 统一处理选中节点 快捷键 ctrl shift +
    if (node) {
      if (node.isSelected) { // node 已选中的情况
        if (ev?.ctrlKey) { // ctrl+选中node->取消选中
          this._unselect(node);
        }
      } else { // node 未选中的情况
        if (ev.ctrlKey || ev.metaKey) { // ctrl+未选node->增添选中
          this._select(node);
          this.box.show()
        } else {
          this._singleSelect(node)
        }
      }
    } else if (!ev.ctrlKey && !ev.shiftKey) {
      this._clear(); // 点击屏幕->清除选中
    }
  }
  isMultiple () {
    return this.selected.length > 1;
  }
  sideBySide () {
    for (var i = this.selected.length; i > 0;) {
      if (this.selected[--i].parent !== this.ctrl.parent) return false;
    }
    return true;
  }
  onNodeSelected() {} // 选中多个节点时
  onNodeUnselect(){}
  onClear() {}
  _select (node) { // 多选节点
    node.select();
    this.selected.push(node);
    this.ctrl = this.ctrl || node;
    this.onNodeSelected(node)
  }
  _singleSelect(node) { // _select 会调用onCtrlSelected判断边界情况导致绘制变慢, 新增单一逻辑执行方法
    while (this.selected.length) this.selected.pop().unselect();
    this.box.board.operateGroup._clear()
    this._shift.length = 0;
    node.select();
    this.selected.push(node);
    this.ctrl = node
    this.onNodeSelected(node)
  }
  _multiSelect(list, needDraw) {
    while (this.selected.length) this.selected.pop().unselect();
    this.box.board.operateGroup._clear();
    list.forEach(node => {
      node.select();
      this.selected.push(node);
    })

    this.ctrl = list[0] // 选中单个元素
    this.ctrl && this.onNodeSelected(this.ctrl, needDraw)
  }

  multiSelect(list){
    while (this.selected.length) this.selected.pop().unselect();
    list.forEach((node) => {
      node.select();
      this.box.isVisible && node.setSelectType("frame");
      this.selected.push(node);
    });
    this.ctrl = list[0] // 选中单个元素
    this.ctrl && this.onNodeSelected(this.ctrl)
  }

  _unselect (node) {
    node.unselect();
    this.selected.splice(this.selected.indexOf(node), 1);
    this.ctrl = this.selected[0]
    this.onNodeUnselect()
  }
  unSelect(ids) {
    // 处理编组元素的反旋
    this.box.board.operateGroup.multiUnSelect(ids)
    this.selected = this.selected.filter((node) => {
      const isContain = ids.includes(node.id)
      isContain && node.unselect();
      return !isContain;
    });
  }
  _clear (except) {
    if (this.selected.length === 0) return
    // 清空select, 清除节点的选中状态
    while (this.selected.length) {
      const node = this.selected.pop()
      this.box.board.operateGroup.multiUnSelect([node.id])
      node.unselect();
    }
    this._shift.length = 0;
    this.ctrl = null;
    if (except) this._select(except);
    else this.onClear();
  }
  get last() {
    return this.selected[this.selected.length - 1];
  }
  get length() {
    return this.selected.length;
  }
}
export default Selector

/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react'
import { Popover, InputNumber, Slider } from 'antd'
import IconButton from 'components/comm/IconButton'
import { useBoardContext } from 'views/board/hooks'
import styled from 'styled-components'

const CssWrapper = styled.div`
  max-width: 320px;  
  .shipe-option {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .options {
      margin-right: 16px;
      font-size: 14px
    }
  }
  .transfor {
    margin-top: 16px;
    width: 94%;
    display: flex;
    justify-content: space-between
  }
`

export function IconButtonControlNodes() {
  const [eWidth, setWidth] = useState(0)
  const [eHeight, setHeight] = useState(0)
  const [eAngle] = useState(0)
  const [eRadius] = useState(0)
  // const [eOpcity, setEOpcity] = useState(100)
  const { board } = useBoardContext()
  useEffect(() => {
    if (board) {
      const { selector } = board
      const ele = selector?.ctrl
      if (ele) {
        setWidth(ele.width)
        setHeight(ele.height)
      }
    }
  }, [board?.selector?.ctrl])
  const formatValue = (value, affix) => {
    return `${Math.trunc(value)}${affix}`
  }

  const changeShipe = (e, type) => {
    const node = board?.selector?.ctrl
    switch (type) {
      case 'width':
        setWidth(eWidth + e)
        node.setWidth(eWidth + e)
        node.draw()
        break
      case 'height':
        setHeight(eHeight + e)
        node.setHeight(eHeight + e)
        node.draw()
        break
    }
  }

  const options = [
    {
      title: '宽度',
      component: <InputNumber size='small' className='input' value={formatValue(eWidth, 'px')} onChange={(e) => changeShipe(e, 'width')} />
    },
    {
      title: '高度',
      component: <InputNumber size='small' className='input' value={formatValue(eHeight, 'px')} onChange={(e) => changeShipe(e, 'height')} />
    },
    {
      title: '旋转',
      component: <InputNumber size='small' className='input' value={formatValue(eAngle, '°')}/>
    },
    {
      title: '圆角',
      component: <InputNumber size='small' className='input' value={formatValue(eRadius, 'px')}/>
    },
    {
      title: '',
      component: <div style={{ width: '100px' }}></div>
    },
    {
      title: '翻动',
      component: <IconButton style={{ float: 'right' }} name='row' toolTip='翻动' />
    },
    {
      title: '翻动',
      component: <IconButton style={{ float: 'right' }} name='column' toolTip='翻动' />
    }
  ]
  const renderContent = useCallback(() => {
    return (
      <CssWrapper>
        <div className='shipe-option'>
          {options.map(option => {
            return <div key={option.title} className='options'>
              {option.component}
              <div className='title'>{option.title}</div>
            </div>
          })}
        </div>
        <div className='transfor'>
          <Slider
            min={1}
            max={100}
            style={{ width: '50%', margin: '5px 6px 5px' }}
          />
          <InputNumber
            size='small'
            min={1}
            max={100}
          />
        </div>
      </CssWrapper>
    )
  }, [board, eHeight, eWidth])
  return (
    <Popover
      placement='bottom'
      content={renderContent()}
      trigger='click'
    >
      <IconButton style={{ marginTop: '4px' }} name='shipe' toolTip='形状控制' />
    </Popover>
  )
}

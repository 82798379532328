/* eslint-disable no-unused-vars */
/*
  用于拖拽形状到画板
 */
import shapes from 'components/leftPanels/shapes.json'
class Drag {
  constructor(container, dragEl, board) {
    this.container = container
    this.dragEls = Array.from(this.container.querySelectorAll('.' + dragEl)) // 可以拖动的元素合集
    this.textEl = document.getElementById('_dragText')
    this.linkEl = document.getElementById('_dragLine')

    this.board = board // 画板实例
    this.board.dragIn = this // 注册drag实例到画板
    this.target = null // 操作元素
    this.type = '' // 拖拽物体的类型
    this.shapes = Drag.dataToMap(shapes)
    this.board.$shapes = this.shapes
    this.init()
  }
  static getInstance(...args) {
    if (!Drag.instance) {
      Drag.instance = new Drag(...args)
    }
    return Drag.instance
  }
  static dataToMap(data) {
    const map = new Map()
    for (const value of Object.values(data)) {
      value.forEach(item => {
        map.set(item.id, item)
      })
    }
    return map
  }
  init() {
    this.initEvents()
    this.addEvents()
  }
  addEvents() {
    this.textEl && this.textEl.setAttribute('dragid', 'text')
    ;['move', 'up'].forEach(type => {
      this.container.addEventListener(`mouse${type}`, ev => {
        this.event[type](ev, ev.buttons)
      })
    })
    ;[...this.dragEls, this.textEl].forEach(el => {
      el && el.addEventListener('mousedown', ev => {
        this.event.down(ev, ev.buttons, el)
      })
      el && el.addEventListener('dblclick', ev => {
        this.event.dblclick(ev, ev.buttons, el)
      })
    })
    this.linkEl && this.linkEl.setAttribute('dragid', 'line')
    ;['move', 'up'].forEach(type => {
      this.container.addEventListener(`mouse${type}`, ev => {
        this.event[type](ev, ev.buttons)
      })
    })
    ;[...this.dragEls, this.linkEl].forEach(el => {
      el && el.addEventListener('mousedown', ev => {
        this.event.down(ev, ev.buttons, el)
      })
      el && el.addEventListener('dblclick', ev => {
        this.event.dblclick(ev, ev.buttons, el)
      })
    })
  }
  dragTarget(ev) {
    const b = this.board
    this.operateTarget.setDragInShape(ev.x - b.origin.x - 30, ev.y - b.origin.y - 20)
    this._dragTarget(ev)
  }
  _dragTarget(ev) {
    const mx = ev.x - this._x // move 的时候，计算拖拽的距离
    const my = ev.y - this._y
    const t = this.target
    t.style.left = `${t.left + mx}px`
    t.style.top = `${t.top + my}px`
  }
  initEvents() {
    this.mouse = {
      type: 0,
      none: 0,
      left: 1,
      right: 2,
      center: 4,
      back: 8,
      front: 16,
      eventType: '',
      x: 0,
      y: 0,
      setMousePoint({ x, y }) {
        this.x = x
        this.y = y
      }
    }
    this.event = {
      move: (ev, type) => {
        if (this.mouse.type !== type) return // 判断是否执行过对应元素down操作
        switch (type) {
          case this.mouse.none:
            break
          case this.mouse.left: // 按住鼠标左键拖动元素
            break
        }
      },
      down: (ev, type, el) => {
        this.board.operateNode.initDragInShape()
        this.board.operateLink.initDragInShape()
        this.mouse.setMousePoint(this.board.$documentMouseToBoard(ev.x, ev.y))
        this.mouse.eventType = 'mousedown'
        this.mouse.type = type
        this.target = el
        const dragId = this.target.getAttribute('dragid') || 'process'
        const shapeProps = this.shapes.get(dragId)
        if (!shapeProps) return

        this.type = shapeProps.type
        this.operateTarget.createDragInShape({ value: shapeProps.text, shapeInfo: shapeProps, type: this.type, x: this.mouse.x, y: this.mouse.y })
      },
      dblclick: () => {
        this.mouse.mouseType = 'dblclick'
        this.container.close()
        this.operateTarget.addDragInShape(this.mouse)
      },
      up: () => {
        this.mouse.type = this.mouse.none
        this.mouse.eventType = ''
      }
    }
  }
  get operateTarget() {
    return this.type === 'line' ? this.board.operateLink : this.board.operateNode
  }
}
export default Drag

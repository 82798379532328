import Arc from './arc'
class Ellipse extends Arc {
  constructor(ctx,sx,sy,radiusX,radiusY,rotation,startAngle,endAngle,anticlockwise,ox,oy) {
    super.call(ctx,sx,sy,radiusX,startAngle,endAngle,anticlockwise,ox,oy),
    this.setRadiusY(radiusY).setRotation(isNaN(rotation) ? 0 : rotation)
  }
  process () {
    this.ctx.beginPath(),
    this.ctx.ellipse(
      this._sx,
      this._sy,
      this.radius,
      this.radiusY,
      this.rotation,
      this.startAngle,
      this.endAngle,
      this.anticlockwise
    )
  }
  _isQuickInPath (x, y) {
    return (
      (x -= this._sx),
      (y -= this._sy),
      Math.sqrt(
        Math.pow(this._cos * x + this._sin * y, 2) * this._ryx +
          Math.pow(-this._sin * x + this._cos * y, 2) * this._rxy
      ) <= this._sqrtRxRy
    )
  }
  _isQuickInStroke (x, y) {
    return (
      (x -= this._sx),
      (y -= this._sy),
      0 ===
        Math.round(
          (Math.sqrt(
            Math.pow(this._cos * x + this._sin * y, 2) * this._ryx +
              Math.pow(-this._sin * x + this._cos * y, 2) * this._rxy
          ) -
            this._sqrtRxRy) /
            this.sty.lineWidth
        )
    )
  }
  getRadiusX () {
    return this.radius
  }
  setRadiusX (radiusX) {
    return this._setRadius((this.radius = radiusX), this.radiusY)
  }
  getRadiusY () {
    return this.radiusY
  }
  setRadiusY (radiusY) {
    return this._setRadius(this.radius, (this.radiusY = radiusY))
  }
  setRadius (radiusX, radiusY) {
    return (
      (this.radius = radiusX),
      radiusY && this.setRadiusY((this.radiusY = radiusY)),
      this
    )
  }
  _setRadius (radiusX, radiusY) {
    return (
      (this._sqrtRxRy = Math.sqrt(radiusX * radiusY)),
      (this._rxy = radiusX / radiusY),
      (this._ryx = radiusY / radiusX),
      this
    )
  }
  getRotation () {
    return this.rotation
  }
  setRotation (rotation) {
    return (
      this.rotation !== rotation &&
        ((this.rotation = rotation),
        (this._cos = Math.cos(rotation)),
        (this._sin = Math.sin(rotation))),
      this
    )
  }
}
export default Ellipse
import FillStrokeStyle from './fillStrokeStyle'

class ShapeStyle extends FillStrokeStyle {
  constructor() {
    super()
    this.lineDash = ShapeStyle.DEFAULT_LINE_DASH
    this.lineDashOffset = ShapeStyle.DEFAULT_LINE_DASH_OFFSET
    this.lineWidth = ShapeStyle.DEFAULT_LINE_WIDTH
    this.lineCap = ShapeStyle.DEFAULT_LINE_CAP
    this.lineJoin = ShapeStyle.DEFAULT_LINE_JOIN
    this.miterLimit = ShapeStyle.DEFAULT_MITER_LIMIT
  }

  static DEFAULT_LINE_DASH = []
  static DEFAULT_LINE_DASH_OFFSET = 0
  static DEFAULT_LINE_WIDTH = 1
  static DEFAULT_LINE_CAP = "butt"
  static DEFAULT_LINE_JOIN = "miter"
  static DEFAULT_MITER_LIMIT = 10

  getLineDash () {
    return this.lineDash;
  }
  setLineDash (lineDash) {
    return (this.lineDash = lineDash), this;
  }
  getLineDashOffset () {
    return this.lineDashOffset;
  }
  setLineDashOffset (lineDashOffset) {
    return (this.lineDashOffset = lineDashOffset), this;
  }
  getLineWidth () {
    return this.lineWidth;
  }
  setLineWidth (lineWidth) {
    return (this.lineWidth = lineWidth), this;
  }
  getLineCap () {
    return this.lineCap;
  }
  setLineCap (lineCap) {
    return (this.lineCap = lineCap), this;
  }
  getLineJoin () {
    return this.lineJoin;
  }
  setLineJoin (lineJoin) {
    return (this.lineJoin = lineJoin), this;
  }
  getMiterLimit () {
    return this.miterLimit;
  }
  setMiterLimit (miterLimit) {
    return (this.miterLimit = miterLimit), this;
  }
}

export default ShapeStyle
import Utils from './utils'
import AnimateRAF from './animate/animateRAF'
import Style from './shapes/styles'
import ShadowStyle from './shapes/styles/shadowStyle'
import FillStrokeStyle from './shapes/styles/fillStrokeStyle'
import ShapeStyle from './shapes/styles/shapeStyle'
import TextStyle from './shapes/styles/textStyle'
import ImageStyle from './shapes/styles/imageStyle'
import BindEvent from './events/bindEvents'
class Canvas {
  constructor(options) {
    this.$wrapper = document.getElementById(options.container)
    this.$wrapper.$ = this
    this.$document = document.body
    if (this.$wrapper._count === void 0) {
      this.$wrapper._count = this.$wrapper.tabIndex = 0 // tabIndex 通过tab键获取激活元素，使用document.activeElement可以获取
      Object.assign(this.$wrapper, BindEvent)
      Object.assign(this.$document, BindEvent)
      this.$resizeWrapper(options.width, options.height)
      this.$canvas = document.getElementById("draw-board")
      this.$gridCanvas = document.getElementById("grid-board") // 网格元素
      this.$gridCtx = this.$gridCanvas.getContext("2d", {alpha: !0, desynchronized: !1}) // 网格上下文
      this.$ctx = this.$canvas.getContext("2d", {alpha: !0, desynchronized: !1})
      Object.defineProperties(this.$ctx, {
        _dpr: {
          writable: !0,
          enumerable: !1,
        },
        _stack: {
          value: [],
          enumberable: !1,
        },
      })
      this.$dpr = window.devicePixelRatio
      this.$duration = options.duration
      this.$interval = options.interval
      this.$raf = new AnimateRAF(
        function (timestamp, interval) {
          this.update(timestamp, interval), this.draw();
        }.bind(this),
        options.duration,
        options.interval
      )
      Object.assign(this, options.props, options.components, options.methods, options.events, options.functions, options.callbacks, options.register)
      this._$visible = false
      this.resizeCanvas().init()
      this.draw()
      if (0 == this.$wrapper._count++) {
        Utils._resizeObserver.observe(this.$wrapper)
        Utils._intersectionObserver.observe(this.$wrapper)
      } else {
        // this._$visible = this.$canvas.previousSibling.this._$visible
        this._$visible && !document.hidden && this.visibilitychange(!0)
      }
      this._$initContextMenu()
      this._$initListeners() // $wrapper 添加事件监听
      this._$addListeners()
    }
  }
  _$initContextMenu() {
    this.$menu = { hide() {}, locate() {} } // type: node 右键节点, board: 右键画布
  }
  $forbiddenContextMenu() {
    this.$wrapper.oncontextmenu = function (ev) {
      ev.stopPropagation();
      ev.preventDefault(); // TODO: 使用 oncontextmenu(x, y) 回调出去，调用 Menu 菜单
    };
  }
  $isVisible () {
    return this._$visible;
  }
  $getCursor () {
    return this.$wrapper.style.cursor;
  }
  $setCursor (cursor) {
    return (this.$wrapper.style.cursor = cursor), this;
  }
  $resizeWrapper (width, height) {
    var sty = this.$wrapper.style;
    sty.width = width ? width + "px" : "100%"
    sty.height = height ? height + "px" : "100%"
    return this
  }
  $resize (width, height) {
    this.resizeCanvas(width, height)
    this.draw()
    return this
  }
  $documentMouseToBoard(x, y) { // 绑定在非画板dom上的事件，获取的 ev.x, ev.y 转换到画板对应的点
    if (isNaN(x) || isNaN(y)) return
    x = x + this.origin.x
    y = y + this.origin.y
    return {x, y}
  }
  resizeCanvas (width, height) {
    var rect, t = this, dpr = (t.$ctx._dpr = t.$dpr || 1);
    rect = t.$wrapper.getBoundingClientRect()
    if (isNaN(width) || isNaN(height)) {
      isNaN(width) && (width = rect.right - rect.left)
      isNaN(height) && (height = rect.bottom - rect.top)
    }
    t.origin = { x: rect.left, y: rect.top }
    t.$canvas.width = Math.round(width * dpr)
    t.$width = Math.round(width)
    t.$canvas.style.width = `${t.$width}px`
    t.$canvas.height = Math.round(height * dpr)
    t.$height = Math.round(height)
    t.$canvas.style.height = `${t.$height}px`
    t.$ctx._stack.length = 0
    return t._$resetTransform().resetCanvasStyle()
  }
  $toDataURL (type, quality) {
    return this.$canvas.toDataURL(type, quality);
  }
  $toBlob (callback, type, quality) {
    return this.$canvas.toBlob(callback, type, quality), this;
  }
  $saveAsImage (filename, type, quality) {
    return (
      (Utils._a.href = this.$toDataURL(type, quality)),
      (Utils._a.download = filename),
      Utils._a.click(),
      this
    );
  }
  $clear () {
    return (
      this._$resetTransform().$ctx.clearRect(0, 0, this.$width, this.$height),
      this
    );
  }
  _$resetTransform () {
    var ctx = this.$ctx,
      dpr = ctx._dpr;
    1 !== dpr ? ctx.setTransform(dpr, 0, 0, dpr, 0, 0) : ctx.resetTransform()
    ctx._m11 = ctx._m11i = ctx._m22 = ctx._m22i = 1
    ctx._m12 = ctx._m12i = ctx._m21 = ctx._m21i = ctx._m41 = ctx._m41i = ctx._m42 = ctx._m42i = 0
    return this
  }

  resetCanvasStyle () {
    var ctx = this.$ctx;
    return (
      (ctx._alpha = Style.DEFAULT_ALPHA),
      (ctx._compositeOperation = Style.DEFAULT_COMPOSITE_OPERATION),
      (ctx._filter = Style.DEFAULT_FILTER),
      (ctx._shadowBlur = ShadowStyle.DEFAULT_SHADOW_BLUR),
      (ctx._shadowColor = ShadowStyle.DEFAULT_SHADOW_COLOR),
      (ctx._shadowOffsetX = ShadowStyle.DEFAULT_SHADOW_OFFSET_X),
      (ctx._shadowOffsetY = ShadowStyle.DEFAULT_SHADOW_OFFSET_Y),
      (ctx._fillStyle = FillStrokeStyle.DEFAULT_FILL_STYLE),
      (ctx._strokeStyle = FillStrokeStyle.DEFAULT_STROKE_STYLE),
      (ctx._lineDash = ShapeStyle.DEFAULT_LINE_DASH),
      (ctx._lineDashOffset = ShapeStyle.DEFAULT_LINE_DASH_OFFSET),
      (ctx._lineWidth = ShapeStyle.DEFAULT_LINE_WIDTH),
      (ctx._lineCap = ShapeStyle.DEFAULT_LINE_CAP),
      (ctx._lineJoin = ShapeStyle.DEFAULT_LINE_JOIN),
      (ctx._miterLimit = ShapeStyle.DEFAULT_MITER_LIMIT),
      (ctx._font = TextStyle.DEFAULT_FONT),
      (ctx._textAlign = TextStyle.DEFAULT_TEXT_ALIGN),
      (ctx._textBaseline = TextStyle.DEFAULT_TEXT_BASELINE),
      (ctx._direction = TextStyle.DEFAULT_DIRECTION),
      (ctx._smoothingEnabled = ImageStyle.DEFAULT_SMOOTHING_ENABLED),
      (ctx._smoothingQuality = ImageStyle.DEFAULT_SMOOTHING_QUALITY),
      this
    );
  }

  _$eventTypes = [
    "mousedown",
    "touchstart",
    "mousemove",
    "touchmove",
    "mouseup",
    "touchend",
    "touchcancel",
    "mouseover",
    "mouseout",
    "click",
    "dblclick",
    "wheel",
    "contextmenu",
    "focus",
    "blur",
    "focusin",
    "focusout",
    "keydown",
    "keyup",
    "keypress",
    "drop",
    "dragover"
  ]

  _$initListeners () { // 初始化listeners: 存储各种键盘和鼠标事件的数组
    const ls = this._$listeners = {}
    this._$eventTypes.forEach(type => {
      if (this[type] === void 0) return
      ls[type] = (ev) => {
        this[type] && this[type](ev);
      }
      switch(type) {
        case 'mouseup':
          // ls.mouseout = ls[type]
          break;
        case 'touchend':
          ls.touchcancel = ls[type]
      }
    })
    this._$visibilitychange = function () {
      this._$visible && this.visibilitychange("visible" === document.visibilityState);
    }
    return this
  }
  _$addListeners () {
    for (let key in this._$listeners) {
      let eventOption = { passive: !1 };
      if(key === 'wheel'){
        eventOption.deltaY = 10
        eventOption.deltaMode = 0
        this.$document._on(key, this._$listeners[key], eventOption)
      } else if(['focus', 'keydown', 'keyup','mousedown', 'mousemove', 'mouseup', 'mouseout'].includes(key)){
        this.$document._on(key, this._$listeners[key], eventOption)
      } else {
        this.$wrapper._on(key, this._$listeners[key], eventOption)
      }
    }
    this.$focusBoard()
    this.$document.addEventListener("visibilitychange", this._$visibilitychange, false)
    return this
  }
  _$removeListeners () {
    for (let key in this._$listeners) {
      this.$wrapper._off(key, this._$listeners[key], { passive: !1 })
    }
    this.$document.removeEventListener("visibilitychange", this._$visibilitychange)
    return this
  }
  $focusBoard() {
    this.$wrapper && this.$wrapper.focus()
  }
  $destroy () {
    var key, $ = this;
    $.$raf.stop()
    delete $.$wrapper.removeChild($.$canvas).$
    if (0 == --$.$wrapper._count) {
      Utils._resizeObserver.unobserve($.$wrapper)
      Utils._intersectionObserver.unobserve($.$wrapper)
    }
    for (key in $) $.hasOwnProperty(key) && delete $[key];
    return $;
  }
};

["init", "resize", "update", "draw", "contextmenu", "visibilitychange" ].forEach(function (method) {
  Canvas.prototype[method] = Utils.noop;
})

export default Canvas
/* eslint-disable react/prop-types */
import React from 'react'

function ArrowNone() {
  return (
    <div>
      None
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 33 20'
      >
        <defs>
          <marker id='arrowhead' markerWidth='10' markerHeight='7'
            refX='0' refY='3.5' orient='auto'>
            <polygon points='0 0, 10 3.5, 0 7' />
          </marker>
        </defs>

      </svg>
    </div>
  )
}

// dir: right: true, 长宽比: 33: 20
function Arrow1({ dir = true, width = 33, height = 20 }) {
  const len = width / 2

  const x1 = !dir ? 0 : width
  const x2 = !dir ? len : width - len

  return (
    <svg xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 33 20'
      width={width} height={height} >
      <defs>
        <marker id='arrowhead' markerWidth='10' markerHeight='7'
          refX='0' refY='3.5' orient='auto'>
          <polygon points='0 0, 10 3.5, 0 7' />
        </marker>
      </defs>
      <line x1={x1} y1={height / 2} x2={x2} y2={height / 2} stroke='#000'
        strokeWidth='2' markerEnd='url(#arrowhead)' />
    </svg>
  )
}

export {
  Arrow1,
  ArrowNone
}

import styled from 'styled-components'

export const AppContainerCssWrapper = styled.div`
    flex: 1 1 0%;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    user-select: none;
    background-color: ${props => props.theme.main.appBackgroundColor};

    .flex1 {
        flex: 1;
    }

    .flex {
        display: flex;
    }

    .flexColumn {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .flexColumnBet {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .flexRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        // height: 100%;
    }

    .flexRowBet {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .flexRowBet<div{
        height:14px
    }

    .flexWrap {
        display: flex;
        flex-wrap: wrap;
    }

    .drawBoard{
       
        position: relative;
    }
`
// background-color: ${props => props.theme.main.drawBoardBackgroundColor};

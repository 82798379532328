/*
  存放连接线需要用到的常量
 */
export const LINETYPE = { // 连接线类型
  FOLD: 'fold', // 折线
  STRAIGHT: 'straight', // 直线
  CURVE: 'curve' // 曲线
}

export const ARROWTYPE = {
  NONE: 'none',
  TRIANGLE: 'triangle'
}
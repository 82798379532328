import http from './request'

class Upload {
  constructor(options) {
    this.uploadType = options.uploadType === null ? 'image' : options.uploadType
    this.fileSize = options.fileSize
    this.fileType = options.fileType
  }

  getFileSize(size) {
    if (!size) return
    const num = 1024.00
    if (size < num) { return size + 'B' }
    if (size < Math.pow(num, 2)) { return (size / num).toFixed(2) + 'KB' }
    if (size < Math.pow(num, 3)) { return (size / Math.pow(num, 2)).toFixed(2) + 'MB' }
    if (size < Math.pow(num, 4)) { return (size / Math.pow(num, 3)).toFixed(2) + 'G' }
    return (size / Math.pow(num, 4)).toFixed(2) + 'T'
  }

  handleBeforeUpload(file) {
    let pattern
    switch (this.uploadType) {
      case 'image':
        pattern = `^image\\/${this.fileType}$`
        break
      default:
        pattern = `^image\\/${this.fileType}$`
    }
    const reg = new RegExp(pattern, 'i')
    if (!reg.test(file.type)) {
      return { 'status': false, 'msg': `只能上传${this.fileType}` }
    } else if (file.size > this.fileSize) {
      const size = this.getFileSize(this.fileSize)
      return { 'status': false, 'msg': `大小不能超过${size}` }
    }
    return { 'status': true }
  }

  async uploadPicFile(file, uploadApi) {
    const formData = new FormData()
    formData.append('imageFile', file)
    const config = {
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    const response = await http.post(uploadApi, formData, config)
    return response
  }

  async getRemotePic(picUrl, uploadApi) {
    const formData = new FormData()
    formData.append('picUrl', picUrl)
    const config = {
      method: 'post'
    }
    const response = await http.post(uploadApi, formData, config)
    return response
  }
}
export default Upload

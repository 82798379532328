import { nanoid } from 'nanoid'
class Helper {
  /**
   * 判断是否苹果系统
   * @returns {boolean}
   */
  static isMac() {
    return /macintosh|mac os x/i.test(navigator.userAgent.toLowerCase())
  }
  /**
   * 加载图片
   * @param url
   * @returns {Promise<unknown>}
   */
  static loadImage(url) {
    return new Promise((resolve) => {
      const img = new Image()
      // img.crossOrigin = 'anonymous'
      img.src = url
      img.onload = () => resolve(img)
      img.onerror = e => {
        throw Error(e)
      }
    })
  }

  /**
   * 所有 Map 的键都是字符串，它可以无损地转为对象
   * @param map
   */
  static strMapToObj(map) {
    const obj = Object.create(null)
    for (const [k, v] of map) {
      obj[k] = v
    }
    return obj
  }

  /**
   *
   * @param value
   * @param keys
   * @param state
   */
  static valueToState(value, keys, state) {
    const list = Object.keys(value)
    keys.forEach(key => {
      if (list.includes(key)) {
        state[key] = value[key]
      }
    })
    return state
  }

  /**
   * 根据Url返回路径
   * @param url
   */
  static urlToPathname(urlPath) {
    if (urlPath === 'None') return urlPath
    const customURL = new URL(urlPath)
    return customURL.pathname
  }

  /**
   * @param {*} item
   * @returns type
   */
  static getType(item) {
    return Object.prototype.toString.call(item).slice(8, -1)
  }

  static isObject(item) {
    return Object.prototype.toString.call(item) === '[object Object]'
  }

  /**
   * 产生nanoid,用来作为全局唯一ID
   * @returns {string}
   */
  static produceNanoId() {
    return nanoid()
  }

  /**
   * 判断选中节点所有的是否为同一个属性
   * @param key
   * @param nodes
   */
  static isSame(key, value, nodes) {
    return (
      nodes && nodes.length > 0 && nodes.some((node) => node.style[key] === value)
    )
  }

  /**
   * 读取剪切板数据
   * @param data
   */
  static async clipboardRead(sucessFunc, errorFunc) {
    let list = []
    try {
      list = await navigator.clipboard.read()
      sucessFunc && sucessFunc(list)
    } catch (err) {
      console.error(err.name, err.message)
      errorFunc && errorFunc()
    }
    return list
  }
  static async clipboardWriteText(data) {
    let res
    try {
      res = await navigator.clipboard.writeText(data)
    } catch (err) {
      console.error(err.name, err.message)
    }
    return res
  }
  static fileRead(data, type = 'text/plain') {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      switch (type) {
        case 'text/plain':
          reader.readAsText(data)
          break
      }
    })
  }

  /**
   * 处理File 格式文件avigator.clipboard.read读取不到的情况
   */
  static listenPaste() {
    // 用来解决处理File 格式的文件，暂时只支持单个文件
    document.addEventListener('paste', (event) => {
      for (let i = 0, len = event.clipboardData.items.length; i < len; i++) {
        const item = event.clipboardData.items[i]
        if (item.kind === 'file') {
          window.aFile = item.getAsFile()
        }
      }
    })
  }

  /**
   * 读取粘贴的File格式文件
   */
  static getPasteTypeFile() {
    return window.aFile
  }

  /**
   * localStorage存储
   * @param key 键
   * @param value 值
   */
  static localStorageSetItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  /**
   * localStorage存储
   * @param key 键
   */
  static localStorageGetItem(key) {
    return JSON.parse(localStorage.getItem(key))
  }

  /**
   * localStorage存储
   * @param key 键
   */
  static localStorageDelItem(key) {
    return localStorage.removeItem(key)
  }

  static checkClipboardChange() {
    let isChanged = false
    let preLastItem = null
    return _ => Helper.clipboardRead().then(list => {
      preLastItem = list[0]
      isChanged = preLastItem !== list[0]
      return isChanged
    })
  }

  /**
   * calculateWidthHeight
   * @param nodes 节点
   */
  static calculatePosition(nodes) {
    const left = []; const right = []; const top = []; const bottom = []
    nodes.forEach(n => {
      left.push(n.left)
      right.push(n.right)
      top.push(n.top)
      bottom.push(n.bottom)
    })
    return {
      left: Math.min.apply(null, left),
      top: Math.min.apply(null, top),
      right: Math.max.apply(null, right),
      bottom: Math.max.apply(null, bottom)
    }
  }
}
export default Helper

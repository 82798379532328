/*
  params: {
    target: 画板实例
    selector: 选择器实例
  }
  功能说明: 满足各种形式选择节点功能
 */
class SelectNode {
  constructor(target, selector) {
    this.t = target
    this.s = selector
  }
  onSelect(n) {
    n.select()
    this.s.selected.push(n)
  }
  selAll() { // 选择所有节点
    const { t } = this
    t._bfs(t._root, (n) => {
      if (n.isMeta) return
      this.onSelect(n)
    })
    t.draw()
  }
  selSiblings() { // 选中兄弟节点
    const ctrl = this.s.ctrl
    if (ctrl) {
      ctrl.parent.children.forEach(n => {
        this.onSelect(n)
      })
    }
    this.t.draw()
  }
  selOthers() { // 反选
    const { t, s } = this
    const ctrl = s.ctrl
    if (ctrl) {
      const map = {}
      s.selected.forEach(n => map[n.id] = n)
      s._clear()
      t._bfs(t._root, (n) => {
        if (n.isMeta || map[n.id]) return
        this.onSelect(n)
      })
    }
    t.draw()
  }
  selSameLevel() { // 选择所有相同级别的节点
    const { t, s } = this
    const ctrl = this.s.ctrl
    if (ctrl) {
      s._clear()
      t._bfs(t._root, (n) => {
        if (!n.isMeta && n.depth === ctrl.depth) {
          this.onSelect(n)
        }
      })
    }
    t.draw()
  }
  selPath() { // 选择路径
    const { s } = this
    const ctrl = s.ctrl
    if (ctrl) {
      s._clear()
      this.upFind(ctrl, n => {
        if (n.isMeta) return
        this.onSelect(n)
      })
    }
    this.t.draw()
  }
  selChild() { // 选择该节点的所有子节点
    const { t, s } = this
    const ctrl = this.s.ctrl
    if (ctrl) {
      s._clear()
      t._bfs(ctrl, (n) => {
        this.onSelect(n)
      })
    }
    t.draw()
  }
  upFind(c, cb) { // 向上递归查找
    cb(c)
    if (c.parent && c.parent !== c) {
      this.upFind(c.parent, cb)
    }
  }
}
export default SelectNode

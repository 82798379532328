import { initSelectObject } from '../state'
import {
  CHANGE_SELECT_OBJECT,
  CHANGE_NODE_STYLE,
  CHANGE_NODE_LOCK
} from '../mutation-type'

const configReducer = (state = initSelectObject, action) => {
  switch (action.type) {
    case CHANGE_SELECT_OBJECT: { // 更新选中的对象
      return {
        ...state,
        activeNode: action.value || state.activeNode
      }
    }

    case CHANGE_NODE_STYLE: // 更新选中的对象属性
      return {
        ...state,
        activeNode: {
          ...state.activeNode,
          style: {
            ...state.activeNode.style,
            ...action.value
          }
        }
      }

    case CHANGE_NODE_LOCK: // 更新选中的对象的锁定状态
      return {
        ...state,
        activeNode: {
          ...state.activeNode,
          funcLock: {
            lockType: action.value
          }
        }
      }

    default:
      return state
  }
}

export default configReducer

import { useContext } from 'react'
import { Context } from '../boardContext'

function useBoardContext() {
  const { board, setBoard } = useContext(Context)
  return {
    board, setBoard
  }
}

export default useBoardContext

import {
  CHANGE_SYSTEM_STYLE
} from '../mutation-type'

const changeSystemStyle = (value) => {
  return {
    type: CHANGE_SYSTEM_STYLE,
    value
  }
}

export {
  changeSystemStyle
}

import { initState } from '../state'
import {
  CHANGE_CANVAS_SETTING,
  CHANGE_CANVAS_SCALE,
  CHANGE_PAGE_SETTING,
  CHANGE_GRID_SETTING
} from '../mutation-type'

export default function configReducer(state = initState, action) {
  switch (action.type) {
    case CHANGE_PAGE_SETTING: // 更新页面设置
      return {
        ...state, page: action.value
      }

    case CHANGE_GRID_SETTING: // 更新网格设置
      return {
        ...state, grid: action.value
      }

    case CHANGE_CANVAS_SCALE:
      return {
        ...state, canvasScale: action.value
      }

    case CHANGE_CANVAS_SETTING: // 更新画布设置
      return {
        ...state, canvas: action.value
      }

    default:
      return state
  }
}

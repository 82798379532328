import Text from '../shapes/text'
import Style from './style'
import Border from './border'
import * as shapes from '../shapes/process'
import Junction from './junction' // 连接线和图形的会交点
import FuncLock from './funcLock' // 功能锁
import Picture from '../shapes/picture'
import Helper from "utils/helper";
import { CREATE_ACTIONS, RowFlex } from './constant'
// import line from '../shapes/line'

const resizeCursors = ['', 'nw', 'n', 'ne', 'e', 'se', 's', 'sw', 'w']
class VNode {
  constructor($ctx, board, {type, action, x, y, id, value, width, height, shapeInfo, picUrl, carrier}) {
    this.board = board
    this.name = 'node'
    // TODO 保存功能完善后需要在保存前进行id校验
    this.id = id || Helper.produceNanoId()
    this.type = type // 节点类型
    this.$ctx = $ctx
    this.style = new VNode.Style(this)
    this.x = x || 0
    this.y = y || 0
    this.width = width || 0
    this.height = height || 0
    this.value = value || ''
    this.action = action || CREATE_ACTIONS.menuToCreate // 节点创建方式
    this.preValue = value // 记录修改前的值，用于对比node值变化
    this.values = [] // 节点的内容
    this.texts = [] // 根据内容生成的节点中的文字对象
    this.background = new shapes[shapes[type] ? type : 'process'](this.$ctx, 0, 0, width, height)
    this.boundary = new shapes[shapes[type] ? type : 'process'](this.$ctx, 0, 0, width, height) // 图形边界线，目前只有在框选图形是才会出现
    this.border = new Border(this.$ctx, this)
    this.shapeInfo = shapeInfo // 节点形状的属性，大小，比例等
    this.carrier = carrier // 载体，连接线上的文字节点，载体 为连接线
    this.isMouseIn = false
    this.mouseInWhich = '' // 鼠标移入到节点内的某个元素
    this.selectType = '' // 选择类型 click 点选, frame 框选
    this.junction = new Junction(this)
    this.isMoving = false
    this.hackerIsAvtive = undefined
    this.isInit = false // 节点是否是初始化时，初始化时
    this.isShowText = true // 是否绘制文字
    this.isIntelHeight = true // 高度是否根据内容变化自动调整
    this.funcLock = new FuncLock($ctx, this, board)
    this.isEditing = false // 是否在修改节点文本
    // 处理图片属性
    if (this.isImageType) {
      Helper.loadImage(picUrl).then((img)=>{
        this.picture = new Picture(this.$ctx, 0, 0, 0, 0, img)
        this.apply()
        this.calculateSize()
        this.board.draw()
      });
    }
    this.picUrl = picUrl
    this.hyperlink = ''
    this.hyperlinkState = false
  }
  apply() { // 初始化设置node相关属性位置
    const { x, y, style } = this
    this.background.setStart(x, y)
    this.boundary.setStart(x, y)
    this.picture && this.picture.setStart(x, y)
    this.calculateSize()
    this.border.apply()
    this.funcLock.apply() // 功能锁

    let textX, textY, w, h = 0
    for (let row of this.values) {
      w = 0
      for(let text of row.elementList) {
        textX = x + w + this.getRowGapByRowFlex(row.width)
        textY = y + style.contentTop + h + row.ascent
        text.shape.setStart(textX, textY).setRowBottom(y + style.contentTop + h + row.height)
        w += text.metrics.width
      }
      h += row.height
    }
    this.junction.apply()
    this.isInit = false
  }
  getRowGapByRowFlex(rowLength) { // 根据文字行排列类型设置文字实际绘制的位置差
    const {paddingLeft, textAlign} = this.style
    switch(textAlign) {
      case RowFlex.LEFT:
        return paddingLeft
      case RowFlex.CENTER:
        return (this.width - rowLength) / 2
      case RowFlex.RIGHT:
        return this.width - paddingLeft - rowLength
    }
  }
  draw() { // 绘制节点
    if (this.isFrameSelected && !this.isMoving) this.boundary.stroke()

    if (this.isImageType) {
      this.picture && this.picture.fill()
    } else {
      this.background.fillStroke()
      this.drawText()
    }
    if (!this.isMoving && !this.funcLock.isLocked) {
      this.junction.draw()
    }

    this.isSelected && this.funcLock.draw() // 绘制功能锁

    if (this.isClickSelected && !this.funcLock.isLocked) {
      this.border.draw() // 选中高亮
    }
  }

  drawText() {
    if (this.isShowText) {
      for (let text of this.texts) {
        text.fill()
      }
    }
  }

  hideText() {
    this.isShowText = false
  }
  showText() {
    this.isShowText = true
  }
  getValue() {
    return this.value
  }
  setValue(value) {
    this.operateNode.setValue(this, value)
  }
  getValues() {
    const value = this.value // 当前的值
    const values = this.values // 多行文本
    values.length = 0
    const rows = this.hacker.textarea.command.computeRowList(this.maxWidth - this.style.paddingLeft * 2, value)

    rows.forEach(row => {
      values.push(row)
    })

    if (rows.length === 0) {
      this.textWidth
    }
    this.textWidth = rows.length ? Math.max.apply(values, values.map(row => row.width)) : 0
    this.textHeight = rows.length ? values.map(v => v.height).reduce((pre, now) => pre + now, 0) : 0

    return { values }
  }
  setTexts() {
    const texts = this.texts
    texts.length = 0
    const { values } = this.getValues()
    if (values.length === 0) return

    let text
    for(const value of values) {
      for(const ele of value.elementList) {
        text = new Text(this.$ctx, 0, 0)
        ele.shape = text
        text.setText(ele.value)
        texts.push(text)
        this.style.setTextStyle(text, ele)
      }
    }
  }
  _apply() { // 在修改完node样式时，根据新的样式重新计算生成text
    this.setTexts()
    if (this.carrier) { // 如果是连接线上的文本节点，节点大小计算方式与普通节点不同
      this.setWidth(this.textWidth + this.style.paddingLeft * 2)
      this.setHeight(this.textHeight + this.style.paddingTop * 2)
      this.hacker.resize()
      if (this.isEditing) {
        this.carrier.layout.adjustText(this)
      }
    } else {
      if (this.isInit) { // 如果是初始化
        if (this.isCopyTextToCreate) { // 当是粘贴文字进来时
          this.setWidth(this.textWidth + this.style.paddingLeft * 2)
          this.setHeight(this.textHeight + this.style.paddingTop * 2)
        }
        this.setIsIntelHeight()
      }
      // 在初始化或者文本的高度小于节点的高度时，节点的高度由内容自动撑起来
      let move, curHeight
      if (this.isEditing) {
        if (this.isIntelHeight) {
          const _height = this.textHeight + this.style.paddingTop * 2
          if (_height > this.height) { // 内容高度大于等于节点高度，自动撑起节点，内容在增加
            curHeight = _height
          } else if (this.height > _height) { // 内容在减少
            if (this.height >= this.baseHeight) { // 节点高度已经超出图形基础高度，在内容减少的时候，取基础高度为最低高度
              curHeight = Math.max(_height, this.baseHeight)
            } else {
              curHeight = _height
            }
          }

          const gapY = curHeight - this.height
          move = {x: this.x, y: this.y - gapY / 2, gapX: 0, gapY: curHeight - this.height}
          if (Math.abs(move.gapY) > 1) {
            this.operateNode._resizeOperation(this, move)
            this.hacker.follow()
            this.hacker.resize()
          }
        } else { // 非自适应模式下，文字高度不在影响节点高度
          if (this.isContentOverflow) { // 节点内容已经溢出
            if (Math.abs(this.preTextHeight - this.textHeight) > 2) { // 换行时
              this.hacker.resize()
            }
          } else {
            if (this.preTextHeight === void 0 || this.preTextHeight > this.height) {
              this.hacker.resize()
            }
          }
          this.preTextHeight = this.textHeight
        }
      }
    }
  }
  calculateSize() {
    const {paddingTop} = this.style
    const middle = {x: (this.width - this.textWidth) / 2, y: (this.height - this.textHeight) / 2}
    this.style.contentTop = this.height > this.textHeight + paddingTop ? middle.y : paddingTop
    this.background.setWidth(this.width).setHeight(this.height)
    this.boundary.setWidth(this.width).setHeight(this.height)
    this.picture && this.picture.setWidth(this.width).setHeight(this.height)
  }
  distance(n) { // 判断一个图形和另一个图形的距离
    return Math.sqrt(Math.pow(this.cx - n.cx, 2) + Math.pow(this.cy - n.cy, 2))
  }
  isNearOther(n, gap = 30) { // 是否靠近或者重叠其他的节点
    if (n) return this.distance(n) - (this.width / 2 + n.width / 2) < gap
  }
  onmouseleave() { // 鼠标离开时
    this.junction.onmouseleave()
    this.border.onmouseleave()
  }
  onscale() { // 画板缩放后的回调 需要对特别图案进行处理
    this.border.onscale()
    this.junction.onscale()
  }
  eventmove(link) { // 判断鼠标是否进入 point: 鼠标对应的画点 mouse: 鼠标点
    const {x, y, boardX, boardY} = this.board.mouse
    this.isMoving = this.isMouseIn = false

    // 锁定图案在节点外部
    if (this.isSelected && this.funcLock.eventmove(x, y)) {
      this.mouseInWhich = 'funcLock'
      this.board.$setCursor('pointer')
    } else if (this.junction.isTouchBoundary = this.background._isPointInStroke(x, y)) { // 触碰到图形边线
      this.mouseInWhich = 'boundary'
      this.junction.setTouchPoint(boardX, boardY)
      // 当touch到连接线，而且连接线被选中的情况下，显示连接线的光标
      // ;(link && link.isSelected) || this.board.$setCursor('crosshair')
    } else if (this.isMouseIn = this.boundary.isPointInPath(x, y)) { // 鼠标进入形状内部
      this.board.$setCursor('move')
      if (this.hyperlink && this.hyperlinkState) {
        this.board.$setCursor("pointer")
      }
    } else { // 当鼠标游离到特殊范围之外，重置回默认形状
      this.board.$setCursor("default")
    }
    // 由于junction和border调节点位于节点内外部，位置出现重叠，需单独处理
    let dir
    if (this.isClickSelected && (dir = this.border.eventmove(boardX, boardY))) { // 鼠标touch到调节节点大小的锚点
      this.mouseInWhich = 'border'
      this.board.$setCursor(`${resizeCursors[dir]}-resize` || 'default')
    } else if (!(link && link.isSelected)) {
      if (this.junction.eventmove({x: boardX, y: boardY}, {x, y})) { // 当节点被选择后，连接处的锚点会向外扩散，不在节点上，需单独处理
        this.mouseInWhich = 'junction'
        this.board.$setCursor('crosshair')
      }
    }
    return this.junction.isMouseIn
  }
  eventup() {
    if (this.isMoving) { // 对图形节点进行移动或缩放等操作时需要隐藏一些属性，结束后属性需要重新展示
      this.isMoving = false
      this.draw()
    }
  }
  isHasLinkInJunction(jp) {
    let end = {}
    const links = this.board.links

    for (let link of Object.values(links)) {
      if (link.first === this || link.last === this) {// 等于当前选中节点
        if (link.start.distance({x: jp.sx, y: jp.sy}) < 0.5) return false// 在touch圆点附近已有连接线
      }
    }

    switch (jp.index) {
      case 0:
        end = {x:jp.sx - 80, y:jp.sy}
        break
      case 1:
        end = {x:jp.sx, y:jp.sy - 80}
        break
      case 2:
        end = {x:jp.sx + 80, y:jp.sy}
        break
      case 3:
        end = {x:jp.sx, y:jp.sy + 80}
        break
    }
    return end;
  }
  eventdown(ev) {
    this.beforeOffset()
    this.jump()
    if (this.junction.mouseInPoint) { // 鼠标是否touch到连接处
      if (this.funcLock.isLocked) return
      if (!this.border.mouseInPoint) { // 在非（touch到调整边框锚点)
        this.operateLink.createPreviewLink(this, this.junction.mouseInPoint, this.board.lineType)
      }
    } else if (this.mouseInWhich === 'funcLock') {
      this.funcLock.unlock()
      this.operateNode.lock([this], this.funcLock.lockType)
    } else {
      let group = this.board.operateGroup.getGroup(this.id);
      // 处理多选节点反选
      if(ev.metaKey && this.isFrameSelected){
        this.board.selector.unSelect(this.board.operateGroup.getGroupMembers(this.id))
        this.draw()
        this.board.box.calculateSize()
      }else{
        if(this.isMouseIn){
          group ? this.board.operateGroup.selectGroup(group):this.board.selector.select(this, ev)
        } else if (!this.border.mouseInPoint) {
          this.board.selector._clear()
        }
      }
    }
  }
  drag(x, y) {
    this.isMoving = true
    this.offset(x, y)
  }

  move(x, y) {
    this.offset(x, y)
  }
  setLayout(x, y) {
    this.layoutX = x
    this.layoutY = y
  }
  resize(move) { // 使用边框的八个锚点来调整节点的大小
    this.operateNode.resize(this, move)
  }
  select() {
    this.setSelectType(this.selectType || 'click') // 默认是点击选中
    if (this.isClickSelected) {
      this.junction.onSpread(1)
    }
  }
  setSelectType(v) { // 选择类型 click 点选, frame 框选
    this.selectType = v
    return this
  }

  setIsIntelHeight() { // 设置高度根据内容自适应开关
    return this.isIntelHeight = (this.textHeight + this.style.paddingTop * 2) <= this.height
  }

  jump(){
    // 点击节点同时按住 command + shift(mac) 跳转
    if (this.hyperlink && this.hyperlinkState) {
      // 每次跳转到新窗口后重置状态
      this.hyperlinkState = false;
      window && window.open(this.hyperlink, "_blank");
    }
  }
  unselect() {
    this.junction.onSpread(0)
    this.setSelectType('')
  }
  beforeOffset() { // 使用_x, _y 记录下偏移前的初始值
    this._x = this.x
    this._y = this.y
  }
  offset(x, y) {
    this.layoutX += x
    this.layoutY += y
  }
  setLayout(x, y) {
    this.layoutX = x
    this.layoutY = y
  }
  setWidth(value){
    this.width = value
    this.right = this.x + this.width
    this.cx = this.x + this.width / 2 // 中点
  }
  setHeight(value) {
    this.height = value
    this.bottom = this.y + this.height
    this.cy = this.y + this.height / 2
  }

  setLink(hyperlink){
    this.hyperlink = hyperlink
  }
  get maxWidth() { // 获取节点最大宽度
    return ((this.isInit && this.isCopyTextToCreate) || this.carrier) ? this.style.maxWidth : this.width
  }
  get isImageType() {
    return this.type === 'image'
  }
  get isTextType() {
    return this.type === 'text'
  }
  get isCopyTextToCreate() {
    return this.action === CREATE_ACTIONS.copyTextToCreate
  }
  get isNode() {
    return this.name === 'node'
  }
  get isSelected() {
    return !!this.selectType
  }
  get isClickSelected() { // 是否点击选中
    return this.selectType === 'click'
  }
  get isFrameSelected() { // 是否是框选
    return this.selectType === 'frame'
  }
  get baseHeight() {
    return this.shapeInfo ? this.shapeInfo.width * this.shapeInfo.ratio * this.board.grid.baseSize : this.height
  }
  get layoutX() {
    return this.x
  }
  set layoutX(x) {
    this.x = x
    this.left = x
    this.right = x + this.width
    this.cx = x + this.width / 2 // 中点
  }
  get layoutY() {
    return this.y
  }
  set layoutY(y) {
    this.y = y
    this.top = y
    this.bottom = y + this.height
    this.cy = y + this.height / 2
  }
  get operateLink() {
    return this.board.operateLink
  }
  get operateNode() {
    return this.board.operateNode
  }
  get hacker() {
    return this.board.hacker
  }
  get isContentOverflow() {
    return this.textHeight + this.style.paddingTop * 2 > this.height
  }
}
VNode.id = 0

VNode.copyValue = (value) => {
  return value.map(v => ({
    ...v,
    shape: null
  }))
}

VNode.formatValue = (value, style = {}) => {
  if (!value) return []
  const ls = []
  let text
  for(let i = 0; i < value.length; i++) {
    text = {
      value: value[i],
      type: 'text',
      fontFamily: style.fontFamily || 'Source Han Sans CN',
      fontColor: style.fontColor || '#1A1A1A',
      fontSize: style.fontSize || 18,
      underline: style.underline || false,
      bold: style.bold || false,
      italic: style.italic || false,
      rowFlex: 'center'
    }
    ls.push(text)
  }
  return ls
}
VNode.createNode = (ctx, board, data = {}) => {
  const { x, y, shapeInfo} = data
  if (shapeInfo) { // 计算节点初始大小
    if (!(data.width > 0 && data.height > 0)) {
      let { ratio, width } = shapeInfo
      width = width * board.grid.baseSize
      data = {...data, width, height: width * ratio}
    }
  }
  const node = new VNode(ctx, board, data)
  // layout 关联很多位置属性特殊处理
  node.isInit = true
  node.layoutX = x
  node.layoutY = y
  typeof node.value === 'string' && (node.value = VNode.formatValue(node.value, data.style))
  node.style.apply(data.style || board.style.node) // 如果是复制和初始化已经存在的节点，会自带样式，需保持配置
  node.apply()
  return node
}
VNode.Style = Style
export default VNode

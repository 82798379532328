import Style from '../style'

class FlodLinkStyle extends Style {
  constructor(target) {
    super(target)
    this.splitWidth = 6
    this.splitPointColor = '#3859DE'
  }
  setSplitStyle() {
    this.target.splitPoints.forEach(c => c.getStyle().setLineWidth(this.arrowLineWidth).setFillStyle(this.splitPointColor))
  }
  applyChildStyle() {
    const { background, compoundLine } = this.target
    background.getStyle().setStrokeStyle(this.backgroundColor).setLineWidth(this.backgroundLineWidth).setLineDash(this.lineDash)
    compoundLine.getStyle().setStrokeStyle(this.lineColor).setLineWidth(this.lineWidth).setLineDash(this.lineDash)
  }
}

export default FlodLinkStyle

import mathHelper from "../../../../utils/mathHelper";

// 这里只针对三阶
function getSamplePoints(compoundCurveLine, ptCount) {
  const { p1, p2, cp1, cp2 } = compoundCurveLine.getCurveLinePoints();
  const line = compoundCurveLine.curveLine;

  const arr = [p1, cp1, cp2, p2];
  const points = [];
  for (let i = 0; i <= ptCount; i++) {
    const p = line.bezier(arr, i / ptCount);
    points.push(p);
  }

  return points;
}

function getCubicCurveLength(compoundCurveLine, sampleCount) {
  const points = getSamplePoints(compoundCurveLine, sampleCount || 40);
  let totDist = 0;

  for (let i = 1; i < points.length; i++) {
    totDist += mathHelper.getDisOfTwoPoints(points[i - 1], points[i]);
  }

  return totDist;
}

// 𝑥𝑡=𝑥1−3(𝑥1−𝑥2)𝑡+3𝑡2(𝑥1−2𝑥2+𝑥3)−𝑡3(𝑥1−3𝑥2+3𝑥3−𝑥4)
// (𝑥1−3(𝑥1−𝑥2)𝑡+3𝑡2(𝑥1−2𝑥2+𝑥3)−𝑡3(𝑥1−3𝑥2+3𝑥3−𝑥4),𝑦1−3(𝑦1−𝑦2)𝑡+3𝑡2(𝑦1−2𝑦2+𝑦3)−𝑡3(𝑦1−3𝑦2+3𝑦3−𝑦4))
// ref: https://math.stackexchange.com/questions/2417451/tangent-to-a-bezier-cubic-curve
// getSlopeByPoints(p1, p2, p3, p4) {
//   const { x: x1, y: y1 } = p1;
//   const { x: x2, y: y2 } = p2;
//   const { x: x3, y: y3 } = p3;
//   const { x: x4, y: y4 } = p4;
//   return (
//     (y1 - y2 - (y1 - 2 * y2 + y3) + 0.25 * (y1 - 3 * y2 + 3 * y3 - y4)) /
//       (x1 - x2 - (x1 - 2 * x2 + x3) + 0.25 * (x1 - 3 * x2 + 3 * x3 - x4)) +
//     Math.PI
//   );
// }

// dP(t) / dt =  -3(1-t)^2 * P1 + 3(1-t)^2 * P2 - 6t(1-t) * P2 - 3t^2 * P3 + 6t(1-t) * P3 + 3t^2 * P4
// dP(t) / dt / -3 = (1-t)^2 * P1 - (1-t)^2 * P2 + 2t(1-t) * P2 + t^2 * P3 - 2t(1-t) * P3 - t^2 * P4
// getSlopeByPoints(p1, p2, p3, p4, t) {
//   const { x: x1, y: y1 } = p1
//   const { x: x2, y: y2 } = p2
//   const { x: x3, y: y3 } = p3
//   const { x: x4, y: y4 } = p4
//   const a1 = 1 - t
//   const a2 = a1 ** 2
//   const a3 = 2 * t * a1
//   const a4 = t ** 2
//   const dpx = a2 * x1 - a2 * x2 + a3 * x2 + a4 * x3 - a3 * x3 - a4 * x4
//   const dpy = a2 * y1 - a2 * y2 + a3 * y2 + a4 * y3 - a3 * y3 - a4 * y4
//   return (dpy / dpx) + Math.PI
// }

// dP(t) / dt =  (1-t)^2 * (P2 - P1) + 2(1-t)t * (P3 - P2) + t^2 * (P4 - P3)
function getSlopeByPoints(p1, p2, p3, p4, t) {
  const { x: x1, y: y1 } = p1;
  const { x: x2, y: y2 } = p2;
  const { x: x3, y: y3 } = p3;
  const { x: x4, y: y4 } = p4;
  const oneMinusT = 1 - t;
  const oneMinusTSquare = oneMinusT * oneMinusT;
  const tSquare = t * t;
  const dpx =
    oneMinusTSquare * (x2 - x1) +
    2 * oneMinusT * t * (x3 - x2) +
    tSquare * (x4 - x3);
  const dpy =
    oneMinusTSquare * (y2 - y1) +
    2 * oneMinusT * t * (y3 - y2) +
    tSquare * (y4 - y3);
  const res = dpy / dpx;
  return res > Math.PI * 2 ? res - Math.PI * 2 : res;
}

// 获取曲线的两个控制点
function getCurveLineInitCPs(p1, p1Angle, p2, p2Angle, distance) {
  const len = distance * 0.2;
  const cp1s = mathHelper.getEndPointOfSlash(p1, p1Angle, len);
  const cp2s = mathHelper.getEndPointOfSlash(p2, p2Angle, len);

  const len_ = len * 2;
  const cp1 = mathHelper.getEndPointOfSlash(p1, p1Angle, len_);
  const cp2 = mathHelper.getEndPointOfSlash(p2, p2Angle, len_);

  return { cp1, cp2, cp1s, cp2s };
}

// 获取曲线
function getCurveLineShowCPs(p1, p2, cp1, cp2) {
  const slope1 = mathHelper.getSlopeByTwoPoint(p1, cp1)
  const len1 = mathHelper.getDisOfTwoPoints(p1, cp1)
  const cp1s = mathHelper.getEndPointOfSlash(p1, slope1, len1 / 2.0)


  const slope2 = mathHelper.getSlopeByTwoPoint(p2, cp2)
  const len2 =  mathHelper.getDisOfTwoPoints(p2, cp2)
  const cp2s = mathHelper.getEndPointOfSlash(p2, slope2, len2 / 2.0);

  return { cp1s, cp2s };
}

function getInitCurveLinePoints(start, end, angles) {
  const [sAngle, eAngle] = angles;
  const distance = mathHelper.getDisOfTwoPoints(start, end);

  const [p1, p2] = [start, end];
  const { cp1, cp2, cp1s, cp2s } = getCurveLineInitCPs(
    p1,
    sAngle,
    p2,
    eAngle,
    distance
  );
  return { p1, p2, cp1, cp2, cp1s, cp2s };
}


export { 
  getCubicCurveLength,
  getSlopeByPoints,
  getInitCurveLinePoints,
  getCurveLineShowCPs
};

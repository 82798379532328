/*
 * @Author: 赵欣 635538643@qq.com
 * @Date: 2022-06-13 13:33:54
 * @LastEditors: 赵欣 635538643@qq.com
 * @LastEditTime: 2022-06-14 23:03:05
 * @FilePath: \han_diagram\src\components\menu\style.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import styled from 'styled-components'

const MenuCssWrapper = styled.div `
  padding: 14px 0;
  width: 200px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, .5);
  border-radius: 4px;
  .item {
    height: 30px;
    line-height: 32px;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor:pointer;
    .item :hover{
      background: #000;
    }
    &-ch {
      text-align: left;
      text-indent: 10px;
      color: rgba(13, 12, 12, 1);
    }
    &-en {
      color: rgba(166, 166, 166, 1);
      text-align: left;
      padding-right: 10px;
      text-align: right;
      flex: 1;
    }
    &:hover {
      background-color: #eee;
    }
    
  }
  .empty-item {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    display: flex;
    align-items: center;
    &-ch {
      text-align: left;
      text-indent: 10px;
      color: rgba(166, 166, 166, 1);
    }
    &-en {
      color: rgba(166, 166, 166, 1);
      text-align: right;
      padding-right: 10px;
      text-align: right;
      flex: 1;
    }
    &-ch :nth-child(6){
    }
    &-en :nth-child(6){
      flex-shrink: 0
   }
  }
`
const SmallCss = styled.div `
  padding: 14px 0;
  width: 190px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, .5);
  border-radius: 4px;
  .items {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    display: flex;
    color: rgba(13, 12, 12, 1);
    cursor:pointer;
    &-ch {
      width: 140px;
      text-align: left;
      text-indent: 10px;
      color: rgba(13, 12, 12, 1);
    }
    &-en {
      max-width: 120px;
      color: rgba(166, 166, 166, 1);
      text-align: left;
      padding-right: 10px;
      overflow: auto;
      text-align: center;
      flex: 1;
    }
    &:hover {
      background-color: #eee;
    }
    
  }
`
export { MenuCssWrapper, SmallCss }

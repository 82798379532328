/*
  矩阵
 */
class Matrix {
  constructor() {
    this.offsetX = Matrix.DEFAULT_OFFSET_X
    this.offsetY = Matrix.DEFAULT_OFFSET_Y
    this.angle = Matrix.DEFAULT_ANGLE
    this._cos = Matrix.DEFAULT_COS
    this._sin = Matrix.DEFAULT_SIN
    this.scaleX = Matrix.DEFAULT_SCALE_X
    this.scaleY = Matrix.DEFAULT_SCALE_Y
    this.skewX = Matrix.DEFAULT_SKEW_X
    this._tanX = Matrix.DEFAULT_TAN_X
    this.skewY = Matrix.DEFAULT_SKEW_Y
    this._tanY = Matrix.DEFAULT_TAN_Y
    this._tanV = Matrix.DEFAULT_TAN_V
  }
  static DEFAULT_SKEW_X = 0
  static DEFAULT_TAN_X = 0
  static DEFAULT_SKEW_Y = 0
  static DEFAULT_TAN_Y = 0
  static DEFAULT_TAN_V = 1
  static DEFAULT_SCALE_X = 1
  static DEFAULT_SCALE_Y = 1
  static DEFAULT_ANGLE = 0
  static DEFAULT_COS = 1
  static DEFAULT_SIN = 0
  static DEFAULT_OFFSET_X = 0
  static DEFAULT_OFFSET_Y = 0

  equals (matrix) {
    return (
      this.angle === matrix.angle &&
      this.scaleX === matrix.scaleX &&
      this.scaleY === matrix.scaleY &&
      this.offsetX === matrix.offsetX &&
      this.offsetY === matrix.offsetY &&
      this.skewX === matrix.skewX &&
      this.skewY === matrix.skewY
    );
  }
  getM11 () {
    return this._cos * this.scaleX - this._tanY * this._sin * this.scaleY;
  }
  getM11i () {
    return (
      (this._cos * this._tanV) / this.scaleX +
      (this._tanX * this._tanV * this._sin) / this.scaleY
    );
  }
  getM12 () {
    return this._sin * this.scaleX + this._tanY * this._cos * this.scaleY;
  }
  getM12i () {
    return (
      (-this._tanY * this._tanV * this._cos) / this.scaleX -
      (this._sin * this._tanV) / this.scaleY
    );
  }
  getM21 () {
    return -this._sin * this.scaleY + this._tanX * this._cos * this.scaleX;
  }
  getM21i () {
    return (
      (this._sin * this._tanV) / this.scaleX -
      (this._tanX * this._tanV * this._cos) / this.scaleY
    );
  }
  getM22 () {
    return this._cos * this.scaleY + this._tanX * this._sin * this.scaleX;
  }
  getM22i () {
    return (
      (-this._tanY * this._tanV * this._sin) / this.scaleX +
      (this._cos * this._tanV) / this.scaleY
    );
  }
  getM41 () {
    return this.offsetX;
  }
  getM41i () {
    return -this.offsetX * this.getM11i() - this.offsetY * this.getM21i();
  }
  getM42 () {
    return this.offsetY;
  }
  getM42i () {
    return -this.offsetX * this.getM12i() - this.offsetY * this.getM22i();
  }
  getSkewX () {
    return this.skewX;
  }
  setSkewX (skewX) {
    return (
      this.skewX !== skewX &&
        ((this.skewX = skewX),
        (this._tanX = Math.tan(skewX)),
        (this._tanV = 1 / (1 - this._tanX * this._tanY))),
      this
    );
  }
  getSkewY () {
    return this.skewY;
  }
  setSkewY (skewY) {
    return (
      this.skewY !== skewY &&
        ((this.skewY = skewY),
        (this._tanY = Math.tan(skewY)),
        (this._tanV = 1 / (1 - this._tanX * this._tanY))),
      this
    );
  }
  setSkew (skewX, skewY) {
    return (
      this.skewX !== skewX &&
        ((this.skewX = skewX), (this._tanX = Math.tan(skewX))),
      this.skewY !== skewY &&
        ((this.skewY = skewY), (this._tanY = Math.tan(skewY))),
      (this._tanV = 1 / (1 - this._tanX * this._tanY)),
      this
    );
  }
  getScaleX () {
    return this.scaleX;
  }
  setScaleX (scaleX) {
    return (this.scaleX = scaleX), this;
  }
  getScaleY () {
    return this.scaleY;
  }
  setScaleY (scaleY) {
    return (this.scaleY = scaleY), this;
  }
  setScale (scaleX, scaleY) {
    return (this.scaleX = scaleX), (this.scaleY = scaleY), this;
  }
  getAngle () {
    return this.angle;
  }
  setAngle (angle) {
    if (this.angle !== angle) {
      this.angle = angle
      this._cos = Math.cos(angle)
      this._sin = Math.sin(angle)
    }
    return this
  }
  getOffsetX () {
    return this.offsetX;
  }
  setOffsetX (offsetX) {
    return (this.offsetX = offsetX), this;
  }
  getOffsetY () {
    return this.offsetY;
  }
  setOffsetY (offsetY) {
    return (this.offsetY = offsetY), this;
  }
  setOffset (offsetX, offsetY) {
    return (this.offsetX = offsetX), (this.offsetY = offsetY), this;
  }
}

export default Matrix
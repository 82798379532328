import Circle from "../comm/customCircle";
import Line from "../../shapes/line";
import BezierLine from '../../shapes/bezierLine'
import mathHelper from "../../../../utils/mathHelper";
import { getCubicCurveLength } from "./cubicHelper";

// 复合曲线
class CompoundCurveLine {
  /**
   * @param {*} config 主要用于创建初始的curve的配置参数, 可以为空
   */
  constructor(ctx, board, style, config) {
    this.ctx = ctx;
    this.board = board;
    this.style = style

    // 曲线头节点
    this.curveHead = this.createCircle(); // 头部调节点

    // 曲线中点载体
    this.centerSplit = this.createCircle();

    // 曲线
    this.curveLine = this.createCurve(config);
    this.background = this.createCurve(config);

    // 控制线
    this.startControl = {
      dragTarget: this.createCircle(),
      sDis: undefined, // 使用相对位置的原因: 当我们拖动端点的时候，控制点都要对应变化，这样在不调整控制点的情况下，控制点的最新位置直接可以基于相对位置计算
      dis: undefined,
      line: this.createLine(),
    };
    this.endControl = {
      dragTarget: this.createCircle(),
      sDis: undefined,
      dis: undefined,
      line: this.createLine(),
    };

    // 控制点是否移动过
    this.isControlPointUpdated = false;
  }

  createCircle() {
    return new Circle(this.ctx, 0, 0, 0, 0, 0, 0);
  }

  createCurve(config) {
    const { sx, sy, ex, ey, cp1x, cp1y, cp2x, cp2y } =
      this.getInitPoints(config);
    return new BezierLine(this.ctx, sx, sy, ex, ey, cp1x, cp1y, cp2x, cp2y);
  }

  createLine() {
    return new Line(this.ctx, 0, 0, 0, 0);
  }

  getInitPoints(config) {
    const { p1, p2, cp1, cp2 } = config || {};
    const sx = p1?.x || 0;
    const sy = p1?.y || 0;
    const ex = p2?.x || 0;
    const ey = p2?.y || 0;

    const cp1x = cp1?.x || 0;
    const cp1y = cp1?.y || 0;
    const cp2x = cp2?.x || 0;
    const cp2y = cp2?.y || 0;

    return {
      sx,
      sy,
      ex,
      ey,
      cp1x,
      cp1y,
      cp2x,
      cp2y,
    };
  }


  getPatchedCurvePoints(move) {
    const { p1, p2, cp1, cp2 } = this.getCurveLinePoints()
    const { cp1s, cp2s } = this.getShowControlsPos()
    const nP1 = mathHelper.getPointPatchedPos(p1, move)
    const nP2 = mathHelper.getPointPatchedPos(p2, move)
    const nCP1 = mathHelper.getPointPatchedPos(cp1, move)
    const nCP2 = mathHelper.getPointPatchedPos(cp2, move)
    const nCP1s = mathHelper.getPointPatchedPos(cp1s, move)
    const nCP2s = mathHelper.getPointPatchedPos(cp2s, move)
    return {p1: nP1, p2: nP2, cp1: nCP1, cp2: nCP2, cp1s: nCP1s, cp2s: nCP2s}
  }

  offset(x, y) {
    const {p1, p2, cp1, cp2, cp1s, cp2s} = this.getPatchedCurvePoints({x, y})
    this.updateCurveLine(p1, p2, cp1, cp2, cp1s, cp2s);
  }

  draw(isSelected, isShowHead) {
    this.curveLine.stroke();

    if (isSelected) {
      this.background.stroke();
      ["startControl", "endControl"].forEach((h) => {
        this[h].dragTarget.strokeFill();
      });

      const ctx = this.ctx;

      ctx.save();
      ctx.setLineDash([4]);
      this.startControl.line.stroke();
      this.endControl.line.stroke();
      ctx.restore();

      this.centerSplit.strokeFill();

      if (isShowHead) {
        this.curveHead.strokeFill();
      }
    }
  }

  isEnterBackground(x, y) {
    return this.background._isQuickInStroke(x, y, 3);
  }

  getBasicSize() {
    return Math.min(this.board.getDrawLength(this.style.headWidth))
  }

  // 控制点和curveHead
  updateControlPointCurveHeadSize(size) {
    [this.startControl, this.endControl].forEach((h) => {
      h.dragTarget.setWidth(size).setHeight(size);
    });
    this.curveHead.setWidth(size).setHeight(size);
    this.centerSplit.setWidth(size).setHeight(size);
    
    return this;
  }

  setControlXY(start, end) {
    start && this.startControl.dragTarget.setStart(start.x, start.y);
    end && this.endControl.dragTarget.setStart(end.x, end.y);
    return this;
  }

  updateCurveLineCentrePoint(p1, p2, cp1, cp2) {
    const mid = this.curveLine.getMiddlePoint([p1, cp1, cp2, p2]);
    const w = this.getBasicSize();
    this.centerSplit.setWidth(w).setHeight(w).setStart(mid.x, mid.y);
  }

  // 鼠标拖动
  getMovedControlsPos(p, cps, move) {
    const newCps = mathHelper.getPointPatchedPos(cps, move);
    const angle = mathHelper.getSlopeByTwoPoint(p, newCps);
    const dis = mathHelper.getDisOfTwoPoints(p, newCps);
    const cp = mathHelper.getEndPointOfSlash(p, angle, 2 * dis);

    return { cps: newCps, cp, angle };
  }

  // oldDis: 上一次距离
  getRotatedControlsPos(p, cps, angle) {
    const dis = mathHelper.getDisOfTwoPoints(p, cps);
    const newCps = mathHelper.getEndPointOfSlash(p, angle, dis);
    const cp = mathHelper.getEndPointOfSlash(p, angle, 2 * dis);

    return { cps: newCps, cp };
  }

  // 显示的拖动控制点
  getShowControlsPos() {
    const sD = this.startControl.dragTarget;
    const eD = this.endControl.dragTarget;

    const cp1s = { x: sD.sx, y: sD.sy };
    const cp2s = { x: eD.sx, y: eD.sy };
    return { cp1s, cp2s };
  }

  // 获取曲线的两个端点和两个真实控制点
  getCurveLinePoints() {
    const { sx, sy, ex, ey, cp1x, cp1y, cp2x, cp2y } = this.curveLine;
    const p1 = { x: sx, y: sy };
    const p2 = { x: ex, y: ey };

    let cp1 = { x: cp1x, y: cp1y };
    let cp2 = { x: cp2x, y: cp2y };

    return { p1, p2, cp1, cp2 };
  }

  // 获取曲线中心点
  getCurveLineCenterPoint() {
    const cS = this.centerSplit;
    const cp = { x: cS.sx, y: cS.sy };
    return cp;
  }

  // 获取曲线终点
  getCurveLineEndPoint() {
    const { ex, ey } = this.curveLine;
    return {x: ex, y: ey }
  }

  // 获取又一定偏移位置的曲线属性的备份
  getCurveLineCopiedAttrWithMove() {
    const cS = this.centerSplit;
    const cp = { x: cS.sx, y: cS.sy };
    return cp;
  }

  // 获取箭头slope和箭头占据曲线的比率
  getArrowSlope(arrowLen, isStart) {
    const len = getCubicCurveLength(this);
    const rate = arrowLen / len;

    const { p1, p2, cp1, cp2 } = this.getCurveLinePoints();
    const arr = [p1, cp1, cp2, p2];
    const bezierLine = this.curveLine;
    let p, slope
    if(isStart) {
      p = bezierLine.bezier(arr, rate);
      slope = mathHelper.getSlopeByTwoPoint(p1, p)
    } else {
      p = bezierLine.bezier(arr, 1- rate);
      slope = mathHelper.getSlopeByTwoPoint(p2, p)
    }

    return slope;
  }

  // 拖动曲线控制点
  dragCurveLineControlPoint(move, arrowLen, isStart) {
    let { cp1s, cp2s } = this.getShowControlsPos();
    let { cp1, cp2, p1, p2 } = this.getCurveLinePoints();

    let controlSlope;
    let arrowSlope

    if (isStart) {
      const ret = this.getMovedControlsPos(p1, cp1s, move);
      cp1s = ret.cps;
      cp1 = ret.cp;
      controlSlope = ret.angle;
      // 只有第一条线才需要计算, 可以优化
      arrowSlope = this.getArrowSlope(arrowLen, isStart)
      this.updateControlPoint(cp1s, cp1, isStart);
    } else {
      const ret = this.getMovedControlsPos(p2, cp2s, move);
      cp2s = ret.cps;
      cp2 = ret.cp;
      controlSlope = ret.angle;
      // 只有最后一条线才需要计算, 可以优化
      arrowSlope = this.getArrowSlope(arrowLen, isStart)
      this.updateControlPoint(cp2s, cp2, isStart);
    }

    // 为了保证一旦任何一个控制点移动后,就记录两个端点的相对位置距离
    // 更新控制点与对应端点的相对距离
    this.updateControlPointsRDis(cp1, cp2, cp1s, cp2s);
    this.updateCurveLineCentrePoint(p1, p2, cp1, cp2);
    // 后面永远是拖动一次就是永远是 true
    this.isControlPointUpdated = true;
    return {controlSlope, arrowSlope};
  }

  // 根据新的角度计算新的控制点位置
  dragCurveLineControlByAnglePoint(angle, isStart) {
    let { cp1s, cp2s } = this.getShowControlsPos();
    let { cp1, cp2, p1, p2 } = this.getCurveLinePoints();

    if (isStart) {
      const ret = this.getRotatedControlsPos(p1, cp1s, angle);
      cp1s = ret.cps;
      cp1 = ret.cp;
      this.updateControlPoint(cp1s, cp1, isStart);
    } else {
      const ret = this.getRotatedControlsPos(p2, cp2s, angle);
      cp2s = ret.cps;
      cp2 = ret.cp;
      this.updateControlPoint(cp2s, cp2, isStart);
    }

    // 为了保证一旦任何一个控制点移动后,就记录两个端点的相对位置距离
    // 更新控制点与对应端点的相对距离
    this.updateControlPointsRDis(cp1, cp2, cp1s, cp2s);
    this.updateCurveLineCentrePoint(p1, p2, cp1, cp2);
  }

  // 更新控制点与起点的相对位置
  updateControlPointsRDis(cp1, cp2, cp1s, cp2s) {
    const { p1, p2 } = this.getCurveLinePoints();
    const cp1sDis = mathHelper.getPointOffsetPos(p1, cp1s);
    const cp1Dis = mathHelper.getPointOffsetPos(p1, cp1);
    this.startControl.sDis = cp1sDis;
    this.startControl.dis = cp1Dis;

    const cp2sDis = mathHelper.getPointOffsetPos(p2, cp2s);
    const cp2Dis = mathHelper.getPointOffsetPos(p2, cp2);
    this.endControl.sDis = cp2sDis;
    this.endControl.dis = cp2Dis;
  }

  // 更新控制点引发的曲线更新 isCp1:是控制点1还是2
  updateControlPoint(cps, cp, isCp1) {
    if (isCp1) {
      this.setControlXY(cps, null);
      this.startControl.line.setEnd(cps.x, cps.y);
      this.curveLine.setControlPoint1(cp.x, cp.y);
      this.background.setControlPoint1(cp.x, cp.y);
    } else {
      this.setControlXY(null, cps);
      this.endControl.line.setEnd(cps.x, cps.y);
      this.curveLine.setControlPoint2(cp.x, cp.y);
      this.background.setControlPoint2(cp.x, cp.y);
    }
  }

  // 更新曲线
  updateCurveLine(p1, p2, cp1, cp2, cp1s, cp2s) {
    const size = this.getBasicSize();
    this.updateControlPointCurveHeadSize(size).setControlXY(cp1s, cp2s);

    this.startControl.line.setStart(p1.x, p1.y).setEnd(cp1s.x, cp1s.y);
    this.endControl.line.setStart(p2.x, p2.y).setEnd(cp2s.x, cp2s.y);

    this.curveHead.setWidth(size).setHeight(size).setStart(p1.x, p1.y);
    this.curveLine
      .setStart(p1.x, p1.y)
      .setEnd(p2.x, p2.y)
      .setControlPoint1(cp1.x, cp1.y)
      .setControlPoint2(cp2.x, cp2.y);
    this.background
      .setStart(p1.x, p1.y)
      .setEnd(p2.x, p2.y)
      .setControlPoint1(cp1.x, cp1.y)
      .setControlPoint2(cp2.x, cp2.y);

    // 更新中点
    this.updateCurveLineCentrePoint(p1, p2, cp1, cp2);
  }

  // 拖动端点产生的变化
  getUpdateCurveLinePoints(p1, p2) {
    const cp1sDis = this.startControl.sDis;
    const cp2sDis = this.endControl.sDis;

    const cp1Dis = this.startControl.dis;
    const cp2Dis = this.endControl.dis;

    const cp1s = { x: p1.x + cp1sDis.x, y: p1.y + cp1sDis.y };
    const cp2s = { x: p2.x + cp2sDis.x, y: p2.y + cp2sDis.y };

    const cp1 = { x: p1.x + cp1Dis.x, y: p1.y + cp1Dis.y };
    const cp2 = { x: p2.x + cp2Dis.x, y: p2.y + cp2Dis.y };

    return { p1, p2, cp1, cp2, cp1s, cp2s };
  }

  isTouchingControlPoint({ x, y }, gap = 0) {
    const a = ["startControl", "endControl"];
    for (let key of a) {
      const circle = this[key].dragTarget;
      const halfW = circle.width / 2;
      if (circle._isQuickInPath(x + halfW, y + halfW, gap)) {
        // circle.getStyle().setFillStyle(this.style.controlPointHoverColor);
        return key;
      }
    }
  }

  // 是否进入分割点
  isTouchingOnSplitPoint({ x, y }, gap = 0) {
    const circle = this.centerSplit;
    const halfW = circle.width / 2;
    const isIn = circle._isQuickInPath(x + halfW, y + halfW, gap);
    if (isIn) {
      // circle.getStyle().setFillStyle(this.style.controlPointHoverColor);
      return "split";
    }
  }

  // 是否进入中点(已经分割过了)
  isTouchingOnDragPoint({ x, y }, gap = 0) {
    const circle = this.curveHead;
    const halfW = circle.width / 2;
    const isIn = circle._isQuickInPath(x + halfW, y + halfW, gap);
    if (isIn) {
      // circle.getStyle().setFillStyle(this.style.controlPointHoverColor);
      return "dragPoint";
    }
  }
}

export default CompoundCurveLine;

const SnapDistance = 5

/**
* 
* @param {*} value1: number
* @param {*} values: Array(number) 
* @param {*} type: Enum 参照对象的那条边
* @returns 
*/
function isInRangeMore(value1, values, type) {
  for (let i = 0; i < values.length; i++) {
    const value = isInRange(value1, values[i], SnapDistance)

    if (value) {
      return { value: value1 - values[i], isFirst: i === 0, isLast: i === values.length -1,  type }
    }
  }

  return { value: false };
}

/**
* 
* @param {*} value1: number
* @param {*} value2: number
* @param {*} diff: number
* @returns 
*/
function isInRange(value1, value2, diff = SnapDistance) {
  value1 = Math.round(value1);
  const inValue = Math.round(value2)

  return (
    inValue >= value1 - diff &&
    inValue <= value1 + diff
  )
}

function getNormalizedRectProps(rect) {
  const { sx, sy, width, height } = rect
  const halfW = width * 0.5
  const halfH = height * 0.5
  const x1 = sx, x2 = sx + halfW, x3 = sx + width
  const y1 = sy, y2 = sy + halfH, y3 = sy + height

  return {
    centerX: x2,
    centerY: y2,
    topY: y1,
    bottomY: y3,
    leftX: x1,
    rightX: x3,
    width,
    height
  }
}


function getExceptNodePosByMoveNode(initPosWithSize, move) {
  const pos = getNormalizedRectProps(initPosWithSize)
  return {
    centerX: pos.centerX + move.x,
    centerY: pos.centerY + move.y,
    topY: pos.topY + move.y,
    bottomY: pos.bottomY + move.y,
    leftX: pos.leftX + move.x,
    rightX: pos.rightX + move.x,
    width: pos.width,
    height: pos.height
  }
}

function getExceptNodePosFromObj(obj, startPoint) {
  const bd = obj.border.boundary
  const { x, y } = startPoint
  const { width, height } = bd

  return {
    centerX: x + width / 2,
    centerY: y + height / 2,
    topY: y,
    bottomY: y + height,
    leftX: x,
    rightX: x + width,
    width,
    height
  }
}

function getExceptNodePosByDragPoint(initPosWithSize, move, pointId) {
  const pos = getNormalizedRectProps(initPosWithSize)

  switch (pointId) {
    case 1: {
      pos.leftX += move.x
      pos.topY += move.y
      pos.width += move.x
      pos.height += move.y
      break
    }
    case 2: {
      pos.topY += move.y
      pos.height += move.y
      break
    }
    case 3: {
      pos.rightX += move.x
      pos.topY += move.y
      pos.width += move.x
      pos.height += move.y
      break
    }
    case 4: {
      pos.rightX += move.x
      pos.width += move.x
      break
    }
    case 5: {
      pos.rightX += move.x
      pos.bottomY += move.y
      pos.width += move.x
      pos.height += move.y
      break
    }
    case 6: {
      pos.bottomY += move.y
      pos.height += move.y
      break
    }
    case 7: {
      pos.leftX += move.x
      pos.bottomY += move.y
      pos.width += move.x
      pos.height += move.y
      break
    }
    case 8: {
      pos.leftX += move.x
      pos.width += move.x
      break
    }
    default: { }
  }

  return pos
}


function getObjectPos(obj) {
  if (!obj || (['node'].indexOf(obj.name) === -1)) return

  switch (obj.name) {
    case 'node': {
      return getNormalizedRectProps(obj.border.boundary)
    }

    default: {
      return {}
    }
  }
}

function getExceptLinkPosByDrag(initPos, move) {
  return {
    x: initPos.x + move.x,
    y: initPos.y + move.y,
  }
}

// 临时,获取选中的分组
function getSelectedGrounp(that) {
  const selects = that.box.selector.selected
  let res
  for(let s of selects) {
    if (res = that.operateBoard.board.operateGroup.getGroup(s.id)) {
      break
    }
  }
  return res
}

function getActiveObject(that) {
  const isMultiSelect = (that.box.selector.selected || []).length > 1
  const hasDragObj = !!that.operateNode.dragInShape

  let activeObject

  if (hasDragObj) {
    activeObject = that.operateNode.dragInShape
  } else {
    if (isMultiSelect) {
      const group = getSelectedGrounp(that)
      if (group) {
        activeObject = group
      } else {
        activeObject = that.box
      }
    } else {
      activeObject = that.box.selector.selected[0] 
    }
  }

  const hasNode = isMultiSelect || activeObject?.name === "node"

  const isPreviewLink = !!that.operateLink.previewLink
  if (isPreviewLink) {
    activeObject = that.operateLink.previewLink
  }

  const isLink = isPreviewLink || activeObject?.name === "link"
  return { activeObject, hasNode, isLink }
}

function isMouseDownLinkEndHead(link) {
  const { mouseInWhich } = link || {}
  return mouseInWhich === "endHead"
}

function getTargetPos(gridSize, gridStartPos, expectPos) {
  const lx = expectPos.x
  const ty = expectPos.y
  const x = lx - ((lx - gridStartPos.x + gridSize / 2) % gridSize) + gridSize / 2
  const y = ty - ((ty - gridStartPos.y + gridSize / 2) % gridSize) + gridSize / 2

  return { x, y }
}

function getRefPosType(pointId) {
  let x
  let y
  switch (pointId) {
    case 1: {
      x = 'left'
      y = 'top'
      break
    }
    case 2: {
      x = 'center'
      y = 'top'
      break
    }
    case 3: {
      x = 'right'
      y = 'top'
      break
    }
    case 4: {
      x = 'right'
      y = 'center'
      break
    }
    case 5: {
      x = 'right'
      y = 'bottom'
      break
    }
    case 6: {
      x = 'center'
      y = 'bottom'
      break
    }
    case 7: {
      x = 'left'
      y = 'bottom'
      break
    }
    case 8: {
      x = 'left'
      y = 'center'
      break
    }
    default: {
      return null
    }
  }

  return { x, y }
}


function hasPosChanged(pos1, pos2) {
  return (Math.abs(pos1.x - pos2.x) > 0.00001) || (Math.abs(pos1.y - pos2.y) > 0.00001)
}


function getPointToLineChuiZu(start, end, point){
  const A = end.y - start.y;
  const B = start.x - end.x;
  const C = - start.y * B - start.x * A;
  const D = A*point.y - B*point.x;
  const x = -(B*D + A*C)/(B*B + A*A);
  const y = B == 0 ? point.y : -(A*x+C)/B;
  const snapPos = {x, y}
  const lineMid = { x: (start.x + end.x) / 2, y: (start.y + end.y) / 2 }
  return { snapPos, lineMid };
}

function getDisOfTwoPoints(p1, p2) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

export default {
  isInRangeMore,
  isInRange,
  isMouseDownLinkEndHead,
  getExceptNodePosByMoveNode,
  getExceptNodePosByDragPoint,
  getExceptNodePosFromObj,
  getExceptLinkPosByDrag,
  getObjectPos,
  getNormalizedRectProps,
  getActiveObject,
  getTargetPos,
  getRefPosType,
  hasPosChanged,
  getDisOfTwoPoints,
  getPointToLineChuiZu
}
import styled from 'styled-components'

export const ToolsLinkStyle = styled.div`
.linkTools{
    padding-left:20px;
    padding-right: 20px;
  }
  .linkTools .shape{
    border-bottom:1px solid #E3E3E3;
    width:100%;
    height:100%;
  }
  .linkTools .shape .iconBox{
    display:flex;
    align-items:center;
  }

  .linkTools .shape .iconBox .icon{
    display: flex;
    align-items: center;
    justify-content:center;
    margin-top:20px;
  }

  .linkTools .shape .btnBox{
    padding-bottom:20px;
    display: flex;
    align-items: center;
    justify-content:space-between;
  }

  .linkTools .shape .btnBox .boxXZ{
    width: 110px;
    height: 34px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content:center;
  }
  .linkTools .shape .btnBox .boxXZ .fang{
    width: 18px;
    height: 18px;
    border:1px solid #000;
  }
  .linkTools .shape .btnBox .num2{
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    width: 110px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content:center;
  }
  .linkTools .shape .btnBox .num2 img{
    width:28px;
    height:20px;
  }

  .linkTools .shape .btn2{
    display: flex;
    align-items: center;
    justify-content:space-between;
  }
  .linkTools .shape .btn2 .boxs{
    display: flex;
    align-items: center;
    justify-content:center;
  }

  .linkTools .shape .boxXZ{
    width: 110px;
    height: 34px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content:center;
  }
`

/*
  添加内容输入层 textarea
  pintevent: none   auto-默认值  none-鼠标捕捉不到元素，可以透过元素捕捉到下层元素
  _bind: 输入层覆盖的元素，输入层的大小和 _bind 保持一致
  oncontextmenu: 用户右击鼠标时触发打开上下文菜单
 */
import Editor from 'canvas-editor'
import { NODE_DEFAULT_STYLE } from '../node/constant'

let timer = null
class Hacker {
  constructor(board) {
    this.board = board
    this.width = 0
    this.height = 0
    this.init()
    this.initCallbacks()
  }
  static unit = 'px'

  init () {
    this.border = document.getElementById("action-wrapper");
    this.textarea = new Editor(this.border, [], {margins: [0, 0, 0, 0],
      width: 1, height: 1, defaultFontColor: NODE_DEFAULT_STYLE.fontColor,
      defaultFontSize: NODE_DEFAULT_STYLE.fontSize,
      defaultFont: NODE_DEFAULT_STYLE.fontFamily
    })
    // this.border.setAttribute('contenteditable', true)
    this.textarea.command.setEditorStyle({
      'position': 'absolute',
      'top': 0,
      'left': 0
    })
    this.border.style.border = 'none'
    this._isActivated = false
    this._hideBorder()
  }

  initCallbacks () {
    var border = this.border;
    border.oncontextmenu = function (ev) {
      ev.stopPropagation();
      ev.preventDefault(); // TODO: 使用 oncontextmenu(x, y) 回调出去，调用 Menu 菜单
    };
    border.addEventListener('mousemove', () => {
      // 清除节点的字元素hover状态
      if (this._bind && this._bind.isSelected && this._bind.border.mouseInPoint) {
        this._bind.border.recoveryEffect()
        this._bind.draw()
      }
    })
    this.textarea.listener.contentChange = this._onInput.bind(this)
    this.textarea.listener.cursorChange = this._onCursorChange.bind(this)
  }
  _showBorder() {
    this.border.style.display = "initial"
    this.border.style.pointerEvents = "auto"
  }
  _hideBorder() {
    this.border.style.display = "none";
    this.border.style.pointerEvents = "none";
  }
  _onInput () {
    // 实时布局
    if (this._bind) {
      this.onInput(this._bind);
    }
  }
  _onCursorChange(text) {
    if (this._bind && text) {
      const {fontFamily, fontSize, fontColor, underline, bold, italic} = text
      const sty = {fontFamily, fontSize, fontColor, underline, bold, italic}
      this._bind.style.changeStyle(sty, false)
      this.onCursorChange(sty);
    }
  }
  onpaste(data, needSetBindValue = true) {
    this.textarea.command.paste(data)
    needSetBindValue && this.setBindValue()
  }
  parseText(payload) {
    return this.textarea.command.parseText(payload)
  }
  focus() {
    this.textarea.command.focus()
  }
  changeTextStyle(styleConfig) {
    if (!this.styleMap) {
      const map = this.styleMap = new Map()
      map.set('fontFamily', (v) => {
        this.textarea.command.font(v)
      })
      map.set('fontSize', (v) => {
        this.textarea.command.size(v)
      })
      map.set('fontColor', (v) => {
        this.textarea.command.color(v)
      })
      map.set('underline', () => {
        this.textarea.command.underline()
      })
      map.set('bold', () => {
        this.textarea.command.bold()
      })
      map.set('italic', () => {
        this.textarea.command.italic()
      })
      map.set('textAlign', (v) => {
        this.textarea.command.rowFlex(v)
      })
    }
    let action
    for(let [key, value] of Object.entries(styleConfig)) {
      action = this.styleMap.get(key)
      action && action(value)
    }
    this._bind.style.changeStyle(styleConfig, false)
    this.focus()
  }
  setBindValue() {
    const value = this.getValue()
    this._bind.showText()
    this._bind.setValue(value)
  }
  bind (node) { // 绑定对应的node节点
    if (!node) return
    this.isActivated && this.deactivate();
    this._bind = node;
    return this
  }
  activate (node, selectAllContent = true) { // 激活输入层
    if (!node) return
    this.beforeActivate(node);
    this._bind = node
    this._isActivated = true
    this._bind.hideText()
    this._showBorder()
    this.setValue(this._bind.getValue())
    this.follow()
    this.resize(selectAllContent)
    this.callDraw()
  }
  deactivate () {
    this._isActivated = false;
    this.textarea.command.recoveryEffect()
    if (this._bind) {
      this.setBindValue()
      this._bind.showText()
    }
    this._hideBorder()
  }

  whenBindResizing() {
    if (this.isActivated) {
      this._hideBorder()
      this._bind.isShowText || this._bind.showText()
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        this.activate(this._bind, false)
      }, 200)
    }
  }

  resize(isSelectAll = false) {
    if (!this.isActivated) return
    const {paddingLeft, paddingTop, textAlign} = this._bind.style
    const {height, textHeight, width} = this._bind
    const _height = Math.max(textHeight + paddingTop * 2, height)
    const { transform, $dpr } = this.board
    const scale = transform.scale / $dpr
    this.setWidth(width * scale)
    this.setHeight(_height * scale)
    const payload = {
      width: Math.ceil(this.width),
      height: Math.ceil(this.height),
      marginLeft: paddingLeft,
      marginTop: paddingTop,
      scale: transform.scale,
      rowFlex: textAlign
    }
    this.textarea.command.resize(payload, isSelectAll)
  }

  selectAll() {
    this.resize(true)
  }
  undo() {
    this.textarea.command.undo()
  }
  redo() {
    this.textarea.command.redo()
  }
  setStart (x, y) {
    let { $dpr,transform } = this.board
    const { scale, offsetX, offsetY } = transform
    this.border.style.left = (x * scale + offsetX) / $dpr + Hacker.unit;
    this.border.style.top = (y * scale + offsetY) / $dpr + Hacker.unit;
  }
  follow () {
    if (!this.isActivated) return
    const {x, y} = this._bind
    this.setStart(x, y);
  }
  setWidth (width) {
    this.width = width
    this.border.style.width = Math.ceil(width) + Hacker.unit;
  }
  setHeight (height) {
    this.border.style.height = (this.height = height) + Hacker.unit;
  }
  getValue () {
    const texts = this.textarea.command.getValue().data
    texts.shift()
    return texts
  }
  setValue (value) {
    this.textarea.command.setValue(value)
  }

  callDraw () {}
  onInput () {}
  onCursorChange() {}
  beforeActivate () {}

  get isActivated() {
    return this._isActivated;
  }
}

export default Hacker
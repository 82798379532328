import Point from '../shapes/point'
import Triangle from '../shapes/triangle'
import Rect from '../shapes/rect'
import Helper from "utils/helper";
import FuncLock from '../node/funcLock' // 功能锁
import Style from './style'
import {LINETYPE, ARROWTYPE} from './constant'
import VNode from '../node'

const BaseLineWidth = 2
const ArrowToLineWidthRatio = 7.5

class Link {
  constructor(ctx, board, {id, points, first, last, start={x:0, y: 0}, end={x:0, y: 0}, texts=[], shapeInfo}) {
    this.name = 'link'
    this.board = board
    this.ctx = ctx
    this.id = id || Helper.produceNanoId()
    this.first = first // 关联的第一个连接的元素
    this.last = last // 关联最后一个连接的元素
    this.selectType = '' // 选择类型 click 点选, frame 框选
    this.points = points || []// 保存绘制点
    this.type = LINETYPE.FOLD // 连接线的类型
    this.anchors = []
    this.length = 0 // 线段总长度
    this.shapeInfo = shapeInfo // 连接线信息，初始化的宽度
    this.start = new Point(start.x, start.y) // 开始点
    this.end = new Point(end.x, end.y)
    this.endArrow = new Triangle(ctx, 0, 0, 0, Math.PI / 5, 0, true, 0, 0) // 尾部箭头
    this.startArrow = new Triangle(ctx, 0, 0, 0, Math.PI / 5, 0, true, 0, 0) // 头部箭头
    this.startHead = new Rect(ctx, start.x - 5, start.y - 5, 0, 0, 0, 0) // 头部调节点
    this.endHead = new Rect(ctx, 0, 0, 0, 0, 0, 0)
    this.funcLock = new FuncLock(ctx, this, board) // 功能锁
    this.mouseInWhich = ''
    this.isMouseIn = false
    this.isMoving = false
    this.isMovingText = false
    this.texts = []
    this.values = texts || []
    this.splitPoints = [] // 连接线二分控制点集合
    this.left = this.right = this.bottom = this.top = 0
    this.touchingtarget = null
    this.operatingTarget = this.startHead // 当前正在操作的元素（头，尾部，控制点或者文字等）
    this.groupId = ''
    this.defaultText = 'text' // 连接线上的默认文本内容
    this._spreadArea = this.spreadArea = 20 // 连接线放大的区域， 用于监测鼠标进入
  }
  initTexts() {
    this.texts.length = 0 // 存放文字类型的node节点
    this.values.forEach(v => {
      this.operateLink._addTextOperation(this, {...v, value: VNode.copyValue(v.value)})
    })
  }
  addTextOperation() {
    throw 'Subclasses must implement the method: addTextOperation!'
  }  // 添加文本的操作

  // 获取节点拖动前的初始端点位置
  getTwoEndPoints() {
    const s = this.start
    const e = this.end
    return {
      start: s,
      end: e,
      startPre: { x: s._x, y: s._y },
      endPre: { x: e._x, y: e._y }
    }
  }

  removeText(t) {
    this.operateLink.removeLinkText(this, t)
  }

  getPoints() {
    const points = this.points.map(p => ({x: p.x, y: p.y}))
    return { points, controlPoints: [] }
  }

  handleEditingText(t) { // 连接线编辑文本后根据对应条件去除掉没有实际使用到的text
    const value = t.value.map(t => t.value).join('')
    if ((!t.isEdited && value === this.defaultText) || value.trim() === '') {
      this.removeText(t)
      return true
    }
  }
  apply() {
    this.setArrowSize().setArrowPosition() // 线的长度有调整,依赖箭头的长度，所以得在applyChildContent前面
    this.applyChildContent()
    this.funcLock.apply() // 功能锁
    this.setHeadSize().setHeadXY() // 两端的可调节锚点
  } // 设置连接线组成部分的位置和大小，可根据不同类型进行自定义

  setBoundary(ps = []) { // ps 根据所有的点来计算连接线的边界
    if (ps.length === 0) return
    let left = ps[0].x
    let top = ps[0].y
    let right = ps[0].x
    let bottom = ps[0].y
    ps.forEach(p => {
      left = Math.min(left, p.x)
      right = Math.max(right, p.x)
      top = Math.min(top, p.y)
      bottom = Math.max(bottom, p.y)
    })
    this.width = right - left
    this.height = bottom - top
    this.left = left
    this.right = right
    this.top = top
    this.bottom = bottom
    this.cx = left + this.width / 2
    this.cy = top + this.height / 2
    return ps
  }
  setStart(x, y) {
    this.start.setXY(x, y)
    return this
  }
  setEnd(x, y) {
    this.end.setXY(x, y)
    return this
  }
  setSelectType(v) { // 选择类型 click 点选, frame 框选
    this.selectType = v
    return this
  }
  setGroupId(groupId){
    this.groupId = groupId
  }
  changeArrowType(type, isStart) { // 箭头类型
    if (isStart) {
      this.style.startArrowType = type
    } else {
      this.style.endArrowType = type
    }
    this.apply()
  }
  setHeadSize() {
    const w = this.board.getDrawLength(this.style.headWidth)
    ;[this.startHead, this.endHead].forEach(h => {
      h.setWidth(w).setHeight(w)
    })
    return this
  }
  getArrowWith() {
    const patch = (this.style.lineWidth - BaseLineWidth) * 2
    return BaseLineWidth * ArrowToLineWidthRatio + patch
  }
  setArrowSize() {
    ;[this.startArrow, this.endArrow].forEach(a => {
      const arrW = this.getArrowWith()
      this.style.arrowLength = arrW
      a.setLength(this.style.arrowLength)
    })
    return this
  }
  setArrowPosition() {
    this.endArrow.setStart(this.end.x, this.end.y)
    this.startArrow.setStart(this.start.x, this.start.y)
  }

  setSpreadArea() { // 连接线放大区域根据缩放适配
    this._spreadArea = this.board.getDrawLength(this.spreadArea)
  }

  /* ***************************
  * 不同子类型的线段需要实现的开始
  * *************************** */
  getStyle() {throw 'Subclasses must implement the method: getStyle!'}
  isEnterLineRange () {throw 'Subclasses must implement the method: isEnterLineRange!'} // 是否进入折线范围
  isEnterSplitPoint() {throw 'Subclasses must implement the method: isEnterSplitPoint!'}
  isEnterControlPoint() { return false }// 判断鼠标是否进入曲线控制点
  isEnterSplitLine() { return false }// 折线需要判断鼠标是否进入折线中的某个线段
  eventmoveInChild() {console.log('Subclasses must implement the method: eventmoveInChild!')}
  drawChildContent() {console.log('Subclasses must implement the method: drawChildContent!')}
  dragSplitPoint() {console.log('Subclasses must implement the method: ondragSplitPoint!')}
  dragControlPoint() {console.log('Subclasses must implement the method: ondragControlPoint!')}
  childResizeFollowNode() { console.log('Subclasses must implement the method: childResizeFollowNode!') }
  collideWidthRect() {} // 使用框选工具的情况下，框选box和连接线的碰撞检测
  eventupInChild() { console.log('Subclasses must implement the method: ondragControlPoint!') }
  onscaleChild() { console.log('Subclasses must implement the method: onscaleChild!') }
  offset() { console.log('Subclasses must implement the method: offset!') }
  isEnterCurveControlPoint() { return false }
  isEnterDragPoint() { return false }
  // 拖动控制点, 只有曲线需要实现
  // 拖动中间节点
  dragDragPoint() {}
  splitLinkLine() {}
  cleanStatus() {} // 直线
  /* ***************************
  * 不同子类型的线段需要实现的 结束
  * *************************** */

  draw() {
    this.drawChildContent() // 绘制连接线线段部分
    this.isShowStartArrow && this.startArrow.strokeFill()
    this.isShowEndArrow && this.endArrow.strokeFill()
    if (this.isMouseIn && !this.isMoving && !this.funcLock.isLocked) {
      !this.board.operateGroup.getGroup(this.id) && ['startHead', 'endHead'].forEach(h => this[h].strokeFill())
    }
    if (this.isSelected) {
      this.funcLock.draw() // 绘制功能锁
    }
    this.texts.forEach(t =>{
      this.ctx.clearRect(t.x, t.y, t.width, t.height)
      t.draw()
    })
  }
  whenEditingText() { // 正在编辑文字时，需要时时调整文字的位置，始终居中
    this.layout.layoutTexts()
    this.hacker.follow()
  }
  select() {
    this.selectType = this.selectType || 'click'
    this.onSelectText()
  }
  unselect() {
    this.selectType = ''
    this.onSelectText()
  }
  onSelectText() {
    if (this.texts.length === 0) return
    const {textStyle} = this.style
    let lineColor, borderOpacity
    if (this.isSelected) {
      lineColor = this.style.backgroundColor
      borderOpacity = 1
    } else {
      lineColor = textStyle.lineColor
      borderOpacity = 0
    }
    this.texts.forEach(t => {
      t.style.setBackgroundBorderStyle({lineColor, borderOpacity})
    })
  }
  beforeOffset() {
    this.start.beforeOffset()
    this.end.beforeOffset()
    this.texts.forEach(t => t.beforeOffset())
  }
  drag(x, y) { // 拖动
    this.isMoving = true
    this.move(x, y)
  }
  move(x, y) {
    this.offset(x, y)
  }
  setHeadXY() {
    const gap = this.startHead.width / 2
    this.startHead.setStart(this.start.x - gap, this.start.y - gap)
    this.endHead.setStart(this.end.x - gap, this.end.y - gap)
    return this
  }
  setDragInSize({x, y}) { // 设置从左侧菜单拖动进来的 link size  point:{x, y}中间点
    const width = this.shapeInfo.width * this.board.grid.baseSize
    this.setStart(x - width / 2, y).setEnd(x + width / 2, y)
  }
  setTouchingTarget(v) {
    this.touchingTarget = v
  }
  setOperatingTarget(v) {
    this.operatingTarget = v
  }
  moveFollowNode(move, position) { // 连接的node 移动的时候
    if (this.isConnectSameNode) {
      this.offset(move.x, move.y)
    } else {
      this[position] && this[position].offset(move.x, move.y)
      this.setOperatingTarget(this[`${position}Head`])
    }
    this.setHeadXY()
  }
  resizeFollowNode(node, move, position) { // 连接的node 调整大小的时候
    let ratio, arr = [position]
    if (this.isConnectSameNode) {
      arr = ['start', 'end']
    }
    arr.forEach(h => {
      let {x, y} = this[h]
      ratio = Math.abs(x - node.x) / node.width
      x = (node.width + move.gapX) * ratio + move.x
      ratio = Math.abs(y - node.y) / node.height
      y = (node.height + move.gapY) * ratio + move.y
      h === 'start' ? this.setStart(x, y) : this.setEnd(x, y)
    })
    this.childResizeFollowNode()
    this.setOperatingTarget(this[`${position}Head`])
  }
  isEnterHead ({x, y}, gap = 0) {
    const a = ['startHead', 'endHead']
    for(let key of a) {
      if (this[key]._isQuickInPath(x, y, gap)) {
        this.setTouchingTarget(this[key])
        return key
      }
    }
  }
  isEnterArrow(x, y) {
    const a = ['startArrow', 'endArrow']
    for(let key of a) {
      if (this[key]._isPointInPath(x, y, 20)) {
        return key
      }
    }
  }
  isEnterText({x, y}) {
    for(let i = 0; i < this.texts.length; i++) {
      if (this.texts[i].background._isQuickInPath(x, y)) {
        this.setTouchingTarget(this.texts[i])
        return true
      }
    }
  }
  eventmove(node) {
    const {x, y, boardX, boardY} = this.board.mouse
    const _mouse = {x: boardX, y: boardY} // 鼠标点映射到画板对应的点坐标
    let target = ''
    this.preMouseInWhich = this.mouseInWhich

    // 重置掉连接线控制点hover的状态
    if (this.isTouchHead) {
      this.getStyle().resetHeadStyle()
    }

    this.mouseInWhich = ''
    this.isMouseIn = this.isMoving = false

     // 锁定图案在节点外部
    if (this.isSelected && this.funcLock.eventmove(x, y)) {
      this.mouseInWhich = 'funcLock'
      this.board.$setCursor('pointer')
      return false
    }
    if (node && !this.isSelected) { // 如果当前鼠标正在touch图形节点, 并且当前连接线没被选择的情况下，不再执行touch逻辑
      this.isMouseIn = false
    } else {
      if (this.eventmoveInChild(_mouse, {x, y})) { // 子类特殊属性逻辑
        this.isMouseIn = true
      } else if (this.isEnterText(_mouse)) { // 是否touch到文字
        this.isMouseIn = true
        this.mouseInWhich = 'text'
      } else if (target = this.isEnterHead(_mouse, 6)) { // 鼠标进入连接线两头(可用来调节的锚点）区域
        this.isMouseIn = true
        if (this.funcLock.isLockedPas) return this.isMouseIn
        this.mouseInWhich = target
        this.getStyle().setHeadTouchStyle(this[target])
        this.board.$setCursor('move')
      } else if (target = this.isEnterArrow(x, y)) { // 鼠标进入箭头区域
        this.isMouseIn = true
        this.board.$setCursor('move')
      } else if (this.isMouseIn = this.isEnterLineRange({x, y}, this._spreadArea)) { // 鼠标是否进入连接线范围
        if (this.isClickSelected && this.isEnterSplitLine(x, y, this._spreadArea)) { // 需要知道鼠标正在touch哪一个线段(折线特有）
          this.mouseInWhich = 'splitLine'
          this.board.$setCursor(this.touchingTarget.isVertical ? 'e-resize' : 's-resize')
        }
      }
    }

    if (this.isMouseIn) {
      if (!this.mouseInWhich) {
        if (this.isConnectedNode) { // 连接线已经连接节点，并且没有碰到线条上其他元素是，鼠标设置为默认状态
          this.board.$setCursor('default')
        } else { // 当连接线没有连接节点的情况下 可以拖动节点, 鼠标显示移动的icon
          this.board.$setCursor('move')
        }
      }
    }

    if (this.preMouseInWhich !== this.mouseInWhich) { this.board.draw() }
    return this.isMouseIn
  }
  eventdown(ev, node, operate) { // node: 当点击link 和 node 重叠的部分, 需要判断node是否存在
    if (!this.isSelected && node) { // 当link没有选中的状态下，并且鼠标同时touch到node, 优先执行click node的逻辑
      return node.eventdown(ev)
    }

    this.beforeOffset()
    if (!this.isTouchHead || !this.isTouchSplit) {
      this.board.selector.select(this, ev)
    }

    if (this.mouseInWhich === 'funcLock') {
      this.funcLock.unlock()
      this.operateNode.lock([this], this.funcLock.lockType)
    } else if (this.isTouchSplitLine) {
      if (this.touchingTarget) {
        const cp = this.touchingTarget.start
        const dir = this.touchingTarget.isVertical
        this.board.nodeGridSnap.saveFoldPartLinkMovePos(cp, dir)
      }
    } else if (this.isTouchSplit) {
      this.splitLinkLine()
    } else {
      let group = this.board.operateGroup.getGroup(this.id);
      if(ev.metaKey && this.isFrameSelected){
        this.board.selector.unSelect(this.board.operateGroup.getGroupMembers(this.id))
        this.draw()
        this.board.box.calculateSize()
      }else{
          group ? this.board.operateGroup.selectGroup(group):this.board.selector.select(this, ev)
          operate.setPriority(this)
      }
    }

    if (this.isStraightLink) {
      if (this.isTouchSplit || this.isTouchDragPoint) { // link.mouseInWhich === 'split'
        const dragPoint = this.getCurrentDragPointPos()
        if (!dragPoint) return
        this.board.nodeGridSnap.saveStraightLinkDragPointPos(dragPoint)
      } else {
        this.cleanStatus()
      }
    }
  }
  eventup() {
    if (this.isMoving) {
      this.isMoving = false
      this.operateNode.linkLeaveAround()
    }
    if (this.isOperatingTarget) {
      this.setOperatingTarget(null)
    }
    this.eventupInChild()
  }

  onscale() {
    this.setHeadSize().setHeadXY()
    this.getStyle().resetHeadStyle()
    this.setSpreadArea()
    this.onscaleChild()
  }
  get isOperatingStartHead () { // 是否正在操作开始点
    return this.operatingTarget === this.startHead
  }
  get isOperatingEndHead() { // 是否正在操作结束点
    return this.operatingTarget === this.endHead
  }
  get isShowStartArrow () {
    return this.style.startArrowType !== ARROWTYPE.NONE
  }
  get isShowEndArrow () {
    return this.style.endArrowType !== ARROWTYPE.NONE
  }
  get isTouchControl() {
    return ~this.mouseInWhich.indexOf("Control")
  }
  get isTouchSplit() { // 鼠标是否正在touch分割点
    return this.mouseInWhich === 'split'
  }
  get isTouchSplitLine() { // 鼠标是否正在touch分割点
    return this.mouseInWhich === 'splitLine'
  }
  get isTouchDragPoint() { // 鼠标是否正在touch分割点
    return this.mouseInWhich === 'dragPoint'
  }
  get isStartSameEnd() {
    return Math.floor(this.start.x) === Math.floor(this.end.x) && Math.floor(this.start.y) === Math.floor(this.end.y)
  }
  get x () {
    return this.start.x
  }
  get y() {
    return this.start.y
  }
  get isLink() {
    return this.name === 'link'
  }
  get isFoldLink() {
    return this.type === LINETYPE.FOLD
  }
  get isCurveLink() {
    return this.type === LINETYPE.CURVE
  }
  get isStraightLink() {
    return this.type === LINETYPE.STRAIGHT
  }
  get isConnectSameNode() { // 头尾是否连接到同一个节点
    return this.first === this.last
  }
  get isConnectedNode() {
    return !!(this.first || this.last)
  }
  get isSelected() {
    return !!this.selectType
  }
  get isClickSelected() { // 是否点击选中
    return this.selectType === 'click'
  }
  get isFrameSelected() { // 是否是框选
    return this.selectType === 'frame'
  }
  get isTouchHead() {
    return this.mouseInWhich.indexOf('Head') > -1
  }
  get isTouchText() {
    return this.mouseInWhich === 'text'
  }
  get isShowBackground() {
    return this.isSelected && !this.isMoving && !this.groupId
  }
  get operateLink() {
    return this.board.operateLink
  }
  get operateNode() {
    return this.board.operateNode
  }
  get hacker() {
    return this.board.hacker
  }
}
Link.Style = Style
Link.id = 0
Link.createLink = function(ctx, board, data={}) {
  let type = data.type || LINETYPE.FOLD
  const n = new Link.type[type](ctx, board, data)
  n.style.apply(data.style || board.style.link)
  n.apply()
  n.initTexts()
  return n
}
export default Link

import Animate from './index'

class AnimateRAF extends Animate { // animateRAF: requestAnimationFrame
  constructor(func, duration, interval) {
    super(func, duration, interval)
  }
  _loop (timestamp) { // 动画帧 timestamp: 开始的时间戳
    this._requestId = requestAnimationFrame(this._loopBind)
    // debugger
    this._realstamp = timestamp
    0 === this._timestart && (this._timestart = timestamp)
    if ( 0 !== this._pausestamp) { // _pausestamp: 暂停的时间戳
      this._pauserange += timestamp - this._pausestamp
      this._pausestamp = 0
      return void 0
    }
    if ((timestamp -= this._pauserange + this._timestart) < this.duration) {
      if (this.interval) {
        if (timestamp >= this._nextstamp) {
          this._callback(this._nextstamp)
          this._nextstamp += this.interval
        }
      } else {
        this._nextstamp = timestamp
        this._callback(this._nextstamp)
      }
    } else {
      this._nextstamp = this.duration
      this._callback(this._nextstam)
      this._stop()
    }
  }
  _resume () { // 重新开始
    this._requestId = requestAnimationFrame(this._loopBind);
  }
  _stop () {
    this._requestId = cancelAnimationFrame(this._requestId);
  }
}

export default AnimateRAF
/*
 绘制左右箭头
 */
import Process from './process.js'
class ArrowLeftRight extends Process {
    constructor(ctx, sx, sy, width, height, ox, oy) {
        super(ctx, sx, sy, ox, oy)
        this.setWidth(width).setHeight(height)
    }

    process() {
        const { ctx, _left, _top, _right, _bottom, width, height, halfX, halfY } = this

        // let width_left = 

        let arrow0 = { x: _left, y: halfY }
        let arrow1 = { x: _left + height / 2, y: _top }
        let arrow2 = { x: _left + height / 2, y: _top + height / 4 }
        let arrow3 = { x: _right - height / 2, y: _top + height / 4 }
        let arrow4 = { x: _right - height / 2, y: _top }
        let arrow5 = { x: _right, y: halfY }
        let arrow6 = { x: _right - height / 2, y: _bottom }
        let arrow7 = { x: _right - height / 2, y: _bottom - height / 4 }
        let arrow8 = { x: _left + height / 2, y: _bottom - height / 4 }
        let arrow9 = { x: _left + height / 2, y: _bottom }

        let start = { x: halfX, y: _top + height / 4 }

        let arcRadius1 = height > 25 && width > 20 ? 4 : 1
        let arcRadius2 = height > 25 && width > 20 ? 2 : 1

        ctx.beginPath();
        ctx.moveTo(start.x, start.y);
        ctx.arcTo(start.x, start.y, arrow3.x, arrow3.y, arcRadius1)
        ctx.arcTo(arrow3.x, arrow3.y, arrow4.x, arrow4.y, arcRadius1)
        ctx.arcTo(arrow4.x, arrow4.y, arrow5.x, arrow5.y, arcRadius2)
        ctx.arcTo(arrow5.x, arrow5.y, arrow6.x, arrow6.y, arcRadius2)
        ctx.arcTo(arrow6.x, arrow6.y, arrow7.x, arrow7.y, arcRadius2)
        ctx.arcTo(arrow7.x, arrow7.y, arrow8.x, arrow8.y, arcRadius1)
        ctx.arcTo(arrow8.x, arrow8.y, arrow9.x, arrow9.y, arcRadius1)
        ctx.arcTo(arrow9.x, arrow9.y, arrow0.x, arrow0.y, arcRadius2)
        ctx.arcTo(arrow0.x, arrow0.y, arrow1.x, arrow1.y, arcRadius2)
        ctx.arcTo(arrow1.x, arrow1.y, arrow2.x, arrow2.y, arcRadius2)
        ctx.arcTo(arrow2.x, arrow2.y, arrow3.x, arrow3.y, arcRadius1)
        ctx.arcTo(start.x, start.y, start.x, start.y, arcRadius1)

    }
}

export default ArrowLeftRight
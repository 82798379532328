import axios from 'axios'
import Cookies from 'js-cookie'
// const isProd = ~location.href.indexOf('mind.shutu88.com/')
// isProd ? 'https://api.shutu88.com' :'http://appshutuapi.loc'
const http = axios.create({
  // 测试
  baseURL: 'https://flow-api.heyapp.cn',
  timeout: 5000
})
// const getToken = () => {
//   const token = Cookies.get('token')
//   return token ? 'Bearer ' + token : ''
// }
// request interceptor
http.interceptors.request.use(config => {
  config.headers = { ...config.headers }
  return config
}, error => {
  return Promise.reject(error)
})

// response interceptor
http.interceptors.response.use(
  response => {
    // token问题
    if (response.data.status === 10000) {
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === 'production') location.href = Cookies.get('returnUrl')
      return
    }
    return response.data
  },
  error => {
    if (!error.response) {
      error = handleError(error)
    }
    const { status } = error.response
    switch (status) {
      case 500:
        console.log('请求超时')
        break
      case 700:
        console.log('网络中断')
        break
      default:
        console.log(error.message)
    }
    return Promise.reject(error)
  }
)

function handleError(error) {
  if (error.message.includes('timeout')) {
    return { response: { status: 500 }, message: '请求超时！' }
  } else {
    return { response: { status: 700 }, message: '网络中断，请检查！' }
  }
}

export default http

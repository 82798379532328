import './App.css'
import { Provider } from 'react-redux'
import store from './store/index.js'
import AppContainer from 'views/appContainer'
import 'antd/dist/antd.min.css'
import { BoardProvider } from 'views/board/'

function App() {
  return (
    <Provider store={ store } >
      <BoardProvider>
        <AppContainer />
      </BoardProvider>
    </Provider>
  )
}

export default App

/**
 * 绘制辅助线
 */

import LineHelper from './lineHelper'
import helpers from "./helper"
import Group from '../group'
class NodeGridSnap extends LineHelper {
  constructor(context, board) {
    super(context)
    this.board = board
    this.initLines();
    this.mouseDownPoint = null
    this.initNodePosWithSize = null

    this.lastLinkSnapPos = null // 用于优化

    // 折线位置
    this.foldLinkPosWithDir = null
  }

  static DistanceTypes = ['top', 'bottom', 'left', 'right']
  static LineTypes = [...NodeGridSnap.DistanceTypes, 'vertical', 'horizon']
  static AlignMinDistance = 0.01

  initLines() {
    this.lines = {}
    NodeGridSnap.LineTypes.forEach(t => {
      this.lines[t] = {
        value: [],
        minDistance: Number.POSITIVE_INFINITY
      }
    })
  }

  // 离参考对象最近的那个
  /**
   * @param {*} distance 
   * @param {*} type: NodeGridSnap.DistanceTypes
   * @returns 
   */
  isMostMinDistance(distance, type) {
    if (NodeGridSnap.DistanceTypes.indexOf(type) > -1) {
      const minDis = this.lines[type].minDistance
      const res = distance < minDis
      if (res) this.lines[type].minDistance = distance
      return res
    }
    return false
  }

  getRelativeMove(point) {
    return { x: point.x - this.mouseDownPoint.x, y: point.y - this.mouseDownPoint.y }
  }

  isMovedObject(aIds, cId) {
    return aIds.indexOf(cId) > -1
  }

  getLinkSize(link) {
    const { startHead, endHead, endArrow, height } = link
    const x = startHead.halfX
    const width = endHead.halfX - x + endArrow.length
    // const y = startHead.halfY
    // const height = endHead.halfY - y + + endArrow.length

    return { width, height }
  }

  getLinkInitPos(link) {
    const { start } = link
    return { x: start._x, y: start._y }
  }

  getNodeInitPosWithSize() {
    const { sx, sy, width, height } = this.initNodePosWithSize || {}
    return { width, height, sx, sy }
  }

  getActiveObjectAttrAndId(obj) {
    const isNode = obj.name === "node"
    const isGroup = obj instanceof Group
    let activeObjAttr, activeIds
    if (isNode) {
      activeObjAttr = helpers.getNormalizedRectProps(obj.border.boundary)
      activeIds = [obj.id]
    } else if (isGroup) {
      const posWithSize = { sx: obj.x, sy: obj.y, width: obj.width, height: obj.height }
      activeObjAttr = helpers.getNormalizedRectProps(posWithSize)
      activeIds = obj.board.selector.selected.map(e => e.id)
    } else {
      activeObjAttr = helpers.getNormalizedRectProps(obj.box)
      activeIds = obj.board.selector.selected.map(e => e.id)
    }

    return {
      activeObjAttr,
      activeIds
    }
  }

  getVerticalLines(distanceArr, curPos, moveTargetAttr, activeObjAttr, posType) {
    const isInTop = moveTargetAttr.centerY > curPos.centerY
    const [y1, y2] = isInTop ?
      [curPos.bottomY, activeObjAttr.topY] : [curPos.topY, activeObjAttr.bottomY]
    const heightDistance = isInTop ? y2 - y1 : y1 - y2

    const mostCloseDis = distanceArr.sort((dis1, dis2) => Math.abs(dis1.value) - Math.abs(dis2.value))[0].value
    const filteredDisArr = distanceArr.filter(dis => dis.value - mostCloseDis < 0.00001)

    let alignVal = Infinity;
    let isNodeAlign = false

    const coordsArr = filteredDisArr.map((dis, index) => {
      const { isFirst, type, isLast } = dis
      const isLeft = type === 'left'
      const isRight = type === 'right'
      const isCenter = type === 'center'

      if (!isNodeAlign) {
        isNodeAlign = true
      }

      const coords = {
        x: isLeft ? curPos.leftX : (!isCenter ? curPos.rightX : curPos.centerX),
        y1,
        y2,
      }

      if (posType) {
        // 对齐点是根据pointId 来确定的
        if (isLeft) {
          alignVal = curPos.leftX
        } else if (isCenter) {
          alignVal = curPos.centerX
        } else if (isRight) {
          alignVal = curPos.rightX
        }
      } else {
        // 只需计算一次即可,后面的都相同
        if (index === 0) {
          let baseVal
          if (isLeft) { // isLeft: 对齐目标节点的左边先, isFirst(移动节点): 对齐移动节点的右节点
            baseVal = curPos.leftX
          } else if (isCenter) {
            baseVal = curPos.centerX
          } else {
            baseVal = curPos.rightX
          }

          if (isFirst) {
            alignVal = baseVal
          } else if (isLast) {
            alignVal = baseVal - moveTargetAttr.width
          } else {
            alignVal = baseVal - moveTargetAttr.width / 2
          }
        }
      }

      const hasSize = isCenter || (filteredDisArr.length === 1)
      return { isCenter, coords, hasSize }
    })
    // alignVal: 表示移动节点左上角应该的位置
    return { coordsArr, isInTop, distance: Math.floor(heightDistance), alignVal, isNodeAlign }
  }

  getHorizontalLines(distanceArr, curPos, moveTargetAttr, activeObjAttr, posType) {
    const isInLeft = curPos.centerX < moveTargetAttr.centerX
    const [x1, x2] = isInLeft ?
      [curPos.rightX, activeObjAttr.leftX] : [curPos.leftX, activeObjAttr.rightX]
    const widthDistance = isInLeft ? x2 - x1 : x1 - x2

    const mostCloseDis = distanceArr.sort((dis1, dis2) => Math.abs(dis1.value) - Math.abs(dis2.value))[0].value
    const filteredDisArr = distanceArr.filter(dis => dis.value - mostCloseDis < 0.00001)

    let alignVal = Infinity;
    let isNodeAlign = false

    const coordsArr = filteredDisArr.map((dis, index) => {
      const { type, isFirst, isLast } = dis // isFirst: 对齐的是上边线

      const isTop = type === 'top'
      const isBottom = type === 'bottom'
      const isCenter = type === 'center'

      if (!isNodeAlign) {
        isNodeAlign = true
      }

      const coords = {
        y: isTop ? curPos.topY : (!isCenter ? curPos.bottomY : curPos.centerY),
        x1,
        x2,
      }

      if (posType) {
        // 是拖动某个点产生的
        if (isTop) {
          alignVal = curPos.topY
        } else if (isCenter) {
          alignVal = curPos.centerY
        } else if (isBottom) {
          alignVal = curPos.bottomY
        }
      } else {
        if (index === 0) { // 这里 alignVal 都是对齐的左上角的点
          let baseVal
          if (isTop) { // isTop: 对齐目标节点上边线, isFirst(移动节点): 用上边线对齐
            baseVal = curPos.topY
          } else if (isCenter) {
            baseVal = curPos.centerY
          } else {
            baseVal = curPos.bottomY
          }

          if (isFirst) {
            alignVal = baseVal
          } else if (isLast) {
            alignVal = baseVal - moveTargetAttr.height
          } else {
            alignVal = baseVal - moveTargetAttr.height / 2
          }
        }
      }

      const hasSize = isCenter || (filteredDisArr.length === 1)
      return { isCenter, coords, hasSize }
    })

    return { coordsArr, isInLeft, distance: Math.floor(widthDistance), alignVal, isNodeAlign }
  }

  /**
   * 
   * @param {*} pos 移动节点吸附的预期位置
   * @param {*} matchedObject 
   * @param {*} nodeAttr 比较节点当前的实际位置
   * @param {*} targetAttr mouse移动的预期节点对应的位置属性
   */
  checkUpdateGridSnapObj(pos, matchedObject, nodeAttr, targetAttr, xCheck, yCheck) {
    const { leftX, centerX, rightX, topY, centerY, bottomY } = nodeAttr

    let xArr = [centerX, leftX, rightX]
    let yArr = [centerY, topY, bottomY]

    const minD = NodeGridSnap.AlignMinDistance
    const isGridSnapObj = (val, cv1, cv2, cv3) => {
      if (cv1 && Math.abs(val - cv1) < minD) return 1
      if (cv2 && Math.abs(val - cv2) < minD) return 2
      if (cv3 && Math.abs(val - cv3) < minD) return 3
      return 0
    }
    const isNeeCheckX = xCheck !== 'center'
    const isNeeCheckY = yCheck !== 'center'

    if (isNeeCheckX) {
      for (let i = 0; i < xArr.length; i++) {
        const x = xArr[i]
        const tW = targetAttr.width
        let posArr = [pos.x, pos.x + tW / 2, pos.x + tW] // 移动节点
        if (xCheck === 'left') { // 拖动节点的边
          posArr = [pos.x, null, null]
        } else if (xCheck === 'center') {
          posArr = [null, pos.x, null]
        } else if (xCheck === 'right') {
          posArr = [null, null, pos.x]
        }
        let target = isGridSnapObj(x, ...posArr)
        if (target) {
          const dir = targetAttr.centerY - centerY > 0 ? "top" : "bottom"
          const { minDis } = matchedObject[dir]
          const dis = dir === "top" ? targetAttr.topY - bottomY : topY - targetAttr.bottomY
          if (dis >= 0 && dis < minDis) {
            let type
            if (i === 0) {
              if (target === 1) { type = 'center-left' }
              else if (target === 2) { type = 'center' }
              else { type = 'center-right' }
            } else {
              type = i === 1 ? 'left' : "right"
            }

            matchedObject[dir] = {
              value: nodeAttr,
              index: i,
              minDis: dis,
              type
            }
          }
          break
        }
      }
    }
    
    if (isNeeCheckY) {
      for (let i = 0; i < yArr.length; i++) {
        const y = yArr[i]
        const tH = targetAttr.height
        let posArr = [pos.y, pos.y + tH / 2, pos.y + tH] // 移动节点
        if (xCheck === 'top') { // 拖动节点的边
          posArr = [pos.y, null, null]
        } else if (xCheck === 'center') {
          posArr = [null, pos.y + tH / 2, null]
        } else if (xCheck === 'bottom') {
          posArr = [null, null, pos.y + tH]
        }
        let target = isGridSnapObj(y, ...posArr)
       
        if (target) {
          const dir = targetAttr.centerX - centerX > 0 ? "left" : "right"
          const { minDis } = matchedObject[dir]
          const dis = dir === "left" ? targetAttr.leftX - rightX : leftX - targetAttr.rightX
          if (dis >= 0 && dis < minDis) {
            let type
            if (i === 0) {
              if (target === 1) { type = 'center-top' }
              else if (target === 2) { type = 'center' }
              else { type = 'center-bottom' }
            } else {
              type = i === 1 ? 'top' : "bottom"
            }

            matchedObject[dir] = {
              value: nodeAttr,
              index: i,
              minDis: dis,
              type
            }
          }
          break
        }
      }
    }
  }

  /**
   * 
   * @param {*} that 
   * @param {*} activeObject : 当前拖动节点或者box(多选的时候),
   * @param {*} refPoint : 吸附参考点
   * @param {*} targetAttr : mouse移动的预期节点对应的位置属性
   * @param {*} pointId: 拖动Node边角点对应的哪个id,一个八个
   * @returns 
   */
  getNodeGridSnapPos(that, activeObject, refPoint, targetAttr, pointId) {
    this.initLines()

    const { activeIds, activeObjAttr } = this.getActiveObjectAttrAndId(activeObject)

    const nodes = Object.values(that.nodes)
    let nodeSnapPos = { x: refPoint.x, y: refPoint.y } // 复制一份

    let gridSnapPos = that.gridPage.getGridSnapPos(nodeSnapPos)
    const { isAlignNode, isAlignGrid } = that.gridPage.getAlignConfig()
    // 存储刚好在gridSnap位置上,且距离最近对应的object
    let gridSnapMatchObj = {}
    if (isAlignGrid) {   // 是否考虑吸附grid
      NodeGridSnap.DistanceTypes.forEach(t => {
        gridSnapMatchObj[t] = { value: null, minDis: Number.POSITIVE_INFINITY }
      })
    }

    let hasNodeAlign = { x: false, y: false }

    // 对齐参考点位置(x: left, center, right, y: top, center, bottom)
    const refPosType = helpers.getRefPosType(pointId)
    const px = refPosType?.x
    const py = refPosType?.y

    isAlignNode && targetAttr && nodes.forEach(currObject => {
      const curPos = helpers.getObjectPos(currObject)
      if (this.isMovedObject(activeIds, currObject.id)) return

      // 计算与gridSnap位置是否吻合
      if (isAlignGrid) {
        const isNeedCheck = { x: px !== 'center', y: py !== 'center' }
        this.checkUpdateGridSnapObj(gridSnapPos, gridSnapMatchObj, curPos, targetAttr, px, py)
      }

      let lRAlignXValues = [targetAttr.leftX, targetAttr.centerX, targetAttr.rightX]
      if (px === 'right') {
        lRAlignXValues = [targetAttr.centerX, targetAttr.rightX]
      } else if (px === 'left') {
        lRAlignXValues = [targetAttr.leftX, targetAttr.centerX]
      } else if (py === 'center') {
        lRAlignXValues = []
      }
      const lDistance = helpers.isInRangeMore(curPos.leftX, lRAlignXValues, 'left');
      const rDistance = helpers.isInRangeMore(curPos.rightX, lRAlignXValues, 'right');
      const cDistance = helpers.isInRangeMore(curPos.centerX, lRAlignXValues, 'center');

      const disArr = [lDistance, rDistance, cDistance].filter(e => typeof e.value === 'number')

      if (disArr.length) {
        // 产生吸附
        const { coordsArr, isInTop, distance, alignVal: val, isNodeAlign } = this.getVerticalLines(disArr, curPos, targetAttr, activeObjAttr, px)
        hasNodeAlign.x = isNodeAlign
        if (distance <= 0 && val !== Infinity) { // 靠着边
          const adjustC = coordsArr.filter(c => (!c.isCenter)).map(
            e => ({ ...e.coords, y1: curPos.topY, y2: curPos.bottomY })
          )
          nodeSnapPos.x = val
          this.addLines('vertical', adjustC)
        } else {
          // 距离较远的对齐会被距离较近的替换掉
          const type = isInTop ? 'top' : 'bottom'
          if (this.isMostMinDistance(distance, type) && val !== Infinity) {
            nodeSnapPos.x = val
            this.cleanLines(type)
            this.addLines(type, coordsArr)
          }
        }
      }

      // snap
      let tBAlignYValues = [targetAttr.topY, targetAttr.centerY, targetAttr.bottomY]
      if (py === 'bottom') {
        tBAlignYValues = [targetAttr.centerY, targetAttr.bottomY]
      } else if (py === 'top') {
        tBAlignYValues = [targetAttr.topY, targetAttr.centerY]
      } else if (py === 'center') {
        tBAlignYValues = []
      }
      const topDistance = helpers.isInRangeMore(curPos.topY, tBAlignYValues, 'top');
      const bottomDistance = helpers.isInRangeMore(curPos.bottomY, tBAlignYValues, 'bottom');
      const centerDistance = helpers.isInRangeMore(curPos.centerY, tBAlignYValues, 'center');

      const disArr2 = [topDistance, bottomDistance, centerDistance].filter(e => typeof e.value === 'number')
      if (disArr2.length) {
        const { coordsArr, isInLeft, distance, alignVal: val, isNodeAlign } = this.getHorizontalLines(disArr2, curPos, targetAttr, activeObjAttr, py)
        hasNodeAlign.y = isNodeAlign

        if (distance <= 0 && val !== Infinity) { // 与边接触
          const adjustC = coordsArr.filter(c => !c.isCenter).map(
            c => ({ ...c.coords, x1: curPos.leftX, x2: curPos.rightX })
          )
          nodeSnapPos.y = val
          this.addLines('horizon', adjustC)
        } else {
          const type = isInLeft ? 'left' : 'right'
          if (this.isMostMinDistance(distance, type) && val !== Infinity) {
            nodeSnapPos.y = val
            this.cleanLines(type)
            this.addLines(type, coordsArr)
          }
        }
      }
    })

    return {
      nodeSnapPos,
      hasNodeAlign,
      gridSnapMatchObj,
      gridSnapPos,
    }
  }

  getSecondLinePoint(firstPoint, linkSize) {
    const { width, height } = linkSize
    const endX = width + firstPoint.x
    const endY = height + firstPoint.y
    return { x: endX, y: endY }
  }

  /**
   *  连接线
   * @param {*} that 
   * @param {*} refPoint // grid 吸附参考点
   * @param {*} isVertical // 参考点方向
   * @param {*} isTwo // 是否需要考虑两个端点的吸附
   * @returns ...
   */
  getLinkSnapPosByNodeGrid(that, refPoint, isVertical, isTwo, linkSize) {
    let gridSnapPos = that.gridPage.getGridSnapPos(refPoint)
    let nodeSnapPos = refPoint

    const nodes = Object.values(that.nodes)
    const hasNodeAlign = { x: false, y: false }
    let isAlginSecond = false

    let alignValX, alignValY, isNearToCenter

    const refX = [refPoint.x]
    const refY = [refPoint.y]

    let secondPoint
    // 还要考虑终点的对齐
    if (isTwo) {
      secondPoint = this.getSecondLinePoint(refPoint, linkSize)
      refX.push(secondPoint.x)
      refY.push(secondPoint.y)
    }

    for (let i = 0; i < nodes.length; i++) {
      const curNode = nodes[i]
      const curPos = helpers.getObjectPos(curNode)

      if (isVertical) {
        Array.from([curPos.topY, curPos.bottomY]).forEach(e => {
          const { value, isFirst } = helpers.isInRangeMore(e, refY);
          if (typeof value === 'number') { // 吸附
            alignValY = e
            if (!isFirst) {
              isAlginSecond = true
            }
          }
        })

        if (alignValY) {
          const { value, isFirst } = helpers.isInRangeMore(curPos.centerX, refX);
          const isNearToCenter = typeof value === 'number'
          if (isNearToCenter) {
            alignValX = curPos.centerX
            if (!isFirst) {
              isAlginSecond = true
            }
          }
        }

      } else {
        Array.from([curPos.leftX, curPos.rightX]).forEach(e => {
          const { value, isFirst } = helpers.isInRangeMore(e, refX);
          if (typeof value === 'number') { // 吸附
            alignValX = e
            if (!isFirst) {
              isAlginSecond = true
            }
          }
        })

        if (alignValX) {
          const { value, isFirst } = helpers.isInRangeMore(curPos.centerY, refY);
          const isNearToCenter = typeof value === 'number'
          if (isNearToCenter) {
            alignValY = curPos.centerY
            if (!isFirst) {
              isAlginSecond = true
            }
          }
        }
      }

      if (alignValX) { break }
      if (alignValY) { break }
    }

    if (alignValX) {
      nodeSnapPos.x = alignValX
      hasNodeAlign.x = true
    }

    if (alignValY) {
      nodeSnapPos.y = alignValY
      hasNodeAlign.y = true
    }

    if (isAlginSecond) {
      if (secondPoint) {
        gridSnapPos = that.gridPage.getGridSnapPos(secondPoint)
        const { width, height } = linkSize
        gridSnapPos.x = gridSnapPos.x - width
        gridSnapPos.y = gridSnapPos.y - height
      }
    }

    return {
      gridSnapPos,
      nodeSnapPos,
      hasNodeAlign,
      isNearToCenter
    }
  }

  // 根据节点吸附和网格吸附的最终位置比较，获取离当前位置最近的那种吸附
  checkIsGridSnap(sourcePos, nSnapPos, gSnapPos, hasNodeAlign, gridSize) {
    const isGridAlign = {}
    const move = {}
    const relativeMove = {};

    const initX = sourcePos._x || 0;
    const initY = sourcePos._y || 0;

    const x1 = nSnapPos.x - sourcePos.x;
    const x2 = nSnapPos.x - initX;
    isGridAlign.x = !(hasNodeAlign.x && Math.abs(x1) <= gridSize);
    move.x = isGridAlign.x ? gSnapPos.x - sourcePos.x : x1;
    relativeMove.x = isGridAlign.x ? gSnapPos.x - initX : x2;

    const y1 = nSnapPos.y - sourcePos.y;
    const y2 = nSnapPos.y - initY;

    isGridAlign.y = !(hasNodeAlign.y && Math.abs(y1) <= gridSize);
    move.y = isGridAlign.y ? gSnapPos.y - sourcePos.y : y1;
    relativeMove.y = isGridAlign.y ? gSnapPos.y - initY : y2;

    return { isGridAlign, move, relativeMove };
  }
  // 拖动Node
  getSnapMoveNode(that, move, targetPoint) {
    const { activeObject, hasNode } = helpers.getActiveObject(that)
    if (!hasNode) return move

    const { isAlignGrid, isAlignNode } = that.gridPage.getAlignConfig()
    // 不考虑吸附
    if (!isAlignGrid && !isAlignNode) return move

    // 需要考虑吸附
    const relativeMove = this.getRelativeMove(targetPoint)
    const initPosWithSize = this.getNodeInitPosWithSize()

    // 防止异常
    if (!initPosWithSize.sx || !initPosWithSize.sy) return move

    const moveTargetAttr = helpers.getExceptNodePosByMoveNode(initPosWithSize, relativeMove)
    const refPoint = { x: moveTargetAttr.leftX, y: moveTargetAttr.topY }

    const snapRes = this.getNodeGridSnapPos(that, activeObject, refPoint, moveTargetAttr)
    const { nodeSnapPos, hasNodeAlign, gridSnapPos, gridSnapMatchObj } = snapRes

    // 不存在网格吸附
    if (!isAlignGrid) {
      return { x: nodeSnapPos.x - activeObject.x, y: nodeSnapPos.y - activeObject.y }
    }

    const gridSize = that.gridPage.getGridSize()
    // isGridAlign: 比较结果是否为grid align
    const { isGridAlign, move: snapMove } = this.checkIsGridSnap(activeObject, nodeSnapPos, gridSnapPos, hasNodeAlign, gridSize)
    // 是网格吸附
    if (isGridAlign.x) {
      // 清除Node吸附的辅助线
      Array.from(['vertical', 'top', 'bottom']).forEach(t => {
        this.cleanLines(t)
      })

      // 需要补上应该对齐Grid的辅助线
      Array.from(['top', 'bottom']).forEach(t => {
        if (gridSnapMatchObj[t]?.value) {
          this.getLines(gridSnapMatchObj[t], moveTargetAttr, activeObject, t)
        }
      })
    }

    if (isGridAlign.y) {
      Array.from(['horizon', 'left', 'right']).forEach(t => {
        this.cleanLines(t)
      })

      Array.from(['left', 'right']).forEach(t => {
        if (gridSnapMatchObj[t]?.value) {
          this.getLines(gridSnapMatchObj[t], moveTargetAttr, activeObject, t)
        }
      })
    }

    return snapMove
  }

  // 拖动Node point
  getSnapMovePoint(that, targetPoint, pointId) {
    const { activeObject, hasNode } = helpers.getActiveObject(that)
    if (!hasNode) return targetPoint

    const { isAlignGrid, isAlignNode } = that.gridPage.getAlignConfig()
    // 不考虑吸附
    if (!isAlignGrid && !isAlignNode) return targetPoint

    const relativeMove = this.getRelativeMove(targetPoint)
    const initPosWithSize = this.getNodeInitPosWithSize()
    const moveTargetAttr = helpers.getExceptNodePosByDragPoint(initPosWithSize, relativeMove, pointId)
    const snapRes = this.getNodeGridSnapPos(that, activeObject, targetPoint, moveTargetAttr, pointId)
    const { nodeSnapPos, hasNodeAlign, gridSnapPos, gridSnapMatchObj } = snapRes

    // 不需要吸附grid
    if (!isAlignGrid) {
      return nodeSnapPos
    }

    const gridSize = that.gridPage.getGridSize()
    const { isGridAlign } = this.checkIsGridSnap(targetPoint, nodeSnapPos, gridSnapPos, hasNodeAlign, gridSize)

    // 是网格吸附
    if (isGridAlign.x) {
      // 需要补上应该对齐Grid的辅助线
      Array.from(['top', 'bottom']).forEach(t => {
        if (gridSnapMatchObj[t]?.value) {
          this.getLines(gridSnapMatchObj[t], moveTargetAttr, activeObject, t)
        }
      })
    }

    // 拖动节点产生的吸附只能清除部分线,即拖动节点对应的线
    if (isGridAlign.y) {
      Array.from(['left', 'right']).forEach(t => {
        if (gridSnapMatchObj[t]?.value) {
          this.getLines(gridSnapMatchObj[t], moveTargetAttr, activeObject, t)
        }
      })
    }

    const snapPoint = {
      x: isGridAlign.x ? gridSnapPos.x : nodeSnapPos.x,
      y: isGridAlign.y ? gridSnapPos.y : nodeSnapPos.y
    }

    let shouleMove = false
    if (this.preSnapPoint) { // 对位置添加判断，由于吸附的原因
      if (Math.abs(this.preSnapPoint.x - snapPoint.x) > 5 || Math.abs(this.preSnapPoint.y - snapPoint.y) > 5) {
        shouleMove = true
      }
    } else {
      shouleMove = true
    }
    this.preSnapPoint = snapPoint

    return shouleMove ? snapPoint : null
  }

  // 初始拖动Node, 基于位置
  getSnapMoveNodeByPos(that, targetPoint) {
    const { activeObject, hasNode } = helpers.getActiveObject(that)
    if (!hasNode) return targetPoint

    const { isAlignGrid, isAlignNode } = that.gridPage.getAlignConfig()
    // 不考虑吸附
    if (!isAlignGrid && !isAlignNode) return targetPoint

    const moveTargetAttr = helpers.getExceptNodePosFromObj(activeObject, targetPoint)

    const snapRes = this.getNodeGridSnapPos(that, activeObject, targetPoint, moveTargetAttr)
    const { nodeSnapPos, hasNodeAlign, gridSnapPos, gridSnapMatchObj } = snapRes

    // 不需要吸附grid
    if (!isAlignGrid) {
      return nodeSnapPos
    }

    const gridSize = that.gridPage.getGridSize()
    const { isGridAlign } = this.checkIsGridSnap(targetPoint, nodeSnapPos, gridSnapPos, hasNodeAlign, gridSize)

    const snapPoint = {
      x: isGridAlign.x ? gridSnapPos.x : nodeSnapPos.x,
      y: isGridAlign.y ? gridSnapPos.y : nodeSnapPos.y
    }

    return snapPoint
  }

  // 拖动连接线, 基于头/尾部
  getLinkSnapByDragHead(that, targetPoint, isCreate) {
    let hasMoved = false
    const { activeObject: link, isLink } = helpers.getActiveObject(that)
    if (!isLink) return { hasMoved, point: targetPoint }

    const { isAlignGrid } = that.gridPage.getAlignConfig()
    // 不考虑吸附
    if (!isAlignGrid) return { hasMoved, point: targetPoint }

    const { startArrow, endArrow } = link

    const isEnd = helpers.isMouseDownLinkEndHead(link)

    const curArrow = isEnd ? endArrow : startArrow
    const isVertical = Math.abs(curArrow._sin) === 1

    const { gridSnapPos, nodeSnapPos, hasNodeAlign } = this.getLinkSnapPosByNodeGrid(that, targetPoint, isVertical)

    // 折线的特殊吸附
    const lineSnapPos = {x: null, y: null}
    if (link.isFoldLink) {
      const len = link.points.length
      if (len === 2 || len === 3 || len === 4) {
        const start = (isCreate || isEnd) ? link.points[0] : link.points[len - 1]
        const end = (isCreate || isEnd) ? link.points[1] : link.points[len -2]
       
        if (start.y === end.y) {
          lineSnapPos.y = start.y
        } else if (start.x === end.x) {
          lineSnapPos.x = start.x
        }
      }    
    }

    const snapPoint = this.getSnapPos(targetPoint, nodeSnapPos, gridSnapPos, lineSnapPos, hasNodeAlign)

    if (this.lastLinkSnapPos) {
      hasMoved = helpers.hasPosChanged(this.lastLinkSnapPos, snapPoint)
    }

    this.lastLinkSnapPos = { ...snapPoint }

    return { hasMoved, point: snapPoint }
  }

  // 根据节点吸附、网格吸附、线的最终位置比较，获取离当前位置最近的那种吸附
  getSnapPos(sourcePos, nSnapPos, gSnapPos, lSnapPos, hasNodeAlign) {
    const snapPoint = nSnapPos;

    if (!hasNodeAlign.x) { // 不存在节点吸附
      if (lSnapPos.x) {
        const disY1 = Math.abs(sourcePos.x - gSnapPos.x);
        const disY2 = Math.abs(sourcePos.x - lSnapPos.x);
        
        if (disY1 <= disY2) {
          snapPoint.x = gSnapPos.x
        } else {
          snapPoint.x = lSnapPos.x
        }
      } else {
        snapPoint.x = gSnapPos.x
      }
    }

    if (!hasNodeAlign.y) {
      if (lSnapPos.y) {
        const disY1 = Math.abs(sourcePos.y - gSnapPos.y);
        const disY2 = Math.abs(sourcePos.y - lSnapPos.y);
        
        if (disY1 <= disY2) {
          snapPoint.y = gSnapPos.y
        } else {
          snapPoint.y = lSnapPos.y
        }
      } else {
        snapPoint.y = gSnapPos.y
      }
    }

    return snapPoint;
  }
  
  // 拖动连接线，整体
  getSnapMoveLink(that, move, targetPoint) {
    const { activeObject: activeObj, isLink } = helpers.getActiveObject(that)
    if (!isLink) return move

    const { isAlignGrid, isAlignNode } = that.gridPage.getAlignConfig()
    // 不考虑吸附
    if (!isAlignGrid && !isAlignNode) return move

    const { startArrow, start } = activeObj
    const isVertical = Math.abs(startArrow._sin) === 1

    const relativeMove = this.getRelativeMove(targetPoint)

    const initPos = this.getLinkInitPos(activeObj)
    const moveLinkPos = helpers.getExceptLinkPosByDrag(initPos, relativeMove)
    const linkSize = this.getLinkSize(activeObj)

    const { gridSnapPos, nodeSnapPos, hasNodeAlign } = this.getLinkSnapPosByNodeGrid(that, moveLinkPos, isVertical, true, linkSize)

    // 不存在网格吸附
    if (!isAlignGrid) {
      return { x: nodeSnapPos.x - moveLinkPos.x, y: nodeSnapPos.y - moveLinkPos.y }
    }

    const gridSize = that.gridPage.getGridSize()
    const { move: snapMove, relativeMove: rMove } = this.checkIsGridSnap(start, nodeSnapPos, gridSnapPos, hasNodeAlign, gridSize)

    return { move: snapMove, rMove }
  }

  // 拖动连接线，折线 部分
  getSnapMovePartLink(that, move, targetPoint) {
    const { activeObject: link, isLink } = helpers.getActiveObject(that)
    if (!isLink) return move

    const { isAlignGrid } = that.gridPage.getAlignConfig()
    // 不考虑吸附
    if (!isAlignGrid) return move

    const relativeMove = this.getRelativeMove(targetPoint)

    const initPos = this.foldLinkPosWithDir
    const moveLinkPos = helpers.getExceptLinkPosByDrag(initPos, relativeMove)
    const gridSnapPos = that.gridPage.getHalfGridSnapPos(moveLinkPos)   
  
    const cp = link.touchingTarget?.start
    if (!cp) return move
    
    if (initPos.isVertical) {
      move.x = gridSnapPos.x - cp.x
    } else {
      move.y = gridSnapPos.y - cp.y
    }
    return move
  }

  // 拖动直线 分割点
  getSnapMoveStraightLinkPoint(that, move, targetPoint) {
    const { activeObject: link, isLink } = helpers.getActiveObject(that)
    if (!isLink) return move

    const { isAlignGrid } = that.gridPage.getAlignConfig()
    // 不考虑吸附
    if (!isAlignGrid) return move

    const relativeMove = this.getRelativeMove(targetPoint)

    const initPos = this.straightLinkDragPointPos

    if (!initPos) return move
    const moveLinkPos = helpers.getExceptLinkPosByDrag(initPos, relativeMove)

    const gridSnapPos = that.gridPage.getGridSnapPos(moveLinkPos)  
    const { start, end } = link.getCurrentDragPointTwoEndPoints()
    const curPos = link.getCurrentDragPointPos()

    const { snapPos: lineSnapPos, lineMid } = helpers.getPointToLineChuiZu(start, end, moveLinkPos)

    if (!curPos || !start || !end) return move

    const gDis = helpers.getDisOfTwoPoints(gridSnapPos, moveLinkPos)
    const lDis = helpers.getDisOfTwoPoints(lineSnapPos, moveLinkPos) 

    const gMove = { // 网格吸附的移动
      x: gridSnapPos.x - curPos.x,
      y: gridSnapPos.y - curPos.y
    }

    const isSnapToGrid = gDis < lDis
    if (isSnapToGrid) return gMove

    return {
      x: lineMid.x - curPos.x,
      y: lineMid.y - curPos.y
    }
  }
  
  getMatchIndex(val, values) {
    for (let i = 0; i < values.length; i++) {
      if (Math.abs(val - values[i]) < NodeGridSnap.AlignMinDistance) {
        return i
      }
    }
  }

  // 网格吸附计算辅助线
  getLines(nodeAttrObj, targetAttr, activeObject, type) {
    const { value: nodeAttr, type: alginType } = nodeAttrObj
    const { leftX, centerX, rightX, topY, centerY, bottomY } = nodeAttr
    const nodeY = [topY, centerY, bottomY] // 参考点位置
    const nodeX = [leftX, centerX, rightX]

    const { activeObjAttr } = this.getActiveObjectAttrAndId(activeObject)

    // 先简单点, 只会吸grid的top或者left
    if (type === "left" || type === 'right') { // 这里的left 是相对位置
      let arrY = []
      const h = targetAttr.height;
      if (alginType === 'top') {
        arrY = [topY - h, topY - h / 2, topY]
      } else if (alginType === 'bottom') {
        arrY = [bottomY, bottomY + h / 2, bottomY + h]
      } else if (alginType === 'center') {
        arrY = [centerY - h / 2, centerY, centerY + h / 2]
      } else if (alginType === 'center-top') {
        arrY = [centerY, centerY + h / 2, centerY + h]
      } else if (alginType === 'center-bottom') {
        arrY = [centerY - h, centerY - h / 2, centerY]
      }

      let coordsArr = []


      arrY.forEach((y) => {
        let nId = this.getMatchIndex(y, nodeY)
        if (typeof nId === 'number') {
          const x1 = type === "left" ? rightX : activeObjAttr.rightX
          const x2 = type === "left" ? activeObjAttr.leftX : leftX
          const coords = { x1, x2, y: nodeY[nId] }
          coordsArr.push({ coords, isCenter: nId === 1, hasSize: nId === 1 })
        }
      })

      if (coordsArr.length === 1) {
        coordsArr = coordsArr.map(e => ({ ...e, hasSize: true }))
      }

      this.addLines(type, coordsArr)
    } else if (type === "top" || type === 'bottom') {
      let arrX = []
      const w = targetAttr.width;
      if (alginType === 'left') {
        arrX = [leftX - 2, leftX - w / 2, leftX]
      } else if (alginType === 'right') {
        arrX = [rightX, rightX + w / 2, rightX + w]
      } else if (alginType === 'center') {
        arrX = [centerX - w / 2, centerX, centerX + w / 2]
      } else if (alginType === 'center-left') {
        arrX = [centerX, centerX + w / 2, centerX + w]
      } else if (alginType === 'center-right') {
        arrX = [centerX - w, centerX - w / 2, centerX]
      }

      let coordsArr = []

      arrX.forEach((x) => {
        let nId = this.getMatchIndex(x, nodeX)
        if (typeof nId === 'number') {
          const y1 = type === "top" ? bottomY : activeObjAttr.bottomY
          const y2 = type === "top" ? activeObjAttr.topY : topY
          const coords = { y1, y2, x: nodeX[nId] }
          coordsArr.push({ coords, isCenter: nId === 1, hasSize: nId === 1 })
        }
      })

      if (coordsArr.length === 1) {
        coordsArr = coordsArr.map(e => ({ ...e, hasSize: true }))
      }
      this.addLines(type, coordsArr)
    }
  }


  saveMouseDownPos(that, point) {
    const { activeObject, hasNode } = helpers.getActiveObject(that)
    if (hasNode) {
      const { activeObjAttr } = this.getActiveObjectAttrAndId(activeObject)
      const { leftX, topY, height, width } = activeObjAttr
      this.initNodePosWithSize = { sx: leftX, sy: topY, height, width }
    }

    this.mouseDownPoint = point
  }

  saveFoldPartLinkMovePos(sp, isVertical, point) {
    this.foldLinkPosWithDir = {
      x: sp.x,
      y: sp.y,
      isVertical
    }
    this.mouseDownPoint = point
  }

  // 直线位置
  saveStraightLinkDragPointPos(sp) {
    this.straightLinkDragPointPos = {
      x: sp.x,
      y: sp.y,
    }
    // 在saveMouseDownPos 已经保存了 this.mouseDownPoint
    // this.mouseDownPoint = point
  }

  drawSnapLines() {
    this.lines['vertical'].value.forEach(e => this.drawZSimpleVerticalLine(e))
    this.lines['horizon'].value.forEach(e => this.drawZSimpleHorizontalLine(e))

    Array.from(['top', 'bottom']).forEach(t => {
      this.lines[t].value.forEach(e => this.drawVerticalLine(e))
    })

    Array.from(['left', 'right']).forEach(t => {
      this.lines[t].value.forEach(e => this.drawHorizontalLine(e))
    })

    NodeGridSnap.LineTypes.forEach(t => {
      this.cleanLines(t)
    })
  }

  cleanLines(type) {
    this.lines[type].value.length = 0
    this.lines[type].minDistance = Number.POSITIVE_INFINITY
  }

  addLines(type, dataArr) {
    this.lines[type].value.push(...dataArr)
  }

  onscale() {
    this.board.gridPage.onscale()
    const { lineWidth, lineHeadWidth, textFontSize } = this.getDefaultLineWidthAndFontSize()
    const newLineWidth = this.board.getDrawLength(lineWidth)
    const newLineHeadWidth = this.board.getDrawLength(lineHeadWidth)
    const newTextFontSize = this.board.getDrawLength(textFontSize)
    this.updateLineWidthAndFontSize(newLineWidth, newLineHeadWidth, newTextFontSize)
  }
}

export default NodeGridSnap
/*
  用于连续操作的记录，如node节点的拖动等
  target: 操作对象
  newValue: 连续操作后 需要保存的节点的相关属性
  oldValue: 连续操作前 需要保存的节点的相关属性
  action: 操作名称
  setValue 来记录操作 dispatch: 操作完毕后的回调
 */
export default class RecordOperation {
  constructor(source, operation) {
    this.source = source
    this.operation = operation
    this.stopRecord()
  }
  setValue(target, action, newV, oldV) { // setvalue 是一个频繁执行的程序
    this.target = target
    this.action = action
    this.isRecording || (this.oldValue = oldV)
    this.isRecording = true
    newV && (this.newValue = newV)
  }
  stopRecord() {
    this.newValue = this.oldValue = null
    this.isRecording = false
    this.action = ''
  }
  dispatch(action, cb) { // 针对eventup,松开鼠标加入历史记录
    if (!this.isRecording) return
    if (action === this.action) {
      cb.apply(this.source, [this.target, this.operation])
      this.stopRecord()
    }
  }
}

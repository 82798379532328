const saveHostName = 'https://api.imiaoban.com'
import axios from 'axios'

// import axios from 'axios'
// import request from './request'
export function ipsSave(apiPath) { return saveHostName + apiPath }

export function saveDoc(doc) {
  // todo 后面需要补全参数
  const headers = {
    // 'nonce': nonce,
    // 'timestamp': timestamp,
    // 'sign': sign,
    'Content-Type': 'application/json;charset=UTF-8'
  }

  const data = {
    // todo 暂用固定值做测试
    title: '测试',
    preview: '',
    description: '',
    doc: JSON.stringify(doc)
  }
  axios({
    method: 'post',
    url: 'https://api.imiaoban.com/v1/template/user',
    data,
    headers
  })

//   return fetch('https://api.imiaoban.com/v1/template/user', {
//     method: 'POST',
//     body: JSON.stringify(doc),
//     // async: false,
//     mode: 'cors', // no-cors, *cors, same-origin
//     cache: 'no-cache',
//     headers: {
//       Accept: 'application/json'
//     }
//     // credentials: 'include'
//   })
}


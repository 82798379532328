/* eslint-disable no-useless-constructor */
import Rect from '../rect'
import utils from '../../utils'
import Arc from '../arc'

class Process extends Rect {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, width, height, ox, oy)
    this.arcRadius = 5
  }
  process() {
    const { ctx, _left, _right, _bottom, _top, arcRadius } = this
    ctx.beginPath()
    ctx.moveTo(_left + arcRadius, _top)
    ctx.arcTo(_right, _top, _right, _bottom, arcRadius)
    ctx.arcTo(_right, _bottom, _left, _bottom, arcRadius)
    ctx.arcTo(_left, _bottom, _left, _top, arcRadius)
    ctx.arcTo(_left, _top, _right, _top, arcRadius)
  }
}
utils._assign(Process, Arc, 'getRadius', 'setRadius')

export default Process
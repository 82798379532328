import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import IconButton from 'components/comm/IconButton'
import CustomButton from './ExpandButton'

export const CssWrapper = styled.div`
  margin: 30px 0;
  // max-height: 200px;
  padding: 0px 12px;

  .bottomBorder {
    // border-bottom: 1px solid ${props => props.theme.main.borderColor};
  }

  .expand-head {
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    padding-bottom: 10px;
    font-size:14px;
  }

  .expand-content {
    // border-left: 1px solid whitesmoke;
    // border-right: 1px solid whitesmoke;
    max-height: 0;
    transition: max-height 0.2s ease-in-out;
    // height:100%;
    // // border:2px solid red;
    // overflow-x:hidden;
  }

  .add-overflow {
    overflow: hidden;
  }

  .expand-content-open {
    max-height: 300px;
    // overflow: auto;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`

const Expand = (props) => {
  const { title, showClose, showBottomBorder = true, hasOverFlow = true, children, onClosed, disabled, isOpenShow } = props
  const [isOpen, setIsOpen] = useState(true)
  const cn = isOpen ? 'expand-content expand-content-open' : 'expand-content'
  let cn2 = (hasOverFlow || !isOpen) ? `${cn} add-overflow` : cn
  cn2 = disabled ? `${cn2} disabled` : cn2
  const bottomCN = showBottomBorder ? 'bottomBorder' : ''

  return (
    <CssWrapper >
      <div className={bottomCN}>
        {isOpenShow && <div className='expand-head'>
          <CustomButton
            isOpen={!isOpen}
            isOpenShow={isOpenShow}
            onClick={() => {
              if (!isOpenShow) {
                setIsOpen(!isOpen)
              }
            }}
            title={title}
          />
          {
            showClose && (
              <IconButton name='close'
                showToolTip={false}
                onClick={() => { onClosed && onClosed() }}
              />
            )
          }
        </div>}
        <div className={cn2} >
          {children}
        </div>
      </div>
    </CssWrapper>
  )
}

Expand.propTypes = {
  title: PropTypes.string,
  showClose: PropTypes.bool,
  showBottomBorder: PropTypes.bool,
  hasOverFlow: PropTypes.bool,
  onClosed: PropTypes.func,
  children: PropTypes.object.isRequired,
  isOpenShow: PropTypes.bool,
  disabled: PropTypes.bool
}

export default Expand

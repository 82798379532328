import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CssWrapper = styled.div`
  display: flex;
  cursor: pointer;
  height: 27px;
  width: 100px;
  
  .tooltip {
    position: relative;
    z-index:2;
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    width: 100px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    // margin: 10px 0;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -50px;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
 
  .svgIcon {
    pointer-events: none;
  }

  .svgIcon .useDiv{
    height: 24px;
    width: 100px;
    // border:1px solid red;
  }
`

const DefaultSelectedColor = '#1071e5'

const IconButton = ({ name, widthInPx = 100, heightInPx, fill, selected, data = {}, className, onClick, toolTip, showToolTip = true, ...rest }) => {
  const [hovered, setHovered] = useState(false)
  const [isDrag, setIsDrag] = useState(false)
  const fillStyle = hovered || selected || data.selectNode ? { fill: fill || DefaultSelectedColor } : {}

  const fixToolTip = (showToolTip && !isDrag) ? (toolTip || name) : ''

  const handleMouseMove = (type) => {
    if (type === 'enter') {
      setHovered(true)
    } else {
      setHovered(false)
    }
  }

  return (

    <CssWrapper
      onClick={onClick}
      onMouseDown={() => setIsDrag(true)}
      onMouseUp={() => setIsDrag(false)}
      {...rest}
      className={className || ''}
      onMouseEnter={() => handleMouseMove('enter')}
      onMouseOut={() => handleMouseMove('out')}
    >
      <div className='tooltip'>
        <div className='dragItem flex' key='svg'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            className='svgIcon '
            style={fillStyle}
            width={100}
            height={heightInPx || widthInPx}
          >
            <div className='useDiv'>
              <use xlinkHref={`#icon-${name}-svg`} />
            </div>
          </svg>
        </div>

        {
          fixToolTip && <div className='tooltiptext' key='tooltipText'>{fixToolTip}</div>
        }
      </div>
    </CssWrapper >
  )
}

IconButton.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  fill: PropTypes.string,
  selected: PropTypes.bool,
  widthInPx: PropTypes.number,
  heightInPx: PropTypes.number,
  toolTip: PropTypes.string,
  showToolTip: PropTypes.bool,
  data: PropTypes.any,
  shapeProps: PropTypes.any
}

export default IconButton


import React, { useEffect, useMemo } from 'react'
import { BoardCssWrapper } from './style'
import Menu from 'components/menu'
import install from './install'
import { useBoardContext } from './hooks'
import PropTypes from 'prop-types'
import Helper from 'utils/helper'
function Board(props) {
  const { setBoard, board } = useBoardContext()
  useEffect(() => {
    const c = install('board-wrapper', 'mouse-menu', {})
    setBoard(c)
    // 处理File 格式文件的粘贴
    Helper.listenPaste()
    // registerEvent(c, props)
    // return function unregister() {
    //   unregisterEvent()
  }, [])
  return useMemo(() => {
    return <div id='board-wrapper'>
      <canvas id='grid-board' />
      <canvas id='draw-board' />
      <div id='action-wrapper' style={{ 'display': 'none' }}>
        {/* <textarea id='draw-action' /> */}
        {/* <canvas id='draw-action' data-name='editor' /> */}
      </div>
    </div>
  }, [board])
}

function Index(props) {
  return (
    <BoardCssWrapper>
      <Board {...props} />
      <Menu />
    </BoardCssWrapper>
  )
}

Index.propTypes = {
  onChangeMouseStr: PropTypes.func
}

Board.propTypes = {
  onChangeMouseStr: PropTypes.func
}

export default Index
export * from './boardContext'

/*  链表结构数据 */
class LinkList {
  constructor() {
    this.clear()
  }
  push (entry) {
    if (null === this.head) {
      this.head = this.tail = entry
    } else {
      this.tail.next = entry
      entry.previous = this.tail
      entry.next = null
      this.tail = entry
      this._length++;
    }
  }
  remove (entry) {
    var previous = entry.previous,
        next = entry.next;
    previous ? (previous.next = next) : (this.head = next);
    next ? (next.previous = previous) : (this.tail = previous)
    entry.previous = entry.next = null
    this._length--;
  }
  clear () {
    this.head = this.tail = null
    this._length = 0
  }
  get length () {
    return this._length
  }
}
export default LinkList

/*
  用于画板对外提供的功能api
  example: 页面组件需要更改画板节点的样式
 */
export default function registerApi() {
  const that = this
  return { // 对外暴露的api
    addConnect() { // 添加关联线
      const sels = that.selector.selected
      if (sels.length > 0 && sels.length < 3) { that.operateLink.add(sels[0], sels[1]); that.$focusBoard() }
    },
    selectNode(type) { // 选择节点
      that.selNode[type] && that.selNode[type]()
    },
    back() { // 后退
      if (that.hacker.isActivated) {
        that.hacker.undo()
      } else {
        that.operation.prev()
      }
    },
    forward() { // 前进
      if (that.hacker.isActivated) {
        that.hacker.redo()
      } else {
        that.operation.next()
      }
    },
    backToCentral() {
    },
    copyTopic() { // 复制
      that._hotkeyCtrl('c')
    },
    setPicture(picture) {
      that.picture.addPicture(picture)
    },
    pasteTopic() { // 粘贴
      that._hotkeyCtrl('v')
    },
    deleteTopic() { // 删除
      that._hotkey('Delete')
    },
    shearTopic() { // 剪切
      that._hotkeyCtrl('x')
    },
    setLineType(t) {
      that.lineType = t
      that.operateLink.switchLink(t)
    },
    registerSaveUrl(saveUrl, updateUrl) {
      that.saveUrl = saveUrl
      that.updateUrl = updateUrl
    },
    save() {
      return that.save()
    },
    onChangeBackground(background) {
      that.board.changeBoard(background)
    },
    changeBoardConfig(config) {
      that.board.setLineType(config)
    },
    changeNodeStyle(config) {
      that.operateNode.changeStyle(that.selector.selected, config)
    },
    search(text, textCase, findType) {
      that.search.find(text, textCase, findType)
    },
    replace(text, newText, textCase, findType) {
      that.search.replace(text, newText, textCase, findType)
    },
    drawMiniMap(navigation, onClickMiniMap, onChangeMiniMap) {
      that.miniMap.show(navigation, onClickMiniMap, onChangeMiniMap)
    },
    brush() { // 格式刷
      that.brush.copyNodeStyle()
    },
    changeNodeLock(v) {
      that.operateNode.lock(null, v)
    },
    changeNodeLink(v) {
      that.operateNode.addHyperlink(v)
    },
    getActiveLink() {
      const sels = that.selector.selected
      if (sels.length === 1) {
        return sels[0]
      }
    },
    changeLinkArrow(type, isStart) {
      const sels = that.selector.selected
      if (sels.length === 1) {
        that.operateLink.changeArrow(sels[0], type, isStart)
      }
    },
    saveAsImage: function(fileName) {
      let left, top, bottom, right
      left = top = Infinity
      right = bottom = -Infinity
      const dpr = that.$dpr
      const padding = 10
      /* end */
      /* 调整画布回到初始状态 start */
      for (const id in that.nodes) {
        if (that.nodes[id].x < left) left = that.nodes[id].x
        if (that.nodes[id].y < top) top = that.nodes[id].y
        if (that.nodes[id].right > right) right = that.nodes[id].right
        if (that.nodes[id].bottom > bottom) bottom = that.nodes[id].bottom
      }

      for (const id in that.links) {
        if (that.links[id].x < left) left = that.links[id].x
        if (that.links[id].y < top) top = that.links[id].y
        if (that.links[id].right > right) right = that.links[id].right
        if (that.links[id].bottom > bottom) bottom = that.links[id].bottom
      }
      const width = right - left + padding * 2; const height = bottom - top + padding * 2
      that._scale({ scale: window.devicePixelRatio, offsetX: 0, offsetY: 0 })
      const sels = [...that.selector.selected]
      const sx = left - padding
      const sy = top - padding
      that.selector._clear()
      that.point.set(-sx, -sy)
      that.resizeCanvas(width * dpr, height * dpr)
      that.draw()
      /* end */
      that.imageData.setImageData(0, 0, width * dpr, height * dpr).saveAsImage(fileName || 'miaoban', 'image/jpg')
      // 恢复节点选中状态
      // 恢复导出之前缩放比例
      that.point.set(sx, sy)
      that.resizeCanvas()
      that._scale(that.transform)
      that.selector._multiSelect(sels)
      that.draw()
    },
    setGrid(status) { // 网格设置
      that.gridPage.updateGridSetting(status)
      that.draw()
    },
    setPage(status) { // 页面设置
      const { size } = status
      that.gridPage.updatePageSize(size.width, size.height)
      that.draw()
    },
    setCanvas(status) { // 画布设置
      that.draw()
    }
  }
}

/*
 绘制下箭头 
 */
import Process from './process.js'
class ArrowBottom extends Process {
    constructor(ctx, sx, sy, width, height, ox, oy) {
        super(ctx, sx, sy, ox, oy)
        this.setWidth(width).setHeight(height)
    }
    process() {
        const { ctx, _left, _top, _right, _bottom, width, height, halfX, halfY } = this

        let height_y = width / 2 > height - 10 && width / 2 !== height - 10 ? 5 + _top : _bottom - width / 2
        //角度的弧度
        let arcRadius = width > 35 && height > 21 ? 2 : 1

        //普通的弧度
        let radius = height > 35 && width > 15 ? 4 : 1


        let arrow0 = { x: _left + width / 4, y: _top }
        let arrow1 = { x: _right - width / 4, y: _top }
        let arrow2 = { x: _right - width / 4, y: height_y }
        let arrow3 = { x: _right, y: height_y }
        let arrow4 = { x: halfX, y: _bottom }
        let arrow5 = { x: _left, y: height_y }
        let arrow6 = { x: _left + width / 4, y: height_y }

        ctx.beginPath();
        ctx.moveTo(arrow0.x, arrow0.y + 5);
        ctx.arcTo(arrow0.x, arrow0.y, arrow1.x, arrow1.y, radius);
        ctx.arcTo(arrow1.x, arrow1.y, arrow2.x, arrow2.y, radius);
        ctx.arcTo(arrow2.x, arrow2.y, arrow3.x, arrow3.y, radius)
        ctx.arcTo(arrow3.x, arrow3.y, arrow4.x, arrow4.y, arcRadius)
        ctx.arcTo(arrow4.x, arrow4.y, arrow5.x, arrow5.y, arcRadius)
        ctx.arcTo(arrow5.x, arrow5.y, arrow6.x, arrow6.y, arcRadius)
        ctx.arcTo(arrow6.x, arrow6.y, arrow0.x, arrow0.y, radius);
        ctx.arcTo(arrow0.x, arrow0.y + 5, arrow0.x, arrow0.y, radius);
    }
}

export default ArrowBottom
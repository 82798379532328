import Style from './index'

class ShadowStyle extends Style {
  constructor() {
    super()
    this.shadowBlur = ShadowStyle.DEFAULT_SHADOW_BLUR
    this.shadowColor = ShadowStyle.DEFAULT_SHADOW_COLOR
    this.shadowOffsetX = ShadowStyle.DEFAULT_SHADOW_OFFSET_X
    this.shadowOffsetY = ShadowStyle.DEFAULT_SHADOW_OFFSET_Y
  }
  static DEFAULT_SHADOW_BLUR = 0
  static DEFAULT_SHADOW_COLOR = "rgba(0, 0, 0, 0)"
  static DEFAULT_SHADOW_OFFSET_X = 0
  static DEFAULT_SHADOW_OFFSET_Y = 0

  getShadowBlur () {
    return this.shadowBlur;
  }
  setShadowBlur (shadowBlur) {
    return (this.shadowBlur = shadowBlur), this;
  }
  getShadowColor () {
    return this.shadowColor;
  }
  setShadowColor (shadowColor) {
    return (this.shadowColor = shadowColor), this;
  }
  getShadowOffsetX () {
    return this.shadowOffsetX;
  }
  setShadowOffsetX (shadowOffsetX) {
    return (this.shadowOffsetX = shadowOffsetX), this;
  }
  getShadowOffsetY () {
    return this.shadowOffsetY;
  }
  setShadowOffsetY (shadowOffsetY) {
    return (this.shadowOffsetY = shadowOffsetY), this;
  }
  setShadowOffset (
    shadowOffsetX,
    shadowOffsetY
  ) {
    return this.setShadowOffsetX(shadowOffsetX).setShadowOffsetY(
      shadowOffsetY
    );
  }
}

export default ShadowStyle
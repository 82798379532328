import Style from '../style'

class StraightLinkStyle extends Style {
  constructor(target) {
    super(target)

    this.headPointColor = "black"
    this.midPointColor = "white"
  }

  applyChildStyle() {
    const { compoundLines } = this.target
    const lines = compoundLines.getAllLines()
    for(let l of lines) {
      const {background, straightLine, straightPatchCircle, backgroundPatchCircle} = l
      background.getStyle().setStrokeStyle(this.backgroundColor).setLineWidth(this.backgroundLineWidth).setLineDash(this.lineDash)
      straightLine.getStyle().setStrokeStyle(this.lineColor).setLineWidth(this.lineWidth).setLineDash(this.lineDash)
      
      // circle patch
      straightPatchCircle.setWidth(this.lineWidth).setHeight(this.lineWidth)
      straightPatchCircle.getStyle().setFillStyle(this.lineColor)

      backgroundPatchCircle.setWidth(this.backgroundLineWidth).setHeight(this.backgroundLineWidth)
      backgroundPatchCircle.getStyle().setFillStyle(this.backgroundColor)
    }
  }

  // 重置控制点、头节点样式
  resetControlStyle() {
    const { compoundLines } = this.target
    const lines = compoundLines.getAllLines()
    for(let l of lines) {
      const { centerSplit, straightHead } = l;
    
      // 直线头节点（即直线的分割点）的默认样式
      straightHead.getStyle().setFillStyle(this.headPointColor)

      // 直线中点
      centerSplit.getStyle().setFillStyle(this.midPointColor)
    }
  }
}

export default StraightLinkStyle

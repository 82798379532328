import styled from 'styled-components'

export const ToolsTextStyle = styled.div`
.toolsText{
    padding-left:20px;
    padding-top: 20px;
  }

  .toolsText .font {
    display:flex;
    align-items:center;
  }
  .toolsText .font .box{
    width: 34px;
    height: 34px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    display:flex;
    align-items: center;
    justify-content:center;
    padding-left:2px;
  }
  .toolsText .font .bold{
    margin-right:15px;
  }
  .toolsText .font .italic{
    margin-right:15px;
  }
  .toolsText .font .underline{
  
  }
  .toolsText .font2 .boxXZ{
    width: 110px;
    height: 34px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content:center;
  }

  .toolsText .font2{
    padding-top:23px;
    display: flex;
    align-items: center;
    padding-right:15px;
    justify-content: space-between;
  }
  .toolsText .font2 .name{
    padding:0;
    border:none;
  }
  .toolsText .font2 .num3{
    padding:0;
    width: 110px;
    height: 34px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
  }
  .toolsText .alignment{
    border-top:1px solid #E3E3E3;
    padding-top:20px;
    margin-top: 20px;
  }
  .toolsText .alignment .btnLink{
    width: 240px;
    height: 34px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content:center;
  }

  .toolsText .alignment .btnLink .btnIconGN{
    width:80px;
    height:34px;
    border-right:1px solid  #E3E3E3;
    display: flex;
    align-items: center;
    justify-content:center;
  } 

  .toolsText .alignment .name{
    padding:0;
    border:none;
  }
  .toolsText .alignment .alignmentTop{
    display:flex;
    align-items:center;
  }
  .toolsText .alignment .alignmentTop .icon{
    display: flex;
    align-items: center;
    justify-content:center;
    margin-top:20px;
  }

  .toolsText .alignment .alignmentTop .btn{
    padding-bottom:20px;
    display: flex;
    align-items: center;
    justify-content:center;
  }
`

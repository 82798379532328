import Helper from 'utils/helper';
import Upload from "utils/upload";
import {UPLOAD_PIC} from "api";
export default class Picture{
    constructor(canvas){
        this.canvas = canvas
    }

    addPicture(picData,type) {
        this.setPicture(picData,type)
    }

    async setPicture(pic,type){
        await this._setPictureOperation(pic,type)
    }

    async _setPictureOperation(pic,type){
        let img = pic;
        if (typeof pic === "string") {
          img = await Helper.loadImage(pic);
        }
        this._setPicture(img, type, pic);
    }

    _setPicture(pic,type,picUrl) {
        // TODO 具体的插入时的信息暂定后面需要项目组根据自己需求完善
        let initPos = {x:80,y:80}
        const {x,y} = this.canvas.mouse;
        if(type === 'paste' || type === "drag") {
            initPos.x = x, initPos.y = y;
        }
        const point = this.canvas.getDrawCoordinate(initPos.x, initPos.y)
        this.canvas.operateNode.add(
          this.canvas.operateBoard.board._gcNode({
            type: "image",
            x: !type ? point.x  : point.x - (pic.width)/2,
            y: !type ? point.y : point.y - (pic.height / pic.width).toFixed(2) * (pic.width) / 2,
            id: Helper.produceNanoId(),
            img: pic,
            picUrl,
            shapeInfo: {
              ratio: (pic.height / pic.width).toFixed(2),
              width: (pic.width)/this.canvas.grid.baseSize,
            },
          })
        );
    }    

    /**
     * 粘贴上传
     */
    clipboardUpload(data) {
        const imageType = 'image/png';
        for (let i = 0; i < data.length; i++) {
            if ( data[i].types.includes(imageType) ) {
                data[i].getType(imageType).then(blob => {
                    const file = new File([blob], Date.now() + '.png', { type: imageType, lastModified: Date.now() })
                    this.uploadImg(file,'paste')
                });
            }
        }
    }

     uploadImg = (file,type) => {
        if ([void 0, null].includes(file)) return
        const upload = new Upload({
            fileSize: 5 * 1024 * 1024,
            fileType: '|jpg|jpeg|png'
        });
        const handleUpload = upload.handleBeforeUpload(file)
        if( handleUpload.status ) {
            upload.uploadPicFile(file, UPLOAD_PIC).then(res => {
                if (res.status === 200) {
                    this.addPicture(res.data.url,type)
                } else {
                    console.log(res.error)
                }
            })
        } else {
            console.log(handleUpload.msg)
        }
    }
}
import {NODE_DEFAULT_STYLE, RowFlex} from './constant'

class Style {
  constructor(target) {
    this.target = target
  }
  apply(style = {}, needResetNode) {
    this.backgroundColor = style.backgroundColor || NODE_DEFAULT_STYLE.backgroundColor
    this.fontColor = style.fontColor || NODE_DEFAULT_STYLE.fontColor
    this.fontSize = style.fontSize || NODE_DEFAULT_STYLE.fontSize
    this.fontFamily = style.fontFamily || NODE_DEFAULT_STYLE.fontFamily
    this.italic = style.italic || false
    this.fontVariant = 'normal'
    this.bold = style.bold || false
    this.underline = style.underline || false
    this.strikeout = style.strikeout || false
    this.lineWidth = style.lineWidth || 2
    this.lineHeight = style.lineHeight || 24

    this.paddingLeft = style.paddingLeft || NODE_DEFAULT_STYLE.paddingLeft
    this.paddingTop = style.paddingTop || NODE_DEFAULT_STYLE.paddingTop
    // this.contentLeft = style.contentLeft || 0 // 内容x开始位置
    this.contentTop = style.contentTop || 0 // 内容y开始位置

    this.lineColor = style.lineColor || NODE_DEFAULT_STYLE.lineColor
    this.borderOpacity = style.borderOpacity !== void 0 ? style.borderOpacity : 1
    this.lineDash = style.lineDash || NODE_DEFAULT_STYLE.lineDash
    this.textOpacity = style.textOpacity || 1
    this.backgroundOpacity = style.backgroundOpacity || 1
    this.textAlign = style.textAlign || RowFlex.CENTER // 整体内容的排列方式 left  center  right
    this.textAngle = style.textAngle || 0
    this.boundaryLineWidth = style.boundaryLineWidth || 8 // 当元素被框选时boundary作为元素的边界线展示
    this.boundaryColor = style.boundaryColor || NODE_DEFAULT_STYLE.boundaryColor // 当元素被框选时boundary作为元素的边界线展示
    this.configSpecialType() // 配置特殊类型节点的样式
    this.setLineHeight()
    this.setMaxWidth()
    // 内容编辑的时候
    this._apply(needResetNode)
    this.setStyleMap()
  }

  configSpecialType() {
    const t = this.target
    if (t.carrier) {
      this.paddingLeft = this.paddingTop = 5
      this.backgroundOpacity = 0
    } else if (t.isImageType) {
      this.paddingLeft = this.paddingTop = 0
    } else if (t.isTextType) {
      this.paddingLeft = this.paddingTop = 10
      this.backgroundOpacity = 0
      this.borderOpacity = 0
    }
  }
  setStyleMap() { // 特殊样式处理，通常为关联需求
    this.fontProps = ['fontFamily', 'bold', 'underline', 'fontSize', 'italic', 'fontColor']
    this.styleMap = {
      "fontSize": (v) => {
        this.fontSize = v
        this.setLineHeight()
      },
      "backgroundColor": (v) => {
        if (v === 'transparent') {
          this.backgroundOpacity = 0
        } else {
          this.backgroundOpacity = 1
          this.backgroundColor = v
        }
      },
      "lineColor": (v) => {
        if (v === 'transparent') {
          this.borderOpacity = 0
        } else {
          this.borderOpacity = 1
          this.lineColor = v
        }
      }
    }
  }

  changeTextStyle(key, value) {
    // 由于修改文字属性会重新生成文字 所以更改样式需要修改value, todo: 后续逻辑优化会进行调整
    this.target.value.forEach(v => {
      v[key] = value
    })
  }

  changeStyle(config, needSetValue = true){
    for(let [key, value] of Object.entries(config)) {
      if (this[key] !== void 0) {
        this.styleMap[key] ? this.styleMap[key](value) : (this[key] = value)
        needSetValue && this.fontProps.includes(key) && this.changeTextStyle(key, value)
      }
    }
    return this
  }

  setLineHeight() {
    this.lineHeight = Math.floor(this.fontSize * 4 / 3)
  }

  setTextStyle(text, style = {}) {
    for(const [key, value] of Object.entries(style)) {
      if ([undefined, null].includes(value)) {
        delete style[key]
      }
    }
    style = {...this, ...style}
    return text.getStyle().setFont(this.getFont(style)).setFillStyle(style.fontColor).
           setOpacity(style.textOpacity).setUnderline(style.underline).setStrikeout(style.strikeout)
  }

  setBackgroundBorderStyle(style) {
    if (style && typeof style === 'object') {
      for(let [key, value] of Object.entries(style)) {
        if (![undefined, null].includes(value)) (this[key] = value)
      }
    }
    this.target.background.getStyle()
        .setLineDash(this.lineDash)
        .setLineWidth(this.lineWidth || 1)
        .setStrokeStyle(this.lineWidth > 0 ? this.lineColor : 'transparent')
        .setFillStyle(this.backgroundColor)
        .setStrokeOpacity(this.borderOpacity)
        .setOpacity(this.backgroundOpacity)
  }

  setMaxWidth() { // 根据需求，不同类型的node最大宽度不一致
    const node = this.target
    this.maxWidth = Math.max(600, node.width) // 默认值
    if (node.isCopyTextToCreate) {
      this.maxWidth = node.board.grid.baseSize * 60
    }
  }

  getFont(style) {
    return `${style.italic ? 'italic' : ''} ${style.fontVariant} ${style.bold ? 'bold' : ''} ${style.fontSize}px \"${style.fontFamily}\"`
  }

  _apply(needResetNode = true) {
    const { border, boundary, junction, funcLock } = this.target
    this.setBackgroundBorderStyle()
    // 边界线目前只在框选的时候才会出现，还未发现自定义样式，后续需求变动，可添加样式配置
    boundary.getStyle().setLineWidth(this.boundaryLineWidth).setStrokeStyle(this.boundaryColor)
    junction.style.apply()
    border.style.apply()
    funcLock.style.apply()
    needResetNode && this.target._apply() // 调用此方法 针对修改text样式后，需要重新计算文本的大小和高度
  }
}

export default Style

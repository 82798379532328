import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Arrow } from './Arrow'
// import useReference from './hooks/useReference'

const CssWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 50px;

  .inputBorder {
    border: 1px solid ${props => props.theme.main.borderColor};
    height: ${props => props.theme.main.inputHeight};
    display: flex;
  }

  .editorInput {
    line-height: 22px;
    margin-right: 8px;
    flex-grow: 1;
    width: 100%;
    border-width: 0;
    outline: none;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    height: 22px;
    width: 6px;
    justify-content: space-between;
    padding-right: 3px;
  }
`

const NumberEditor = (props) => {
  const { value, unit, onValueChanged = () => {}, hasBorder, min = 1, max = 10 } = props
  const [valStr, setValStr] = useState('1px')

  const handleClick = (type) => {
    const newV = type === '+' ? value + 1 : (value - 1) >= 0 ? (value - 1) : 0
    updateNewValStr(newV)
  }

  const updateNewValStr = useCallback((_value, type) => {
    let _valStr = ''
    if (type === 'initial') {
      _valStr = _value < 0 ? valStr : _value + unit
    } else {
      value !== _value && onValueChanged(_value)
      _valStr = _value + unit
    }
    setValStr(_valStr)
  }, [onValueChanged])

  useEffect(() => {
    updateNewValStr(value, 'initial')
  }, [updateNewValStr, value])

  const handValueChange = useCallback((e) => {
    setValStr(e.target.value)
  }, [])

  const handBlurEvent = useCallback((e) => {
    const value = e.target.value
    const numberStr = value.replace(/[^0-9]/g, '') || min
    let number = parseInt(numberStr)
    if (number > max) {
      number = max
    } else if (number <= min) {
      number = min
    }

    updateNewValStr(number)
  }, [updateNewValStr])

  return (
    <CssWrapper className={hasBorder ? 'inputBorder' : ''}>
      <input
        value={valStr}
        onInput={handValueChange}
        className='editorInput'
        onBlur={handBlurEvent}
        onKeyUp={e => { if (e.key === 'Enter') handBlurEvent(e) }}
      />
      <div className='buttons'>
        <Arrow disabled={value >= max} angle={0} onClick={() => { handleClick('+') }} />
        <Arrow disabled={value <= min} angle={180} onClick={() => { handleClick('-') }} />
      </div>
    </CssWrapper>
  )
}

NumberEditor.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  unit: PropTypes.string,
  onValueChanged: PropTypes.func,
  hasBorder: PropTypes.bool
}

export default NumberEditor


import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import line_sharp from 'assets/img/sharp/line-shape-28px-@2x.png'
import text_sharp from 'assets/img/sharp/text-shape-28px-@2x.png'

const CssWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: 27px;

  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    width: 100px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    // margin: 10px 0;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -50px;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .imageButton {
    width: 25px;
    pointer-events: none;
  }
`

const ImageSharpMap = {
  'line': line_sharp,
  'text': text_sharp
}

const ImageButton = (props) => {
  const { name, toolTip, ...rest } = props
  const [hovered, setHovered] = useState(false)
  const [isDrag, setIsDrag] = useState(false)

  const handleMouseMove = (type) => {
    if (type === 'enter') {
      setHovered(true)
    } else {
      setHovered(false)
    }
  }

  return (
    <CssWrapper
      onMouseEnter={() => handleMouseMove('enter')}
      onMouseOut={() => handleMouseMove('out')}
      onMouseDown={() => setIsDrag(true)}
      onMouseUp={() => setIsDrag(false)}
      // className={}
      {...rest}
    >
      {/* <div className={className || ''}> */}
      <div className='tooltip'>
        <div className='flex'>
          <img className='imageButton' src={ImageSharpMap[name]} />
          {
            (hovered && !isDrag) && <span className={'tooltiptext'}>{toolTip}</span>
          }
        </div>
        {/* </div> */}
      </div>

    </CssWrapper>
  )
}

ImageButton.propTypes = {
  name: PropTypes.oneOf(['line', 'text']),
  type: PropTypes.string,
  className: PropTypes.string,
  toolTip: PropTypes.string,
  onClick: PropTypes.func,
  shapeProps: PropTypes.object
}

export { ImageButton }

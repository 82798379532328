import React from 'react'
import IconButton from 'components/comm/IconButton'
import { IconButtonWithColor2 } from 'components/comm/Buttons'
import { ToolsLinkStyle } from './style'
import PropTypes from 'prop-types'
import LineSetting from '../lineSetting'
import { DashLines } from '../config'
// import zhihuan from 'assets/img/zhihuan.png'
import ArrowSetting from '../arrowSetting'
import { SelectLine } from '../SelectLine'

const ToolsLink = (props) => {
  const { onChangeChecked, listChecked4, listChecked5, lineColor, lineDash, lineWidth, handleChangeStyle, arrowGroup, ArrowTypes, handleArrowChange } = props
  return (
    <ToolsLinkStyle>
      <div className='linkTools'>
        <div className='shape'>
          <div className='iconBox'>
            <div className='icon' onClick={() => onChangeChecked(4)}>
              <IconButton
                name={listChecked4 ? 'topBtn' : 'bottomBtn'}
                showToolTip={false}
              />
            </div>
            <div className='name'>线条</div>
          </div>
          {listChecked4 && <div className='btnBox'>
            <div className='name'>颜色</div>
            <div className='num boxXZ'>
              <IconButtonWithColor2
                name='line-color'
                link='link'
                showToolTip={false}
                color={lineColor}
                widthInPx={24}
                onColorChange={value => handleChangeStyle({ lineColor: value })}
              />
            </div>
          </div>}
          {listChecked4 && <div className='btnBox'>
            <div className='name'>粗细</div>
            <div className='num'>
              <LineSetting
                index={1}
                lines={DashLines}
                curLine={lineDash}
                onLineChange={(value) => handleChangeStyle({ lineDash: value })}
                lineSize={lineWidth}
                showToolTip={false}
                maxSize={10}
                minSize={0}
                name={'link'}
                onLineSizeChange={(value) => handleChangeStyle({ lineWidth: value })}
              />
            </div>
          </div>}
          {listChecked4 && <div className='btnBox'>
            <div className='name'>样式</div>
            <div className='num'>
              <LineSetting
                index={0}
                lines={DashLines}
                curLine={lineDash}
                onLineChange={(value) => handleChangeStyle({ lineDash: value })}
                lineSize={lineWidth}
                showToolTip={false}
                maxSize={10}
                minSize={0}
                onLineSizeChange={(value) => handleChangeStyle({ lineWidth: value })}
              />
            </div>
          </div>}
          {listChecked4 && <div className='btnBox'>
            <div className='name'>类型</div>
            <div className='num'>
              <SelectLine />
            </div>
          </div>}
        </div>
        {<div className='shape'>
          <div className='iconBox'>
            <div className='icon' onClick={() => onChangeChecked(5)}>
              <IconButton
                name={listChecked5 ? 'topBtn' : 'bottomBtn'}
                showToolTip={false}
              />
            </div>
            <div className='name'>端点</div>
          </div>
          {listChecked5 && <div className='btnBox btn2'>
            <div className='boxs'>
              <div className='name'>起点</div>
              <div className='arrow'>
                <ArrowSetting
                  arrowGroup={arrowGroup}
                  arrows={ArrowTypes}
                  onChange={handleArrowChange}
                  endpoint={'start'}
                />
              </div>
            </div>
            <div className='boxs'>
              <div className='name'>终点</div>
              <div className='arrow'>
                <ArrowSetting
                  arrowGroup={arrowGroup}
                  arrows={ArrowTypes}
                  onChange={handleArrowChange}
                  endpoint={'end'}
                />
              </div>
            </div>
          </div>}
          {/* {listChecked5 && <div className='btnBox'>
            <div className='name'>箭头互换</div>
            <div className='num2'>
              <img src={zhihuan} alt='' />
            </div>
          </div>} */}
        </div>}
      </div>
    </ToolsLinkStyle>
  )
}

export { ToolsLink }

ToolsLink.propTypes = {
  onChangeChecked: PropTypes.func,
  listChecked4: PropTypes.bool,
  listChecked5: PropTypes.bool,
  lineColor: PropTypes.string,
  handleArrowChange: PropTypes.func,
  handleChangeStyle: PropTypes.func,
  lineDash: PropTypes.any,
  lineWidth: PropTypes.number,
  arrowGroup: PropTypes.object,
  ArrowTypes: PropTypes.array,
  edition_seven_seven: PropTypes.bool
}

class Style {
  constructor() {
    this.alpha = Style.DEFAULT_ALPHA
    this.compositeOperation = Style.DEFAULT_COMPOSITE_OPERATION
    this.filter = Style.DEFAULT_FILTER
  }
  static DEFAULT_ALPHA = 1
  static DEFAULT_COMPOSITE_OPERATION = "source-over"
  static DEFAULT_FILTER = "none"
  getAlpha () {
    return this.alpha;
  }
  setAlpha (alpha) {
    this.alpha = alpha
    return this;
  }
  getCompositeOperation () {
    return this.compositeOperation;
  }
  setCompositeOperation (compositeOperation) {
    this.compositeOperation = compositeOperation
    return this;
  }
  getFilter () {
    return this.filter;
  }
  setFilter (filter) {
    this.filter = filter
    return this;
  }
}

export default Style

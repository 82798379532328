import { initSystemConfig } from '../state'
import {
  CHANGE_SYSTEM_STYLE
} from '../mutation-type'

export default function configReducer(state = initSystemConfig, action) {
  switch (action.type) {
    case CHANGE_SYSTEM_STYLE: // 更新页面设置
      return {
        ...state, ...action.value
      }

    default:
      return state
  }
}

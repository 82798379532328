import ShapeStyle from './styles/shapeStyle'
import utils from '../utils'
import Matrix from './matrix'
class Shape {
  constructor(ctx, sx, sy, ox, oy) {
    this.ctx = ctx
    this.init(sx, sy, isNaN(ox) ? 0 : ox, isNaN(oy) ? 0 : oy)
    this.setMatrix(new Matrix())
    this._initStyle()
  }
  _initStyle() {
    // lineLimit lineDash lineCap lineJoin lineWidth
    this.setStyle(new ShapeStyle())
  }

  init(sx, sy, ox, oy) { // 初始化点坐标 ox: 画布原点x sx: 鼠标在屏幕上捕捉到的开始点 _sx: 绘制的开始点
    this.sx = sx
    this.sy = sy
    this.ox = ox
    this.oy = oy
    this._sx = sx - ox
    this._sy = sy - oy
    return this
  }

  fill(fillRule) {
    return this._setFillStyles()._preprocess()._fill(fillRule)
  }
  _setFillStyles() {
    return this._setStyle()._setShadowStyle()._setFillStyle()
  }
  _fill() {
    return this.ctx.fill(), this
  }
  stroke() {
    return this._setStrokeStyles()._preprocess()._stroke()
  }
  _setStrokeStyles() {
    return this._setStyle()._setShadowStyle()._setStrokeStyle()
  }
  _stroke() {
    this.ctx.stroke()
    return this
  }
  fillStroke(fillRule) { // 填充路径颜色
    return this._setFillStrokeStyles()
      ._preprocess()
      ._fill(fillRule)
      ._stroke()
  }
  strokeFill(fillRule) { // 填充图形颜色
    return this._setFillStrokeStyles()
      ._preprocess()
      ._stroke()
      ._fill(fillRule)
  }
  _setFillStrokeStyles() {
    return this._setStyle()
      ._setShadowStyle()
      ._setFillStyle()
      ._setStrokeStyle()
  }
  _setStyle() {
    let ctx = this.ctx
    let sty = this.sty
    return (
      ctx._alpha !== sty.alpha && (ctx.globalAlpha = ctx._alpha = sty.alpha),
      ctx._compositeOperation !== sty.compositeOperation &&
      (ctx.globalCompositeOperation = ctx._compositeOperation =
        sty.compositeOperation),
      ctx._filter !== sty.filter && (ctx.filter = ctx._filter = sty.filter),
      this
    )
  }
  _setShadowStyle() {
    let ctx = this.ctx
    let sty = this.sty
    let dpr = ctx._dpr
    return (
      ctx._shadowBlur !== sty.shadowBlur &&
      (ctx.shadowBlur = (ctx._shadowBlur = sty.shadowBlur) * dpr),
      ctx._shadowColor !== sty.shadowColor &&
      (ctx.shadowColor = ctx._shadowColor = sty.shadowColor),
      ctx._shadowOffsetX !== sty.shadowOffsetX &&
      (ctx.shadowOffsetX = (ctx._shadowOffsetX = sty.shadowOffsetX) * dpr),
      ctx._shadowOffsetY !== sty.shadowOffsetY &&
      (ctx.shadowOffsetY = (ctx._shadowOffsetY = sty.shadowOffsetY) * dpr),
      this
    )
  }
  _setFillStyle() { // 设置图形的填充颜色
    let ctx = this.ctx
    let sty = this.sty
    if (ctx._fillStyle !== sty.fillStyle) {
      ctx.fillStyle = ctx._fillStyle = sty.fillStyle
    }
    return this
  }
  _setStrokeStyle() { // 设置图形轮廓的颜色
    let ctx = this.ctx
    let sty = this.sty
    return (
      ctx._strokeStyle !== sty.strokeStyle &&
      (ctx.strokeStyle = ctx._strokeStyle = sty.strokeStyle),
      ctx._lineDash !== sty.lineDash &&
      ctx.setLineDash((ctx._lineDash = sty.lineDash)),
      ctx._lineDashOffset !== sty.lineDashOffset &&
      (ctx.lineDashOffset = ctx._lineDashOffset = sty.lineDashOffset),
      (ctx.lineWidth !== sty.lineWidth || ctx._lineWidth !== sty.lineWidth) &&
      (ctx.lineWidth = ctx._lineWidth = sty.lineWidth),
      ctx._lineCap !== sty.lineCap &&
      (ctx.lineCap = ctx._lineCap = sty.lineCap),
      ctx._lineJoin !== sty.lineJoin &&
      (ctx.lineJoin = ctx._lineJoin = sty.lineJoin),
      ctx._miterLimit !== sty.miterLimit &&
      (ctx.miterLimit = ctx._miterLimit = sty.miterLimit),
      this
    )
  }
  clip(fillRule) {
    return this._preprocess()._save()._clip(fillRule)
  }
  _clip(fillRule) {
    this.ctx.clip(fillRule)
    return this
  }
  _save() {
    let ctx = this.ctx
    ctx._stack.push({
      _m11: ctx._m11,
      _m12: ctx._m12,
      _m21: ctx._m21,
      _m22: ctx._m22,
      _m41: ctx._m41,
      _m42: ctx._m42,
      _m11i: ctx._m11i,
      _m12i: ctx._m12i,
      _m21i: ctx._m21i,
      _m22i: ctx._m22i,
      _m41i: ctx._m41i,
      _m42i: ctx._m42i,
      _alpha: ctx._alpha,
      _compositeOperation: ctx._compositeOperation,
      _filter: ctx._filter,
      _shadowBlur: ctx._shadowBlur,
      _shadowColor: ctx._shadowColor,
      _shadowOffsetX: ctx._shadowOffsetX,
      _shadowOffsetY: ctx._shadowOffsetY,
      _fillStyle: ctx._fillStyle,
      _strokeStyle: ctx._strokeStyle,
      _lineDash: ctx._lineDash,
      _lineDashOffset: ctx._lineDashOffset,
      _lineWidth: ctx._lineWidth,
      _lineCap: ctx._lineCap,
      _lineJoin: ctx._lineJoin,
      _miterLimit: ctx._miterLimit,
      _font: ctx._font,
      _textAlign: ctx._textAlign,
      _textBaseline: ctx._textBaseline,
      _direction: ctx._direction,
      _smoothingEnabled: ctx._smoothingEnabled,
      _smoothingQuality: ctx._smoothingQuality
    })
    ctx.save()
    return this
  }
  restore(count) {
    let pop, ctx, stack
    ctx = this.ctx
    stack = ctx._stack
    while (count > 0) {
      pop = stack.pop()
      ctx._m11 = pop._m11
      ctx._m12 = pop._m12
      ctx._m21 = pop._m21
      ctx._m22 = pop._m22
      ctx._m41 = pop._m41
      ctx._m42 = pop._m42
      ctx._m11i = pop._m11i
      ctx._m12i = pop._m12i
      ctx._m21i = pop._m21i
      ctx._m22i = pop._m22i
      ctx._m41i = pop._m41i
      ctx._m42i = pop._m42i
      ctx._alpha = pop._alpha
      ctx._compositeOperation = pop._compositeOperation
      ctx._filter = pop._filter
      ctx._shadowBlur = pop._shadowBlur
      ctx._shadowColor = pop._shadowColor
      ctx._shadowOffsetX = pop._shadowOffsetX
      ctx._shadowOffsetY = pop._shadowOffsetY
      ctx._fillStyle = pop._fillStyle
      ctx._strokeStyle = pop._strokeStyle
      ctx._lineDash = pop._lineDash
      ctx._lineDashOffset = pop._lineDashOffset
      ctx._lineWidth = pop._lineWidth
      ctx._lineCap = pop._lineCap
      ctx._lineJoin = pop._lineJoin
      ctx._miterLimit = pop._miterLimit
      ctx._font = pop._font
      ctx._textAlign = pop._textAlign
      ctx._textBaseline = pop._textBaseline
      ctx._direction = pop._direction
      ctx._smoothingEnabled = pop._smoothingEnabled
      ctx._smoothingQuality = pop._smoothingQuality
      ctx.restore()
      --count
    }
    return this
  }
  clear(sx, sy, width, height, fillRule) {
    this.clip(fillRule).ctx.clearRect(
      this._sx + sx,
      this._sy + sy,
      width,
      height
    )
    return this.restore()
  }
  _setTransform() {
    let ctx = this.ctx
    let mat = this.mat
    let m11 = mat.getM11()
    let m22 = mat.getM22()
    let m12 = mat.getM12()
    let m21 = mat.getM21()
    let m41 = mat.offsetX + this.ox
    let m42 = mat.offsetY + this.oy
    if (m11 !== ctx._m11 || m22 !== ctx._m22 || m12 !== ctx._m12 || m21 !== ctx._m21) {
      ctx.transform(
        m11 * ctx._m11i + m12 * ctx._m21i,
        m11 * ctx._m12i + m12 * ctx._m22i,
        m21 * ctx._m11i + m22 * ctx._m21i,
        m21 * ctx._m12i + m22 * ctx._m22i,
        m41 * ctx._m11i + m42 * ctx._m21i + ctx._m41i,
        m41 * ctx._m12i + m42 * ctx._m22i + ctx._m42i
      )
      ctx._m11 = m11
      ctx._m12 = m12
      ctx._m21 = m21
      ctx._m22 = m22
      ctx._m41 = m41
      ctx._m42 = m42
      ctx._m11i = mat.getM11i()
      ctx._m12i = mat.getM12i()
      ctx._m21i = mat.getM21i()
      ctx._m22i = mat.getM22i()
      ctx._m41i = -m41 * ctx._m11i - m42 * ctx._m21i
      ctx._m42i = -m41 * ctx._m12i - m42 * ctx._m22i
    } else {
      if (m41 !== ctx._m41 || m42 !== ctx._m42) {
        ctx.translate(m41 * ctx._m11i + m42 * ctx._m21i + ctx._m41i, m41 * ctx._m12i + m42 * ctx._m22i + ctx._m42i)
        ctx._m41 = m41
        ctx._m42 = m42
        ctx._m41i = -m41 * ctx._m11i - m42 * ctx._m21i
        ctx._m42i = -m41 * ctx._m12i - m42 * ctx._m22i
      }
    }
    return this
  }
  _resetTransform() {
    let ctx = this.ctx
    return (
      1 !== ctx._m11i || 1 !== ctx._m22i || 0 !== ctx._m12i || 0 !== ctx._m21i
        ? (ctx.transform(
          ctx._m11i,
          ctx._m12i,
          ctx._m21i,
          ctx._m22i,
          ctx._m41i,
          ctx._m42i
        ),
        (ctx._m11 = ctx._m11i = ctx._m22 = ctx._m22i = 1),
        (ctx._m12 =
          ctx._m12i =
          ctx._m21 =
          ctx._m21i =
          ctx._m41 =
          ctx._m41i =
          ctx._m42 =
          ctx._m42i =
          0))
        : (0 === ctx._m41i && 0 === ctx._m42i) ||
      (ctx.translate(ctx._m41i, ctx._m42i),
      (ctx._m41 = ctx._m41i = ctx._m42 = ctx._m42i = 0)),
      this
    )
  }
  _preprocess() {
    return this._setTransform()._process()
  }
  _process() {
    this.process()
    return this
  }
  get process() {
    return utils.noop
  }
  _isPointIn(type, x, y) {
    let ctx = this.ctx
    let m22 = this.mat
    let m11i = m22.getM11i()
    let m12i = m22.getM12i()
    let m21i = m22.getM21i()
    let m22i = m22.getM22i()
    let m41i = -(m22.offsetX + this.ox) * m11i - (m22.offsetY + this.oy) * m21i
    let m42i = -(m22.offsetX + this.ox) * m12i - (m22.offsetY + this.oy) * m22i
    let m11 = ctx._m11
    let m12 = ctx._m12
    let m21 = ctx._m21
    m22 = ctx._m22
    return this['_isPointIn' + type](
      (m11i * m11 + m12i * m21) * x +
      (m21i * m11 + m22i * m21) * y +
      m41i * m11 +
      m42i * m21 +
      ctx._m41,
      (m11i * m12 + m12i * m22) * x +
      (m21i * m12 + m22i * m22) * y +
      m41i * m12 +
      m42i * m22 +
      ctx._m42
    )
  }
  isPointInPath(x, y) {
    return this._isPointIn('Path', x, y)
  }
  _isPointInPath(x, y, range = 0) {
    let ctx = this._process().ctx
    let dpr = ctx._dpr
    ctx.lineWidth = this.getStyle ? this.getStyle().lineWidth + range : range
    return ctx.isPointInPath(x * dpr, y * dpr)
  }
  isPointInStroke(x, y) {
    return this._isPointIn('Stroke', x, y)
  }
  _isPointInColorStroke(x, y) {
    let ctx = this._process().ctx
    let dpr = ctx._dpr
    const d = ctx.getImageData(x * dpr, y * dpr, 1, 1)
    return d.data[3] > 0
  }
  _isPointInStroke(x, y, range = 0) {
    range = range || 3
    let ctx = this._process().ctx
    let dpr = ctx._dpr
    ctx.lineWidth = this.getStyle ? this.getStyle().lineWidth + range : range
    return ctx.isPointInStroke(x * dpr, y * dpr)
  }
  _isQuickIn(type, x, y) {
    let m42i = this.mat
    let m11i = m42i.getM11i()
    let m12i = m42i.getM12i()
    let m21i = m42i.getM21i()
    let m22i = m42i.getM22i()
    let m41i = -(m42i.offsetX + this.ox) * m11i - (m42i.offsetY + this.oy) * m21i
    m42i = -(m42i.offsetX + this.ox) * m12i - (m42i.offsetY + this.oy) * m22i
    return this['_isQuickIn' + type](
      m11i * x + m21i * y + m41i,
      m12i * x + m22i * y + m42i
    )
  }
  isQuickInPath(x, y) {
    return this._isQuickIn('Path', x, y)
  }
  _isQuickInPath(x, y) {
    return this._resetTransform()._isPointInPath(x, y)
  }
  isQuickInStroke(x, y) {
    return this._isQuickIn('Stroke', x, y)
  }
  _isQuickInStroke(x, y) {
    return this._resetTransform()._isPointInStroke(x, y)
  }
  getStartX() {
    return this.sx
  }
  setStartX(sx) {
    return (this.sx = sx), (this._sx = sx - this.ox), this
  }
  getStartY() {
    return this.sy
  }
  setStartY(sy) {
    this.sy = sy
    this._sy = sy - this.oy
    return this
  }
  setStart(sx, sy) {
    return this.setStartX(sx).setStartY(sy)
  }
  getOriginX() {
    return this.ox
  }
  setOriginX(ox) {
    return (this.ox = ox), (this._sx = this.sx - ox), this
  }
  getOriginY() {
    return this.oy
  }
  setOriginY(oy) {
    return (this.oy = oy), (this._sy = this.sy - oy), this
  }
  setOrigin(ox, oy) {
    return this.setOriginX(ox).setOriginY(oy)
  }
  getVectorX() {
    return this._sx
  }
  getVectorY() {
    return this._sy
  }
  getMatrix() {
    return this.mat
  }
  setMatrix(mat) {
    return (this.mat = mat), this
  }
  getStyle() {
    return this.sty
  }
  setStyle(sty) {
    this.sty = sty
    return this
  }
}

export default Shape

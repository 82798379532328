import { combineReducers } from 'redux'
import canvasConfig from './canvasConfig'
import selectObject from './selectObject'
import systemConfig from './systemConfig'

const reducers = combineReducers({
  canvasConfig,
  selectObject,
  systemConfig
})

export default reducers

import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'

const { Option } = Select

const CustomSelect = (props) => {
  const { values, value, valueKey = 'id', onChange } = props
  const [val, setVal] = useState(value[valueKey])

  useEffect(() => {
    setVal(value[valueKey])
  }, [value])

  return (<Select
    style={{ width: '70px' }}
    onChange={(v) => {
      setVal(v)
      onChange(v)
    }}
    value={val}
    bordered={false}
  >
    {values.map((e, index) => (
      <Option
        key={index}
        value={e[valueKey]}
        label={e[valueKey]}
        style={{ borderLeft: `6px solid ${e[valueKey] === val ? 'black' : 'transparent'}` }}
        className={e[valueKey] === val ? 'selected' : ''}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%'
        }}>
          {e.component}
        </div>
      </Option>
    ))}
  </Select>
  )
}

CustomSelect.propTypes = {
  values: PropTypes.array,
  value: PropTypes.object,
  valueKey: PropTypes.string,
  onChange: PropTypes.func
}

export default CustomSelect

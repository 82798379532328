/* eslint-disable  */
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { LeftPanelsCssWrapper } from './style'
import List from './list'
import IconButton from 'components/comm/IconButton'
import { useBoardContext } from 'views/board/hooks'
import PropTypes from 'prop-types'

import Upload from 'utils/upload'
import { UPLOAD_PIC } from '../../api'

const upload = new Upload({
  fileSize: 20 * 1024 * 1024, // 上传图片最大大小
  fileType: '|jpg|jpeg|png|webp'
})
const TabItems = [
  // {
  //   icon: 'tab-item1',
  //   id: 'Shapes',
  //   name: "形状组合",
  //   src: 'xingzhuang'
  // },
  {
    icon: 'tab-item1',
    id: 'London',
    src: 'shapePng',
    name: "形状"
  },
  {
    icon: 'tab-item1',
    id: 'Line',
    src: 'lianjiexian',
    name: "连接线"
  },
  {
    icon: 'tab-item1',
    id: 'Text',
    name: "文本",
    src: 'textPng'
  },
  // {
  //   icon: 'tab-item1',
  //   id: 'Container',
  //   name: "容器",
  //   src: 'containerPng'
  // },
  {
    icon: 'tab-item1',
    id: 'Upload',
    name: "上传",
    src: 'uploadPng'
  }
]

export default function LeftPanels(props) {
  const [showId, setShowId] = useState("");
  const fileEl = useRef(null)
  const expandPanelEl = useRef(null)
  const { board } = useBoardContext()
  const { shapeList, onShapeChecked, shapeContentList, shapeDiv, onClose, shapeName } = props

  useEffect(() => {
    const click = (event) => {
      if (expandPanelEl.current.needClose) {
        showId && setShowId("");
      }
      expandPanelEl.current.needClose = true
    }
    document
      .getElementById("drawBoardContainer")
      .addEventListener("click", click);

    return () => {
      document
        .getElementById("drawBoardContainer")
        .removeEventListener("click", click);
    };
  });

  const changeShowContent = (showId) => {
    expandPanelEl.current.needClose = true
    if (showId === "Upload") {
      fileEl.current.click()
      //TODO 完善图片上传
    }
    setShowId(showId)
  }

  const handleImageChange = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    e.target.value = ''
    const handleUpload = upload.handleBeforeUpload(file)

    if (handleUpload.status) {
      upload.uploadPicFile(file, UPLOAD_PIC).then(res => {
        if (res.status === 200) {
          board.api.setPicture(res.data.url)
        } else {
          console.log('上传错误!')
        }
      })
    }
  }

  const closeExpandTool = () => {
    setShowId("");
  }

  useEffect(() => {
    shapeDiv && setShowId('Shapes')
  }, [shapeDiv])

  const handleMove = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
  }, [])

  return (
    <LeftPanelsCssWrapper onMouseMove={handleMove}>
      <div className='tab'>
        {TabItems.map((item, index) => (
          <div className={`category${index} category`} key={item.id} id={item.id === 'Text' ? '_dragText' : item.id === 'Line' ? '_dragLine' : ''} onClick={() => changeShowContent(item.id)}>
            {/* <button className={showId === item.id  ? 'tablinks active' : 'tablinks'}>
              <IconButton name={''} fill='#5682E6' selected={true} showToolTip={false} widthInPx={24}/>
            </button> */}
            <button className={showId === item.id ? 'tabBtn active' : 'tabBtn'}>
              <div className='img'>
                <IconButton name={item.src} fill='#000' selected={true} showToolTip={false} widthInPx={20} />
              </div>
            </button>

            {/* <span className={"desc"}>{item.name}</span> */}
            <div className={`div${index} desc`}>
              <div className={`text${index} text`}>{item.name}</div>
            </div>
          </div>
        ))}
      </div>

      {TabItems.map((item) => (
        // TODO 根据功能不断完善
        <div ref={item.id === 'London' ? expandPanelEl : null} key={item.id} id={item.id} className={item.id === showId && showId === "London" ? 'tabcontent' : item.id === "Shapes" && showId === "Shapes" ? 'tabShape' : 'hidden'}>
          {/* {item.com} */}
          {
            item.id === "London" && !shapeDiv ? <List expandPanelEl={expandPanelEl.current} closeExpandTool={closeExpandTool} /> : ""
          }
          {/* {
            showId === "Shapes" || shapeDiv ? <Shape
              closeExpandTool={closeExpandTool} shapeList={shapeList} onShapeChecked={onShapeChecked}
              shapeContentList={shapeContentList} onClose={onClose} shapeName={shapeName}
            /> : ""
          } */}
        </div>
      ))}

      {/* <div className='shapeDiv'>
        {
          showId === "Shapes" || shapeDiv ? <Shape
            closeExpandTool={closeExpandTool} shapeList={shapeList} onShapeChecked={onShapeChecked}
            shapeContentList={shapeContentList} onClose={onClose} shapeName={shapeName}
          /> : ""
        }
      </div> */}

      <div className='insertFile'>
        <input
          onChange={(e) => { handleImageChange(e) }}
          ref={fileEl} type='file' accept='image/*' style={{ display: 'none' }} />
      </div>
    </LeftPanelsCssWrapper>
  )
}

LeftPanels.propTypes = {
  shapeList: PropTypes.array,
  shapeContentList: PropTypes.array,
  onListShapesFn: PropTypes.func,
  onShapeChecked: PropTypes.func,
  onClose: PropTypes.func,
  shapeDiv: PropTypes.bool,
  shapeName: PropTypes.string
}

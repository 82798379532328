import styled from 'styled-components'

export const ToolsFrameStyle = styled.div`
.frameTools{
    padding-left:20px;
    padding-right: 20px;
  }
  .frameTools .shape{
    border-bottom:1px solid #E3E3E3;
    width:100%;
    height:100%;
  }
  .frameTools .shape .iconBox{
    display:flex;
    align-items:center;
  }

  .frameTools .shape .iconBox .icon{
    display: flex;
    align-items: center;
    justify-content:center;
    margin-top:20px;
  }
  .frameTools .shape .btnBox{
    padding-bottom:20px;
    display: flex;
    align-items: center;
    justify-content:space-between;
  }

  .frameTools .shape .btnBox .boxXZ{
    width: 110px;
    height: 34px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content:center;
  }
  .frameTools .shape .btnBox .boxXZ .fang{
    width: 18px;
    height: 18px;
    border:1px solid #000;
  }

  .frameTools .shape .btnBox{
    padding-bottom:20px;
    display: flex;
    align-items: center;
    justify-content:space-between;
  }

`

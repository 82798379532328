/*
  表示数据的icon
 */
import Process from './process'
class Data extends Process {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.width = width || 160
    this.height = height || 120
    this.arcRadius = 5
  }
  process() {
    const { ctx, _sx, _sy, _right, _bottom, arcRadius, height } = this
    const radian = 15 / 180 * Math.PI
    const tilt = height * Math.tan(radian) // x轴倾斜的距离
    const sinV = Math.sin(radian)
    const cosV = Math.cos(radian)
    ctx.beginPath()
    const fc = { x: _sx + tilt, y: _sy } // firstCorner
    const fp = { x: fc.x - sinV * arcRadius, y: fc.y + cosV * arcRadius } // firstPoint
    const sArcRadius = arcRadius / 2
    ctx.moveTo(fp.x, fp.y)
    ctx.arcTo(fc.x, fc.y, fc.x + arcRadius, fc.y, arcRadius)
    const sc = { x: _right, y: _sy } // secondCorner
    ctx.arcTo(sc.x, sc.y, sc.x - sinV * sArcRadius, sc.y + cosV * sArcRadius, sArcRadius)
    const tc = { x: _right - tilt, y: _bottom } // thirdCorner
    ctx.arcTo(tc.x, tc.y, tc.x - arcRadius, tc.y, arcRadius)
    const lc = { x: _sx, y: _bottom }
    ctx.arcTo(lc.x, lc.y, lc.x + sinV * sArcRadius, lc.y - cosV * sArcRadius, sArcRadius)
    ctx.lineTo(fp.x, fp.y)
  }
}
export default Data

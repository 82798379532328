import mathHelper from "../../../../utils/mathHelper";
import Point from 'components/drawBoard/shapes/point'

export default class LinkLayout {
  constructor(target) {
    this.target = target
    this.start = this.end = null // 开始点和结束点
  }
  judgeDirIsVertical(start, node) { // 判断线段的连接方向是否是上下结构 true 上下 false 左右
    if (!node) return false
    const {x, right, bottom, y} = node
    let gap = Math.abs(start.x - x)
    const isAroundLeft = gap >= 8 && gap <= 20
    gap = Math.abs(start.x - right)
    const isAroundRight = gap >= 8 && gap <= 20
    gap = Math.abs(start.y - bottom)
    const isAroundBottom = gap >= 0 && gap <= 5
    gap = Math.abs(start.y - y)
    const isAroundTop = gap >= 0 && gap <= 5
    return isAroundLeft || isAroundRight || isAroundBottom || isAroundTop
  }
  compute() {
    const {start, end, first, last} = this.target

    const _first = first || last
    const _last = _first === first ? last : first
    let _start, _end;

    if (_first == first) {
      _start = start
      _end = end
    } else {
      _start = end
      _end = start
    }
    this.start = _start
    this.end = _end

    let startSlope = 0 // 起点斜率
    let endSlope = 0 // 终点斜率

    if (_start.distance(_end) < 2) { // 当开始点和结束点距离非常近，不再执行智能判断逻辑
      return [_start, _end, startSlope, endSlope]
    }

    if(_first) { // 起点连接了
      const {x, right, bottom, y} = _first
      const cx = (x + right) / 2.0
      const cy = (y + bottom) / 2.0
      const isVerticalStart = this.judgeDirIsVertical(_start, _first)

      if (isVerticalStart) { // 起点竖向连接线
        startSlope = _start.y > cy ? (Math.PI * 3 / 2.0) : (Math.PI / 2.0)
      } else { // 起点横向连接线
        startSlope = _start.x > cx ? Math.PI: 0
      }
    } else {
      startSlope = mathHelper.getSlopeByTwoPoint(_start, _end)
    }

    if (_last) { // 终点连接了
      const {x, right, bottom, y} = _last
      const cx = (x + right) / 2.0
      const cy = (y + bottom) / 2.0
      const isVerticalEnd = this.judgeDirIsVertical(_end, _last)

      if(isVerticalEnd) { // 上下
        endSlope = _end.y > cy ?  Math.PI * 3 / 2.0 : Math.PI / 2.0
      } else {
        endSlope = _end.x > cx ?  Math.PI : 0
      }
    } else { // 终点没连接
      endSlope = mathHelper.getSlopeByTwoPoint(_end, _start)
    }

    const copyStart = new Point(_start.x, _start.y)
    const copyEnd = new Point(_end.x, _end.y)
    return [copyStart, copyEnd, startSlope, endSlope]
  }
  setTextRate(n) { // 获取文字长度占比 此方法只在添加文字时执行一次
    n.rateAtLink = 0.5
    return n
  }
  adjustText(t) { // t: text 对象
    t.layoutX = t.layoutX - t.width / 2
    t.layoutY = t.layoutY - t.height / 2
    t.apply()
  }
  layoutTexts() { // 计算文字节点位置
    const { target} = this
    const cp = target.compoundLines.getCenterPoint()
    target.texts.forEach((text) => {
      text.setLayout(cp.x, cp.y)
      this.adjustText(text)
    })
  }
}
class Point {
  constructor(x, y) {
    this.init(x, y)
    this.isUsed = false
  }
  static basisX = new Point(1, 0)
  static basisY = new Point(0, 1)
  static vertical2point = function (x2, y2, result) {
    var x1, y1;
    0 === y2.x
      ? result.init(0, x2.y)
      : 0 === y2.y
      ? result.init(x2.x, 0)
      : ((x1 = x2.x),
        (y1 = x2.y),
        (x2 = y2.x),
        (y2 = y2.y),
        result.init(
          (x1 * x2 * x2 + x2 * y2 * y2 + (y1 - y2) * x2 * y2) /
            (x2 * x2 + y2 * y2),
          (y1 * y2 * y2 + y2 * x2 * x2 + (x1 - x2) * x2 * y2) /
            (x2 * x2 + y2 * y2)
        ));
  }
  static vertical2line = function (y2, x3, y3, result) {
    var x1, y1, x2;
    x3.x === y3.x
      ? result.init(x3.x, y2.y)
      : x3.y === y3.y
      ? result.init(y2.x, x3.y)
      : ((x1 = y2.x),
        (y1 = y2.y),
        (x2 = x3.x),
        (y2 = x3.y),
        (x3 = y3.x),
        (y3 = y3.y),
        result.init(
          (x1 * (x2 - x3) * (x2 - x3) +
            x2 * (y2 - y3) * (y2 - y3) +
            (y1 - y2) * (x2 - x3) * (y2 - y3)) /
            ((x2 - x3) * (x2 - x3) + (y2 - y3) * (y2 - y3)),
          (y1 * (y2 - y3) * (y2 - y3) +
            y2 * (x2 - x3) * (x2 - x3) +
            (x1 - x2) * (x2 - x3) * (y2 - y3)) /
            ((x2 - x3) * (x2 - x3) + (y2 - y3) * (y2 - y3))
        ));
  }
  static line2line = function (b2, a2, den, c2, result) {
    var a1 = a2.y - b2.y,
      b1 = b2.x - a2.x,
      c1 = -a1 * b2.x - b1 * b2.y,
      a2 = c2.y - den.y,
      b2 = den.x - c2.x,
      c2 = -a2 * den.x - b2 * den.y,
      den = a1 * b2 - a2 * b1;
    result.init((c2 * b1 - c1 * b2) / den, (c1 * a2 - c2 * a1) / den);
  }
  static line2arc = function (delta, mp1, point, x, result1, result2) {
    delta.x === mp1.x &&
      ((y = Math.sqrt(Math.pow(x, 2) - Math.pow(delta.x - point.x, 2))),
      result1.init(delta.x, point.y - y),
      result2.init(delta.x, point.y + y));
    var m = (mp1.y - delta.y) / (mp1.x - delta.x),
      d = delta.y - m * delta.x,
      y = Math.pow(m, 2),
      mp1 = y + 1,
      delta = Math.sqrt(
        Math.pow(x, 2) * mp1 - Math.pow(point.y - m * point.x - d, 2)
      ),
      x = point.x + point.y * m - d * m,
      y = d + point.x * m + point.y * y;
    result1.init((x + delta) / mp1, (y + m * delta) / mp1),
      result2.init((x - delta) / mp1, (y - m * delta) / mp1);
  }
  static arc2arc = function (b, n, y, m, result1, result2) {
    var x1 = b.x,
      y1 = b.y,
      x = y.x,
      y2 = y.y,
      r1p = Math.pow(n, 2),
      r2p = Math.pow(m, 2),
      b = Math.pow(x - x1, 2) + Math.pow(y2 - y1, 2),
      y = Math.sqrt(b),
      n = (x - x1) / y,
      m = (y2 - y1) / y,
      y = (r1p - r2p) / 2 / y,
      x = (x1 + x) / 2 + y * n,
      y = (y1 + y2) / 2 + y * m,
      b = Math.sqrt((r1p + r2p) / 2 - Math.pow(r1p - r2p, 2) / 4 / b - b / 4),
      m = b * m,
      n = -b * n;
    result1.init(x + m, y + n), result2.init(x - m, y - n);
  }
  static arc = function (xy2, xy3, a, result) {
    var x1 = xy2.x,
      b = xy2.y,
      c = xy3.x,
      y2 = xy3.y,
      x3 = a.x,
      y3 = a.y,
      xy1 = x1 * x1 + b * b,
      xy2 = c * c + y2 * y2,
      xy3 = x3 * x3 + y3 * y3,
      a = x1 * (y2 - y3) - b * (c - x3) + c * y3 - x3 * y2,
      b = xy1 * (y3 - y2) + xy2 * (b - y3) + xy3 * (y2 - b),
      c = xy1 * (c - x3) + xy2 * (x3 - x1) + xy3 * (x1 - c);
    result.init(-b / a / 2, -c / a / 2);
  }
  static arcin = function (a, b, c, result) {
    var x1 = a.x,
      y1 = a.y,
      x2 = b.x,
      y2 = b.y,
      x3 = c.x,
      y3 = c.y,
      a = Math.sqrt(Math.pow(x2 - x3, 2) + Math.pow(y2 - y3, 2)),
      b = Math.sqrt(Math.pow(x1 - x3, 2) + Math.pow(y1 - y3, 2)),
      c = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
    result.init(
      (a * x1 + b * x2 + c * x3) / (a + b + c),
      (a * y1 + b * y2 + c * y3) / (a + b + c)
    );
  }
  init (x, y) {
    return (this.x = x), (this.y = y), this;
  }
  equals (point) {
    return this.x === point.x && this.y === point.y;
  }
  getX () {
    this.isUsed = true
    return this.x;
  }
  setX (x) {
    this.isUsed = true
    return (this.x = x), this;
  }
  setXY(x, y) {
    this.isUsed = true
    this.setX(x).setY(y)
    return this
  }
  getY () {
    this.isUsed = true
    return this.y;
  }
  setY (y) {
    this.isUsed = true
    return (this.y = y), this;
  }
  ratio2point (point) {
    return 0 === point.x ? this.y / point.y : this.x / point.x;
  }
  ratio2line (point1, point2) {
    return point1.x === point2.x
      ? (this.y - point1.y) / (point2.y - point1.y)
      : (this.x - point1.x) / (point2.x - point1.x);
  }
  anti2point (point) {
    return this.x * point.y < this.y * point.x;
  }
  anti2line (point1, point2) {
    return (
      (this.x - point1.x) * (this.y - point2.y) <
      (this.y - point1.y) * (this.x - point2.x)
    );
  }
  modulus () {
    return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
  }
  slope () {
    return this.y / this.x;
  }
  radian () {
    return Math.atan2(this.y, this.x);
  }
  distance (point) { // 两点之间线条的长度
    return Math.sqrt(
      Math.pow(this.x - point.x, 2) + Math.pow(this.y - point.y, 2)
    );
  }
  center(point) { // 两点之间中点
    return new Point((this.x + point.x) / 2, (this.y + point.y) / 2)
  }
  manhattan (point) { // 两点之间直角的线段长度和
    return Math.abs(this.x - point.x) + Math.abs(this.y - point.y);
  }
  dotProduct (point) {
    return this.x * point.x + this.y * point.y;
  }
  crossProduct (point) {
    return this.x * point.y - this.y * point.x;
  }
  theta (point) {
    return Math.acos(
      this.dotProduct(point) / (this.modulus() * point.modulus())
    );
  }
  copy (point) {
    return this.init(point.x, point.y);
  }
  clone () {
    return new Point(this.x, this.y);
  }
  unit () {
    var modulus = this.modulus();
    return (this.x /= modulus), (this.y /= modulus), this;
  }
  abs () {
    return (
      this.x < 0 && (this.x = -this.x), this.y < 0 && (this.y = -this.y), this
    );
  }
  inverse () {
    return (this.x = -this.x), (this.y = -this.y), this;
  }
  inverseX () {
    return (this.x = -this.x), this;
  }
  inverseY () {
    return (this.y = -this.y), this;
  }
  vertical () {
    var y = this.x;
    return (this.x = -this.y), (this.y = y), this;
  }
  verticalAnti () {
    var y = -this.x;
    return (this.x = this.y), (this.y = y), this;
  }
  add (point) {
    return (this.x += point.x), (this.y += point.y), this;
  }
  subtract (point) {
    return (this.x -= point.x), (this.y -= point.y), this;
  }
  multiply (point) {
    var y = this.y * point.x + this.x * point.y;
    return (this.x = this.x * point.x - this.y * point.y), (this.y = y), this;
  }
  divide (point) {
    var den = Math.pow(point.x, 2) + Math.pow(point.y, 2),
      y = (this.y * point.x - this.x * point.y) / den;
    return (
      (this.x = (this.x * point.x + this.y * point.y) / den),
      (this.y = y),
      this
    );
  }
  inline (point, ratio) {
    this.x += (point.x - this.x) * ratio
    this.y += (point.y - this.y) * ratio
    return this
  }
  skewX (skewX) {
    return (this.x += this.y * Math.tan(skewX)), this;
  }
  skewY (skewY) {
    return (this.y += this.x * Math.tan(skewY)), this;
  }
  skew (skewX, y) {
    y = this.y + this.x * Math.tan(y);
    return (this.x += this.y * Math.tan(skewX)), (this.y = y), this;
  }
  scaleX (scaleX) {
    return (this.x *= scaleX), this;
  }
  scaleY (scaleY) {
    return (this.y *= scaleY), this;
  }
  scale (scaleX, scaleY) {
    return (this.x *= scaleX), (this.y *= scaleY), this;
  }
  rotate (y) {
    var cos = Math.cos(y),
      sin = Math.sin(y),
      y = this.x * sin + this.y * cos;
    return (this.x = this.x * cos - this.y * sin), (this.y = y), this;
  }
  beforeOffset() {
    this._x = this.x
    this._y = this.y
  }
  get layoutX() {
    return this.x
  }
  set layoutX(v) {
    this.x = v
  }
  get layoutY() {
    return this.y
  }
  set layoutY(v) {
    this.y = v
  }
  offsetX (offsetX) {
    return (this.layoutX += offsetX), this;
  }
  offsetY (offsetY) {
    return (this.layoutY += offsetY), this;
  }
  offset (offsetX, offsetY) {
    return (this.layoutX += offsetX), (this.layoutY += offsetY), this;
  }
  transform (mat) {
    var y = this.x * mat.getM12() + this.y * mat.getM22() + mat.offsetY
    this.x = this.x * mat.getM11() + this.y * mat.getM21() + mat.offsetX
    this.y = y
    return this
  }
  resetTransform (mat) {
    (this.x -= mat.offsetX), (this.y -= mat.offsetY)
    var y = this.x * mat.getM12i() + this.y * mat.getM22i()
    this.x = this.x * mat.getM11i() + this.y * mat.getM21i()
    this.y = y
    return this
  }
}

export default Point
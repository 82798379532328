/*
  连接线 曲线类型
  fLink: CurveLink
 */
import Link from "../index";
import Layout from "./linkLayout";
import Line from "../../shapes/line";
import CompoundCurveLines from "./compoundCurveLines";
import Style from "./curveLinkStyle";
import mathHelper from "../../../../utils/mathHelper";
import {LINETYPE} from '../constant'

class CurveLink extends Link {
  constructor(ctx, board, data) {
    super(ctx, board, data);
    this.type = LINETYPE.CURVE;
    this.length = 0;
    this.isInited = false // 是否是初始化
    this.style = new Style(this);
    this.initLines(ctx, board, data);  // this.compoundLines
    this.line = new Line(ctx, 0, 0, 0, 0); // 用于碰撞检测
    this.layout = new Layout(this);
    this.drawPoints = [];
  }

  initLines(ctx, board, data) {
    this.compoundLines = new CompoundCurveLines(ctx, board, this.style, data);

    // 修正两端箭头
    const lines = this.compoundLines.lines
    if (lines.length === 0) return;
    const firstLine = lines[0];
    const ps1 = firstLine.getCurveLinePoints();
    const slope1 = mathHelper.getSlopeByTwoPoint(ps1.p1, ps1.cp1);

    const lastLine = lines[lines.length - 1];
    const ps2 = lastLine.getCurveLinePoints();
    const slope2 = mathHelper.getSlopeByTwoPoint(ps2.p2, ps2.cp2);

    if (slope1) {
      this.startArrow.setRotation(slope1);
    }

    if (slope2) {
      this.endArrow.setRotation(slope2);
    }
  }


  isEnterLineRange({ x, y }, gap = 0) {
    return this.compoundLines.isEnterBackground(x, y);
  }

  isEnterSplitPoint(point) {
    return this.compoundLines.isTouchingSplitPoint(point, 3);
  }
  isEnterCurveControlPoint(point) {
    return this.compoundLines.isTouchingControlPoint(point, 3);
  }

  isEnterDragPoint(point) {
    return this.compoundLines.isTouchingDragPoint(point, 3);
  }

  drawChildContent() {
    this.compoundLines.draw(this.isShowBackground);
  }

  dragControlPoint(move) {
    const isStartMove = this.mouseInWhich === "startControl";
    const arrowLen = this.getArrowWidth()
    const newSlope = this.compoundLines.dragControlPoint(move, arrowLen, isStartMove);

    if (newSlope) {
      if (isStartMove) {
        this.startArrow.setRotation(newSlope);
      } else {
        this.endArrow.setRotation(newSlope);
      }
    }

    this.style.resetControlStyle()
    this.updateTextPos()
  }


  dragDragPoint(move) {
    const arrowLen = this.getArrowWidth()
    const arrow = this.compoundLines.dragDragPoint(move, arrowLen);
    if (arrow.start) {
      this.startArrow.setRotation(arrow.start);
    }

    if (arrow.end) {
      this.endArrow.setRotation(arrow.end);
    }

    this.style.resetControlStyle()
    this.updateTextPos()
  }

  dragSplitPoint(move) {
    this.dragDragPoint(move);
  }

  offset(x, y) { // 移动
    this.start.offset(x, y)
    this.end.offset(x, y)
    this.texts.forEach(it => {
      it.offset(x, y)
    })
    this.compoundLines.getAllCurves().forEach((l) => {
      l.offset(x, y);
    });
  }

  splitLinkLine() {
    this.compoundLines.splitLine();
    this.style.applyChildStyle();
  }

  mergeUnmovedCurveLine() {
    this.compoundLines.mergeLine();
    this.compoundLines.lastSplitIndex = null
  }

  onscaleChild() {
    const size = Math.min(
      this.board.getDrawLength(this.getStyle().headWidth));
    this.compoundLines.setControlPointCurveHeadSize(size);
  }

  addTextOperation(t) { // t: text
    t.rateAtLink || this.layout.setTextRate(t)
    this.texts.push(t)
    this.layout.layoutTexts()
    this.onSelectText()
    return t
  }
  getStyle() {
    return this.style;
  }
  getArrowWidth() {
    return this.getStyle().arrowLineWidth
  }
  applyChildContent() {
    const [start, end, sSlope, eSlope] = this.layout.compute();
    this.drawPoints = this.setBoundary([start, end]);

    // 复合曲线
    if (this.compoundLines.isControlPointsUpdated()) {
      this.compoundLines.apply(start, end, {isInited: this.isInited});
    } else {
      this.compoundLines.apply(start, end, {initAngles: [sSlope, eSlope], isInited: this.isInited});
    }

    // 更新arrow angle
    const arrowLen = this.getArrowWidth()
    const fLine = this.compoundLines.getFirstLine()
    const lLine = this.compoundLines.getLastLine()

    // arrow 和控制线的 slope 不一样
    const startSlope = fLine.getArrowSlope(arrowLen, true)
    const endSlope = lLine.getArrowSlope(arrowLen, false)

    if (startSlope) {
      this.startArrow.setRotation(startSlope)
    }
    if (endSlope) {
      this.endArrow.setRotation(endSlope)
    }

    this.updateTextPos()

    this.isInited = true
  }

  updateTextPos() {
    this.layout.layoutTexts()
  }

  getPoints() {
    let points = [];
    let controlPoints = [];
    const lines = this.compoundLines.getAllCurves();

    for (let i = 0; i < lines.length; i++) {
      const cl = lines[i];
      const { p1, cp1, cp2 } = cl.getCurveLinePoints();
      if (i !== 0) {
        // 保留第二条先开始的起始点,即所有的中点分割点
        points.push(p1);
      }

      controlPoints.push(cp1);
      controlPoints.push(cp2);
    }

    return { points, controlPoints };
  }

  collideWidthRect(box) {
    const ps = this.drawPoints;
    for (let i = 0; i < ps.length - 1; i++) {
      this.line.setStart(ps[i].x, ps[i].y).setEnd(ps[i + 1].x, ps[i + 1].y);
      if (this.line.collidesWithRect(box)) {
        return true;
      }
    }
    return false;
  }
  eventmoveInChild(_mouse) {
    let target = ''
    if ((target = this.isEnterDragPoint(_mouse))) {
      this.isMouseIn = true;
      this.mouseInWhich = target;
      this.board.$setCursor('move')
    } else if ((target = this.isEnterSplitPoint(_mouse))) { // 曲线特有
      this.isMouseIn = true;
      this.mouseInWhich = target;
      if (this.isCurveLink || this.isStraightLink) {
        this.board.$setCursor('move')
      }
    } else if ((target = this.isEnterCurveControlPoint(_mouse))) {
      // 鼠标是否进入连接线的控制点
      this.isMouseIn = true;
      this.mouseInWhich = target;
      this.board.$setCursor('move')
    }
    return this.isMouseIn
  }
  eventup() {
    this.mergeUnmovedCurveLine();
    if (this.isMovingText) {
      this.layout.setTextRate(this.touchingTarget);
      this.isMovingText = false;
    }
  }
}

export default CurveLink;

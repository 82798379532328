/*
  使用lru策略来维持链表的大小长度
 */
import LinkList from './linkList'
class Entry {
  constructor(value) {
    this.value = value
  }
}
class LRU {
  constructor(maxSize) {
    this._list = new LinkList()
    this._map = new Map()
    this._maxSize = isNaN(maxSize) ? 10 : maxSize
  }
  get (list) {
    var entry = this._map.get(list);
    if (void 0 !== entry) {
      list = this._list;
      return (
        entry !== list.tail && (list.remove(entry), list.push(entry)),
        entry.value
      );
    }
  }
  set (key, value) {
    var list, entry, head, map = this._map;
    if (map.get(key) === void 0) {
      list = this._list
      head = list.length
      entry = this._last
    }
    if (head >= this._maxSize && 0 < head) {
      head = list.head
      list.remove(head)
      this._last = head
    }
    entry ? (entry.value = value) : (entry = new Entry(value));
    entry.key = key
    list.push(entry)
    map.set(key, entry)
  }
  clear () {
    this._list.clear(), this._map.clear();
  }
  get length () {
    return this._list.length
  }
}
export default LRU


import Shape from './index.js'
import Utils from '../utils'
import Ellipse from './ellipse'

class Triangle extends Shape {
  constructor(ctx, sx, sy, length, angle, rotation, closed, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.setLength(isNaN(length) ? 10 : length)
    this.setAngle(isNaN(angle) ? Math.PI / 2 : angle)
    this.setRotation(isNaN(rotation) ? 0 : rotation)
    this.setClosed(void 0 === closed || closed)
  }
  process() {
    var ctx = this.ctx
    var pacy = this.length
    var cx = this._sx - pacy * this._cos
    var cy = this._sy - pacy * this._sin
    var pacx = pacy * this._tan * this._sin
    var pacy = -pacy * this._tan * this._cos
    ctx.beginPath()
    ctx.moveTo(cx + pacx, cy + pacy)
    ctx.lineTo(this._sx, this._sy)
    ctx.lineTo(cx - pacx, cy - pacy)
    ctx.closePath()
  }
  getLength() {
    return this.length
  }
  setLength(length) {
    return (this.length = length), this
  }
  getAngle() {
    return this.angle
  }
  setAngle(angle) {
    return (
      (angle = Math.abs(angle) % Math.PI),
      this.angle !== angle &&
        ((this.angle = angle), (this._tan = Math.tan(angle / 2))),
      this
    )
  }
  getClosed() {
    return this.closed
  }
  setClosed(closed) {
    return (this.closed = closed), this
  }
}

Utils._assign(Triangle, Ellipse, 'getRotation', 'setRotation')

export default Triangle

import {RecordOperation} from '../operation'
import Link from './injectLineType'
import {LINETYPE} from './constant'

class OperateLink {
  constructor(ctx, board, links) {
    Link.injectLineType()
    this.ctx = ctx
    this.board = board // 指向画板实例
    this.previewLink = null // 点击节点添加的link
    this.dragInShape = null // 从左侧面板拖拽进来的link
    this.recordOpt = new RecordOperation(this, OperateLink.OPERATION) // 记录连接线的操作
    this.init(links)
  }
  static OPERATION = {
    'ADD': '_addOperation',
    'REMOVE': '_removeOperation',
    'ADJUST': '_adjustOperation', // 拖动连接线头尾部调整连接线
    'ADDTEXT': '_addTextOperation',
    'REMOVETEXT': '_removeTextOperation', // 移除连接线文字
    'SWITCHTYPE': '_switchTypeOperation', // 改变连接线类型
    'DRAGSPLITPOINT': '_dragSplitPoint', // 拖动曲线分割点
    'DRAGCONTROLPOINT': '_dragControlPoint', // 拖动曲线控制点
    'DRAGSPLITEDPOINT': '_dragSplitedPoint', // 拖动曲线分割点（另外一种形式）
    'CHANGEARROWTYPE': '_changeArrowType' // 修改箭头类型
  }
  static setOnOperation = cb => {
    this.prototype.onOperation = cb
  }
  setPriority(link) { // 设置连接线优先级，后续根据需求变更
    const links = Object.values(this.links)
    this.links = this.board.links = {}
    this.links[link.id] = link
    links.forEach(lk => {
      this.links[lk.id] = lk
    })
  }
  init(data) { // 初始化
    this.links = this.board.links = {}
    const nodes = this.board.nodes

    if (data && Object.prototype.toString(data) === '[object Object]' && Object.keys(data).length) {
      for(let c of Object.values(data)) {
        this._addOperation([this.board._gcLink({first: nodes[c.firstId], last: nodes[c.lastId], ...c})])
      }
    }
  }
  saveLink() { // 保存连接线
    const data = {}
    for(let [cid, c] of Object.entries(this.links)) {
      data[cid] = {
        ...c
      }
    }
    return data
  }

  beforeOffset() { // 在移动连接线前记录连接线的移动前的位置
    for(let i in this.links) {
      this.links[i].beforeOffset()
    }
  }
  offset(x, y, position) { // 移动连接线
    for(let link of Object.values(this.links)) {
      link.offset(x, y, position)
    }
  }
  touchNode(p, m) { // 连接线出现在触碰到node时
    let v;
    if (v = this.board.operateNode.onadsorbJunction(p)) {
      return v
    } else {
      return [this.board.operateNode.pointInNodeBoundary(m), p]
    }
  }
  pointInNode(p) { // 判断鼠标点是否在节点内部
    return this.board.operateNode.pointInNode(p)
  }
  setPreviewLink(end, mouse) { // 改变由touch节点生成的连接线位置
    this.aroundNode([end])
    if (this.pointInNode(mouse)) return // 连接线不能进入节点内部，后期根据需求可进行变更
    const link = this.previewLink
    const [node, _end] = this.touchNode(end, mouse)
    link.isMoving = true
    link.last = node
    link.setEnd(_end.x, _end.y)
    if (link.isStartSameEnd) return
    link.apply()
    this.board.draw()
    link.draw()
    link.isUsed = true
  }

  initPreviewLink() { // 初始化新生成的连接线
    // 点击锚点
    this.previewLink.isMoving = false
    if(this.previewLink.isUsed){
      delete this.previewLink.isUsed
      this.add(this.previewLink)
    }
    this.board.operateNode.linkLeaveAround()
    this.previewLink = null
  }
  initDragInShape() { // 初始化左侧拖动进来的图形对象
    this.dragInShape = null
    this.board.operateTarget = null
  }
  addDragInShape(p) { // 添加左侧拖动进来的图形节点p: position: 添加的位置
    const l = this.dragInShape
    if (l) {
      p && l.setDragInSize(p)
      this.add(l)
      this.initDragInShape()
    }
    return l
  }
  onDragInShape(x, y) {
    if (this.dragInShape) { // 当拖动左侧图标到画板时
      this.setDragInShape({x, y})
      return true
    }
  }
  createDragInShape(data) { // 创建从左侧拖动进来的图形node
    this.board.operateTarget = this
    this.dragInShape = this.board._gcLink({...data, type: LINETYPE.FOLD})
    const p = this.board.getDrawCoordinate(data.x, data.y)
    this.dragInShape.setDragInSize(p)
  }
  setDragInShape(position) { // 实时设置从左侧拖动进来的图形node位置
    this.board.$setCursor('move')
    this.dragInShape.setDragInSize(position)
    this.dragInShape.apply()
    this.board.draw()
    this.dragInShape.draw()
  }
  createPreviewLink(first, jp, lineType) { //first 连接线的开始节点 jp: junction point 连接开始点
    if (first.isSelected && !jp.isTouchPoint) { // 如果是已经选中的节点，连接点已经扩散，需要得到真实的连接开始点
      jp = first.junction._points[jp.index]
    }
    const start = {x: jp.sx, y: jp.sy}
    const end = first.isHasLinkInJunction(jp)
    this.previewLink = this.board._gcLink({first, start, end, type: lineType})
    this.previewLink.setOperatingTarget(this.previewLink.endHead)
    end && (this.previewLink.isUsed = true)
    this.board.selector._clear()
  }
  add(link) { // 添加连接线
    const links = Array.isArray(link) ? link : [link]
    link = this._addOperation(links)
    this.onOperation(links, OperateLink.OPERATION.ADD)
    return link
  }
  addLinkText(link, data) { // 连接线添加文字
    const text = this._addTextOperation(link, data)
    this.hacker.activate(text)
    this.onOperation(link, OperateLink.OPERATION.ADDTEXT)
  }
  _addTextOperation(link, data) { // 添加的位置
    const text = this.board._gcNode({...data, style: data.style || link.style.textStyle, carrier: link, isEdited: false})
    text.rateAtLink = data.rateAtLink
    link.addTextOperation(text)
    link.apply()
    return text
  }
  removeLinkText(link, text) { // 删除连接线上的文字节点
    this._removeTextOperation(link, text)
    this.onOperation(link, OperateLink.OPERATION.REMOVETEXT)
  }
  _removeTextOperation(link, text) {
    const ind = link.texts.findIndex(t => t === text)
    link.texts.splice(ind, 1)
  }
  _addOperation(links) {
    links.forEach(link => {
      this.links[link.id] = link
    })
    return links[0]
  }
  remove(arr = []) { // 删除连接线
    if (!arr.length) return
    this._removeOperation(arr)
    this.board.draw()
    this.onOperation(arr, OperateLink.OPERATION.REMOVE)
  }
  _removeOperation(list) {
    list.forEach(link => {
      delete this.links[link.id]
    })
  }
  // t后面用枚举常量替换
  switchLink(type) {
    const link = this.board.box.selector.selected[0]
    if (!link) return

    this.hacker.isActivated && this.hacker.deactivate()
    const {start, end, first, last, style, texts, id} = link
    type === LINETYPE.CURVE && (style.lineWidth = 2)
    const newLink = this.board._gcLink({first, last, start, end, type, id, style, texts})
    newLink.isFoldLink && (newLink.setOperatingTarget(newLink.startHead), newLink.apply())
    this.board.links[link.id] = newLink
    this.onOperation(link, OperateLink.OPERATION.SWITCHTYPE)
  }
  setRelateNode(nodes) { // 当删除节点时，需要同步设置连接线的节点关联属性（first, last)
    nodes.forEach(n => {
      for (let link of Object.values(this.links)) {
        if (link.first === n) link.first = null
        if (link.last === n) link.last = null
      }
    })
  }
  findNodeLinks(n) { // 查找和节点n连接的连接线
    const a = []
    for(let link of Object.values(this.links)){
      if (link.first === n) {
        a.push({link, position: 'start'})
      } else if (link.last === n) {
        a.push({link, position: 'end'})
      }
    }
    return a
  }
  followNode(node, move, type = 'move', followFiteredLinkIdMap = {}) { //move: 节点移动的距离 type1: 'node move' type2: 'node resize'
    if (!node.isNode) return
    let links = this.findNodeLinks(node)
    if (node.isEditing) links = links.filter(it => it.link.start.y <= node.top + 1 || it.link.start.y >= node.bottom - 1)
    if (links.length === 0) return
    links.forEach(it => {
      const isFitered = followFiteredLinkIdMap[it.link.id]
      if (isFitered) return
      if (type === 'resize') { // 如果是resize, 则，移动距离按照比例计算
        it.link.resizeFollowNode(node, move, it.position)
      } else {
        it.link.moveFollowNode(move, it.position)
        it.link.apply()
      }
    })
    return links.map(it => it.link)
  }
  aroundNode(p) { // 调整连接线，游离到节点附近时，节点会表现出暧昧的态度
    this.board.operateNode.linkInAround(p)
  }
  adjustLink(link, point, mouse) { // 拖动连接线头尾改变连接线的位置
    this.aroundNode([point])
    //连接线锁定位置和大小 或者 连接线进入节点内部，则返回。后期根据需求可进行变更
    if (link.funcLock.isLockedPas || this.pointInNode(mouse)) return
    const position = link.mouseInWhich
    const [node, _point] = this.touchNode(point, mouse)
    this.recordOpt.setValue(link, OperateLink.OPERATION.ADJUST)
    this._adjustOperation(link, {point: _point, node, position})
    this.board.draw()
  }
  /**
   * @param {*} link 
   * @param {*} param1 
   * @param {*} param1 secondPoint: 第二个点, 存在: 拖动整条线段，否则: 拖动端点
   * @returns 
   */
  _adjustOperation(link, {point, node, position, secondPoint}) {
    const {x, y} = point
    link.isMoving = true
    link.setOperatingTarget(link[position])
    if (position === 'startHead') {
      link.setStart(x, y)
      link.first = node || null
      if (secondPoint) {
        link.setEnd(secondPoint.x, secondPoint.y)
      }
    } else {
      link.setEnd(x, y)
      link.last = node || null
      if (secondPoint) {
        link.setStart(secondPoint.x, secondPoint.y)
      }
    }
    if (link.isStartSameEnd) return
    link.apply()
  }
  dragText(text, move) { // 拖动连接线上的文字节点位置
    text.drag(move.x, move.y)
    text.apply()
    this.board.draw()
  }
  dragControlPoint(link, move) {
    link.dragControlPoint(move)
    this.recordOpt.setValue(link, OperateLink.OPERATION.DRAGCONTROLPOINT)
    this.board.draw()
  }
  dragSplitPoint(link, move) {
    link.dragSplitPoint(move)
    this.recordOpt.setValue(link, OperateLink.OPERATION.DRAGSPLITPOINT)
    this.board.draw()
  }
  dragDragPoint(link, move) {
    link.dragDragPoint(move)
    this.recordOpt.setValue(link, OperateLink.OPERATION.DRAGSPLITEDPOINT)
    this.board.draw()
  }
  adjustDrag(link, rMove) { // sels： 多选的元素 target: 鼠标正在操作的当前元素
    const {start, end, startPre, endPre} = link.getTwoEndPoints()
    const nStart = { x : startPre.x + rMove.x, y: startPre.y + rMove.y }
    const nEnd = { x : endPre.x + rMove.x, y: endPre.y + rMove.y }

    let move = { x: nStart.x - start.x, y: nStart.y - start.y }
    this.aroundNode([nStart, nEnd])

    const ret = this.board.operateNode.onadsorbJunction([nStart, nEnd])
    if (ret) { // 产生吸附到节点效果
      const [node, newP, index] = ret
      // 吸附连接时,给连接点添加颜色, 没有实现
      // node.junction?.show()
      // node.junction?.setTouchPoint(newP.x, newP.y)

      const isEndHead = !!index // index 和传入的[nStart, nEnd] 对应
      const position = isEndHead ? 'endHead' : 'startHead'

      move = {x: newP.x - (isEndHead ? end.x : start.x), y: newP.y - (isEndHead ? end.y : start.y)}
      const secondPoint = { x: (isEndHead ? start.x : end.x) + move.x, y: (isEndHead ? start.y : end.y) + move.y }
      this._adjustOperation(link, {point: newP, node, position, secondPoint})
      this.board.draw()
    }
    this.drag(this.board.selector.selected, link, move)
  }
  drag(sels, target, move) { // sels： 多选的元素 target: 鼠标正在操作的当前元素
    this.board.operateNode.drag(sels, target, move)
  }
  changeArrow(link, type, isStart) {
    link.changeArrowType(type, isStart)
    this.onOperation(link, OperateLink.OPERATION.CHANGEARROWTYPE)
  }
  initStyle() {
    for(let v of Object.values(this.links)) {
      v.style.apply(v.configStyle || this.board.style.link)
    }
  }
  apply() {
    for(let v of Object.values(this.links)) {
      v.apply()
    }
  }
  layout() {
    for(let v of Object.values(this.links)) {
      v.layout()
    }
  }
  draw() {
    for(let v of Object.values(this.links)) {
      v.draw()
    }
  }
  onpaste(links, move, nodes) { // 粘贴连接线
    if (!(Array.isArray(links) && links.length)) return
    const ls = []
    links.forEach(link => {
      let first, last, nLink
      const {start, end, points, type, texts, isUsedSplit} = link
      for(let n of nodes) {
        if (n.copyFrom === link.first) first = n
        if (n.copyFrom === link.last) last = n
        if (first && last) break
      }

      const ps = points.map(p => ({
        x: p.x + move.x,
        y: p.y + move.y
      }))

      nLink = this.board._gcLink({start: {x: start.x + move.x, y: start.y + move.y}, first, last, isUsedSplit,
        end: {x: end.x + move.x, y: end.y + move.y}, points: ps, type, texts, style: link.style
      })
      ls.push(nLink)
    })
    this._addOperation(ls)
    return ls
  }
  onscale() {
    for(let link of Object.values(this.links)) {
      link.onscale()
    }
  }
  ondblclick(link) { // 双击事件
    if (link.funcLock.isLockedContent) return
    const isClickSplitText = (link.mouseInWhich ==='split') && link.texts.length > 0
    if (link.mouseInWhich === 'text' || isClickSplitText) {
      this.hacker.bind(link.touchingTarget).activate(link.touchingTarget)
    } else {
      const {boardX, boardY} = this.board.mouse
      // 连接线文字需求变更，只允许添加一个文字 todo: 后续根据需求可进行修改
      if (link.texts.length > 0) return
      this.addLinkText(link, {x: boardX, y: boardY, value: link.defaultText})
    }
  }
  eventdown(ev, link, node) { // 鼠标点击事件
    this.hacker.isActivated && this.hacker.deactivate()
    link.eventdown(ev, node, this)
  }
  eventup() { // 鼠标 mouseup 事件
    if (this.previewLink) this.initPreviewLink()
    const ops = ['ADJUST', 'DRAGSPLITPOINT', 'DRAGSPLITEDPOINT', 'DRAGCONTROLPOINT']
    ops.forEach(key => {
      this.recordOpt.dispatch(OperateLink.OPERATION[key], (target, operation) => {
        this.onOperation(target, operation[key])
      })
    })
  }
  /**
   * @param {*} link 
   * @param {*} point 鼠标对应的移动点的位置
   * @param {*} mouse 
   * @param {*} move: 单次移动位置
   * @param {*} rMove: 相对mouseDown时的移动位置
   * @param {*} cb 
   */
  eventdrag(link, move, rMove, cb) { //鼠标移动+左键事件 cb: 拖动连接线移动的同步动作 move：鼠标移动的距离 rMove: 鼠标移动的相对（初始位置）距离
    const {boardX, boardY, x, y} = this.board.mouse
    const position = {x: boardX, y: boardY} // 鼠标对应的画板位置
    const mouse = {x, y} // 鼠标位置
    if (link.isTouchHead) { // 当鼠标位于连接线头尾
      const { point: newPoint, hasMoved } = this.board.nodeGridSnap.getLinkSnapByDragHead(this.board, position)
      if(!hasMoved) return
      this.adjustLink(link, newPoint, mouse)
      cb && cb()
    } else if (link.isTouchText) { // 当鼠标位于连接线文字上
      // 需求变更，暂不支持拖动连接线上的文字 todo: 后续可根据需求进行变动
      if (!1 && link.background._isPointInStroke(mouse.x, mouse.y, 30)) {
        link.isMovingText = true
        this.dragText(link.touchingTarget, move)
      }
    } else if (link.isTouchControl) {
      this.dragControlPoint(link, move)
    } else if (link.isTouchSplit || link.isTouchSplitLine) {
      let newMove = move
      if (link.isFoldLink) {
        newMove = this.board.nodeGridSnap.getSnapMovePartLink(this.board, move, position)
      } else if (link.isStraightLink) {
        newMove = this.board.nodeGridSnap.getSnapMoveStraightLinkPoint(this.board, move, position)
      }
      this.dragSplitPoint(link, newMove)
    } else if (link.isTouchDragPoint) {
      const newMove = this.board.nodeGridSnap.getSnapMoveStraightLinkPoint(this.board, move, position)
      this.dragDragPoint(link, newMove)
    } else { //拖动连接线body部分，移动连接线
      if (link.isConnectedNode) return
      rMove = this.board.nodeGridSnap.getSnapMoveLink(this.board, move, position).rMove
      this.adjustDrag(link, rMove)
    }
  }
  eventmove(node) { // point: 鼠标对画板的映射点，mouse: 鼠标点, node： 当前鼠标正在touch的图形节点
    for(let link of Object.values(this.links)) {
      if (link.eventmove(node)) return link
    }
  }
  get hacker () {
    return this.board.hacker
  }
}
export default OperateLink
export {Link}
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// eslint-disable-next-line react/prop-types
const Arrow = ({ isExpand = false, onClick, size, color }) => {
  return <i
    style={{
      width: '0',
      height: '0',
      border: `${size || 3}px solid transparent`,
      borderBottom: `${size * 2 || 6}px solid ${color || 'black'}`,
      transform: `rotate(${isExpand ? 180 : 90}deg)`,
      cursor: 'pointer',
      position: 'relative',
      top: `${isExpand ? 5 : 0}px`
    }}
    onClick={onClick}
  >
  </i>
}

export const CssWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

const CustomButton = (props) => {
  const { title, isOpen, onClick, isOpenShow, ...rest } = props
  const [opened, setOpened] = useState(!!isOpen)

  const handClick = () => {
    setOpened(!opened)
    onClick && onClick()
  }

  return (
    <CssWrapper {...rest} onClick={handClick}>
      {!isOpenShow && <Arrow size={5} isExpand={opened} color='#6f7681' />}
      <div>{title}</div>
    </CssWrapper>
  )
}

CustomButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  isOpenShow: PropTypes.bool
}

export default CustomButton

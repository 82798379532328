/*
  注入历史记录模块
  节点操作记录回调
 */
import { cloneDeep } from 'utils/cloneDeep'

const registerOperation = (t) => { // t: target 画板实例
  t.addSpecialMoveToOperation = (operation) => { // 由于没有记录画板的移动，导致回到上一步出现位置无记录, 临时方案，后续更改画板移动方式
    const _pre = t.operation.getCurrentData()
    if (_pre) {
      const pre = _pre.value
      const id = Object.keys(t.nodes)[0]
      if (id && pre.nodes[id] && operation !== t.OperateNode.OPERATION.DRAG) {
        const move = { x: parseInt(t.nodes[id].x) - parseInt(pre.nodes[id].x), y: parseInt(t.nodes[id].y) - parseInt(pre.nodes[id].y) }
        if (move.x !== 0 || move.y !== 0) {
          // const cp = cloneDeep(pre)
          // const nodes = { ...cp.nodes, ...cp.links }
          // for (const n of Object.values(nodes)) {
          //   if (n.name === 'node') {
          //     n.x += move.x
          //     n.y += move.y
          //   } else {
          //     [...n.points, ...n.controlPoints, n.start, n.end].forEach(p => {
          //       p.x += move.x
          //       p.y += move.y
          //     })
          //   }
          // }
          if (t.operateBoard.tempData) t.operation.push(t, t.OperateBoard.OPERATION.MOVE, t.operateBoard.tempData)
        }
      }
    }
  }

  t.addOperation = (...args) => {
    t.addSpecialMoveToOperation(args[1])
    const value = t.collectData().data
    t.operation.push.apply(t.operation, args.concat(value))
  }

  t.addOperation(t, 'init')

  const { OperateLink, OperateNode, OperateBoard } = t;
  // 注入历史记录
  [OperateNode, OperateLink, OperateBoard].forEach(it => { // 设置对应模块预留的operation操作
    it.setOnOperation((...args) => t.addOperation(...args))
  })

  t.operation.onMove = (target, operation) => { // 根据操作记录，恢复target状态（选中等）
    target = Array.isArray(target) ? target : [target]
    target = target.map(n => t.getAllNodes()[n.id])

    switch (operation) {
      case OperateNode.OPERATION.RESIZE: // 修改节点大小
        t._layout()
        break
      case OperateNode.OPERATION.CHANGESTYLE:
      case OperateNode.OPERATION.DRAG:
        t._layout()
        break
      case OperateNode.OPERATION.LOCK:
        break
      case OperateBoard.OPERATION.PASTE:
      case OperateNode.OPERATION.ADD:
      case OperateLink.OPERATION.ADD:
      case OperateLink.OPERATION.SWITCHTYPE:
      case OperateLink.OPERATION.DRAGSPLITPOINT:
      case OperateLink.OPERATION.DRAGCONTROLPOINT:
      case OperateLink.OPERATION.DRAGSPLITEDPOINT:
      case OperateLink.OPERATION.CHANGEARROWTYPE:
        t.selector._multiSelect(target, false)
        break
      case OperateNode.OPERATION.REMOVE:
      case OperateLink.OPERATION.REMOVE:
        break
      case OperateLink.OPERATION.ADJUST: // 调整节点
      case OperateLink.OPERATION.REMOVETEXT: // 调整节点
        break
      case OperateLink.OPERATION.ADDTEXT: // 调整节点
        break
    }
    t.draw()
  }

  t.refresh = () => {
    const { nodes, links, groups, properties: { transform }} = t.data
    t.operateNode.init(nodes)
    t.operateLink.init(links)
    t.operateGroup.init(groups)
    t.transform = transform
    t.initStyles()
    t._layout()
  }

  t.operation.onDispatch = (data) => {
    t.data = cloneDeep(data)
    t.refresh()
  }

  t.operation.onPrev = (cursor) => {} // cursor: 当前最近一次的操作记录的索引值
  t.operation.onNext = (cursor, ol) => {} // ol: operation 操作记录的总长度
  t.operation.onPop = (source, t, operation) => {}
}
export default registerOperation

// 创建node的动作类型
export const CREATE_ACTIONS = {
  menuToCreate: 'MENUTOCREATE', // 从左侧栏创建
  copyTextToCreate: 'COPYTEXTTOCREATE', // 复制文字创建
  copyNodeToCreate: 'COPYNODETOCREATE' // 复制节点创建
}

export const RowFlex = {
  LEFT: 'left', // 从左侧栏创建
  CENTER: 'center', // 复制文字创建
  RIGHT: 'right' // 复制节点创建
}

export const NODE_DEFAULT_STYLE = {
  fontColor: '#1A1A1A',
  fontSize: 18,
  fontFamily: 'Source Han Sans CN',
  backgroundColor: '#fff',
  italic: false,
  bold: false,
  underline: false,
  strikeout: false,
  lineColor: '#000',
  lineDash: [],
  textAngle: 0,
  boundaryColor: 'rgba(160, 194, 253, 0.8)',
  paddingLeft: 20,
  paddingTop: 10
}

export const FontStyleEnum = {
  fontFamily: 'fontFamily',
  fontSize: 'fontSize',
  fontColor: 'fontColor',
  underline: 'underline',
  italic: 'italic',
  bold: 'bold',
  textAlign: 'textAlign'
}

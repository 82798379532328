/*
  表示文件的icon
 */
  import Rect from '../rect'
  class Octagon extends Rect {
    constructor(ctx, sx, sy, width, height, ox, oy) {
      super(ctx, sx, sy, ox, width, height, oy)
      this.arc1 = width * 4
      this.arc2 = height * 4
    }
     process(){ // 绘制八边形
      const {ctx, _left,_top, _right,_bottom, width,height}=this
      const _wf = width / 8*2.5
      const _hf = height / 8*2.5
      let arc2 = this.arc2 / height
      let arc1 = this.arc1 / width
      if(width < 40){
        arc1 = 1, arc2 = 2
        if(width < 20){
          arc1 = 0, arc2 = 1
        }
      }
      if(height<40){
        arc1 = 2, arc2 = 1
        if(width < 20){
          arc2 = 0, arc1 = 1
        }
      }
      ctx.beginPath()
      ctx.moveTo(_left+width/2,_top)
      ctx.arcTo(_right -_wf,_top,_right,_top+_hf,arc2)
      ctx.arcTo(_right,_top+_hf,_right,_bottom-_hf,arc1)
      ctx.arcTo(_right,_bottom-_hf, _right- _wf,_bottom,arc1)
      ctx.arcTo(_right- _wf,_bottom, _left+_wf,_bottom,arc2)
      ctx.arcTo(_left+_wf,_bottom, _left,_bottom-_hf,arc2)
      ctx.arcTo( _left,_bottom-_hf, _left,_top+_hf,arc1)
      ctx.arcTo( _left,_top+_hf,_left+_wf,_top,arc1)
      ctx.arcTo( _left+_wf,_top,_right -_wf,_top,arc2)
      ctx.lineTo(_left+width/2,_top)
    }

  }

  export default Octagon
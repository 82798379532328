import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useOutsideClick from './useOutsideClick'

const MoreColorList = [
  '#000000', '#F2F2FF', '#FBF0FF', '#FFF0FB', '#FFF0F0', '#FFF3D9', '#FCFCCA', '#E3FAE3', '#EDF5FF',
  '#F2F3F5', '#DEDEFF', '#F4D9FF', '#FFD6F5', '#FFD9D9', '#FFDDA6', '#FFF7A1', '#C3F7C8', '#CFE4FF',
  '#A9AFB8', '#A7A6FF', '#E08FFF', '#FF80DF', '#FF8F8F', '#FC9432', '#FFE342', '#54C45E', '#6DB1FF',
  '#6F7681', '#635DFF', '#BA23F6', '#D916A8', '#E81313', '#CC4E00', '#FCCE14', '#008A0E', '#1071E5',
  '#3A414A', '#21265C', '#4D0066', '#7D005D', '#860303', '#702B00', '#F2BA00', '#024D02', '#003D87',

  '#333333', '#FF8839', '#C92D39', '#FAFAFA', '#FF9E56', '#FFB584', '#F36100', '#FFEADB', '#FFF6F0',
  '#7AB648', '#FE5900', '#282C33', '#4C535D', '#806858', '#5D5C80'

]

const MoreColorListLink = [
  'transparent', '#FFFFFF', '#EFF0F3', '#D9DCE2', '#C6CAD2', '#878C97', '#5E626E', '#3D414B', '#000000',
  '#EFEEFF', '#F5EBFF', '#F8EBFA', '#F8ECEC', '#FAF1D1', '#FBFEC0', '#E5FADE', '#DEF9F2', '#ECF2FF',
  '#EFEEFF', '#E6CEFD', '#F0CAF1', '#F0CFD1', '#F1D696', '#FBF793', '#CCF8BD', '#C4F4EA', '#CDDDFF',
  '#817AFE', '#BF74FC', '#D864D5', '#DA797B', '#D88125', '#F3DF39', '#74BC51', '#62B898', '#77A0FD',
  '#5141FC', '#8C00F1', '#A70095', '#B50005', '#9F3A04', '#E9C41B', '#407A15', '#3E7361', '#3859DE'
]

export const ModalCssWrapper = styled.div`
  position: absolute;
  left: -50%;
  transform: translateX(-50%);
  top: 35px;
  z-index: 1;
  box-shadow: 1px 1px 3px 0px rgba(4, 0, 0, 0.1);
  padding: 10px;
  background-color: white;s
  border-radius: 6px;

  .tooltip {
    position: relative;
    display: inline-block;
    height: 24px;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    width: 100px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    // margin: 10px 0;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -50px;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .modalContent {
    width:320px; 
    height:270px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  // ::before {
  //   content: "";
  //   position: absolute;
  //   top: -20px;
  //   transform: rotate(180deg);
  //   left: 50%;
  //   margin-left: -12px;
  //   border-width: 10px;
  //   border-style: solid;
  //   border-color: #ffffff transparent transparent transparent;
  // }

  .modalItem {
    width: 24px;
    height: 24px;
    margin: 5px;
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid #E3E3E3;
  }

  .noFillDiv:hover{
    cursor:pointer;
  }
  .noFillDiv{
    width:24px;
    height:24px;
    border: 1px solid #E3E3E3;
    border-radius: 50%;
    margin: 5px 5px 0 5px;
  }
  .noFill{
    width:21px;
    height:1px;
    border-bottom: 1px solid red;
    transform: rotate(30deg);
    margin: 10px 0 0 1px;
  }
`

const ColorItem = (props) => {
  const { color, onColorChange, index, noFill } = props
  const [hovered, setHovered] = useState(false)

  const handleMouseMove = (type) => {
    if (type === 'enter') {
      setHovered(true)
    } else {
      setHovered(false)
    }
  }

  return (
    <div className='tooltip'>
      {index !== 0 && <div
        className='modalItem'
        style={{
          backgroundColor: color
        }}
        onClick={(e) => {
          onColorChange(color)
          e.stopPropagation()
        }}
        onMouseEnter={() => handleMouseMove('enter')}
        onMouseOut={() => handleMouseMove('out')}
      />}
      {index === 0 && noFill && <div
        className='noFillDiv'
        onMouseEnter={() => handleMouseMove('enter')}
        onMouseOut={() => handleMouseMove('out')}
        onClick={(e) => {
          onColorChange(color)
          e.stopPropagation()
        }}
      >
        <div className='noFill'></div>
      </div>}
      {
        hovered && <div className='tooltiptext'>{color === 'transparent' ? '无填充' : color}</div>
      }
    </div>
  )
}

ColorItem.propTypes = {
  color: PropTypes.string,
  onColorChange: PropTypes.func,
  index: PropTypes.number,
  noFill: PropTypes.bool
}

const withMoreColorPick2 = (CustomBtn) => {
  const ColorCmp = (props) => {
    const { noFill, onColorChange, disabled, link, ...rest } = props
    const [isShow, setIsShow] = useState(false)
    const handleChangeColor = (color) => {
      onColorChange && onColorChange(color)
      setIsShow(false)
    }

    const handleClick = () => {
      !disabled && setIsShow(!isShow)
    }

    const handleOutClick = useCallback(() => {
      setIsShow(false)
    }, [])

    const wrapperRef = useRef(null)
    useOutsideClick(wrapperRef, handleOutClick)

    const list = link === 'link' ? MoreColorListLink : MoreColorList

    return (
      <div ref={wrapperRef} style={{
        position: 'relative',
        height: '24px'
      }}>
        <CustomBtn onClick={handleClick} {...rest} />
        {isShow && (
          <ModalCssWrapper style={link === 'link' ? { width: '340px', height: '200px' } : { width: '312px' }}>
            <div className='modalContent' style={link === 'link' ? { padding: '0px 7px 20px', height: '200px' } : { padding: '15px 10px 15px 10px' }}>
              {list.map((c, index) => (
                <ColorItem
                  key={index}
                  color={c}
                  index={index}
                  noFill={noFill}
                  onColorChange={handleChangeColor}
                />
              ))}
            </div>
          </ModalCssWrapper>
        )}
      </div>
    )
  }

  ColorCmp.propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    onColorChange: PropTypes.func,
    link: PropTypes.string,
    noFill: PropTypes.bool
  }

  return ColorCmp
}

export default withMoreColorPick2

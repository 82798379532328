const eventCenter = {
  _listeners: {
    mousedown: function (ev) {
      this._desktop(ev)
      this._x = ev.$x // _x _y: 鼠标按下的时候，记录下鼠标当前的坐标值
      this._y = ev.$y
      this.preX = ev.$x // 存放上一次鼠标的信息
      this.preY = ev.$y
      // add for merge
      this._trigger("mousedown", ev)
    },
    touchstart: function (ev) {
      for (var i = 0; i < ev.targetTouches.length; i++) {
        var touch = this._mobile(ev.targetTouches[i]);
        (this["_x" + i] = touch.$x), (this["_y" + i] = touch.$y);
      }
      this._trigger("touchstart", ev);
    },
    mousemove: function (ev) {
      this._desktop(ev)
      ev.$movementX = ev.$x - (this.preX || ev.$x) // 原生ev自带的movementX, movementY, 经测试不同设备，得出的值不一样
      ev.$movementY = ev.$y - (this.preY || ev.$y)
      this.preX = ev.$x
      this.preY = ev.$y
      ev.$moveX = ev.$x - this._x // move 的时候，计算拖拽的距离
      ev.$moveY = ev.$y - this._y
      this._trigger("mousemove", ev);
    },
    touchmove: function (ev) {
      for (var i = 0; i < ev.targetTouches.length; i++) {
        var touch = this._mobile(ev.targetTouches[i]);
        (touch.$moveX = touch.$x - this["_x" + i]),
          (touch.$moveY = touch.$y - this["_y" + i]);
      }
      this._trigger("touchmove", ev);
    },
    mouseup: function (ev) {
      this._trigger("mouseup", this._desktop(ev));
    },
    touchend: function (ev) {
      for (var i = 0; i < ev.targetTouches.length; i++)
        this._mobile(ev.targetTouches[i]);
      this._trigger("touchend", ev);
    },
    touchcancel: function (ev) {
      for (var i = 0; i < ev.targetTouches.length; i++)
        this._mobile(ev.targetTouches[i]);
      this._trigger("touchcancel", ev);
    },
    mouseover: function (ev) {
      this._trigger("mouseover", this._desktop(ev));
    },
    mouseout: function (ev) {
      this._trigger("mouseout", this._desktop(ev));
    },
    click: function (ev) {
      this._trigger("click", this._desktop(ev));
    },
    dblclick: function (ev) {
      this._trigger("dblclick", this._desktop(ev));
    },
    contextmenu: function (ev) {
      ev.preventDefault(), this._trigger("contextmenu", this._desktop(ev));
    },
    wheel: function (ev) {
      this._trigger("wheel", this._desktop(ev))
    },
    focus: function (ev) {
      this._trigger("focus", ev);
    },
    blur: function (ev) {
      this._trigger("blur", ev);
    },
    focusin: function (ev) {
      this._trigger("focusin", ev);
    },
    focusout: function (ev) {
      this._trigger("focusout", ev);
    },
    keydown: function (ev) {
      ev.isComposing ||
        "Process" === ev.key ||
        this._trigger("keydown", ev);
    },
    keyup: function (ev) {
      ev.isComposing || "Process" === ev.key || this._trigger("keyup", ev);
    },
    keypress: function (ev) {
      this._trigger("keypress", ev);
    },
    drop: function (ev) {
      this._trigger("drop", ev);
    },
    dragover: function (ev) {
      this._desktop(ev)
      this._trigger("dragover", ev);
    },
  },
  _desktop: function (ev) { // 设置当前鼠标对象的$x, $y 取值为相对$wrapper的offset位置
    return (ev.$x = ev.offsetX), (ev.$y = ev.offsetY), ev;
  },
  _mobile: function (ev) {
    return (
      (ev.$x = ev.pageX - this.offsetLeft),
      (ev.$y = ev.pageY - this.offsetTop),
      ev
    );
  },
  _preventDefault: function () {
    this._$preventDefault = !0;
  },
  _on: function (type, listener, options) { // _listeners
    var map = this._listenersMap;
    map = map || (this._listenersMap = {})
    if (map[type] === void 0) { // 不存在则初始化一个数组来保存
      map[type] = []
      this.addEventListener(type, (ev)=>{
        this._listeners[type].call(this,ev)
      }, options)
    }
    map[type].unshift(listener)
  },
  _off: function (type, listener, options) {
    let listeners = this._listenersMap[type]
    for (let i = 0; i < listeners.length; i++) {
      if (listeners[i] === listener) {
        listeners.splice(i, 1);
        break;
      }
    }
    if (0 === listeners.length) { // 事件监听队列为空的时候 移除监听
      delete this._listenersMap[type]
      this.removeEventListener(type, this._listeners[type], options)
    }
  },
  _trigger: function (type, ev) {
    ev._$preventDefault = !1
    ev.$preventDefault = this._preventDefault
    let listeners = this._listenersMap[type]
    for (let i = 0; i < listeners.length; i++ ) {
      listeners[i].call(this, ev)
      if (ev._$preventDefault) break;
    }
  }
}
export default eventCenter
import { RecordOperation } from '../operation'
import Helper from 'utils/helper'
import { CREATE_ACTIONS } from './constant'
import {NODE_DEFAULT_STYLE, FontStyleEnum} from './constant'
import VNode from './index'

class OperateNode {
  constructor(ctx, board, nodes) {
    this.ctx = ctx
    this.board = board // 指向画板实例
    this.dragInShape = null
    this.needActivateHacker = false
    this.init(nodes)
    this.recordOpt = new RecordOperation(this, OperateNode.OPERATION) // 记录连续操作的关键帧
  }
  static OPERATION = {
    'ADD': '_addOperation',
    'REMOVE': '_removeOperation',
    'RECOVERY': '_recoveryOperation',
    'RESIZE': '_resizeOperation',
    'SETVALUE': '_setValueOperation',
    'LOCK': '_lockOperation',
    'DRAG': '_dragOperation',
    'CHANGESTYLE': '_changeStyleOperation',
    'ADDHYPERLINK': '_addHyperlinkOperation'
  }

  static setOnOperation = (cb => {
    this.prototype.onOperation = cb
  })

  init(data) {
    this.nodes = this.board.nodes = {}
    this.createNodes(data)
  }

  createNodes(data) { // 初始化
    if (data && Object.prototype.toString(data) === '[object Object]' && Object.keys(data).length) {
      for (let c of Object.values(data)) {
        this._addOperation([this.board._gcNode(c)])
      }
    }
  }
  linkInAround(p) { // 连接线在节点附近移动，节点需要做出响应
    for (let n of Object.values(this.nodes)) {
      n.junction.pointInAround(p)
    }
  }
  linkLeaveAround() { // 连接线不再移动
    let catched = false
    for (let n of Object.values(this.nodes)) {
      if (n.junction.isMouseIn) catched = true
      n.junction.hide()
    }
    catched && this.board.draw()
  }
  pointInNode({x, y}) {
    for (let n of Object.values(this.nodes)) {
      if (n.background._isPointInPath(x, y)) {
        return true
      }
    }
  }
  pointInNodeBoundary({x, y}) {
    for (let n of Object.values(this.nodes)) {
      if (n.background._isPointInStroke(x, y, 1)) {
        return n
      }
    }
  }
  beforeOffset() {
    for (let i in this.nodes) {
      this.nodes[i].beforeOffset()
    }
  }
  offset(x, y) {
    for (let c of Object.values(this.nodes)) {
      c.offset(x, y)
    }
  }
  /**
   * 1. 没有连接的几点在外面, drag
   * 2. 两个连接都在外面, no drag
   * 3. 连接在选框里面, 外面的没连接, follow node
   * 4. 连接的在选框外面, 里面的没连接 follow selectBox
   */
  getProcessedNodes(objs) {
    let nodes = objs.filter(o => o.isNode)
    const nIds = nodes.map(o => o.id)

    const noNeedFollowNodeLinkIdMap = {}
    const needFollowSelectLinks = { start: [], end: [] }
    const filteredNodes = objs.filter(e => {
      if (e.isNode) return true
      // 主要有一个不在selected nodes中, 就follow
      const fId = e.first?.id
      const lId = e.last?.id

      // 是否没有外部关联node
      let fNR, lNR // fNR: 有头，但没有连接到选区外面节点/没头,  lNR: 有尾，但没有连接到选区外面节点/没尾
      if (!fId || nIds.indexOf(fId) > -1) {
        fNR = true
      }

      if (!lId || nIds.indexOf(lId) > -1) {
        lNR = true
      }

      // 1. 没有连接在选区外面
      if (fNR && lNR) {
        noNeedFollowNodeLinkIdMap[e.id] = true
        return true
      }

      // 2. 两个外部连接 2
      if (!fNR && !lNR) {
        return false
      }

      // 3-4. 有一头关连接外部，另一头连接到里面
      // 4. 连接的在选框外面, 里面的没连接
      const isHeadOutSelect = nIds.indexOf(fId) === -1
      const isTailOutSelect = nIds.indexOf(lId) === -1
      if (isHeadOutSelect && isTailOutSelect) {
        if(e.first) {
          needFollowSelectLinks.end.push(e)
        } else {
          needFollowSelectLinks.start.push(e)
        }
        return false
      }

      // 3. 连接在选框里面, 外面的没连接 3
      return false
    })

    const follow = {
      noNeedFollowNodeLinkIdMap, needFollowSelectLinks
    }

    return { filteredNodes, follow }
  }
  drag(sels, target, move) { // 拖动节点，由于框选的问题，此方法兼容连接线 sels： 多选的元素 target: 鼠标正在操作的当前元素
    const nodes = Array.isArray(sels) ? sels : [sels]
    const { filteredNodes, follow } = this.getProcessedNodes(nodes)
    // 当前操作的元素是连接线并且连接线已经连接到节点 或者 当前的元素已经被锁定
    if (!filteredNodes.length || target.isConnectedNode || target.funcLock.isLockedPas) return
    this.recordOpt.setValue([...filteredNodes], 'dragNode')
    this._dragOperation(filteredNodes, move, follow) // 拖拽移动
    this.board._layout()
    this.board.draw()
  }
  _dragOperation(nodes, move, follow) {
    nodes.forEach(n => {
      if(n.funcLock.isLockedPas) return
      n.drag(move.x, move.y)
      this.board.operateLink.followNode(n, move, 'move', follow.noNeedFollowNodeLinkIdMap)
    })

    // 选区的follow
    follow.needFollowSelectLinks.start.forEach(l => l.start.offset(move.x, move.y))
    follow.needFollowSelectLinks.end.forEach(l => l.end.offset(move.x, move.y))
    this.board.box.drag(move.x, move.y)
  }

  // TODO 需要和drag()做合并，进行逻辑复用
  move(n, move){
    const nodes = Array.isArray(n) ? n : [n]
    this.recordOpt.setValue([...nodes], 'moveNode')
    this._moveOperation(nodes, move)
    this.board._layout()
    this.board.draw()
  }

  _moveOperation(nodes, move) {
    nodes.forEach(n => {
      if (n.funcLock.isLockedPas) return
      n.move(move.x, move.y)
      nodes.length > 1 || this.board.operateLink.followNode(n, move)
    })
    this.board.box.drag(move.x, move.y)
  }
  resize(n, move) { // 调整节点大小
    if (n.funcLock.isLockedPas) return
    this.recordOpt.setValue(n, 'resizeNode')
    this._resizeOperation(n, move);
    this.board._layout()
    this.board.draw()
  }
  _resizeOperation(n, move) {
    let { x, y, gapX, gapY } = move;
    const links = this.board.operateLink.followNode(n, move, 'resize')
    n.setWidth(n.width + gapX)
    n.setHeight(n.height + gapY)
    n.setIsIntelHeight()
    n.setLayout(x, y)
    n._apply()
    n.calculateSize()
    links && links.forEach(link => link.apply())
  }
  setValue(n, value) { // 设置节点text
    if (n.carrier && n.carrier.handleEditingText(n)) return // 连接线文字编辑时，根据条件对文字进行处理
    const preValue = n.preValue
    if (preValue === value) return
    n.preValue = value
    this._setValueOperation(n, value)
    this.onOperation(n, OperateNode.OPERATION.SETVALUE)
  }
  _setValueOperation(n, v) {
    n.isEditing = true
    n.isEdited = true
    n.value = v
    n._apply()
    n.apply()
    n.carrier && n.carrier.whenEditingText()
    n.isEditing = false
  }
  lock(list, v) { // 锁定节点
    list = list || this.selected
    if (list.length === 0) return
    this._lockOperation(list, [v])
    this.onOperation([...list], OperateNode.OPERATION.LOCK)
  }
  _lockOperation(list, value) { // value: [array, object], 类型为array时，存放多个节点锁定前的值
    list.forEach((n, ind) => {
      n.funcLock.setLock(value[ind] || value[0])
    })
  }

  add(node) { // 添加节点
    const nodes = Array.isArray(node) ? node : [node]
    node = this._addOperation(nodes)
    // this.onOperation(this, nodes, OperateNode.OPERATION.ADD, nodes, OperateNode.OPERATION.REMOVE, nodes)
    this.onOperation(nodes, OperateNode.OPERATION.ADD)
    return node
  }

  _addOperation(nodes) {
    nodes.forEach(n => {
      this.nodes[n.id] = n
    })
    return nodes[0]
  }
  _addHyperlinkOperation(list,v){
    list.forEach(n => {
      n.setLink(v)
    })
  }

  addHyperlink(v,list) { // 给节点添加超链接
    list = list || this.selected
    this._addHyperlinkOperation(list, v)
  }

  _recoveryOperation(old) {
    this.nodes = { ...old }
  }
  changeStyle(nodes, styleConfig) {
    this._changeStyleOperation(nodes, [styleConfig])
  }
  _changeStyleOperation(nodes, styleConfig) {
    const key = styleConfig[0] ? Object.keys(styleConfig[0])[0] : ''
    if (this.hacker.isActivated && FontStyleEnum[key]) {
      this.hacker.changeTextStyle(styleConfig[0])
    } else {
      nodes.forEach((n, ind) => {
        n.style.changeStyle(styleConfig[ind] || styleConfig[0])
        n.style._apply()
      })
      this.hacker.isActivated && this.hacker.focus()
      this.onOperation([...nodes], OperateNode.OPERATION.CHANGESTYLE)
    }
  }
  initDragInShape() {
    this.dragInShape = null
    this.board.operateTarget = null
  }
  addDragInShape(p) { // draginshape 的开始点
    const n = this.dragInShape
    if (n) {
      p && n.setLayout(p.x, p.y)
      this.add(n)
      this.initDragInShape()
    }
    return n
  }
  setDragInShape(x, y) {
    this.board.$setCursor('move')
    const {width, height} = this.dragInShape
    x -= width / 2, y -= height / 2
    const sp = this.board.nodeGridSnap.getSnapMoveNodeByPos(this.board, {x, y})
    this.dragInShape.setLayout(sp.x, sp.y)

    this.dragInShape.apply()
    this.board.draw()
    this.dragInShape.draw()
  }
  setLinkState(state){
    for (let i in this.nodes) {
      this.nodes[i].hyperlinkState = state
    }
  }
  onDragInShape() { // 从其他地方拖到画板的元素
    let isShapeReady = false
    const { boardX, boardY } = this.board.mouse
    if (isShapeReady = this.dragInShape) { // 当拖动左侧图标到画板时
      this.setDragInShape(boardX, boardY)
    } else {
      isShapeReady = this.board.operateLink.onDragInShape(boardX, boardY)
    }
    return isShapeReady
  }
  createDragInShape(data) {
    this.board.operateTarget = this
    this.dragInShape = this.board._gcNode(data)
    const p = this.board.getDrawCoordinate(data.x, data.y)
    this.dragInShape.setLayout(p.x, p.y)
  }
  remove(arr) {
    if (!arr.length) return
    this._removeOperation(arr)
    this.onOperation(arr, OperateNode.OPERATION.REMOVE)
  }
  _removeOperation(list) {
    list.forEach(n => {
      delete this.nodes[n.id]
    })
    this.board.operateLink.setRelateNode(list)
  }
  apply() {
    for (let v of Object.values(this.nodes)) {
      v.apply()
    }
  }
  draw() {
    for (let v of Object.values(this.nodes)) {
      v.draw()
    }
  }
   // 粘贴外部文本
  onpasteExternalText(data) {
    const {copiedNodes, tempTime} = this.board
    const type = data.types.includes('text/html') ? 'text/html' : 'text/plain'
    data.getType(type).then((res) => {
      Helper.fileRead(res).then(content => {
        const { boardX, boardY } = this.board.mouse
        if (content == tempTime && copiedNodes.length) {
          this.operateBoard.onpaste(copiedNodes)
        } else {
          let node = this.selected.length === 1 ? this.selected[0] : void 0
          if (node) {
            this.hacker.onpaste({content, type: type})
          } else {
            const value = this.hacker.parseText({content, type: type})
            node = this.board._gcNode({
              value: value,
              type: "text",
              id: Helper.produceNanoId(),
              x: boardX,
              y: boardY,
              shapeInfo: this.board.$shapes.get('text'),
              action: CREATE_ACTIONS.copyTextToCreate
            })
            this.add(node)
          }
          copiedNodes.length = 0
        }
      })
    });
  }
  onpaste(nodes, move) { // 粘贴复制的节点
    if (!(Array.isArray(nodes) && nodes.length)) return
    const ns = []
    let node
    nodes.forEach(n => {
      const { width, height, type, picUrl } = n
      node = this.board._gcNode({x: n.x + move.x, y: n.y + move.y, width, height, value: VNode.copyValue(n.value), type, picUrl,
        style: n.style, action: CREATE_ACTIONS.copyNodeToCreate
      })
      node.copyFrom = n
      ns.push(node)
    })
    this._addOperation(ns)
    return ns
  }
  onkeydown(ev, n) {
    if (n.isClickSelected) {
      const { fontColor, fontSize, fontFamily } = NODE_DEFAULT_STYLE
      n.value.length = 0
      n.value.push({
        value: ev.key,
        fontColor,
        fontSize,
        fontFamily
      })
      this.hacker.isActivated || this.hacker.activate(n, false)
    }
  }
  ondblclick(node) {
    // 当节点内容被锁定
    if (node.funcLock.isLockedContent) {
      if (this.hacker.isActivated) this.hacker.deactivate()
    } else {
      // TODO 需优化，暂时处理
      if(node.type != 'image'){
        this.hacker.activate(node)
      }
    }
  }
  onscale() { // 画板缩放后的回调
    this.hacker.whenBindResizing()
    for (let node of Object.values(this.nodes)) {
      node.onscale()
    }
  }
  onadsorbJunction(p) { //连接线吸附 节点和连接线的结合处
    let j
    let index // 标识是哪一个节点引发的吸附
    const ps = Array.isArray(p) ? p : [p] // 考虑可能同时吸附头节点和尾结点
    for (let node of Object.values(this.nodes)) {
      for (let i = 0; i<ps.length; i++) {
        j = node.junction.onadsorb(ps[i])
        index = i
        if (j) break
      }
      if (j) {
        return [node, j, index]
      }
    }
  }
  eventdown(ev, n) {
    if (this.dragInShape && this.board.mouse.eventType === 'mousemove') { // 如果是左侧栏图形单击，则生成dragInShape, 跟随鼠标移动
      this.addDragInShape()
    } else {
      n && n.eventdown(ev)
    }
    const group = this.board.operateGroup.getGroup(n.id);
    group && !ev.metaKey && group.eventdown(ev)
  }
  eventmove(link) { // point: 鼠标对应的画点 mouse: 鼠标点
    for (let node of Object.values(this.nodes)) {
      if (node.eventmove(link)) return node
    }
  }
  eventDrag(ev, node, move) { //鼠标移动+左键事件
    const { mouseInPoint } = node.border
    const { boardX, boardY } = this.board.mouse
    let position = {x: boardX, y: boardY}
    if (mouseInPoint) { // touch到node的调整大小锚点
      position = node.board.nodeGridSnap.getSnapMovePoint(this.board, position, mouseInPoint)
      this.hacker.isActivated && (this.hacker.deactivate(), this.needActivateHacker = true)
      position && node.border.eventDrag(position, ev)
    } else { // 移动node
      move = node.board.nodeGridSnap.getSnapMoveNode(this.board, move, position)
      if (node.isMouseIn) {
        this.drag(this.board.selector.selected, node, move);
      }
    }
  }
  eventup(ev) {
    this.recordOpt.dispatch('dragNode', (target, operation) => { // 拖动节点后
      target.forEach(n => n.eventup())
      this.onOperation(target, operation.DRAG)
    })
    this.recordOpt.dispatch('resizeNode', (target, operation) => { // 调整节点大小后
      this.onOperation(target, operation.RESIZE)
      if (this.needActivateHacker) {
        this.hacker.activate(target, false)
        this.needActivateHacker = false
      }
    })
  }
  get hacker() {
    return this.board.hacker
  }
  get selected() {
    return this.board.selector.selected
  }
  get operateBoard() {
    return this.board.operateBoard
  }
}
export default OperateNode
export { VNode }
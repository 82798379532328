/* eslint-disable react/prop-types */
import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'

import useOutsideClick from './useOutsideClick'

export const ModalCssWrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  min-height: 10px;
  padding: 4px;
  box-shadow: 0px 0px 5px #9f8d8d;
  background-color: white;
`

const withClickModal = (CustomBtn, CustomModalContent) => {
  // eslint-disable-next-line react/prop-types
  const ColorCmp = ({ btnProps, modProps }) => {
    const [isShow, setIsShow] = useState(false)
    const handleClick = useCallback(() => {
      setIsShow(!isShow)
    }, [])

    const handleOutClick = useCallback(() => {
      setIsShow(false)
    }, [])

    const wrapperRef = useRef(null)
    useOutsideClick(wrapperRef, handleOutClick)
    return (
      <div ref={wrapperRef} style={{
        position: 'relative'
      }}>
        <CustomBtn selectNode= { modProps.data ? modProps.data.selectNode : ''} onClick={handleClick} {...btnProps}></CustomBtn>
        <ModalCssWrapper style={{ display: isShow ? 'initial' : 'none' }}>
          <CustomModalContent {...modProps} isShow={isShow} handleOutClick = {handleOutClick}/>
        </ModalCssWrapper>
      </div>
    )
  }

  return ColorCmp
}

export default withClickModal

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CustomSelect from 'components/antd/CustomSelect'
// import { DivideV } from 'components/comm/Divide'
import { Arrow1, ArrowNone } from './arrows'

// 箭头id <-> 组件 对应关系
const getArrowComponent = (dir) => ({
  'null': <ArrowNone />,
  'arrow1': <Arrow1 dir={dir}/>
})

const CssWrapper = styled.div`
  border: 1px solid ${props => props.theme.main.borderColor};
  height: 23px;
  margin-left: 5px;
  
  .arrowSelect {
    border: none;
    color: #000;
    outline: none;
    appearance: none;
  }
`

export default function ArrowSetting(props) {
  const { arrowGroup, onChange, arrows, endpoint } = props
  const { start, end } = arrowGroup

  // 添加arrow对应的显示组件
  const leftArrows = arrows.map(e => (
    { ...e, component: getArrowComponent(true)[e.id] }
  ))

  const rightArrows = arrows.map(e => (
    { ...e, component: getArrowComponent(false)[e.id] }
  ))

  return (
    <CssWrapper className='flexRow'>
      {endpoint === 'start' && <CustomSelect
        value={{ id: start }}
        valueKey={'id'}
        values={leftArrows}
        onChange={(val) => onChange(val, true)}
      />}
      {/* {endpoint === 'end' && <DivideV />} */}
      {endpoint === 'end' && <CustomSelect
        value={{ id: end }}
        valueKey={'id'}
        values={rightArrows}
        onChange={(val) => onChange(val, false)}
      />}
    </CssWrapper>
  )
}

ArrowSetting.propTypes = {
  onChange: PropTypes.func,
  arrowGroup: PropTypes.object,
  arrows: PropTypes.array,
  endpoint: PropTypes.string
}


/* eslint-disable react/prop-types */
import React from 'react'
import IconButton from 'components/comm/IconButton'
import withMoreColorPick from 'components/comm/hoc/withMoreColorPick'
import styled from 'styled-components'

const IconButtonWithColorPick = withMoreColorPick(IconButton)

const CssWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .colorBlock {
    height: 4px;
    width: 22px;
  }
`

export function IconButtonWithColor(props) {
  return (
    <CssWrapper>
      <IconButtonWithColorPick {...props}/>

      <div className='colorBlock' style={{ backgroundColor: props.color }}></div>
    </CssWrapper>
  )
}

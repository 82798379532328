import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import { DivideV } from '../Divide'

const CssWrapper = styled.div`
  // border: 1px solid ${props => props.theme.main.borderColor};
  // width:100px; 

  .flexBox{
  }
  
  .flexBox .numSelect {
    width:110px;
    height:34px;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    color: #000;
  }
  
  .flexBox .numSelect option{
    text-align:center;
    width:100px;
  }
`

export function SelectNum({ values = [], unit = 'px', onValueChange, curValue = 6 }) {
  const [options, setOptions] = useState(values)

  const updateOptions = useCallback((v) => {
    const isExist = values.find((t) => t === v)
    if (isExist) return
    const newOption = [...values]
    let index = newOption.findIndex((t) => t > v)
    if (index === -1) index = newOption.length
    newOption.splice(index, 0, v)
    setOptions(newOption)
  }, [])

  const handleChange = (e) => {
    const newV = Number(e.target.value)
    onValueChange && onValueChange(newV)
  }

  // const handleBtnClick = (type) => {
  //   const newV = type === '+' ? curValue + 1 : (curValue - 1) > 0 ? (curValue - 1) : 0
  //   updateOptions(newV)
  //   onValueChange && onValueChange(newV)
  // }

  useEffect(() => {
    updateOptions(curValue)
  }, [updateOptions, curValue])

  return (
    <CssWrapper className='flexRow'>
      {/* <div className='leftButton' onClick={() => handleBtnClick('-')}>-</div>
      <DivideV /> */}
      <div className='flexBox'>
        <select className='numSelect' onChange={handleChange} value={curValue}>
          {options.map((v, index) => (
            <option
              value={v} key={index} className='option'
            >
              {`${v}px`}
            </option>
          ))}
        </select>
        {/* <div className='px'>{unit}</div> */}
      </div>
      {/* <DivideV />
      <div className='rightButton' onClick={() => handleBtnClick('+')}>+</div> */}
    </CssWrapper>
  )
}

SelectNum.propTypes = {
  onValueChange: PropTypes.func,
  values: PropTypes.array,
  unit: PropTypes.string,
  curValue: PropTypes.number
}

/*
  表示决定的icon 圆形状
 */
import Rect from '../rect'
class Circle extends Rect {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.width = width || 100
    this.height = height || 100
  }
  process() {
    const { ctx, width, height, halfX, halfY } = this
    const center = {x: halfX, y: halfY}
    ctx.beginPath()
    ctx.ellipse(center.x, center.y, width / 2, height / 2, 0, 0, 2 * Math.PI)
  }
}
export default Circle

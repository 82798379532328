import {
  CHANGE_CANVAS_SETTING,
  CHANGE_CANVAS_SCALE,
  CHANGE_PAGE_SETTING,
  CHANGE_GRID_SETTING
} from '../mutation-type'

const changePageSetting = value => {
  return {
    type: CHANGE_PAGE_SETTING,
    value
  }
}

const changeGridSetting = value => {
  return {
    type: CHANGE_GRID_SETTING,
    value
  }
}

const changeCanvasSetting = value => {
  return {
    type: CHANGE_CANVAS_SETTING,
    value
  }
}

const changeCanvasScale = value => {
  return {
    type: CHANGE_CANVAS_SCALE,
    value
  }
}

export {
  changeCanvasSetting,
  changeCanvasScale,
  changePageSetting,
  changeGridSetting
}


const DefaultLineWidth = 0.5
const DefaultLineHeadSize = 10
const DefaultTextFontSize = 16
export default class LineHelper {
  constructor(context) {
    this.context = context

    this.alignLineWidth = DefaultLineWidth
    this.alignLineHeadWidth = DefaultLineHeadSize
    this.alignTextFontSize = DefaultTextFontSize
  }

  static LineHeadWidth = 10
  static LineSpanToNode = 1
  static LineTextFillColor = 'red'
  static AligningLineColor = '#d916a8'


  static getTextBoundingSize(ctx, str) {
    const textMetrics = ctx.measureText(str);
    const { width, fontBoundingBoxDescent, fontBoundingBoxAscent } = textMetrics
    const height = fontBoundingBoxAscent + fontBoundingBoxDescent;

    return { width, height }
  }

  drawLine(x1, y1, x2, y2, isDash = false) {
    const ctx = this.context
    ctx.save();
    ctx.lineWidth = this.alignLineWidth;
    ctx.strokeStyle = LineHelper.AligningLineColor;
    ctx.beginPath();
    if (isDash) { // 虚线
      ctx.setLineDash([4]);
    }
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
    ctx.restore();
  }

  getDefaultLineWidthAndFontSize() {
    return {
      lineWidth: DefaultLineWidth,
      lineHeadWidth: DefaultLineHeadSize,
      textFontSize: DefaultTextFontSize
    }
  }

  updateLineWidthAndFontSize(w1, w2, s) {
    this.alignLineWidth = w1
    this.alignLineHeadWidth = w2, 
    this.alignTextFontSize = s
  }

  drawLineWithSize(x1, y1, x2, y2, isHorizontal = true, isDash = false) {
    const ctx = this.context
    const spanLength = isHorizontal ? x2 - x1 : y2 - y1
    const chars = `${Math.round(spanLength)}px`

    // 现场保存
    ctx.save();

    ctx.font = `${this.alignTextFontSize}px serif`
    ctx.fillStyle = LineHelper.LineTextFillColor
    const halfLW = this.alignLineHeadWidth / 2
    const spanToNode = LineHelper.LineSpanToNode
    const { width, height } = LineHelper.getTextBoundingSize(ctx, chars)

    ctx.textBaseline = 'top'
    ctx.lineWidth = this.alignLineWidth;
    ctx.strokeStyle = LineHelper.AligningLineColor;

    ctx.beginPath();
    if (isDash) { // 虚线
      ctx.setLineDash([4]);
    }

    if (isHorizontal) {
      const fixX1 = x1 + spanToNode
      const fixX2 = x2 - spanToNode

      if (!isDash) {
        ctx.moveTo(fixX1, y1 - halfLW)
        ctx.lineTo(fixX1, y1 + halfLW)
        ctx.moveTo(fixX2, y1 - halfLW)
        ctx.lineTo(fixX2, y1 + halfLW)
      }

      const midX = (x1 + x2) / 2
      ctx.moveTo(fixX1, y1)
      ctx.lineTo(midX - width / 2, y1)
      ctx.fillText(chars, midX - width / 2, y1 - height / 2)
      ctx.moveTo(midX + width / 2, y1)
      ctx.lineTo(fixX2, y1)

    } else {
      const fixY1 = y1 + spanToNode
      const fixY2 = y2 - spanToNode

      if (!isDash) {
        ctx.moveTo(x1 - halfLW, fixY1)
        ctx.lineTo(x1 + halfLW, fixY1)
        ctx.moveTo(x1 - halfLW, fixY2)
        ctx.lineTo(x1 + halfLW, fixY2)
      }
      const midY = (y1 + y2) / 2
      ctx.moveTo(x1, fixY1)
      ctx.lineTo(x1, midY - height / 2)
      ctx.fillText(chars, x1 - width / 2, midY - height / 2)
      ctx.moveTo(x1, midY + height / 2)
      ctx.lineTo(x1, fixY2)

    }
    ctx.stroke();

    // 现场恢复
    ctx.restore();
  }

  drawZSimpleHorizontalLine(coords) {
    const { x1, x2, y } = coords
    const [xx1, xx2] = x1 > x2 ? [x2, x1] : [x1, x2]
    const yy = y + 0.5
    this.drawLine(xx1, yy, xx2, yy);
  }

  drawZSimpleVerticalLine(coords) {
    const { x, y1, y2 } = coords
    const [yy1, yy2] = y1 > y2 ? [y2, y1] : [y1, y2]
    const xx = x + 0.5
    this.drawLine(xx, yy1, xx, yy2);
  }

  drawVerticalLine(data) {
    const { coords, isCenter, hasSize } = data
    const { x, y1, y2 } = coords
    const [yy1, yy2] = y1 > y2 ? [y2, y1] : [y1, y2]
    const xx = x + 0.5
    if (isCenter || hasSize) {
      this.drawLineWithSize(xx, yy1, xx, yy2, false, !isCenter);
    } else {
      this.drawLine(xx, yy1, xx, yy2, !isCenter);
    }
  }

  drawHorizontalLine(data) {
    const { coords, isCenter, hasSize } = data
    const { x1, x2, y } = coords
    const [xx1, xx2] = x1 > x2 ? [x2, x1] : [x1, x2]
    const yy = y + 0.5
    if (isCenter || hasSize) {
      this.drawLineWithSize(xx1, yy, xx2, yy, true, !isCenter);
    } else {
      this.drawLine(xx1, yy, xx2, yy, !isCenter);
    }
  }

}
import Shape from './index.js'

class Rect extends Shape {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.setWidth(width).setHeight(height)
    this.shapeRatio = 1
  }
  setCarrier(value) { // 当前矩形所在的图形（可能是条线或者其他的图案）
    this.carrier = value
    return this
  }
  setShapeRatio(v) { // 形状宽高比例
    this.shapeRatio = v
  }
  initial(sx, sy, ox, oy) { // 初始化画布
    return this.init(sx, sy, ox, oy)._horizontal()._vertical()
  }
  process() { // 绘制矩形
    this.ctx.beginPath()
    this.ctx.rect(this._sx, this._sy, this.width, this.height)
    this.ctx.closePath()
  }
  _isQuickInPath(x, y, gap = 0) { // 判断点是否存在于矩形内
    return x >= this._left - gap && x <= this._right + gap && y >= this._top -gap && y <= this._bottom + gap
  }
  _isQuickInStroke(x, y) { // 判断点是否存在线条内 需要了解 x,y 在线条的中点
    const lineWidth = this.sty.lineWidth
    const { _left, _right, _top, _bottom } = this
    return (
      (0 === Math.round((x - _left) / lineWidth) || 0 === Math.round((x - _right) / lineWidth)) &&
      (0 === Math.round((y - _top) / lineWidth) || 0 === Math.round((y - _bottom) / lineWidth)))
  }
  _setStartX(sx) {
    return this.setStartX(sx)._horizontal()
  }
  _setStartY(sy) {
    return this.setStartY(sy)._vertical()
  }
  setStart(sx, sy) {
    return this._setStartX(sx)._setStartY(sy)
  }
  _setOriginX(ox) {
    return this.setOriginX(ox)._horizontal()
  }
  _setOriginY(oy) {
    return this.setOriginY(oy)._vertical()
  }
  offset(x, y) {
    let {sx, sy} = this
    this._setStartX(sx += x)._setStartY(sy += y)
  }
  getWidth() {
    return this.width
  }
  setWidth(width) {
    this.width = width
    return this._horizontal()
  }
  getHeight() {
    return this.height
  }
  setHeight(height) {
    this.height = height
    return this._vertical()
  }
  _horizontal() {
    if (this.width > 0) {
      this._left = this._sx
      this._right = this._left + this.width
    } else {
      this._right = this._sx
      this._left = this._right + this.width
    }
    this.halfX = this._left + this.width / 2
    return this
  }
  _vertical() {
    if (this.height > 0) {
      this._top = this._sy
      this._bottom = this._top + this.height
    } else {
      this._bottom = this._sy
      this._top = this._bottom + this.height
    }
    this.halfY = this._top + this.height / 2
    return this
  }
}

export default Rect

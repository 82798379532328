/* eslint-disable  */
import { Component } from 'react'
import styled from 'styled-components'
import IconButton from 'components/comm/IconButton'

const CustomDialogCssWrapper = styled.div`
  width: 250px;
  z-index: 100;
  position: absolute;
  top: 100px;
  left: 100px;
  background-color: #fafbfc;
  user-select: none;
  box-shadow: 0px 0px 5px #9f8d8d;
  border-radius: 4px;
  padding: 4px;

  .custom-modal-header {
    display: flex;
    justify-content: space-between;
    height: 41px;
    display: flex;
    align-items: center;
    font-size: 20px;
    border-bottom: 1px solid ${props => props.theme.main.borderColor};;
  }

  .custom-modal-content {}
`

export class CustomDialogDraggable extends Component {
  simpleClass;
  header;
  contain;
  modalContent;
  mouseDown = 0;
  mouseDownY = 0;
  deltaX = 0;
  deltaY = 0;
  sumX = 0;
  sumY = 0;

  constructor(props) {
    super(props);
    this.simpleClass = Math.random()
      .toString(36)
      .substring(2);
  }

  handleMove = (event) => {
    const deltaX = event.pageX - this.mouseDownX;
    const deltaY = event.pageY - this.mouseDownY;

    this.deltaX = deltaX;
    this.deltaY = deltaY;

    this.contain.style.transform = `translate(${deltaX + this.sumX}px, ${deltaY + this.sumY}px)`;
  };

  initialEvent = (visible) => {
    const { title } = this.props;
    if (title && visible) {
      setTimeout(() => {
        window.removeEventListener('mouseup', this.removeUp, false);

        this.contain = document.getElementsByClassName(this.simpleClass)[0];
        this.header = this.contain.getElementsByClassName('custom-modal-header')[0];
        this.modalContent = this.contain.getElementsByClassName('custom-modal-content')[0];

        this.header.style.cursor = 'all-scroll';
        this.header.onmousedown = (e) => {
          this.mouseDownX = e.pageX;
          this.mouseDownY = e.pageY;
          document.body.onselectstart = () => false;
          window.addEventListener('mousemove', this.handleMove, false);
        };

        window.addEventListener('mouseup', this.removeUp, false);
      }, 0);
    }
  };

  removeMove = () => {
    window.removeEventListener('mousemove', this.handleMove, false);
  };

  removeUp = () => {
    document.body.onselectstart = () => true;

    this.sumX = this.sumX + this.deltaX;
    this.sumY = this.sumY + this.deltaY;

    this.removeMove();
  };

  componentDidMount() {
    const { visible = false } = this.props;
    this.initialEvent(visible);
  }

  componentWillUnmount() {
    this.removeMove();
    window.removeEventListener('mouseup', this.removeUp, false);
  }

  render() {
    const { children, onClose, title } = this.props;

    return (
      <CustomDialogCssWrapper className={this.simpleClass}>
        <div className='custom-modal-header'>
          <span>{title}</span>
          <IconButton onClick={() => { onClose() }} name='close' showToolTip={false}/>
        </div>
        <div className='custom-modal-content'>
          {children}
        </div>
      </CustomDialogCssWrapper>)
  }
}
import styled from 'styled-components'

export const RightToolbox = styled.div`
  * {
    box-sizing: border-box
  }

  .boxDiv{
    background:#fff;
    width: 280px;
    box-shadow: 1px 1px 5px 0px rgba(4, 0, 0, 0.1);
    height: 100%;
    // display: flex;
    position: fixed;
    right: 0;
    top: 61px;
    z-index: 1;
  }
  .boxDiv .names{
    height:40px;
    width:100%;
    display:flex;
    justify-content: space-between;
    padding: 0 16px 0 20px;
    align-items:center;
    border-bottom:1px solid #E3E3E3;
  }

  .boxDiv .names .text{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1C1C1C;
    display:flex;
    align-items:center;
  }

  .boxDiv .names  .close{
    width: 12px;
    height: 12px;
  }
  .boxDiv .tools{
    display:flex;
    align-items:center;
  }
  .boxDiv .tools .textTwo{
    border-bottom:1px solid #E3E3E3;
    height:40px;
    align-items:center;
    width:100%;
    display:flex;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1C1C1C;
    padding-left:20px;
  }
  .boxDiv .tools .textTwo .boxYS{
    height:40px;
  }
  .boxDiv .tools .textTwo .boxYS .text{
    width:35px;
    height:38px;
    display:flex;
    align-items:center;
  }
  .boxDiv .tools .textTwo .boxYS:hover .activeDiv{
    width:35px;
    height:2px;
    background:#FF9F1C;
    margin-left:-3px;
  }

  .boxDiv .tools .textTwo .boxYS .true{
    width:35px;
    height:2px;
    background:#FF9F1C;
    margin-left:-3px;
  }
  .boxDiv .tools .textTwo .boxWB{
    margin-left:20px;
    height:40px;
  }
  .boxDiv .tools .textTwo .boxWB .text{
    width:35px;
    height:38px;
    display:flex;
    align-items:center;
  }
  
  .boxDiv .tools .textTwo .boxWB:hover .activeDiv{
    width:35px;
    height:2px;
    background:#FF9F1C;
    margin-left:-3px;
  }

  .boxDiv .tools .textTwo .boxWB .true{
    width:35px;
    height:2px;
    background:#FF9F1C;
    margin-left:-3px;
  }

`

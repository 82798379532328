import ShapeStyle from './shapeStyle'

class ImageStyle extends ShapeStyle {
  constructor() {
    super()
    this.smoothingEnabled = ImageStyle.DEFAULT_SMOOTHING_ENABLED
    this.smoothingQuality = ImageStyle.DEFAULT_SMOOTHING_QUALITY
  }

  static DEFAULT_SMOOTHING_ENABLED = !0
  static DEFAULT_SMOOTHING_QUALITY = "low"

  getSmoothingEnabled () {
    return this.smoothingEnabled;
  }
  setSmoothingEnabled (smoothingEnabled) {
    return (this.smoothingEnabled = smoothingEnabled), this;
  }
  getSmoothingQuality () {
    return this.smoothingQuality;
  }
  setSmoothingQuality (smoothingQuality) {
    return (this.smoothingQuality = smoothingQuality), this;
  }
}
export default ImageStyle
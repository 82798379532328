import Rect from './shapes/rect'
import Collision from './shapes/collision'
class SelectBox {
  constructor(ctx, board) {
    this.box = new Rect(ctx, 0, 0, 0, 0)
    this.x = this.y = this.cx = this.cy = 0
    this.width = this.height = 0
    this.board = board
    this.isVisible = false
    this.isNeedCover = true // 是否需要覆盖层，半透明的覆盖层

    this.style = new Style(this)
  }
  draw() {
    if (this.isVisible) {
      if (this.isNeedCover) {
        this.box.fillStroke()
      } else {
        this.box.stroke()
      }
    }
  }
  setStart(p) {
    this.x = p.x
    this.y = p.y
    return this
  }
  apply() {
    const {x, y, width, height} = this
    this.box.setStart(x, y)
    this.box.setWidth(width).setHeight(height)
  }
  drag(x, y) {
    // if (!this.isVisible) return
    this.offset(x, y)
    this.calculateSize()
  }
  offset(x, y) {
    this.x += x
    this.y += y
    return this
  }
  hide() {
    this.isVisible = false
    return this
  }
  show() {
    this.isVisible = true
    return this
  }
  showCover() {
    this.isNeedCover = true
  }
  hideCover() {
    this.isNeedCover = false
  }
  calculateSize(sels) {
    sels = sels || this.selector.selected
    let left = [], right = [], top = [], bottom = []
    sels.forEach(n => {
      if (n.funcLock.isLockedPas) return
      left.push(n.left)
      right.push(n.right)
      top.push(n.top)
      bottom.push(n.bottom)
    })
    left = Math.min.apply(null, left)
    top = Math.min.apply(null, top)
    right = Math.max.apply(null, right)
    bottom = Math.max.apply(null, bottom)
    this.x = left
    this.y = top
    this.width = right - left
    this.height = bottom - top
    this.cx = this.x + this.width / 2
    this.cy = this.y + this.height / 2
    this.apply()
  }
  eventdown(point) { // 鼠标对应的画点
    this.setStart(point).hide()
  }
  eventdrag(p) { // p: point 鼠标对应的画点
    this.width = p.x - this.x
    this.height = p.y - this.y
    this.apply()
    this.show()
    this.showCover()
    this.select()
    this.board.draw()
    return this
  }
  eventup() {
    if (this.isVisible) { // 使用cmd/ctrl + click 多选元素时
      const sels = this.selector.selected
      if (sels.length <= 1) { // 当框选中只有一个元素的时候
        sels[0] && sels[0].setSelectType('click')
        this.hide()
      } else {
        if (
          sels.every((sel) => {
            return this.board.operateGroup.getGroup(sel.id);
          })
        ) {
          this.board.operateGroup.getGroup(sels[0].id).setSelectType("click");
          this.hide();
        } else {
          sels.forEach((s) => {
            s.setSelectType("frame")
            this.board.operateGroup.getGroup(s.id)?.setSelectType("frame")
          });

          this.calculateSize();
          this.hideCover();
        }
      }
      this.board.draw()
    }
  }
  collidesWith(node) { // node: 图形节点
    return Collision.irregularImage(node.background, this.board, node.border.boundary, this.box)
  }
  select() {
    const { nodes, links } = this.board
    const list = []
    const groupIds = []
    const ids = []

    for(let n of Object.values(nodes)) {
      if (this.collidesWith(n)) {
        ids.push(n.id)
        let group = this.board.operateGroup.getGroup(n.id);
        if(group){ groupIds.push(group.id) }
        list.push(n)
      }
    }
    for(let k of Object.values(links)) {
      if (k.collideWidthRect(this.box)) {
        ids.push(k.id)
        let group = this.board.operateGroup.getGroup(k.id);
        if(group){ groupIds.push(group.id) }
        list.push(k)
      }
    }
    // 处理组合的情况
    for(let n of Object.values(nodes)) {
      let group = this.board.operateGroup.getGroup(n.id);
      if (
        group &&
        groupIds.indexOf(group.id) != -1 &&
        ids.indexOf(n.id) === -1
      ) {
        list.push(n)
      }
    }

    for(let k of Object.values(links)) {
      let group = this.board.operateGroup.getGroup(k.id);
      if (
        group &&
        groupIds.indexOf(group) != -1 &&
        ids.indexOf(k.id) === -1
      ) {
        list.push(k)
      }
    }
    this.selector._multiSelect(list)
    list.forEach((n)=>{
      n.setSelectType('frame')
    })
  }

  allSelect(){
    const { nodes, links } = this.board
    const list = []
    for(let n of Object.values(nodes)) {list.push(n)}
    for(let k of Object.values(links)) {list.push(k)}
    this.selector._multiSelect(list)
    list.forEach(it => it.setSelectType('frame'))
    this.calculateSize()
    this.hideCover()
    this.show()
    this.board.draw()
  }
  get selector() {
    return this.board.selector
  }
}

class Style {
  constructor(target) {
    this.target = target
  }
  apply(style={}) {
    this.borderColor = style.borderColor || 'blue'
    this.backgroundColor = style.backgroundColor || 'rgba(109, 177, 255, 0.3)'
    this.lineWidth = style.lineWidth || 1
    this._apply()
  }
  _apply() {
    const { box } = this.target
    box.getStyle().setStrokeStyle(this.borderColor).setFillStyle(this.backgroundColor).setOpacity(0.1)
  }
}

export default SelectBox

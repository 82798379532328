/*
 绘制左箭头 
 */
import Process from './process.js'
class ArrowLeft extends Process {
    constructor(ctx, sx, sy, width, height, ox, oy) {
        super(ctx, sx, sy, ox, oy)
        this.setWidth(width).setHeight(height)
    }
    
    process() {

        const { ctx, _left, _top, _right, _bottom, width, height, halfX, halfY } = this
        let width_X = height / 2 > width ? _left + width / 2 : _left + height / 2

        let arrow0 = { x: width_X, y: _top }
        let arrow1 = { x: width_X, y: _top + height / 4 }
        let arrow2 = { x: _right, y: _top + height / 4 }
        let arrow3 = { x: _right, y: _bottom - height / 4 }
        let arrow4 = { x: width_X, y: _bottom - height / 4 }
        let arrow5 = { x: width_X, y: _bottom }
        let arrow6 = { x: _left, y: halfY}

        let arcRadius1 = height > 25 && width > 20 ? 4 : 1
        let arcRadius2 = height > 25 && width > 20 ? 2 : 1

        ctx.beginPath();
        ctx.moveTo(arrow3.x, arrow3.y - 5);
        ctx.arcTo(arrow3.x, arrow3.y, arrow4.x, arrow4.y, arcRadius1)
        ctx.arcTo(arrow4.x, arrow4.y, arrow5.x, arrow5.y, arcRadius1)
        ctx.arcTo(arrow5.x, arrow5.y, arrow6.x, arrow6.y, arcRadius2)
        ctx.arcTo(arrow6.x, arrow6.y, arrow0.x, arrow0.y, arcRadius2);
        ctx.arcTo(arrow0.x, arrow0.y, arrow1.x, arrow1.y, arcRadius2);
        ctx.arcTo(arrow1.x, arrow1.y, arrow2.x, arrow2.y, arcRadius1);
        ctx.arcTo(arrow2.x, arrow2.y, arrow3.x, arrow3.y, arcRadius1)
        ctx.arcTo(arrow3.x, arrow3.y - 5, arrow3.x, arrow3.y, arcRadius1);
    }
}

export default ArrowLeft
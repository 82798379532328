/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import IconButton from 'components/comm/IconButton'
import withClickModal from 'components/comm/hoc/withClickModal'
import PropTypes from 'prop-types'
// import { NormalSW } from 'components/comm/Switch'
// import { NormalCB } from 'components/comm/CheckBox'

import styled from 'styled-components'

const MCCssWrapper = styled.div`
  .modContent {
    width: 260px;
    padding: 6px;
    .link{
      text-align: center;
      cursor: pointer;
      display: inline-flex;
      width: 60px
      background-color: #e6e6e6;
    }
    .desc{
      display: flex;
      justify-content: space-between;
      .link{
        font-weight:600
      }
      .clear{
        color: #282c33;
        cursor: pointer;
      }
    }
    .urlInput{
      display: flex;
      justify-content: space-between;
      .inputBox{
        padding: 5px 8px;
        cursor: auto;
        text-overflow: inherit;
        height: 25px;
        width: 180px;
      }
      .determineBtn{
        background-color: #1071e5;
        color: #fff;
        border-radius: 5px;
        width:60px;
        font-weight:600;
        text-align: center;
        cursor: pointer;
      }
    }
  }
`

function CustomLinkBtn(props) {
  return (
    <IconButton links={true} selected={!!(props.selectNode && props.selectNode.hyperlink)} name='insert' showToolTip={false} {...props} />
  )
}

const InsertTypeConfig = [
  {
    type: 'url',
    text: '超链接'
  }
]

function CustomModalContent(props) {
  const { isShow } = props
  return (
    <div className='flexColumn modContent'>
      {/* {
        data.InsertTypeConfig.map((item) => {
          return <div className='link' key={item.type}>{item.text}</div>
        })
      } */}
      {
        isShow && <InputBox {...props}></InputBox>
      }
    </div>
  )
}

function InputBox(props) {
  const { onChange, data, handleOutClick } = props
  const defaultValue = data.selectNode.hyperlink || ''
  const [inputValue, setInputValue] = useState(defaultValue)

  const determineBtn = () => {
    // 处理用户只输入网址也能跳转
    const reg = /(http|https):\/\/\S*/
    let applyUrl = inputValue
    if (!reg.test(applyUrl)) {
      applyUrl = `http://${applyUrl}`
    }
    onChange(applyUrl)
    handleOutClick()
  }

  const inputChange = (event) => {
    setInputValue(event.target.value)
  }

  const clearBtnClick = () => {
    setInputValue('')
    onChange('')
  }

  return (
    <>
      <div className='desc'>
        <div className='link'>链接到Web</div>
        <div className='clear' onClick={clearBtnClick}>清除</div>
      </div>
      <div>使用此操作链接到外部网页</div>
      <div className='urlInput' onKeyDown={(event) => {
        // 阻止input onChange事件触发onKeyDow冒泡到document
        event.stopPropagation()
      }}>
        <input className='inputBox' value={inputValue} onChange={inputChange}/>
        <div className='determineBtn' onClick={determineBtn}>确定</div>
      </div>
    </>
  )
}

const InsertBtn = withClickModal(CustomLinkBtn, CustomModalContent)

function InsertLink({ data, onChange }) {
  const btnProps = {}
  const modProps = { data, onChange }
  return (
    <MCCssWrapper>
      <InsertBtn btnProps={btnProps} modProps={modProps} />
    </MCCssWrapper>
  )
}

InsertLink.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.any
}

export { InsertTypeConfig }
export default InsertLink

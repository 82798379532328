/* 
  绘制等腰三角形
*/

import Process from './process.js'
class Equilateral extends Process {
  constructor(ctx, sx, sy, width, height, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.setWidth(width).setHeight(height)
    this.arcRadius=0.5   
  }
  initial(sx, sy, ox, oy) { // 初始化画布
    return this.init(sx, sy, ox, oy)._horizontal()._vertical()
  }
  process() { // 绘制三角形
    const { ctx, _left, _top, _right, width, height, halfY,_bottom, halfX,arcRadius  } = this
    let arc2 = this.arc2 / height
    let arc1 = this.arc1 / width
    if (width < 28) arc1 = 0, arc2 = 1
    if (height < 28) arc2 = 0, arc1 = 1
    const start = { x: (_left + halfX) / 2, y: (_top + _bottom) / 2}
    const fc = { x: _left, y: _bottom } // first corner 第一个拐角的点
    const sc = { x: halfX, y: _top }
    const tc = { x: _right, y: _bottom }
    const lc = { x: halfX, y: _bottom }
    ctx.beginPath();
    ctx.moveTo(start.x,start.y);
    ctx.arcTo(sc.x,sc.y,tc.x,tc.y,arcRadius);
    ctx.arcTo(tc.x,tc.y,fc.x,fc.y,arcRadius);
    ctx.arcTo(fc.x,fc.y,start.x,start.y,arcRadius);
    ctx.lineTo(start.x,start.y);
  }
}
export default Equilateral



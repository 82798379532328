import styled from 'styled-components'

export const BoardCssWrapper = styled.div `
  font-size: 12px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  #board-wrapper {
    position: relative;
    outline: none;
  }

  #draw-board {
    position: absolute;
    pointer-events: none;
  }

  #grid-board {
    position: absolute;
    pointer-events: none;
  }

  #action-wrapper {
    position: absolute;
    border-style: solid;
    margin: 0;
  }

  #draw-action {
    display: block;
    overflow: hidden;
    outline: none;
    border: none;
    resize: none;
    word-wrap: none;
    /* white-space: break-spaces; */
    word-break: break-all;
    box-sizing: border-box;
    margin: 0;
    tab-size: 1;
  }
  
  .image-viewer-box {
    background: rgba(0,0,0,.75);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
    display:none;
  }
  
  .image-viewer-toolbar{
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    background: rgba(0,0,0,.75);
    text-align: right;
    -webkit-transition: all .25s;
    transition: all .25s;
    z-index: 1;
  }
  
  .image-viewer-close{
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjY0IiBoZWlnaHQ9Ijg4IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTY3LjAwMiA1OS41djMuNzY4Yy02LjMwNy44NC05LjE4NCA1Ljc1LTEwLjAwMiA5LjczMiAyLjIyLTIuODMgNS41NjQtNS4wOTggMTAuMDAyLTUuMDk4VjcxLjVMNzMgNjUuNTg1IDY3LjAwMiA1OS41ek0xMyAyOXYtNWgydjNoM3YyaC01em0wLTE0aDV2MmgtM3YzaC0ydi01em0xOCAwdjVoLTJ2LTNoLTN2LTJoNXptMCAxNGgtNXYtMmgzdi0zaDJ2NXpNNjIgMjR2NWgtMnYtM2gtM3YtMmg1em0wLTRoLTV2LTJoM3YtM2gydjV6bTggMHYtNWgydjNoM3YyaC01em0wIDRoNXYyaC0zdjNoLTJ2LTV6TTIwLjU4NiA2NmwtNS42NTYtNS42NTYgMS40MTQtMS40MTRMMjIgNjQuNTg2bDUuNjU2LTUuNjU2IDEuNDE0IDEuNDE0TDIzLjQxNCA2Nmw1LjY1NiA1LjY1Ni0xLjQxNCAxLjQxNEwyMiA2Ny40MTRsLTUuNjU2IDUuNjU2LTEuNDE0LTEuNDE0TDIwLjU4NiA2NnpNMTExLjc4NSA2NS4wMyAxMTAgNjMuNWwzLTMuNWgtMTB2LTJoMTBsLTMtMy41IDEuNzg1LTEuNDY4TDExNyA1OWwtNS4yMTUgNi4wM3pNMTUyLjIxNSA2NS4wMyAxNTQgNjMuNWwtMy0zLjVoMTB2LTJoLTEwbDMtMy41LTEuNzg1LTEuNDY4TDE0NyA1OWw1LjIxNSA2LjAzek0xNjAuOTU3IDI4LjU0M2wtMy4yNS0zLjI1LTEuNDEzIDEuNDE0IDMuMjUgMy4yNXoiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNMTUyLjUgMjdhNS41IDUuNSAwIDEgMCAwLTExIDUuNSA1LjUgMCAxIDAgMCAxMXoiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTUwIDIxaDV2MWgtNXoiLz48Zz48cGF0aCBkPSJtMTE2Ljk1NyAyOC41NDMtMS40MTQgMS40MTQtMy4yNS0zLjI1IDEuNDE0LTEuNDE0IDMuMjUgMy4yNXoiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNMTA4LjUgMjdhNS41IDUuNSAwIDEgMCAwLTExIDUuNSA1LjUgMCAxIDAgMCAxMXoiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTA2IDIxaDV2MWgtNXoiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJtMTA5LjA0MyAxOS4wMDgtLjA4NSA1LTEtLjAxNy4wODUtNXoiLz48L2c+PC9nPjwvc3ZnPg==);
      background-position: 0 -44px;
      border: 0;
      cursor: pointer;
      height: 44px;
      outline: 0;
      width: 44px;
  }
  
  .image-viewer-container {
    bottom: 0;
    left: 0;
    overflow: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }
  
  .image-viewer-container:before {
    content: "";
    display: inline-block;
    font-size: 0;
    height: 100%;
    vertical-align: middle;
    width: 0;
  }
  
  .image-viewer-container img {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
  }
`

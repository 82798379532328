/*
用于流程图节点的外边框，显示节点范围
可调节的九个位置
*/
// import Rect from './react'
import Rect from '../shapes/rect'
class Border {
  constructor(ctx, target) {
    this.target = target
    this.board = target.board
    this.ctx = ctx
    this.boundary = new Rect(ctx, 0, 0, 0, 0)
    this.style = new Border.Style(this)
    this.mouseInPoint = 0
    this.initPoint() // 初始化方位点
  }
  initPoint() {
    this.points = []
    let i = 0
    while (i < 8) { this.points.push(new Rect(this.ctx, 0, 0, 0, 0)); i++ }
  }
  apply() {
    const { target } = this
    const { x, y, width, height } = target
    this.boundary.setStart(x, y).setWidth(width).setHeight(height)
    const list = [
      { x, y }, 
      { x: x + width / 2, y: y }, 
      { x: x + width, y: y },
      { x: x + width, y: y + height/2},
      { x: x + width, y: y + height},
      { x: x + width/2, y: y + height},
      { x: x, y: y + height},
      { x: x, y: y + height/2}
    ]
    const pointSide = Math.max(this.board.getDrawLength(this.style.anchorWidth), 2) // 方位点的边长
    list.forEach((it, i) => {
      this.points[i].setStart(it.x - pointSide / 2, it.y - pointSide / 2).setWidth(pointSide).setHeight(pointSide)
    })
  }
  draw() {
    this.apply()
    this.boundary.stroke()
    // this.points.forEach(p => {
    //   p.fillStroke()
    // })
  }
  eventDrag({x, y}, ev, type) {
    let gapX = 0, gapY = 0;
    const { right, bottom, top, left, width, height } = this.target
    // 特殊处理图片类型，图片按比例缩放
    const inProportion = ev.shiftKey || (type && type === "image") // 按照比例调节大小
    const center = ev.altKey // 是否以中心作为缩放的origin

    switch(this.mouseInPoint) { // 调整原理： 先计算width, height的增量，在根据增量计算出left, top
      case 1: // 1左上角的点，2，3，4...后面的点按照顺时针依次排列
        if (x > right - 10 || y > bottom - 10) return
        gapX = left - x
        gapY = top - y

        if (inProportion) {
          gapY = gapX / width * height
        }
        x = left - gapX
        y = top - gapY
        break
       case 2:
        if (y > bottom - 10) return
        gapX = 0
        gapY = top - y
        x = left
        y = top - gapY

        if (inProportion) {
          gapX = gapY / height * width
          x = left - gapX / 2
        }
        if (center) {
          x = left - gapX
        }
        break
      case 3:
        if (x < left + 10 || y > bottom - 10) return
        gapX = x - right
        gapY = top - y
        x = left
        if (inProportion) {
          gapY = gapX / width * height
        }
        y = top - gapY
        if (center) {
          x = x - gapX
        }
        break
      case 4:
        if (x < left + 10) return
        gapX = x - right
        gapY = 0
        x = left
        y = top

        if (inProportion) {
          gapY = gapX / width * height
          y = top - gapY / 2
        }
        if (center) {
          x = left - gapX
          y = top - gapY
        }
        break
      case 5:
        if (x < left + 10 || y <  top + 10) return
        gapX = x - right
        gapY = y - bottom
        x = left
        y = top

        if (inProportion) {
          gapY = gapX / width * height
        }
        if (center) {
          x = x - gapX
          y = y - gapY
        }
        break
      case 6:
        if (y < top + 10) return
        gapX = 0
        gapY = y - bottom
        x = left
        y = top

        if (inProportion) {
          gapX = gapY / height * width
          x = left - gapX / 2
        }
        if (center) {
          x = left - gapX
          y = top - gapY
        }
        break
      case 7:
        if (x > right - 10 || y < top + 10) return
        gapX = left - x
        gapY = y - bottom
        y = top
        if (inProportion) {
          gapY = gapX / width * height
        }
        x = left - gapX
        if (center) {
          y = y - gapY
        }
        break
      case 8:
        if (x > right - 10) return
        gapX = left - x
        gapY = 0
        y = top
        x = left - gapX
        if (inProportion) {
          gapY = gapX / width * height
          y = top - gapY / 2
        }
        if (center) {
          y = top - gapY
        }
        break
    }
    if (center) {
      gapX *= 2
      gapY *= 2
    }
    this.target.isMoving = true
    this.target.resize({x, y, gapX, gapY})
  }
  onmouseleave() {
    this.mouseInPoint = 0
  }
  eventmove(x, y) {
    let p, ps = this.points
    this.preMouseInPoint = this.mouseInPoint
    this.mouseInPoint = 0
    this.style.setUnhoverStyle(ps[this.preMouseInPoint - 1])
    if (this.boundary._isQuickInPath(x, y, this.style.anchorWidth / 2)) {
      for(let i = 0; i < ps.length; i++) {
        p = ps[i]
        if (p._isQuickInPath(x, y)) {
          this.style.setHoverStyle(p)
          this.mouseInPoint = i + 1
          break
        }
      }
    }
    if (this.preMouseInPoint !== this.mouseInPoint) this.board.draw()
    return this.mouseInPoint
  }
  onscale() {
    this.style._apply()
    this.apply()
  }
  eventup() {}
}

class Style {
  constructor(target) {
    this.target = target
  }
  apply(style = {}) {
    this.backgroundColor = style.backgroundColor || '#fff'
    this.hoverColor = style.hoverColor || 'blue'
    this.color = style.color || '#FF7722'
    this.lineColor = style.lineColor || '#1097e5'
    this.lineWidth = style.lineWidth || 1
    this.anchorWidth = style.anchorWidth || 8
    this._apply()
  }
  setHoverStyle(anchor) {
    anchor.getStyle().setFillStyle(this.hoverColor)
  }
  setUnhoverStyle(anchor) {
    anchor && anchor.getStyle().setFillStyle(this.backgroundColor)
  }
  _apply() {
    const { boundary, points, board } = this.target
    const lineW = Math.max(board.getDrawLength(this.lineWidth), 0.3)
    boundary.getStyle().setLineWidth(lineW).setStrokeStyle(this.lineColor)
    points.forEach(p => {
      p.getStyle().setLineWidth(lineW).setFillStyle(this.backgroundColor).setStrokeStyle(this.lineColor)
    })
  }
}
Border.Style = Style
export default Border

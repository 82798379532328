/*
 * @Author: 赵欣 635538643@qq.com
 * @Date: 2022-05-09 20:41:03
 * @LastEditors: 赵欣 635538643@qq.com
 * @LastEditTime: 2022-05-13 11:48:47
 * @FilePath: \project\src\components\drawBoard\shapes\process\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import data from './data'
import decision from './decision'
import document from './document'
import multiDocument from './multiDocument'
import manualInput from './manualInput'
import process from './process'
import image from './image'
import circle from './circle'
import terminator from './terminator'
import triangle from './triangle' // 三角形
import equilateral from './equilateral' // 等腰三角
import pentagon from './pentagon' // 五边形
import hexagon from './hexagon' // 六边形
import multi from './multi' // 多重文档
import octagon from "./octagon"
import cross from "./cross"
import arrowTop from "./arrowTop"
import arrowBottom from "./arrowBottom"
import arrowLeft from "./arrowLeft"
import arrowRight from "./arrowRight"
import arrowLeftRight from "./arrowLeftRight"
import arrowTopBottom from "./arrowTopBottom"
import fiveStar from "./fiveStar"

export {
  data,
  decision,
  document,
  multiDocument,
  multi,
  manualInput,
  process,
  circle,
  terminator,
  triangle,
  equilateral,
  pentagon,
  hexagon,
  octagon,
  cross,
  arrowTop,
  arrowBottom,
  arrowLeft,
  arrowRight,
  arrowLeftRight,
  arrowTopBottom,
  fiveStar,
  image
}
/* eslint-disable react/prop-types */
import { createContext, useState, useEffect } from 'react'
import * as dispatches from 'store/dispatches'

export const Context = createContext({ // 默认值，当子组件匹配不到provider才会取
  // zoomRatio: 1, // 画板缩放比例
  // setZoomRatio: () => {},
  board: null, // 画板实例
  setBoard: () => {} // 设置画板实例
  // activeNode: {}, // 画板中选择的元素
  // setActiveNode: () => {} // 设置画板中选择的元素
})

export const BoardProvider = ({ children }) => {
  const [board, setBoard] = useState(null)
  // const [zoomRatio, setZoomRatio] = useState(null)
  // const [activeNode, setActiveNode] = useState({})
  const context = { board, setBoard }
  useEffect(() => {
    if (board) { // 暂时先这样判断，由于热更新的逻辑引起错误异常
      board.registerDispatch && board.registerDispatch({ ...dispatches }) // 画板实例注册用于改变页面组件状态的方法
    }
  }, [board])
  return <Context.Provider value={context}>{children}</Context.Provider>
}

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CssWrapper = styled.select`
  // border: 1px solid ${props => props.theme.main.borderColor};
  // height: ${props => props.theme.main.inputHeight};
  width: 105px;
  height: 30px;
  border: none;
  padding-left: 5px;
`

const SelectS = (props) => {
  const { values = [] } = props
  return (
    <CssWrapper>
      {
        values.map((e, index) => (
          <option key={index} value=''> {e} </option>
        ))
      }
    </CssWrapper>
  )
}

SelectS.propTypes = {
  onValueChange: PropTypes.func,
  values: PropTypes.array,
  unit: PropTypes.string
}

export { SelectS }

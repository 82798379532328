import styled from 'styled-components'

export const LeftPanelsCssWrapper = styled.div`
* {
    box-sizing: border-box
  }

  display: flex;
  position: fixed;
  left: 20px;
  top: 50%;
  margin-top:-140px;
  z-index: 1;
  width: 48px;
  background: #FFFFFF;
  box-shadow: 1px 1px 5px 0px rgba(4, 0, 0, 0.1);
  border-radius: 6px;

  //7月7号--->15号版本的：后需要修改回来的===================》
  // height: 283px;
  //7月7号--->15号版本的：后需要删除的===================》
  height: 195px;
 
  .tab {
    // border: 1px solid ${props => props.theme.main.borderColor};
    display: flex;
    flex-direction: column;
    width: 48px;
    // height:230px;
    // background: #FFFFFF;
    // box-shadow: 1px 1px 3px 0px rgba(4, 0, 0, 0.1);
    // border-radius: 5px;
    align-items: center;
  }
  
  .tab button {
    display: block;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background:#fff;
    margin: 0 5px;
  }

  .tab button:hover {
    background-color: #ddd;
  }

  .tab button.active {
    background: #F0F2F5;
    padding-left:5px;
    padding-right:5px;
  }

  .tab .tabBtn{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tab .tabBtn .img{
    width:20px;
    height:20px;
    color:#000;
  }

  .hidden {
    display: none;
  }

  .active {
    background-color: #ccc;
  }

  .tab .category{
    margin-top:15px;
    width: 48px;
    display: flex;
    justify-content: center;
  }

  .tab .category .desc{
    visibility: hidden;
    opacity: 0;
    width: 45px;
    height:37px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    font-size:14px;
  }
  .tab .category .desc::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
    transform: rotate(90deg);
  }
  .tab .category:hover .desc{
    visibility: visible;
    opacity: 1;
  }
  .tab .category .tabBtn .img{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    padding-left: 2px;
  }

  
  // 形状组合的样式
  // .tab .category .div0 .text{
  //   transform: rotate(0deg);
  // }
  // .tab .category .div0::after{
  //   margin-left: -47px;
  //   margin-top: -23px;
  // }
  // .tab .category .div0{
  //   width:75px;
  //   top:10px;
  //   left: 53px;
  // }
  // .tab .category0  .tabBtn .img{
  //   margin-left: -2px;
  //   margin-top: -5px;
  // }

  //形状的
  // .tab .category .div1{
  //   top:55px;
  //   left: 53px;
  // }
  // .tab .category .div1 .text{
  //   transform: rotate(0deg);
  // }
  // .tab .category .div1::after{
  //   margin-left: -32px;
  //   margin-top: -23px;
  // }
  
  //连接线的
  // .tab .category .div2{
  //   top: 100px;
  //   left: 53px;
  //   width: 65px;
  // }
  // .tab .category .div2 .text{
  //   transform: rotate(0deg);
  // }
  // .tab .category .div2::after{
  //   margin-left: -42px;
  //   margin-top: -23px;
  // }

  //文本的
  // .tab .category .div3{
  //   top:145px;
  //   left: 53px;
  // }
  // .tab .category .div3 .text{
  //   transform: rotate(0deg);
  // }
  // .tab .category .div3::after{
  //   margin-left: -32px;
  //   margin-top: -23px;
  // }

  //容器
  // .tab .category .div4{
  //   top:190px;
  //   left: 53px;
  // }
  // .tab .category .div4 .text{
  //   transform: rotate(0deg);
  // }
  // .tab .category .div4::after{
  //   margin-left: -32px;
  //   margin-top: -23px;
  // }
  // .tab .category4  .tabBtn .img{
  //   margin-left: -3px;
  //   margin-top: -5px;
  // }

  //上传
  // .tab .category .div5{
  //   top:235px;
  //   left: 53px;
  // }
  // .tab .category .div5 .text{
  //   transform: rotate(0deg);
  // }
  // .tab .category .div5::after{
  //   margin-left: -32px;
  //   margin-top: -23px;
  // }
  
  .tabcontent {
    float: left;
    width:280px;
    height:410px;
    padding-top: 20px;
    border: 1px solid ${props => props.theme.main.borderColor};
    border-left: none;
    flex: 1;
    margin-left: 10px;
    background: #FFFFFF;
    box-shadow: 1px 1px 3px 0px rgba(4, 0, 0, 0.1);
    border-radius: 5px;
  }

  .tabShape{
    width:0;
    height:0;
    position: relative;
  }

  .shapeDiv{
    position:fixed;
    z-index:22;
    top: 50%;
    left: 50%;
  }


  //7月7号--->15号版本的：后需要删除的===================》
  .tab .category .div0{
    top:10px;
    left: 53px;
  }
  .tab .category .div0 .text{
    transform: rotate(0deg);
  }
  .tab .category .div0::after{
    margin-left: -32px;
    margin-top: -23px;
  }

  .tab .category .div1{
    top: 55px;
    left: 53px;
    width: 65px;
  }
  .tab .category .div1 .text{
    transform: rotate(0deg);
  }
  .tab .category .div1::after{
    margin-left: -42px;
    margin-top: -23px;
  }

  .tab .category .div2{
      top:100px;
      left: 53px;
    }
    .tab .category .div2 .text{
      transform: rotate(0deg);
    }
    .tab .category .div2::after{
      margin-left: -32px;
      margin-top: -23px;
    }

    .tab .category .div3{
        top:145px;
        left: 53px;
      }
      .tab .category .div3 .text{
        transform: rotate(0deg);
      }
      .tab .category .div3::after{
        margin-left: -32px;
        margin-top: -23px;
      }
`

import mathHelper from "../../../../utils/mathHelper";

export default class LinkLayout {
  constructor(target) {
    this.target = target
    this.lines = [] // 统计连接线共有多少条线段
    this.start = this.end = null // 开始点和结束点
  }
  compute() {
    const {start, end, compoundLines } = this.target
    const lines = compoundLines.getAllLines()

    let startSlope, endSlope
    if (lines.length === 1) {
      startSlope = mathHelper.getSlopeByTwoPoint(start, end)
      endSlope = startSlope + Math.PI
    } else {
      const fLine = compoundLines.getFirstLine() 
      const lLine = compoundLines.getLastLine() 
      startSlope = mathHelper.getSlopeByTwoPoint(start, fLine.getLineTwoEndPoints().end)
      endSlope = mathHelper.getSlopeByTwoPoint(end, lLine.getLineTwoEndPoints().start)
    }

    return [start, end, startSlope, endSlope]
  }
  setTextRate(n) { // 获取文字长度占比 此方法只在添加文字时执行一次
    n.rateAtLink = 0.5
    return n
  }
  adjustText(t) { // t: text 对象
    t.layoutX = t.layoutX - t.width / 2
    t.layoutY = t.layoutY - t.height / 2
    t.apply()
  }
  layoutTexts() { // 计算文字节点位置
    const { target} = this
    const cp = target.compoundLines.getCenterPoint()
    target.texts.forEach((text) => {
      text.setLayout(cp.x, cp.y)
      this.adjustText(text)
    })
  }
}
import React from 'react'
import { CustomDialogDraggable } from 'components/comm/CustomDialogDraggable'
import IconButton from 'components/comm/IconButton'
import Switch from 'components/antd/Switch'
import Expand from 'components/comm/Expand'
import withMoreColorPick from 'components/comm/hoc/withMoreColorPick'

import styled from 'styled-components'
import PropTypes from 'prop-types'
// import { SelectS } from 'components/comm/Select'
import NumberEditor from 'components/comm/NumberEditor'
// import PageSizeSelect from './PageSizeSelect'

const IconButtonWithColorPick = withMoreColorPick(IconButton)

const CssWrapper = styled.div`
  height:100px;
  border:1px soild red;
  .item {
    height: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .buttonBorder {
    border: 1px solid #bfbfbf;
  }
`

export const PageSetting = (props) => {
  const { visible, setVisible, canvas, setCanvas, grid, setGrid } = props

  return (
    <>
      {visible && (
        <CustomDialogDraggable
          title={'页面设置'}
          visible={visible}
          onClose={() => { setVisible(false) }}
        >
          <CssWrapper>
            <Expand title='画布' hasOverFlow={false}>
              <>
                <div className='item'>
                  <div>背景颜色</div>
                  <div style={{ backgroundColor: canvas.color }}>
                    <IconButtonWithColorPick
                      name=''
                      disabled={true}
                      className='buttonBorder'
                      widthInPx={28}
                      heightInPx={22}
                      onColorChange={(val) => { setCanvas({ ...canvas, color: val }) }}
                    />
                  </div>

                </div>
                <div className='item'>
                  <div>单位</div>
                  <div>像素</div>
                  {/* <SelectS values={['像素']}></SelectS> */}
                </div>
                <div className='item'>
                  <div>无线画布</div>
                  <Switch
                    checked={true} // canvas.infinite
                    disabled={true}
                  // onChange={() => { setCanvas({ ...canvas, infinite: !canvas.infinite }) }}
                  />
                </div>
              </>
            </Expand>

            {/* <Expand title='页' disabled={disabled}>
              <>
                <div className='item'>
                  <div>页面大小</div>
                  <PageSizeSelect selectedType={page.type} onTypeChanged={(size) => { setPage({ ...page, size }) }} />
                </div>
                <div className='item'>
                  <div>自动平铺</div>
                  <Switch
                    checked={page.auto}
                    onChange={() => { setPage({ ...page, auto: !page.auto }) }}
                  />
                </div>
                <div className='item'>
                  <div>显示页面平铺</div>
                  <Switch
                    checked={page.tiling}
                    onChange={() => { setPage({ ...page, tiling: !page.tiling }) }}
                  />
                </div>
              </>
            </Expand> */}

            <Expand title='网格' showBottomBorder={false}>
              <>
                <div className='item'>
                  <div>显示网格</div>
                  <Switch
                    checked={grid.isShow}
                    onChange={() => { setGrid({ ...grid, isShow: !grid.isShow }) }}
                  />
                </div>
                <div className='item'>
                  <div>对齐网络</div>
                  <Switch
                    checked={grid.align}
                    onChange={() => { setGrid({ ...grid, align: !grid.align }) }}
                  />
                </div>
                <div className='item'>
                  <div>网格大小</div>
                  <NumberEditor
                    hasBorder={true}
                    value={grid.size}
                    max={100}
                    unit='pt'
                    onValueChanged={(newVal) => { setGrid({ ...grid, size: newVal }) }} />
                </div>
              </>
            </Expand>
          </CssWrapper>
        </CustomDialogDraggable>
      )}
    </>
  )
}

PageSetting.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  canvas: PropTypes.any,
  setCanvas: PropTypes.func,
  page: PropTypes.any,
  setPage: PropTypes.any,
  grid: PropTypes.any,
  setGrid: PropTypes.func,
  disabled: PropTypes.bool
}

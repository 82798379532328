/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react'
import { MenuCssWrapper, SmallCss } from './style'
import { useBoardContext } from 'views/board/hooks'
import Helper from 'utils/helper'
import { connect } from 'react-redux'

const isMac = Helper.isMac()
const key = isMac ? '⌘' : 'Ctrl'
const list = [
  {
    ch: '复制',
    en: `${key} + C`,
    active: 'copyTopic'
  },
  {
    ch: '粘贴',
    en: `${key} + V`,
    active: 'pasteTopic'
  },
  {
    ch: '剪切',
    en: `${key} + X`,
    active: 'shearTopic'
  },
  {
    ch: '删除',
    en: 'Delete',
    active: 'deleteTopic'
  },
  // {
  //   ch: '设置默认样式',
  //   en: '',
  //   active: ''
  // },
  {
    ch: '锁定/解锁',
    en: '▷',
    active: 'lockNode'
  },
  {
    ch: '组合',
    en: `${key} + G`,
    active: ''
  },
  {
    ch: '取消组合',
    en: `shift + ${key} + G`,
    active: ''
  // },
  // {
  //   ch: '移至顶层',
  //   en: '',
  //   active: ''
  // },
  // {
  //   ch: '移至底层',
  //   en: '',
  //   active: ''
  // }
  }]
const list2 = [
  {
    ch: '粘贴',
    en: `${key} + V`,
    active: 'pasteTopic'
  }
  // ,
  // {
  //   ch: '设置页面',
  //   en: '',
  //   active: ''
  // }
]
const smalls = [
  {
    ch: '全部锁定',
    en: ``,
    value: 'all',
    active: ''
  },
  {
    ch: '锁定大小和位置',
    value: 'size_position',
    en: ``,
    active: ''
  },
  {
    ch: '锁定样式',
    en: ``,
    value: 'style',
    active: ''
  },
  {
    ch: '锁定内容',
    en: ``,
    value: 'content',
    active: ''
  }
]

function Index(props) {
  const menuDom = useRef(null)
  const [coord, setsx] = useState({ x: null, y: null })
  const { board } = useBoardContext()
  const [small, setSmall] = useState(smalls)
  const [isMouse, setIsMouse ] = useState(false)
  const [isClipboardEmpty, setIsClipboardEmpty] = useState(true)
  const [initStyle, setInitStyle] = useState({
    'position': 'absolute', 'top': '-9990em', 'left': '-9990em'
  })
  const [cssStyle, setCssStyle] = useState({
    'position': 'absolute', 'top': '-9990em', 'left': '-9990em'
  })
  const { activeNode } = props.selectObject

  function Menu(props) {
    const { isClipboardEmpty, data } = props
    const isSingleSelect = useMemo(() => {
      return board?.selector?.selected?.length === 1
    }, [(board?.selector?.selected || []).length])
    const isAllSeletedInOneGroup = useMemo(() => {
      let allInGroup = false
      const selectedNodes = board?.selector?.selected
      if (selectedNodes) {
        const allNodeHasGroupId = selectedNodes.every(node => !!node.groupId)
        if (allNodeHasGroupId) {
          const groupIds = selectedNodes.map(node => node.groupId)
          allInGroup = [...new Set(groupIds)].length === 1
        }
      }
      return allInGroup
    }, [(board?.selector?.selected || []).length])
    return (
      <div>
        {
          data.map((it, ind) => {
            switch (it.ch) {
              case '粘贴':
                return <div key={ind} className={`${isClipboardEmpty ? 'empty-item' : 'item'}`} onClick={e => props.onActive(it, e)} onMouseEnter={ e => props.onHover(it)}>
                  <span className={`${isClipboardEmpty ? 'empty-item-ch' : 'item-ch'}`}>{it.ch}</span>
                  <span className={`${isClipboardEmpty ? 'empty-item-en' : 'item-en'}`}>{it.en}</span>
                </div>
              case '组合':
                return <div key={ind} style={{ display: isAllSeletedInOneGroup ? 'none' : '' }} className={`${isSingleSelect ? 'empty-item' : 'item'}`} onClick={e => !isSingleSelect && props.onActive(it, e)} onMouseEnter={ e => props.onHover(it)}>
                  <span className={`${isSingleSelect ? 'empty-item-ch' : 'item-ch'}`}>{it.ch}</span>
                  <span className={`${isSingleSelect ? 'empty-item-en' : 'item-en'}`}>{it.en}</span>
                </div>
              case '取消组合':
                return <div key={ind} style={{ display: !isAllSeletedInOneGroup ? 'none' : '' }} className='item' onClick={e => props.onActive(it, e)} onMouseEnter={ e => props.onHover(it)}>
                  <span className='item-ch'>{it.ch}</span>
                  <span className='item-en'>{it.en}</span>
                </div>
              default:
                return <div key={ind} className='item' onClick={e => props.onActive(it, e)} onMouseEnter={ e => props.onHover(it)} >
                  <span className='item-ch'>{it.ch}</span>
                  <span className='item-en'>{it.en}</span>
                </div>
            }
          })
        }
      </div>
    )
  }
  function Small(props) {
    return (
      <div onMouseLeave={ e => props.onHovers()}>
        {
          props.data.map((it, ind) => <div key={ind} className='items' onClick= {e => props.onLock(it)} >
            <span className='items-ch'>{it.ch}</span>
            <span className='items-en'>{it.en}</span>
          </div>)
        }
      </div>
    )
  }
  useEffect(_ => {
    menuDom.current.oncontextmenu = function(ev) {
      ev.stopPropagation()
      ev.preventDefault() // TODO: 使用 oncontextmenu(x, y) 回调出去，调用 Menu 菜单
    }
    if (board) {
      board.registerMenu && board.registerMenu({
        locate(x, y, type) {
          setsx(() => {
            return { x, y }
          })
          if (type === 'node') {
            setIsMouse(true)
            setInitStyle({ ...initStyle, top: y, left: x })
          } else {
            Helper.clipboardRead().then(list => {
              setIsClipboardEmpty(!list.length && !board.copiedNodes.length)
              setIsMouse(false)
              setInitStyle({ ...initStyle, top: y, left: x })
            })
          }
        },
        hide() {
          setInitStyle({ ...initStyle })
          setCssStyle({ ...cssStyle })
        }
      })
    }
  }, [board])
  useEffect(() => {
    const { funcLock: { lockType }} = activeNode
    const copySmall = []
    if (Object.keys(lockType).length > 0) {
      Object.keys(lockType).forEach(key => {
        small.forEach(item => {
          if (item.value === key) {
            let en = ''
            lockType[key] ? en = '√' : ''
            copySmall.push({
              ...item,
              en
            })
          }
        })
      })
      setSmall(copySmall)
    }
  }, [activeNode])
  const onHover = (it) => {
    if (it.ch !== '锁定/解锁') {
      setCssStyle({
        ...cssStyle,
        top: '-9990em',
        left: '-9990em'
      })
    } else {
      setCssStyle({
        ...cssStyle,
        top: (coord.y + 135) + 'px',
        left: (coord.x + 203) + 'px'
      })
      setInitStyle({ ...initStyle })
    }
  }
  const onHovers = () => {
    setCssStyle({
      ...cssStyle,
      top: '-9990em',
      left: '-9990em'
    })
  }
  const onActive = useCallback((it, ...args) => {
    it.value !== void 0 && args.unshift(it.value)
    board.api[it.active] && board.api[it.active](...args)
    if (it.ch) {
      setCssStyle({ ...cssStyle })
      setInitStyle({ ...initStyle })
    }
    if (it.ch === '组合') {
      board.operateGroup.setGroup()
    }
    if (it.ch === '取消组合') {
      board.operateGroup.disaggregateGroup()
    }
  }, [board, menuDom, coord])

  const onLock = (it) => {
    let newArray = []
    if (it.ch === '全部锁定') {
      if (it.en === '√') {
        const arr = small.map(item => {
          return {
            ...item,
            en: ''
          }
        })
        newArray = arr
        setSmall(arr)
      } else {
        const arr = small.map(item => {
          return {
            ...item,
            en: '√'
          }
        })
        newArray = arr
        setSmall(arr)
      }
    } else {
      if (it.en === '√') {
        const arr = small.map((item, ind) => {
          if (item.ch === it.ch) {
            return {
              ...item,
              en: ''
            }
          }
          return item
        })
        const arrs = arr.filter(v => v.ch !== '全部锁定').filter(v => v.en === '√')
        if (arrs.length === 3) {
          arr[0].en = '√'
        } else {
          arr[0].en = ''
        }
        newArray = arr
        setSmall(arr)
      } else {
        const arr = small.map(item => {
          if (item.ch === it.ch) {
            return {
              ...item,
              en: '√'
            }
          }
          return item
        })
        const arrs = arr.filter(v => v.ch !== '全部锁定').filter(v => v.en === '√')
        if (arrs.length === 3) {
          arr[0].en = '√'
        } else {
          arr[0].en = ''
        }
        newArray = arr
        setSmall(arr)
      }
    }
    const param = {}
    newArray.forEach(item => {
      param[item.value] = item.en === '√'
    })
    board.api.changeNodeLock(param)
  }
  return (
    <div>
      <MenuCssWrapper ref={menuDom} style={initStyle} id='mouse-menu'>
        <Menu selectObject onActive={onActive} onHover={onHover} isClipboardEmpty={isClipboardEmpty} data={isMouse ? list : list2}/>
      </MenuCssWrapper>
      <SmallCss style={cssStyle}>
        <Small onLock={onLock} onHovers={onHovers} data={small}></Small>
      </SmallCss>
    </div >
  )
}
const mapStateToProps = state => {
  return {
    selectObject: state.selectObject
  }
}
export default connect(mapStateToProps)(Index)

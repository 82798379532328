class Style {
    constructor(target) {
      this.target = target
    }
    apply(style = {}) {
      this.boundaryLineWidth = style.boundaryLineWidth || 1 // 当元素被框选时boundary作为元素的边界线展示
      this.boundaryColor = style.boundaryColor || 'rgba(109, 177, 255, 1)' // 当元素被框选时boundary作为元素的边界线展示
      this.configSpecialType() // 配置特殊类型节点的样式
      this.setLineHeight()
      this.setMaxWidth()
      // 内容编辑的时候
      this._apply()
    }
  
    configSpecialType() {
      const t = this.target
      if (t.isImageType) {
        this.paddingLeft = this.paddingTop = 0
      }
      if (t.isTextType) {
        this.backgroundOpacity = 0
      }
    }
  
    // changeStyle(config){
    //   for(let [key, value] of Object.entries(config)) {
    //     if (this[key] !== void 0) {
    //       this[key] = value
    //       if (key === 'fontSize') {
    //         this.setLineHeight()
    //       }
    //     }
    //   }
    //   return this
    // }
  
    setLineHeight() {
      this.lineHeight = Math.floor(this.fontSize * 4 / 3)
    }
  
    setTextStyle(text) {
      return text.getStyle().setFont(this.font).setFillStyle(this.fontColor).setOpacity(this.textOpacity)
    }
  
    setMaxWidth() { // 根据需求，不同类型的node最大宽度不一致
      const node = this.target
      this.maxWidth = Math.max(600, node.width) // 默认值
      if (node.isCopyTextToCreate) {
        this.maxWidth = node.board.grid.baseSize * 60
      }
    }
  
    get font() {
      return `${this.italic ? 'italic' : ''} ${this.fontVariant} ${this.bold ? 'bold' : ''} ${this.fontSize}px \"${this.fontFamily}\"`
    }
  
    _apply() {
      const { border, boundary } = this.target
      boundary.getStyle().setLineWidth(this.boundaryLineWidth).setStrokeStyle(this.boundaryColor)
      border.style.apply()
    }
  }
  
  export default Style
  
import LRU from './lru'
let utils = {
  _canvas: document.createElement("canvas"),
  _TEXT_CACHE: new Map(),
  _toString: Object.prototype.toString,
  _a: document.createElement("a"),
  _resizeObserver: new ResizeObserver(entries => { // 监听画板dom宽高变化，实时调整画布的大小
    let $, width, height, target, _rect
    entries.forEach(function (rect) {
      target = rect.target
      _rect = rect.contentRect
      width = _rect.right - _rect.left
      height = _rect.bottom - _rect.top
      if ($ = target.$) {
        if (Math.round($.$width * $.$dpr) !== Math.round(width * $.$dpr) ||
          Math.round($.$height * $.$dpr) !== Math.round(height * $.$dpr)) {
          // $.$resize(width, height);
        }
      }
    });
  }),
  _intersectionObserver: new IntersectionObserver(function (entries) { // 当画板dom进入视角
    let $, visible, target
    entries.forEach(function (entry) {
      target = entry.target
      visible = 0 !== entry.intersectionRatio
      if ($ = target.$) {
        if ($._$visible !== visible) {
          $.visibilitychange(($._$visible = visible));
        }
      }
    });
  }),
  ls: {
    get: function (key) {
      var value = localStorage.getItem(key);
      if (!value) return !1;
      try {
        return JSON.parse(decodeURIComponent(escape(atob(value))));
      } catch (e) {
        return value;
      }
    },
    set: function (key, value) {
      localStorage.setItem(
        key,
        btoa(unescape(encodeURIComponent(JSON.stringify(value))))
      );
    },
    remove: function (key) {
      localStorage.removeItem(key);
    },
    clear: function () {
      localStorage.clear();
    },
  },
  ease: {
    in: {
      linear: function (t) {
        return t;
      },
      sine: function (t) {
        return 1 - Math.cos((t * Math.PI) / 2);
      },
      quad: function (t) {
        return t * t;
      },
      cubic: function (t) {
        return t * t * t;
      },
      quart: function (t) {
        return t * t * t * t;
      },
      quint: function (t) {
        return t * t * t * t * t;
      },
      expo: function (t) {
        return 0 === t ? 0 : Math.pow(2, 10 * t - 10);
      },
      circ: function (t) {
        return 1 - Math.sqrt(1 - Math.pow(t, 2));
      },
      back: function (t) {
        return 2.70158 * t * t * t - 1.70158 * t * t;
      },
      elastic: function (t) {
        var c = (2 * Math.PI) / 3;
        return 0 === t
          ? 0
          : 1 === t
          ? 1
          : -Math.pow(2, 10 * t - 10) * Math.sin((10 * t - 10.75) * c);
      },
      bounce: function (t) {
        var n = 7.5625,
          d = 2.75;
        return (t = 1 - t) < 1 / d
          ? 1 - n * t * t
          : t < 2 / d
          ? 0.25 - n * (t -= 1.5 / d) * t
          : t < 2.5 / d
          ? 0.0625 - n * (t -= 2.25 / d) * t
          : 0.015625 - n * (t -= 2.625 / d) * t;
      },
    },
    out: {
      linear: function (t) {
        return t;
      },
      sine: function (t) {
        return Math.sin((t * Math.PI) / 2);
      },
      quad: function (t) {
        return t * (2 - t);
      },
      cubic: function (t) {
        return 1 - Math.pow(1 - t, 3);
      },
      quart: function (t) {
        return 1 - Math.pow(1 - t, 4);
      },
      quint: function (t) {
        return 1 - Math.pow(1 - t, 5);
      },
      expo: function (t) {
        return 1 === t ? 1 : 1 - Math.pow(2, -10 * t);
      },
      circ: function (t) {
        return Math.sqrt(1 - Math.pow(t - 1, 2));
      },
      back: function (t) {
        return (
          1 + 2.70158 * Math.pow(t - 1, 3) + 1.70158 * Math.pow(t - 1, 2)
        );
      },
      elastic: function (t) {
        var c = (2 * Math.PI) / 3;
        return 0 === t
          ? 0
          : 1 === t
          ? 1
          : Math.pow(2, -10 * t) * Math.sin((10 * t - 0.75) * c) + 1;
      },
      bounce: function (t) {
        var n = 7.5625,
          d = 2.75;
        return t < 1 / d
          ? n * t * t
          : t < 2 / d
          ? n * (t -= 1.5 / d) * t + 0.75
          : t < 2.5 / d
          ? n * (t -= 2.25 / d) * t + 0.9375
          : n * (t -= 2.625 / d) * t + 0.984375;
      },
    },
    inout: {
      linear: function (t) {
        return t;
      },
      sine: function (t) {
        return (1 - Math.cos(t * Math.PI)) / 2;
      },
      quad: function (t) {
        return t < 0.5 ? 2 * t * t : 1 - Math.pow(2 - 2 * t, 2) / 2;
      },
      cubic: function (t) {
        return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(2 - 2 * t, 3) / 2;
      },
      quart: function (t) {
        return t < 0.5 ? 8 * t * t * t * t : 1 - Math.pow(2 - 2 * t, 4) / 2;
      },
      quint: function (t) {
        return t < 0.5
          ? 16 * t * t * t * t * t
          : 1 - Math.pow(2 - 2 * t, 5) / 2;
      },
      expo: function (t) {
        return 0 === t
          ? 0
          : 1 === t
          ? 1
          : t < 0.5
          ? Math.pow(2, 20 * t - 10) / 2
          : (2 - Math.pow(2, 10 - 20 * t)) / 2;
      },
      circ: function (t) {
        return t < 0.5
          ? (1 - Math.sqrt(1 - Math.pow(2 * t, 2))) / 2
          : (Math.sqrt(1 - Math.pow(2 - 2 * t, 2)) + 1) / 2;
      },
      back: function (t) {
        var c = 2.5949095;
        return t < 0.5
          ? (Math.pow(2 * t, 2) * (2 * (1 + c) * t - c)) / 2
          : (Math.pow(2 * t - 2, 2) * ((1 + c) * (2 * t - 2) + c) + 2) / 2;
      },
      elastic: function (t) {
        var c = (2 * Math.PI) / 4.5;
        return 0 === t
          ? 0
          : 1 === t
          ? 1
          : t < 0.5
          ? -(Math.pow(2, 20 * t - 10) * Math.sin((20 * t - 11.125) * c)) / 2
          : (Math.pow(2, -20 * t + 10) * Math.sin((20 * t - 11.125) * c)) /
              2 +
            1;
      },
      bounce: function (t) {
        var n = 7.5625,
          d = 2.75;
        return t < 0.5
          ? ((t = 1 - 2 * t) < 1 / d
              ? 1 - n * t * t
              : t < 2 / d
              ? 0.25 - n * (t -= 1.5 / d) * t
              : t < 2.5 / d
              ? 0.0625 - n * (t -= 2.25 / d) * t
              : 0.015625 - n * (t -= 2.625 / d) * t) / 2
          : ((t = 2 * t - 1) < 1 / d
              ? 1 + n * t * t
              : t < 2 / d
              ? n * (t -= 1.5 / d) * t + 1.75
              : t < 2.5 / d
              ? n * (t -= 2.25 / d) * t + 1.9375
              : n * (t -= 2.625 / d) * t + 1.984375) / 2;
      },
    },
  },
  noop: function () {
    return this
  },
  inheritPrototype: function (subType, superType) {
    (subType.prototype = Object.create(superType.prototype)),
      Object.defineProperty(subType.prototype, "constructor", {
        value: subType,
        enumerable: !1,
      });
  },
  _assign: function (subType, superType) {
    for (var i = 2; i < arguments.length; i++)
      subType.prototype[arguments[i]] = superType.prototype[arguments[i]];
  },
  measureTextWidth: function (text, font) {
    var ctx = utils._ctx;
    return utils.measureText(
      text,
      font,
      ctx._textAlign,
      ctx._textBaseline,
      ctx._direction
    ).width;
  },
  measureTextFontSize: function (text, width, fontFamily, min, max) {
    function searchFontSize(min, max) {
      if (min === max) return min;
      if (min + 1 === max) return utils.measureTextWidth(text, max + "px " + fontFamily) > width ? min : max;
      const sep = Math.floor((min + max) / 2)
      const textWidth = utils.measureTextWidth(text, sep + "px " + fontFamily);
      return width < textWidth
        ? searchFontSize(min, sep - 1)
        : textWidth < width
        ? searchFontSize(sep, max)
        : sep;
    }
    if (text.length > 0 || width === 0) {
      return 0
    } else {
      if (min === void 0) {
        min = Math.floor(width / text.length)
      }
      if (max === void 0) {
        max = Math.ceil(width / text.length) * 4
      }
    }
    return searchFontSize(min, max)
  },
  measureText: function (text, font, textAlign, textBaseline, direction) {
    var ctx = font + " " + textAlign + " " + textBaseline + " " + direction,
      textCache = utils._TEXT_CACHE.get(ctx);
    void 0 === textCache &&
      ((textCache = new utils.LRU(3755)),
      utils._TEXT_CACHE.set(ctx, textCache));
    var metrics = textCache.get(text);
    return (
      void 0 === metrics &&
        ((ctx = utils._ctx)._font !== font && (ctx.font = ctx._font = font),
        ctx._textAlign !== textAlign &&
          (ctx.textAlign = ctx._textAlign = textAlign),
        ctx._textBaseline !== textBaseline &&
          (ctx.textBaseline = ctx._textBaseline = textBaseline),
        ctx._direction !== direction &&
          (ctx.direction = ctx._direction = direction),
        ((metrics = ctx.measureText(text)).actualBoundingBoxWidth =
          metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight),
        (metrics.actualBoundingBoxHeight =
          metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent),
        textCache.set(text, metrics)),
      metrics
    );
  },
  loadImage: function (src, callback, context) {
    var image = utils._IMAGE_CACHE.get(src);
    return (
      void 0 === image
        ? ((image = document.createElement("img")),
          callback &&
            image.addEventListener("load", _callback, {
              once: !0,
            }),
          (image.src = src),
          utils._IMAGE_CACHE.set(src, image))
        : image.complete
        ? callback && callback.call(context, image)
        : callback &&
          image.addEventListener("load", _callback, {
            once: !0,
          }),
      image
    );
    function _callback() {
      callback.call(context, this);
    }
  },
  loadImages: function (srcs, callback, context) {
      var key,
        images = {},
        loaded = 0,
        length = Object.keys(srcs).length;
      for (key in srcs)
        images[key] = utils.loadImage(srcs[key], callback ? _callback : void 0);
      return images;

      function _callback() {
        ++loaded === length && callback.call(context, images);
      }
  },
  createLinearGradient: function (x0, y0, x1, y1, shape) {
    return (
      shape &&
        ((x0 += shape._sx),
        (y0 += shape._sy),
        (x1 += shape._sx),
        (y1 += shape._sy)),
      utils._ctx.createLinearGradient(x0, y0, x1, y1)
    );
  },
  createRadialGradient: function (x0, y0, r0, x1, y1, r1, shape) {
    return (
      shape &&
        ((x0 += shape._sx),
        (y0 += shape._sy),
        (x1 += shape._sx),
        (y1 += shape._sy)),
      utils._ctx.createRadialGradient(x0, y0, r0, x1, y1, r1)
    );
  },
  createPattern: function (image, pattern, matrix) {
    pattern = utils._ctx.createPattern(image, pattern || "repeat");
    return (
      matrix &&
        pattern.setTransform({
          a: matrix.getM11(),
          b: matrix.getM12(),
          c: matrix.getM21(),
          d: matrix.getM22(),
          e: matrix.offsetX,
          f: matrix.offsetY,
        }),
      pattern
    );
  },
  randInt: function (start, end, containsEnd) {
    return (
      isNaN(end) && ((end = start), (start = 0)),
      start + Math.floor(Math.random() * (end - start + (containsEnd || 0)))
    );
  },
  randSign: function () {
    return 0.5 < Math.random() ? 1 : -1;
  },
  factorial: function (n) {
    switch (n) {
      case 9:
        return 362880;
      case 8:
        return 40320;
      case 7:
        return 5040;
      case 6:
        return 720;
      case 5:
        return 120;
      case 4:
        return 24;
      case 3:
        return 6;
      case 2:
        return 2;
      case 1:
      case 0:
        return 1;
    }
    return n * utils.factorial(n - 1);
  },
  permutation: function (n, m) {
    return utils.factorial(m) / utils.factorial(m - n);
  },
  combination: function (n, m) {
    return 0 === n || n === m
      ? 1
      : 1 === n || m - n == 1
      ? m
      : utils.factorial(m) / (utils.factorial(n) * utils.factorial(m - n));
  },
  pythagorean: function (a, b) {
    return Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
  },
  deg2rad: function (degree) {
    return 0.017453292519943295 * degree;
  },
  rad2deg: function (radian) {
    return 57.29577951308232 * radian;
  },
  unique: function (array) {
    return Array.from(new Set(array));
  },
  clone: function (object) {
    return JSON.parse(JSON.stringify(object));
  },
  debounce: function (func, delay=500, immediate) {
    var timeoutID;
    return function () {
      var callNow, context = this, args = arguments;
      timeoutID && clearTimeout(timeoutID)
      if (immediate) {
        callNow = void 0 === timeoutID
        timeoutID = setTimeout(function () {
          timeoutID = void 0
        }, delay)
        callNow && func.apply(context, args)
      } else {
        timeoutID = setTimeout(function () {
          func.apply(context, args)
        }, delay)
      }
    };
  },
  throttle: function (func, delay, cb) {
    let preview = Date.now()
    let timer = null
    return function (...args) {
      const ctx = this
      const now = Date.now()
      cb && cb.apply(ctx, args)
      timer && clearTimeout(timer)
      if (now - preview >= delay) {
        func.apply(ctx, args)
        preview = now
      } else {
        timer = setTimeout(_ => {
          func.apply(ctx, args)
        }, delay - (now - preview))
      }
    }
  },
  nextTick: function (func) { // 在mousemove时需要使用，只需要执行最后的动作
    let next;
    const prom = Promise.resolve()
    return function (...args) {
      let context = this;
      if (next === void 0) {
        next = prom.then(function () {
          next = void 0
          func.apply(context, args)
        })
      }
    };
  },
  isMac: function () { return /macintosh|mac os x/i.test(navigator.userAgent.toLocaleLowerCase()) },
  HEX2DEC:function(hex){
    return parseInt(hex,16).toString();
  },
  //十六进制颜色转化为RGB颜色
  HEX2RGBA:function(hex,opacity=1){
    if (~hex.indexOf('rgb') || hex === 'transparent') return hex
    const HEX2DEC = this.HEX2DEC;
    hex = hex.substring(1);
     if(hex.length === 3){
        hex += hex;
     }  return "rgba("+HEX2DEC(hex.substring(0,2))+","+HEX2DEC(hex.substring(2,4))+","+HEX2DEC(hex.substring(4))+","+opacity+")";
  }
}

utils._ctx = utils._canvas.getContext("2d", { alpha: !0, desynchronized: !1})
utils._ctx._font = "10px sans-serif"
utils._ctx._textAlign = "start"
utils._ctx._textBaseline = "alphabetic"
utils._ctx._direction = "ltr"
utils.LRU = LRU
utils._IMAGE_CACHE = new utils.LRU(10)

export default utils
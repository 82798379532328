import Shape from './index.js'

class Arc extends Shape {
  constructor(ctx, sx, sy, radius, startAngle, endAngle, anticlockwise, ox, oy) {
    super(ctx, sx, sy, ox, oy)
    this.setRadius(radius)
      .setStartAngle(isNaN(startAngle) ? 0 : startAngle)
      .setEndAngle(isNaN(endAngle) ? 2 * Math.PI : endAngle)
      .setAnticlockwise(!!anticlockwise)
  }
  process() {
    this.ctx.beginPath()
    this.ctx.arc(this._sx, this._sy, this.radius, this.startAngle, this.endAngle, this.anticlockwise)
  }
  _isQuickInPath(x, y) {
    return Math.sqrt(Math.pow(this._sx - x, 2) + Math.pow(this._sy - y, 2)) <= this.radius
  }
  _isQuickInStroke(x, y) {
    const a = (Math.sqrt(Math.pow(this._sx - x, 2) + Math.pow(this._sy - y, 2)) - this.radius) / this.sty.lineWidth
    return 0 === Math.round(a)
  }
  getRadius() {
    return this.radius
  }
  setRadius(radius) {
    this.radius = radius
    return this
  }
  getStartAngle() {
    return this.startAngle
  }
  setCircle() {
    this.setStartAngle(0).setEndAngle(2*Math.PI)
    return this
  }
  setStartAngle(startAngle) {
    return (this.startAngle = startAngle), this
  }
  getEndAngle() {
    return this.endAngle
  }
  setEndAngle(endAngle) {
    return (this.endAngle = endAngle), this
  }
  getAnticlockwise() {
    return this.anticlockwise
  }
  setAnticlockwise(anticlockwise) {
    this.anticlockwise = anticlockwise
    return this
  }
}

export default Arc

/*
  功能： 画布Grid设置
 */
import Grid from "./grid";
import GridBorder from "../shapes/rect";

const MMToPXMultiplier = 3.779528

const getSV = (v) => Math.floor(v * MMToPXMultiplier)

class GridPage {
  constructor(ctx, board, gridConfig) {
    this.board = board
    this.resize()
    const cw = getSV(board.$width)
    const ch = getSV(board.$height)
    const {width, height, sx: _sx, sy: _sy} = this._getInfiniteSize(cw, ch)
    this.name = 'gridPage'
    this.ctx = ctx
    this.grid = new Grid(ctx, _sx, _sy, width, height, null, null, gridConfig)
    this.gridBorder = new GridBorder(ctx, _sx, _sy, width, height)
  }

  apply() { // 初始化设置node相关属性位置
    [this.grid, this.gridBorder].forEach(g => {
      const { sx, sy } = g
      g.setStart(sx, sy)
    })
  }

  resize() {
    const {canvas, renderLayer} = this
    canvas.style.width = renderLayer.style.width
    canvas.style.height = renderLayer.style.height
    canvas.width = renderLayer.width
    canvas.height = renderLayer.height
  }

  clear() {
    const {sx, sy, width, height} = this.grid
    this.ctx.clearRect(sx || 0, sy || 0, width, height)
  }

  draw() {
    this.ctx.save()
    this.grid.fill()
    this.ctx.globalAlpha = 0
    this.gridBorder.stroke()
    this.ctx.restore()
  }

  getGridSize() {
    return this.grid.gridSize
  }

  onscale() {
    const { scale, offsetX, offsetY } = this.transform
    this.ctx.setTransform(scale, 0, 0, scale, offsetX, offsetY)
    const oldWidth = this.grid.initLineWidth
    const newWidth = this.board.getDrawLength(oldWidth)
    const w = newWidth > 1 ? 1 :newWidth
    this.grid.updateLineWidth(w)
    this.draw()
  }

  // 如果开启吸附,就返回吸附的值,否则返回原来的值
  getGridSnapPos(expectPos) {
    const { sx, sy, gridSize, showGrid, isAlign } = this.grid

    if (!isAlign || !showGrid) {   // 是否考虑吸附grid
      return expectPos
    }

    const lx = expectPos.x
    const ty = expectPos.y
    const x = lx - ((lx - sx + gridSize / 2) % gridSize) + gridSize / 2
    const y = ty - ((ty - sy + gridSize / 2) % gridSize) + gridSize / 2

    return { x, y }
  }

  getHalfGridSnapPos(expectPos) {
    const { sx, sy, gridSize, showGrid, isAlign } = this.grid
    const halfS = gridSize / 2

    if (!isAlign || !showGrid) {   // 是否考虑吸附grid
      return expectPos
    }

    const lx = expectPos.x
    const ty = expectPos.y
    const x = lx - ((lx - sx + halfS / 2) % halfS) + halfS / 2
    const y = ty - ((ty - sy + halfS / 2) % halfS) + halfS / 2

    return { x, y }
  }

  getGridStartPos() {
    const { sx, sy } = this.grid
    return { x: sx, y: sy }
  }

  getAlignConfig() {
    const { showGrid, isAlign, isAlignNode } = this.grid
    return { isAlignGrid: isAlign && showGrid, isAlignNode }
  }

  updateGridSetting(newStatus) {
    const { isShow, size, align, alignNode } = newStatus
    this.grid.updateIsAlign(align)
    this.grid.updateIsAlignNode(alignNode)
    this.grid.updateShowGrid(isShow)
    this.grid.updateGridSize(size)
  }

  updatePageSize(width, height) {
    const w = getSV(width)
    const h = getSV(height)

    const arr = [this.grid, this.gridBorder]

    arr.forEach(g => {
      g.setWidth(w)
      g.setHeight(h)
    })
  }

  updateGridBorderSize(width, height, sx, sy) {
    this.gridBorder.setWidth(width)
    this.gridBorder.setHeight(height)
    this.gridBorder.setStart(sx, sy)

    this.gridBorder._x = sx
    this.gridBorder._y = sy
  }

  _getInfiniteSize(width, height) {
    const bigMulti = 8
    const _width = width * bigMulti
    const _height = height * bigMulti

    const sx = - 0.4 * _width;
    const sy = - 0.4 * _height;
    return {
      width: _width,
      height: _height,
      sx,
      sy
    }
  }


  beforeOffset() { // 使用_x, _y 记录下偏移前的初始值
    const arr = [this.grid, this.gridBorder]
    arr.forEach(g => {
      g._x = g.sx
      g._y = g.sy
    })
  }

  offset(offsetX, offsetY) {
    const arr = [this.grid, this.gridBorder]
    arr.forEach(g => {
      g.sx += offsetX
      g.sy += offsetY
    })
    this.draw()
  }

  get transform () {
    return this.board.transform
  }
  get canvas () {
    return this.board.$gridCanvas
  }

  get renderLayer() {
    return this.board.$canvas
  }
}

export default GridPage;

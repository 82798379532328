/*
 绘制五边形 
 */
 import Process from './process.js'
 class Pentagon extends Process {
   constructor(ctx, sx, sy, width, height, ox, oy) {
     super(ctx, sx, sy, ox, oy)
     this.setWidth(width).setHeight(height)
   }
   initial(sx, sy, ox, oy) { // 初始化画布
     return this.init(sx, sy, ox, oy)._horizontal()._vertical()
   }
   process() { // 五边形
     const base=this.width/4;
     const dip=this.width/4*3;
     const heightY=this.height/9*4
     this.ctx.beginPath();
    this.ctx.moveTo(this.halfX,this._bottom);
    this.ctx.arcTo(this._right-dip,this._bottom,this._left,this._top+heightY,3)
    this.ctx.arcTo(this._right-dip,this._bottom,this._left,this.top+heightY,3);
    this.ctx.arcTo(this._left,this._top+heightY,this.halfX,this._top,3);
    this.ctx.arcTo(this.halfX,this._top,this._right,this._top+heightY,3);
    this.ctx.arcTo(this._right,this._top+heightY,this._right-base,this._bottom,3);
    this.ctx.arcTo(this._right-base,this._bottom,this._right-dip,this._bottom,3);
    this.ctx.lineTo(this.halfX,this._bottom)
   }
 }

 export default Pentagon
/* eslint-disable react/prop-types */
import React from 'react'
import IconButtonNew from 'components/comm/IconButtonNew'
import withMoreColorPick2 from 'components/comm/hoc/withMoreColorPick2'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const IconButtonWithColorPick = withMoreColorPick2(IconButtonNew)

const CssWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .colorBlock {
    height: 24px;
    width: 100px;
    position: absolute;
    z-index:0;
  }

  .noFillBlock{
    height: 24px;
    width: 100px;
    position: absolute;
    z-index:0;
  }

  .noFillBlock:hover{
    cursor:pointer;
  }
  .noFills{
    border-bottom: 1px solid red;
    transform: rotate(12deg);
    margin: 10px 0 0 1px;
  }
`

export function IconButtonWithColor2(props) {
  const { color } = props
  return (
    <CssWrapper>
      <IconButtonWithColorPick {...props}/>

      {color === 'transparent' ? <div className='noFillBlock'>
        <div className='noFills'></div>
      </div> : <div className='colorBlock' style={{ backgroundColor: props.color }}></div>}

    </CssWrapper>
  )
}

IconButtonWithColor2.propTypes = {
  color: PropTypes.string
}

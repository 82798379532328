/*
 * useForWhat? 连接线（折线类型）走线规则
 * @param {*} {start, end, first, last, isVerticalStart, isVerticalEnd}
 * start: 连接线开始点 end: 连接线结束点
 * first: 开始点连接的node last: 结束点连接的node
 * isVerticalStart: 开始点的箭头是否是垂直 isVerticalEnd：结束点的箭头是否是垂直的
 * @returns [] points：计算得出的中间点集合
 */
import Point from 'components/drawBoard/shapes/point'
class Compute {
  constructor(params) {
    this.params = params
    this.points = []
    this.action()
  }
  action() {
    const {start, end, first, last, isVerticalStart, isVerticalEnd} = this.params
    const gapX = Math.abs(end.x - start.x)
    const gapY = Math.abs(end.y - start.y)
    const cx = (end.x + start.x) / 2
    const cy = (end.y + start.y) / 2
    const corner1 = new Point(0, 0)
    const corner2 = new Point(0, 0)
    const corner3 = new Point(0, 0)
    const corner4 = new Point(0, 0)
    this.points.length = 0
    if (first) {
      const {x, right, bottom, y} = first
      let gx, gy, t;
      if (isVerticalStart) { // 起点竖向连接线
        // 正常情况起点箭头没有跨越起点 1: 由于靠近节点吸附和描边效果没有实现，为了实现连接更加容易，增加了1px 左右的模糊值
        if ((start.y > first.cy && end.y > first.bottom - 1) || (start.y < first.cy && end.y < first.y + 1)) {
          if (last) {
            if (isVerticalEnd) { // 终点箭头竖向
              // 终点箭头没有跨过last
              if ((start.y > first.cy && end.y < last.cy) || (start.y < first.cy && end.y > last.cy)) {
                corner1.setXY(start.x, cy)
                corner2.setXY(end.x, cy)
              } else { // 终点箭头跨过last
                gx = start.x > end.x ? last.x - 20 : last.right + 20
                gy = start.y > first.cy ? 20 : -20
                if (start.x > last.x - 20 && start.x < last.right + 20 && first !== last) {
                  corner1.setXY(start.x, start.y + gy)
                  corner2.setXY(gx, start.y + gy)
                  corner3.setXY(gx, end.y + gy)
                  corner4.setXY(end.x, end.y + gy)
                } else {
                  corner1.setXY(start.x, end.y + gy)
                  corner2.setXY(end.x, end.y + gy)
                }
              }
            } else { // 终点箭头横向
              // 终点箭头跨过last部分
              if ((end.x < last.cx && start.x < last.x) || (end.x > last.cx && start.x > last.right)) {
                corner1.setXY(start.x, end.y)
              } else { // 终点箭头没有跨过last
                gx = start.x < end.x ? 20 : -20
                gy = start.y < end.y ? 20 : -20
                corner1.setXY(start.x, start.y + gy)
                corner2.setXY(end.x + gx, start.y + gy)
                corner3.setXY(end.x + gx, end.y)
              }
            }
          } else {
            if (gapX < gapY) { // 正常连接，不跨越节点
              corner1.setXY(start.x, cy)
              corner2.setXY(end.x, cy)
            } else {
              corner1.setXY(start.x, end.y)
            }
          }
        } else { // 跨越节点 顶部的点向下连接or底部的点向上连接
          if(last) {
            if (isVerticalEnd) { // end竖向连接
              gy = start.y < first.cy ? -20 : 20
              if ((start.y >= first.cy && end.y > last.cy) || (start.y <= first.cy && end.y < last.cy)) {
                  // end 没有越过last
                gx = start.x > end.x ? first.right + 20 : first.x - 20
                if (end.x >= first.x - 30 && end.x <= first.right + 30) { // first 和 last y轴出现重叠
                  corner1.setXY(start.x, start.y + gy)
                  corner2.setXY(gx, start.y + gy)
                  corner3.setXY(gx, end.y + gy)
                  corner4.setXY(end.x, end.y + gy)
                } else { // 没有重叠的情况
                  corner1.setXY(start.x, start.y + gy)
                  corner2.setXY(end.x, start.y + gy)
                  corner3.isUsed = false
                  corner4.isUsed = false
                }
              } else { // end 越过last
                gx = start.x > end.x ? Math.min(first.x, last.x) - 20 : Math.max(first.right, last.right) + 20
                corner1.setXY(start.x, start.y + gy)
                if (first.right < last.x || last.right < first.x) {
                  corner2.setXY(cx, start.y + gy)
                  corner3.setXY(cx, end.y - gy)
                  corner4.setXY(end.x, end.y - gy)
                } else {
                  corner2.setXY(gx, start.y + gy)
                  corner3.setXY(gx, end.y - gy)
                  corner4.setXY(end.x, end.y - gy)
                }
              }
            } else { // end横向连接
              gy = start.y < first.cy ? -20 : 20
              if ((first.x > last.right && end.x > last.cx) || (last.x > first.right && end.x < last.cx)) {
                // first 和 last y轴没有重叠
                gx = end.x < last.cx ? last.x - 20 : last.right + 20
                corner1.setXY(start.x, start.y + gy)
                corner2.setXY(gx, start.y + gy)
                corner3.setXY(gx, end.y)
              } else {
                gx = end.x > last.cx ? Math.max(first.right, last.right) + 20 : Math.min(first.x, last.x) - 20
                corner1.setXY(start.x, start.y + gy)
                corner2.setXY(gx, start.y + gy)
                corner3.setXY(gx, end.y)
              }
            }
          } else {
            const gy = start.y < first.cy ? -30 : 30
            const gx = end.x > start.x ? -30 : 30
            corner1.setXY(start.x, start.y + gy)
            if (gapX < gapY) { // end竖向连接
              t = end.x >= cx ? right + 20 : x - 20
              corner2.setXY(t, start.y + gy)
              corner3.setXY(t, end.y + gy)
              corner4.setXY(end.x, end.y + gy)
            } else { // end横向
              corner2.setXY(end.x + gx, start.y + gy)
              corner3.setXY(end.x + gx, end.y)
            }
          }
        }
      } else { // 起点横向连接线
        // 正常连接 无跨越
        if ((start.x > first.cx && end.x > first.right - 1) || ( start.x < first.cx && end.x < first.x + 1)) {
          if (last) {
            if (isVerticalEnd) { // end 竖向连接
              if ((end.y > last.cy && end.y > start.y) || (end.y < last.cy && end.y < start.y)) { // end 跨越last
                gx = start.x > first.cx ? first.right + 20 : first.x - 20
                gy = end.y > last.cy ? last.bottom + 30 : last.y - 30
                // last 和 first 在x轴出现重叠
                corner1.setXY(gx, start.y)
                corner2.setXY(gx, gy)
                corner3.setXY(end.x, gy)
              } else { // end 没有跨越last
                corner1.setXY(end.x, start.y)
              }
            } else { // end 横向
              gx = start.x > first.cx ? 20 : -20
              if ((end.x > last.cx && start.x > first.cx) || (start.x < first.cx && end.x < last.cx)) { // end 跨越last
                gy = start.y > end.y ? last.bottom + 30 : last.y - 30
                if (start.y > last.y - 30 && start.y < last.bottom + 30 && first !== last) { // 节点横向并排
                  corner1.setXY(start.x + gx, start.y)
                  corner2.setXY(start.x + gx, gy)
                  corner3.setXY(end.x + gx, gy)
                  corner4.setXY(end.x + gx, end.y)
                } else {
                  corner1.setXY(end.x + gx, start.y)
                  corner2.setXY(end.x + gx, end.y)
                }
              } else { //end 没有跨越last
                corner1.setXY(cx, start.y)
                corner2.setXY(cx, end.y)
              }
            }
          } else {
            if (gapX > gapY) {
              corner1.setXY(cx, start.y)
              corner2.setXY(cx, end.y)
            } else {
              corner1.setXY(end.x, start.y)
            }
          }
        } else { // 跨越节点 左侧的点向右连接or右侧的点向左连接
          if (last) {
            gx = start.x > first.cx ? 20 : -20
            if (isVerticalEnd) { // end 箭头竖向
              gy = end.y > last.cy ? 20 : -20
              corner1.setXY(start.x + gx, start.y)
              corner2.setXY(start.x + gx, end.y + gy)
              corner3.setXY(end.x, end.y + gy)
            } else { // end 箭头横向
              if ((start.x < first.cx && end.x < last.cx) || (start.x > first.cx && end.x > last.cx)) {  // end 没有跨越
                gy = start.y > end.y ? first.y - 30 : first.bottom + 30
                if (end.y > first.y - 30 && end.y < first.bottom + 30) {
                  corner1.setXY(start.x + gx, start.y)
                  corner2.setXY(start.x + gx, gy)
                  corner3.setXY(end.x + gx, gy)
                  corner4.setXY(end.x + gx, end.y)
                } else {
                  corner1.setXY(start.x + gx, start.y)
                  corner2.setXY(start.x + gx, end.y)
                }
              } else { // end 跨越
                corner1.setXY(start.x + gx, start.y)
                if (first.y > last.bottom || last.y > first.bottom) {
                  corner2.setXY(start.x + gx, cy)
                  corner3.setXY(end.x - gx, cy)
                  corner4.setXY(end.x - gx, end.y)
                } else {
                  gy = start.y < end.y ? last.bottom + 20 : last.y - 20
                  corner2.setXY(start.x + gx, gy)
                  corner3.setXY(end.x - gx, gy)
                  corner4.setXY(end.x - gx, end.y)
                }
              }
            }
          } else {
            const gx = start.x < first.cx ? - 40 : 40
            const gy = end.y > start.y ? -30 : 30
            corner1.setXY(start.x + gx, start.y)
            if (gapX < gapY) { // 终点竖向连接
              corner2.setXY(start.x + gx, end.y + gy)
              corner3.setXY(end.x, end.y + gy)
            } else { // 终点横向连接
              if (end.y > y - 20 && end.y < bottom + 20) { // 绕过整个节点
                const t = end.y > cy ? bottom + 20 : y - 20
                corner2.setXY(start.x + gx, t)
                corner3.setXY(end.x + gx, t)
                corner4.setXY(end.x + gx, end.y)
              } else { // 绕过节点部分
                corner2.setXY(start.x + gx, end.y)
              }
            }
          }
        }
      }
    } else { // 连接线没有连接node的情况下
      if (gapX === 0 || gapY === 0) {
        this.points.length = 0
      } else {
        if (gapX < gapY) { // 箭头上下指向
          corner1.setXY(start.x, cy)
          corner2.setXY(end.x, cy)
        } else if(gapX >= gapY) { // 箭头左右指向
          corner1.setXY(cx, start.y)
          corner2.setXY(cx, end.y)
        }
      }
    }
    ;[corner1, corner2, corner3, corner4].forEach(corner => corner.isUsed && this.points.push(corner))
    // 去除多余点

    if (this.points.length === 2) {
      if (this.isInSameLine([start, ...this.points, end])) {
        this.points.length = 0
      }
    }
    this.setNearPointToLine([start, ...this.points, end])
  }
  setNearPointToLine(ps) {
    let pre, cur;
    for(let i = 1; i < ps.length; i++) {
      pre = ps[i - 1]
      cur = ps[i]
      for(let k of ['x', 'y']) { // 当两个点接近成为垂直线
        if (Math.abs(cur[k] - pre[k]) < 1) {
          cur[k] = pre[k]
        }
      }
    }
  }
  isInSameLine(points = []) { // 判断点是否在同一条直线上
    const start = points[0], second = points[1]
    const key = parseInt(start.x) === parseInt(second.x) ? 'x' : 'y'

    let flag = true
    for(let p of points) {
      if (parseInt(p[key]) !== parseInt(start[key])) {
        flag = false
        break
      }
    }

    return flag
  }
}

  export default Compute
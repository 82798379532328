import React from 'react'
import IconButton from 'components/comm/IconButton'
import { IconButtonWithColor2 } from 'components/comm/Buttons'
import { ToolsFrameStyle } from './style'
import PropTypes from 'prop-types'
import LineSetting from '../lineSetting'
import { DashLines } from '../config'

const ToolsFrame = (props) => {
  const { edition_seven_seven, backgroundColor, onChangeChecked, listChecked2, listChecked3, handleChangeStyle, lineColor, lineDash, lineWidth, noFill } = props
  return (
    <ToolsFrameStyle>
      <div className='frameTools'>
        <div className='shape'>
          <div className='iconBox'>
            <div className='icon' onClick={() => onChangeChecked(2)}>
              <IconButton
                name={listChecked2 ? 'topBtn' : 'bottomBtn'}
                showToolTip={false}
              />
            </div>
            <div className='name'>形状</div>
          </div>
          {edition_seven_seven && listChecked2 && <div className='btnBox'>
            <div className='name'>选择形状</div>
            <div className='num boxXZ'></div>
          </div>}
          {listChecked2 && <div className='btnBox'>
            <div className='name'>填充颜色</div>
            <div className='num boxXZ'>
              <IconButtonWithColor2
                name='fill-color'
                showToolTip={false}
                noFill={noFill}
                link='link'
                color={backgroundColor}
                widthInPx={24}
                onColorChange={(value) => handleChangeStyle({ backgroundColor: value })}
              />
            </div>
          </div>}
        </div>
        <div className='shape'>
          <div className='iconBox'>
            <div className='icon' onClick={() => onChangeChecked(3)}>
              <IconButton
                name={listChecked3 ? 'topBtn' : 'bottomBtn'}
                showToolTip={false}
              />
            </div>
            <div className='name'>边框</div>
          </div>
          {listChecked3 && <div className='btnBox'>
            <div className='name'>颜色</div>
            <div className='num boxXZ'>
              <IconButtonWithColor2
                link='link'
                name='line-color'
                showToolTip={false}
                color={lineColor}
                noFill={noFill}
                widthInPx={24}
                onColorChange={value => handleChangeStyle({ lineColor: value })}
              />
            </div>
          </div>}
          {listChecked3 && <div className='btnBox'>
            <div className='name'>粗细</div>
            <div className='num'>
              <LineSetting
                index={1}
                lines={DashLines}
                curLine={lineDash}
                onLineChange={(value) => handleChangeStyle({ lineDash: value })}
                lineSize={lineWidth}
                showToolTip={false}
                maxSize={10}
                name={'node'}
                minSize={0}
                onLineSizeChange={(value) => handleChangeStyle({ lineWidth: value })}
              />
            </div>
          </div>}
          {listChecked3 && <div className='btnBox'>
            <div className='name'>样式</div>
            <div className='num'>
              <LineSetting
                index={0}
                lines={DashLines}
                curLine={lineDash}
                onLineChange={(value) => handleChangeStyle({ lineDash: value })}
                lineSize={lineWidth}
                showToolTip={false}
                maxSize={10}
                minSize={0}
                onLineSizeChange={(value) => handleChangeStyle({ lineWidth: value })}
              />
            </div>
          </div>}
        </div>
      </div>
    </ToolsFrameStyle>
  )
}

export { ToolsFrame }

ToolsFrame.propTypes = {
  backgroundColor: PropTypes.string,
  onChangeChecked: PropTypes.func,
  listChecked2: PropTypes.bool,
  listChecked3: PropTypes.bool,
  handleChangeStyle: PropTypes.func,
  lineColor: PropTypes.string,
  lineDash: PropTypes.any,
  lineWidth: PropTypes.number,
  noFill: PropTypes.bool,
  edition_seven_seven: PropTypes.bool
}

/* eslint-disable no-sequences */
import Board from 'components/drawBoard/board'
import Canvas from 'components/drawBoard'
import Hacker from 'components/drawBoard/hacker'
import NodeGridSnap from 'components/drawBoard/nodeGridSnap'
import Rect from 'components/drawBoard/shapes/rect'
import Utils from 'components/drawBoard/utils'
import Point from 'components/drawBoard/shapes/point'
import events from 'components/drawBoard/events'
import Operation from 'components/drawBoard/operation'
import OperateLink, { Link } from 'components/drawBoard/link/operateLink'
import OperateNode, { VNode } from 'components/drawBoard/node/operateNode'
import OperateBoard from '../../../components/drawBoard/operateBoard'
import registerOperation from './registerOperation'
import Group from 'components/drawBoard/group'
import OperateGroup from 'components/drawBoard/group/operateGroup'
import Watermark from 'components/drawBoard/watermark'
import GridPage from 'components/drawBoard/gridPage'
import ImageData from 'components/drawBoard/imageData'
import _registerApi from './registerApi'

import Selector from 'components/drawBoard/selector'
import SelectNode from './selectNode'
import SelectBox from 'components/drawBoard/selectBox'
import Picture from 'components/drawBoard/picture'
import Helper from 'utils/helper'
import { getInitData } from './dataHelper'
import { getNode, getLink, getGroup } from '../../../utils/docTemplate'

import { LINETYPE } from 'components/drawBoard/link/constant'
import AnimateRAF from 'components/drawBoard/animate/animateRAF'
export default function install(containerId, menuId, { watermark, watermarkDisplay, backgroundImage, backgroundColor }) {
  return new Canvas({
    container: containerId,
    menuId: menuId,
    duration: 50, // 动画执行时间
    interval: 10, // 动画间隔时间
    props: {
      data: getInitData(),
      lineType: LINETYPE.FOLD, //  straight curve fold
      style: { // 默认配置
        watermarkDisplay: watermarkDisplay || false, // 水印是否显示
        watermark: watermark, // 水印
        backgroundImage: backgroundImage || 'None', // 背景图片
        backgroundColor: backgroundColor || '',
        box: {
          backgroundColor: '#fff',
          borderColor: '#2ebdff',
          lineWidth: 2
        },
        node: { // 根结点的连接线样式
          backgroundColor: '#fff',
          lineWidth: 2, spacing: 13, arcRadius: 6, font: '10px Open Sans'
        },
        link: { // 子节点的连接线样式
          lineWidth: 2,
          lineColor: '#000'
          // backgroundColor: 'rgba(84, 196, 94, 0.5)'
        }
      },
      point: {
        _offset: new Point(0, 0),
        _scale: new Point(2, 2),
        set: function(x, y) { // 重新设置偏移量
          this._offset.init(x, y)
          this.onOffsetChanged(x, y)
        },
        add: function(x, y) { // 添加整体偏移量 cb: 借用_offset累增，移动连接线的控制点
          this._offset.translate(x, y)
          this.onOffsetChanged(this._offset.x, this._offset.y)
        },
        scale: function(transform) {
          this._scale.init(transform.scale)
          this.onScaleChanged(transform)
        },
        eventdown: function() {
          this._conflict.init(0, 0)
        },
        eventmove: function(moveX, moveY) { // type: all 移动所有 single: 移动自己
          this.set(moveX, moveY)
        }
      },
      mouse: {
        type: 0,
        none: 0,
        left: 1,
        right: 2,
        center: 4,
        back: 8,
        front: 16,
        x: 0, // 鼠标点位置
        y: 0,
        boardX: 0, // 鼠标位置对应的画点
        boardY: 0,
        eventType: '', // 记录事件类型
        board: null, // 画板实例
        reset() {
          this.type = this.none
          this.eventType = ''
          return this
        },
        setEventType(value) {
          this.eventType = value
        },
        setBoard(b) {
          this.board = b
        },
        setBoardPosition(p) {
          const pos = this.board.getDrawCoordinate(p.x, p.y)
          this.boardX = pos.x
          this.boardY = pos.y
          return this
        },
        setPosition(p) {
          this.x = p.x, this.y = p.y
          return this
        }
      },
      transform: {
        max: 50,
        min: window.devicePixelRatio / 4,
        step: 0.2,
        offsetX: 0,
        offsetY: 0,
        scale: window.devicePixelRatio * 0.75
      },
      grid: { // 网格基础配置
        baseSize: 20
      },
      format: {
        markdown: {
          prefix: '- ',
          suffix: '\n',
          indent: 2,
          separate: ' ',
          regex: /\r\n(?= )/
        },
        xmind: {
          prefix: '',
          suffix: '\n',
          indent: 1,
          separate: '\t',
          regex: /\r\n(?=\t)/
        }
      },
      topic: function(depth) {
        return depth ? '子主题' + ' ' : '分支主题' + ' '
      }
    },
    components: { Selector, VNode, Operation, Hacker, OperateLink, Board, OperateNode, OperateBoard },
    methods: {
      init() {
        // 注入画布实例
        this.initFrame() // 初始化画布的框架
        this.initStyles() // 初始化整体的样式
        this.initCallbacks() // 添加自定义钩子函数
        this.registerApi() // 注册对外部组件提供的api
        this._scale(this.transform)
      },
      initFrame() { // 初始化框架
        this._garbage = []
        this._arrowKey = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']
        this._hotKey = ['Tab', 'Enter', ' ', 'Backspace', 'Delete', 'Alt', 'Escape']
        this._queue = [] // shift unshift
        this._stack = [] // push pop
        this.copiedNodes = []
        this.tempTime = '' // 用于保存执行某一个动作的时间
        this.mouse.setBoard(this)
        this.picture = new Picture(this)
        this.board = new Board(this) // 背景/水印/背景图片
        this.background = new Rect(this.$ctx, 0, 0, this.$width, this.$height) // 背景颜色，同步画布大小
        this.watermark = new Watermark(this.$ctx, 0, 0, this.$width, this.$height) // 水印
        this.gridPage = new GridPage(this.$gridCtx, this, { size: this.grid.baseSize }) // 页面画布(虚拟的)
        this.box = new SelectBox(this.$ctx, this, 0, 0) // 多选框
        this.imageData = new ImageData(this.$ctx, 0, 0)
        this.selector = new this.Selector(this.box) // 生成选择器
        this.selNode = new SelectNode(this, this.selector) // 添加选择节点工具箱
        this.hacker = new this.Hacker(this) // 添加操作层
        this.nodeGridSnap = new NodeGridSnap(this.$ctx, this)
        this.operation = new this.Operation(this)
        // this.history = new History(this)
        this.operateNode = new OperateNode(this.$ctx, this, this.data.nodes)
        this.operateLink = new OperateLink(this.$ctx, this, this.data.links)
        this.operateGroup = new OperateGroup(this.$ctx, this, this.data.groups)
        this.operateBoard = new OperateBoard(this)
        this._nextDraw = Utils.nextTick(this.draw)
      },
      initStyles: function() {
        this.board.initStyle() // 背景图片、颜色、水印初始化
        this.box.style.apply(this.style.box)
      },
      initCallbacks() {
        /* 选择器的触发回调 */
        this.selector.onNodeSelected = (node, needDraw = true) => {
          if (node.name === 'node') this.hacker.bind(node)
          needDraw && this.draw()
          this.$dispatch.handleChangeActiveObject(node)
        }
        this.selector.onClear = () => {
          if (this.hacker.isActivated) this.hacker.deactivate()
          this.draw()
          this.$dispatch.handleChangeActiveObject(null)
        }
        this.selector.onNodeUnselect = () => {
          console.log('取消选中节点的回调')
        }
        /* 操作层的触发回调 */
        this.hacker.callDraw = () => {
          this.draw()
        }
        this.hacker.onInput = (node) => {
          this.operateNode._setValueOperation(node, this.hacker.getValue())
          this.draw()
        }

        this.hacker.onCursorChange = (styleConfig) => {
          this.$dispatch.handleChangeActiveNodeStyle(styleConfig)
        }

        this.hacker.beforeActivate = (bind) => {
          if (this.selector.isMultiple()) this.selector.select(bind)
        }

        this.point.onOffsetChanged = (x, y) => {
          // console.log('开始同步node节点内容的位置========》〉》')
          this._offset(x, y)
        }

        this.point.onScaleChanged = (transform) => {
          // console.log('开始缩放画布========》〉》')
          this._scale(transform)
        }

        // 注册历史记录回调
        registerOperation(this)
      },
      _layout: function() {
        this.operateNode.apply()
        this.gridPage.apply()
        this.operateLink.apply()
        this.box.apply()
      },
      _offset: function(x, y) {
        this.operateNode.offset(x, y)
        this.operateNode.apply()
        this.gridPage.offset(x, y)
        this.gridPage.apply()
        this.operateLink.offset(x, y)
        this.operateLink.apply()
        this.box.offset(x, y)
        this.box.apply()
        this.operateGroup.apply(x, y)
        this.hacker.follow() // 输入层位置同步跟随
      },
      scaleBackground({ scale, offsetX, offsetY, min, max }) {
        const bg = this.background
        const wm = this.watermark
        max = Math.max(this.$dpr / min, max) // 清理区域的放大系数
        const sx = -Math.abs(offsetX) / scale // offsetX 相对于基坐标计算
        const sy = -Math.abs(offsetY) / scale
        bg.setStart(sx, sy)
        bg.setWidth(this.$width * max)
        bg.setHeight(this.$height * max)
        wm.scaleRect(sx, sy, this.$width * max, this.$height * max)
      },
      _scale: function(transform) {
        const { scale, offsetX, offsetY } = transform
        this.scaleBackground(transform)
        this.$ctx.setTransform(scale, 0, 0, scale, offsetX, offsetY)
        this.operateNode.onscale()
        this.operateLink.onscale()
        this.nodeGridSnap.onscale()
      },
      update: function(interval) {
        this.point.animation.update(interval)
      },
      clearCanvas: function() {
        const { sx, sy, width, height } = this.background
        this.$ctx.clearRect(sx, sy, width, height)
      },
      // isMove 如果是移动过程中重新绘制，概要定位暂时不需要动
      draw: function(needClear = true) {
        // 广度优先 匹配 _layout布局算法
        if (needClear) {
          this.clearCanvas()
        }
        this.operateNode.draw()
        this.operateLink.draw()
        this.operateGroup.draw()
        this.box.draw()
        this.nodeGridSnap.drawSnapLines()
      },
      _gcNode: function(data) { // 生成vnode
        return VNode.createNode(this.$ctx, this, data)
      },
      _gcLink: function(data) {
        return Link.createLink(this.$ctx, this, data)
      },
      _gcGroup: function(data) {
        return Group.createGroup(this.$ctx, this, data)
      }
    },
    register: {
      registerApi() { // 注册对外部组件提供的api
        this.api = _registerApi.bind(this)()
      },
      registerDispatch(dp = {}) { // 注册改变外边组件状态的方法
        this.$dispatch = dp
      },
      registerMenu({ hide, locate }) { // 注册画板右键菜单
        this.$menu = {
          hide, locate
        }
      }
    },
    functions: {
      getAllNodes() { // 获取所有的节点，包含各种类型
        const { nodes, links, groups } = this
        return { ...nodes, ...links, ...groups }
      },
      getDrawCoordinate(x, y) { // 鼠标点位置对应画板实际位置
        const o = Object.create(null)
        const { scale, offsetX, offsetY } = this.transform
        o.x = (x * this.$dpr - offsetX) / scale
        o.y = (y * this.$dpr - offsetY) / scale
        return o
      },
      getMousePoint(x, y) { // 画点对应的鼠标点
        const o = Object.create(null)
        const { scale, offsetX, offsetY } = this.transform
        o.x = (x * scale + offsetX) / this.$dpr
        o.y = (y * scale + offsetY) / this.$dpr
        return o
      },
      getDrawDistance(x, y) { // 鼠标移动的距离x,y 对应 画板上移动的距离
        const o = Object.create(null)
        const { scale } = this.transform
        o.x = x * this.$dpr / scale
        o.y = y * this.$dpr / scale
        return o
      },
      getDrawLength(len) { // 缩放后固定距离对应缩放前的距离
        const { scale } = this.transform
        return len / scale * this.$dpr
      },
      collectData() { // 用于收集数据
        const props = {
          data: { properties: {}, nodes: {}, links: {}, groups: {}}
        }
        // 保存node
        for (const id in this.nodes) {
          props.data.nodes[id] = getNode(this.nodes[id])
        }
        // 保存link
        for (const id in this.links) {
          props.data.links[id] = getLink(this.links[id])
        }
        // 保存group
        for (const id in this.groups) {
          props.data.groups[id] = getGroup(this.groups[id])
        }
        props.data.properties.transform = { ...this.transform }
        return props
      },
      // TODO 暂时仅做本地化存储，后续需要迁移
      save: function() { // 保存数据
        const props = this.collectData()
        Helper.localStorageSetItem('data', props)
        return props
        // TODO需要确定存储结构后完善
      },
      flatToTree(arr) { // 动态规划
        const map = {}
        let rootId
        for (const it of arr) {
          map[it.id] = { ...it, children: [] }
        }
        for (const it of arr) {
          (it.pid === 0 && (rootId = it.id)) || map[it.pid].children.push(map[it.id])
        }
        return map[rootId]
      },
      scaleAnimate: function() {
        return new AnimateRAF(
          function() {
            const t = this.transform
            const scale = t.currentScale / 20
            this.operateBoard.onscale(t.mouse, -1, scale)
          }.bind(this),
          400,
          21
        )
      }
    },
    events
  })
}

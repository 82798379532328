export const FontSize = [
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  14,
  18,
  24,
  30,
  36,
  48,
  60,
  72,
  96
]

export const AliBtnAttrs = [
  {
    icon: 'topLeft',
    toolTip: '顶部左对齐'
  },
  {
    icon: 'topCenter',
    toolTip: '顶部居中对齐'
  },
  {
    icon: 'topRight',
    toolTip: '顶部右对齐'
  },
  {
    icon: 'centerLeft',
    toolTip: '中部左对齐'
  },
  {
    icon: 'centerCenter',
    toolTip: '中部居中对齐'
  },
  {
    icon: 'centerRight',
    toolTip: '中部右对齐'
  },
  {
    icon: 'bottomLeft',
    toolTip: '底部左对齐'
  },
  {
    icon: 'bottomCenter',
    toolTip: '底部居中对齐'
  },
  {
    icon: 'bottomRight',
    toolTip: '底部右对齐'
  }
]
export const DefaultAlign = AliBtnAttrs[0].icon

export const DashLines = [
  {
    icon: 'line',
    value: []
  },
  {
    icon: 'dash-line-1',
    value: [4]
  },
  {
    icon: 'dash-line-2',
    value: [10, 4, 4, 4]
  },
  {
    icon: 'dash-line-3',
    value: [10, 4, 2, 4]
  }
]


import { cloneDeep } from 'utils/cloneDeep'
import Helper from 'utils/helper'

const InitData = {
  version: null,
  links: {},
  nodes: {
    'Q9PtJNlHvSHAiucgN4d94': { value: 'test1', x: 300, y: 100, id: 'Q9PtJNlHvSHAiucgN4d94', type: 'process', width: 80 * 2, height: 60 * 2 }
    // 'G21tkeA6-a4FPXk53Vh0M': { value: 'test2', x: 800, y: 200, id: 'G21tkeA6-a4FPXk53Vh0M', type: 'decision', width: 80 * 2, height: 80 * 2 }
  }
}

const LatestVersion = 1
const DataKey = 'data'
export function getInitData() {
  const res = cloneDeep(Helper.localStorageGetItem(DataKey))
  let { data = {}} = res || {}
  if (data.version !== LatestVersion) {
    console.log(`version changed, old is ${data.version}, new is ${LatestVersion}`)
    Helper.localStorageDelItem(DataKey)
    data = {}
  } else {
    console.log('version not changed')
  }

  return {
    ...InitData,
    ...data,
    version: LatestVersion
  }
}
